import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal as AntModal, Alert } from 'antd';

import { Button, Switch } from 'components/common';
import { usePost } from 'hooks';
import {
  toggleCanPushToSalesforce,
  toggleCanPushToHubspot,
  toggleUserManager,
} from 'services/api';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > div {
    margin-bottom: 10px;
  }
`;

const Roles = ({ activeUser, setModal, fetchUsers }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(
    activeUser.isUserManagementAdmin
  );
  const [canPushToSalesforce, setCanPushToSalesforce] = useState(
    activeUser.canPushToSalesforce
  );
  const [canPushToHubspot, setCanPushToHubspot] = useState(
    activeUser.canPushToHubspot
  );

  const [
    {
      res: toggleCanPushToSalesforceResult,
      loading: canPushToSalesforceLoading,
      error: toggleCanPushToSalesforceError,
    },
    toggleCanPushToSalesforceApiCall,
  ] = usePost({
    url: toggleCanPushToSalesforce,
  });

  const [
    {
      res: toggleCanPushToHubspotResult,
      loading: canPushToHubspotLoading,
      error: toggleCanPushToHubspotError,
    },
    toggleCanPushToHubspotApiCall,
  ] = usePost({
    url: toggleCanPushToHubspot,
  });

  const [{ res: userRes, loading: userLoading }, toggleUser] = usePost({
    url: toggleUserManager,
  });

  useEffect(() => {
    if (toggleCanPushToSalesforceResult) {
      setCanPushToSalesforce(s => !s);
    }
  }, [toggleCanPushToSalesforceResult]);

  useEffect(() => {
    if (toggleCanPushToHubspotResult) {
      setCanPushToHubspot(s => !s);
    }
  }, [toggleCanPushToHubspotResult]);

  useEffect(() => {
    if (userRes) {
      setIsUserAdmin(s => !s);
    }
  }, [userRes]);

  const handleCanPushToSalesforceToggle = () => {
    setHasChanges(true);
    toggleCanPushToSalesforceApiCall({
      newUserId: activeUser.userId,
      enabled: !canPushToSalesforce,
    });
  };

  const handleCanPushToHubspotToggle = () => {
    setHasChanges(true);
    toggleCanPushToHubspotApiCall({
      newUserId: activeUser.userId,
      enabled: !canPushToHubspot,
    });
  };

  const handleUserToggle = () => {
    setHasChanges(true);
    toggleUser({ newUserId: activeUser.userId, enabled: !isUserAdmin });
  };

  const handleClose = () => {
    if (hasChanges) {
      fetchUsers();
    }
    setModal();
  };

  return (
    <AntModal
      open
      title={`User Roles for ${activeUser.fullname}`}
      onCancel={handleClose}
      footer={
        <Button type='primary' onClick={handleClose}>
          Close
        </Button>
      }
    >
      <Content>
        <Switch
          disabled={userLoading}
          checked={isUserAdmin}
          onChange={handleUserToggle}
          label='User Manager'
        />
        <Alert
          message="Users with this permission will be able to create, disable, and enable users as well as modify the user's permissions"
          type='info'
        />
        <Switch
          disabled={canPushToSalesforceLoading}
          checked={canPushToSalesforce}
          onChange={handleCanPushToSalesforceToggle}
          label='Can Push To Salesforce'
        />
        {toggleCanPushToSalesforceError && (
          <Alert
            message='A push to Salesforce licence is not available. Contact support to purchase licences.'
            type='error'
          />
        )}
        <Alert
          message='Users with this permission can push SciLeads lists into Salesforce'
          type='info'
        />
        <Switch
          disabled={canPushToHubspotLoading}
          checked={canPushToHubspot}
          onChange={handleCanPushToHubspotToggle}
          label='Can Push To HubSpot'
        />
        {toggleCanPushToHubspotError && (
          <Alert
            message='A push to HubSpot licence is not available. Contact support to purchase licences.'
            type='error'
          />
        )}
        <Alert
          message='Users with this permission can push SciLeads lists into HubSpot'
          type='info'
        />
      </Content>
    </AntModal>
  );
};

export default Roles;
