import { Icon, Tooltip } from 'components/common';
import { colours } from 'utils/theme';

const Warning = ({ message }) => {
  return (
    <Tooltip title={message}>
      <div style={{ marginLeft: 5, fontSize: 11 }}>
        <Icon icon='error' size='2x' color={colours.orange} />
      </div>
    </Tooltip>
  );
};

export default Warning;
