window.sigma.canvas.labels.def = (() => (node, context, settings) => {
  var fontSize,
    prefix = settings('prefix') || '',
    size = node[prefix + 'size'];

  if (size < settings('labelThreshold')) return;

  if (!node.label || typeof node.label !== 'string') return;

  fontSize = 13;

  context.font =
    (settings('fontStyle') ? settings('fontStyle') + ' ' : '') +
    fontSize +
    'px Roboto';
  context.fillStyle =
    settings('labelColor') === 'node'
      ? node.color || settings('defaultNodeColor')
      : settings('defaultLabelColor');

  context.fillText(
    node.label,
    Math.round(node[prefix + 'x'] + size + 3),
    Math.round(node[prefix + 'y'] + fontSize / 3)
  );
})();

window.sigma.canvas.nodes.def = (() => (node, context, settings) => {
  const prefix = settings('prefix');
  context.save();
  context.beginPath();
  context.arc(
    node[prefix + 'x'],
    node[prefix + 'y'],
    node[prefix + 'size'],
    0,
    Math.PI * 2,
    true
  );
  context.fillStyle = node.color;
  context.fill();
  context.closePath();
})();

window.sigma.canvas.hovers.def = (() => (node, context, settings) => {
  var x,
    y,
    w,
    h,
    e,
    fontStyle = settings('hoverFontStyle') || settings('fontStyle'),
    prefix = settings('prefix') || '',
    size = node[prefix + 'size'],
    fontSize = 13;

  context.font = (fontStyle ? fontStyle + ' ' : '') + fontSize + 'px Roboto';

  context.beginPath();
  context.fillStyle = '#555';

  if (node.label && settings('labelHoverShadow')) {
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context.shadowBlur = 4;
    context.shadowColor = 'rgba(0,0,0,0.5)';
  }

  if (node.label && typeof node.label === 'string') {
    x = Math.round(node[prefix + 'x'] - fontSize / 2 - 2);
    y = Math.round(node[prefix + 'y'] - fontSize / 2 - 2);
    w = Math.round(
      context.measureText(node.label).width + fontSize / 2 + size + 7
    );
    h = Math.round(fontSize + 4);
    e = Math.round(fontSize / 2 + 2);

    context.moveTo(x, y + e);
    context.arcTo(x, y, x + e, y, e);
    context.lineTo(x + w, y);

    context.arcTo(x + w + e, y, x + w + e, y + e, e);
    context.arcTo(x + w + e, y + h, x + w, y + h, e);

    context.lineTo(x + e, y + h);
    context.arcTo(x, y + h, x, y + h - e, e);
    context.lineTo(x, y + e);

    context.closePath();
    context.fill();

    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context.shadowBlur = 0;
  }

  // Node border:
  if (settings('borderSize') > 0) {
    context.beginPath();
    context.fillStyle =
      settings('nodeBorderColor') === 'node'
        ? node.color || settings('defaultNodeColor')
        : settings('defaultNodeBorderColor');
    context.arc(
      node[prefix + 'x'],
      node[prefix + 'y'],
      size + settings('borderSize'),
      0,
      Math.PI * 2,
      true
    );
    context.closePath();
    context.fill();
  }

  // Node:
  var nodeRenderer =
    window.sigma.canvas.nodes[node.type] || window.sigma.canvas.nodes.def;
  nodeRenderer(node, context, settings);

  // Display the label:
  if (node.label && typeof node.label === 'string') {
    context.fillStyle = 'white';

    context.fillText(
      node.label,
      Math.round(node[prefix + 'x'] + size + 3),
      Math.round(node[prefix + 'y'] + fontSize / 3)
    );
  }
})();
