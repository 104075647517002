import styled from 'styled-components';
import { Statistic } from 'components/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPurchasingSearchRequest } from 'selectors/organisation';

const Stats = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-bottom: ${p => p.theme.gutter};
  > div {
    flex: 1;
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

const PurchasingStatistics = ({ organisation }) => {
  const purchasingItems = useSelector(
    getPurchasingSearchRequest(organisation.id)
  );
  return (
    <Stats>
      <Statistic
        icon='company'
        title='Total Purchasers'
        value={purchasingItems ? purchasingItems.sumPurchasers : '-'}
      />
      <Statistic
        icon='dollar'
        title='Total Amount'
        value={purchasingItems ? purchasingItems.totalSales : '-'}
      />
      <Statistic
        icon='fingerprint'
        title='Unique Lines'
        value={purchasingItems ? purchasingItems.uniqueLines : '-'}
      />
      <Statistic
        icon='dollar'
        title='Average Order Amount'
        value={purchasingItems ? purchasingItems.averageOrderAmounnt : '-'}
      />
    </Stats>
  );
};

export default PurchasingStatistics;
