import styled from 'styled-components';

const Container = styled.div`
  font-size: 13px;
  line-height: 1.3;
  padding-top: 10px;
  ul {
    padding-left: 25px;
    padding-top: 5px;
    margin-bottom: 0px;
  }
`;

const ListDescription = ({ description }) => {
  return (
    <Container>
      <p>
        <strong>Description:</strong> {description}
      </p>
    </Container>
  );
};

export default ListDescription;
