import styled from 'styled-components';

import React from 'react';

import { Popover } from 'antd';
import { Button } from 'components/common';

const CrmSection = styled.div`
  padding-top: 10px;
`;

const CrmPushDetailsContent = ({ crmPushDetails }) => {
  const rows = [];
  for (let i = 0; i < crmPushDetails.length; i++) {
    if (crmPushDetails[i].crmError) {
      rows.push(
        <CrmSection key={i}>
          <b>{crmPushDetails[i].crmType}</b>
          <pre>{crmPushDetails[i].crmError}</pre>
          Please search this error code with your CRM provider.
        </CrmSection>
      );
    } else {
      rows.push(
        <CrmSection key={i}>
          <a
            href={crmPushDetails[i].crmMemberUrl}
            target='_blank'
            rel='noreferrer'
          >
            <b>Open in {crmPushDetails[i].crmType}</b>
          </a>
        </CrmSection>
      );
    }
  }
  return rows;
};

const CRMStatus = ({ crmPushDetails }) => {
  const openLink = () => window.open(crmPushDetails[0].crmMemberUrl, '_blank');
  const anySuccessPush = crmPushDetails.findIndex(p => p.crmMemberUrl) >= 0;
  const moreThanOneCrm = crmPushDetails.length > 1;

  if (!crmPushDetails || crmPushDetails.length === 0) return null;

  if (anySuccessPush && !moreThanOneCrm) {
    return (
      <Button size='small' onClick={openLink}>
        CRM
      </Button>
    );
  }
  let buttonLabel = crmPushDetails[0].crmStatus;
  if (anySuccessPush) {
    buttonLabel = 'CRM';
  } else if (moreThanOneCrm) {
    buttonLabel = 'Push Failed';
  }
  return (
    <Popover
      placement='left'
      trigger='click'
      content={<CrmPushDetailsContent crmPushDetails={crmPushDetails} />}
    >
      <Button size='small' type={!anySuccessPush ? 'danger' : 'default'}>
        {buttonLabel}
      </Button>
    </Popover>
  );
};

export default CRMStatus;
