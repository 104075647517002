import styled from 'styled-components';
import { Statistic as AntStatistic } from 'antd';

import { Icon, Pane } from 'components/common';
import { siNumber } from 'utils/number';

const Content = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 10px;
  }

  ${p => p.theme.breakpoint.mobile} {
    .ant-statistic-title {
      display: none;
    }

    .ant-statistic-content {
      font-size: 18px;
    }
  }
`;

const Statistic = ({ icon, iconColor, ...rest }) => {
  return (
    <Pane>
      <Content>
        {icon ? (
          <AntStatistic
            {...rest}
            prefix={<Icon icon={icon} color={iconColor} size='lg' />}
            formatter={siNumber}
          />
        ) : (
          <AntStatistic {...rest} formatter={siNumber} />
        )}
      </Content>
    </Pane>
  );
};

Statistic.defaultProps = {
  iconColor: '#56B3C3',
};

export default Statistic;
