import { compare as dateCompare } from 'utils/date';
import { compare as stringCompare } from 'utils/string';
import { compare as booleanCompare } from 'utils/boolean';
import { headerKeys } from './constants';

// sort case insensitive:
// - name asc/desc then start date asc then location asc
// - start date asc/desc then location ascending then name asc
// - location asc/desc then date asc then name asc
const sort = (a, b, key, dir) => {
  const aValue = a[key];
  const bValue = b[key];
  switch (key) {
    case headerKeys.name: {
      const keyComparer =
        dir === 'asc'
          ? stringCompare(aValue, bValue)
          : stringCompare(bValue, aValue);
      return (
        keyComparer ||
        dateCompare(a.startDate, b.startDate, 'd') ||
        stringCompare(a.location, b.location)
      );
    }
    case headerKeys.startDate: {
      const keyComparer =
        dir === 'asc'
          ? dateCompare(aValue, bValue, 'd')
          : dateCompare(bValue, aValue, 'd');
      return (
        keyComparer ||
        stringCompare(a.location, b.location) ||
        stringCompare(a.name, b.name)
      );
    }
    case headerKeys.location: {
      const keyComparer =
        dir === 'asc'
          ? stringCompare(aValue, bValue)
          : stringCompare(bValue, aValue);
      return (
        keyComparer ||
        dateCompare(a.startDate, b.startDate, 'd') ||
        stringCompare(a.name, b.name)
      );
    }
    case headerKeys.isUserRegistered: {
      const keyComparer = dir === 'desc' ? aValue - bValue : bValue - aValue;
      return (
        keyComparer ||
        booleanCompare(a.availableToDownload, b.availableToDownload) ||
        dateCompare(a.startDate, b.startDate, 'd') ||
        stringCompare(a.name, b.name)
      );
    }
    default:
      return 0;
  }
};

export default sort;
