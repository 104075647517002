import styled from 'styled-components';

import { Detail as CommonDetail, Pane as CommonPane } from 'components/common';

export const DetailContainer = styled.div`
  display: flex;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const Detail = styled(CommonDetail)`
  margin-bottom: 10px;
  line-height: 1.4em;
  font-size: 14px;
`;

export const DetailColumn = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  margin-right: 15px;
  :last-child {
    margin-right: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${p => p.theme.gutter};
`;

export const Row = styled.div`
  display: flex;
  > div {
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const Pane = styled(CommonPane)`
  margin-bottom: ${p => p.theme.gutter};
  .content {
    font-size: 14px;
  }
`;

export const SearchDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
