import styled from 'styled-components';

import { Button, Table } from 'components/common';

export const Container = styled.div`
  margin-top: ${p => p.theme.gutter};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  width: 100%;
`;

export const ResultsContainer = styled.div`
  > div:last-child {
    border-bottom: 0px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
    align-items: flex-end;
    .exportOptions {
      margin-top: 5px;
    }
  }
  > * {
    margin-left: 5px;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding-top: 8px;
  > span {
    font-size: 13px;
  }
  > * {
    margin-right: 8px;
  }
`;

export const LoadMore = styled(Button)`
  margin-top: 10px;
`;

export const StyledTable = styled(Table)`
  margin-top: 8px;
  margin-left: 10px;
  padding-right: 20px;
  grid-template-columns: 50px repeat(
      ${p => (p.config?.headers?.length || 1) - 1},
      auto
    ) !important;
`;

export const TableContainer = styled.div`
  display: inline-block;
  width: 100% !important;
  margin-top: 15px;

  .cell {
    padding: 5px 15px !important;
  }
`;

export const RegionSelectorContainer = styled.div`
  > span {
    font-size: 17px;
  }
  > * {
    margin-right: 8px;
  }
`;

export const MapContainer = styled.div`
  height: 80vh;
`;
