import { Fragment } from 'react';

import { Link, Clamp } from 'components/common';
import { format } from 'utils/date';

export const tableConfig = {
  headers: [
    {
      label: 'Purchaser Name',
      key: 'PurchaserName',
      minWidth: 150,
      maxContentWidth: 250,
    },
    { label: 'Purchaser Location', key: 'PurchaserLocation', minWidth: 80 },
    {
      label: 'Supplier Name',
      key: 'SupplierName',
      minWidth: 120,
      maxContentWidth: 250,
    },
    { label: 'Item', key: 'Item', minWidth: 220, maxContentWidth: 350 },
    { label: 'Quantity', key: 'Quantity', minWidth: 70 },
    { label: 'Date', key: 'Date', minWidth: 100 },
    { label: 'Price (USD)', key: 'Price', minWidth: 100 },
  ],
  createRow: (r, i) => (
    <Fragment key={`${r.tree}-${i}`}>
      <div>{r.purchaserName}</div>
      <div>{r.purchaserLocation}</div>
      <div>{r.supplierName}</div>
      <div>
        <Link to={`/item/${r.sciLeadsIdentifier}`}>
          <Clamp text={r.item ? r.item : 'No data provided'} lines={1} />
        </Link>
      </div>
      <div>{r.quantity}</div>
      <div>{format(r.date)}</div>
      <div>{r.priceAmount != null ? r.priceAmount : 'Not Available'}</div>
    </Fragment>
  ),
};
