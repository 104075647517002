import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Detail, Radio, TokenInput, Tooltip } from 'components/common';

import { keywordTypes } from './config';
import DetailsTooltip from './DetailsTooltip';

const KeywordsOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  .keywords-options {
    display: flex;
    width: 100%;
    .ant-radio-button-wrapper:not(:last-child) {
      flex: 1;
    }
    .ant-radio-button-wrapper {
      padding: 0 8px;
    }
  }
`;

function Keywords({
  hasKeywords,
  value,
  onChange,
  keywordType,
  onKeywordTypeChange,
  style,
  loading,
}) {
  return (
    <div style={style}>
      <KeywordsOptionsContainer>
        {hasKeywords && (
          <Radio
            disabled={loading}
            value={keywordType}
            options={[
              {
                label: (
                  <Tooltip
                    title='This uses the keywords listed on your account (found under keyword management)'
                    placement='right'
                  >
                    Use Account keywords
                  </Tooltip>
                ),
                value: keywordTypes.default,
              },
              {
                label: (
                  <Tooltip
                    title='This allows you to enter your own keywords'
                    placement='right'
                  >
                    Use Custom keywords
                  </Tooltip>
                ),
                value: keywordTypes.custom,
              },
              {
                label: (
                  <Tooltip
                    title='This uses a combination of your Account keywords and Custom keywords'
                    placement='right'
                  >
                    Use Both keywords
                  </Tooltip>
                ),
                value: keywordTypes.custonAndDefault,
              },
            ]}
            optionType='button'
            onChange={value => onKeywordTypeChange(value)}
            containerStyle={{ width: '100%' }}
            style={{ fontWeight: 'normal' }}
            className='keywords-options'
            size='medium'
          />
        )}
      </KeywordsOptionsContainer>
      {keywordType !== keywordTypes.default && (
        <Detail
          label={
            <>
              keywords - match with platform data
              <DetailsTooltip title='Maximum 10 keywords and 255 characters' />
            </>
          }
          style={{
            marginTop: hasKeywords ? '12px' : 0,
            marginBottom: 0,
          }}
        >
          <TokenInput
            placeholder='Keywords'
            value={value}
            onChange={value => onChange(value)}
            tokenLimit={10}
            maxTotalCharactersLength={255}
            disabled={loading}
          />
        </Detail>
      )}
    </div>
  );
}

Keywords.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  hasKeywords: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default Keywords;
