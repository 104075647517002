import styled from 'styled-components';

import { Detail as CommonDetail, Pane as CommonPane } from 'components/common';

export const Container = styled.div`
  display: flex;
  margin-top: ${p => p.theme.gutter};
  flex-direction: column;
  .formula-hide {
    display: none;
  }
  .formula-na {
    display: block;
    font-weight: bold;
  }
`;

export const Column = styled.div`
  flex: 1;
  margin-right: ${p => p.theme.gutter};
  :last-child {
    margin-right: 0px;
  }
  > div {
    margin-bottom: ${p => p.theme.gutter};
  }
`;

export const LeftColumn = styled(Column)`
  flex: 0 1 65%;
  ${p => p.theme.breakpoint.tablet} {
    flex: 1;
  }
`;

export const RightColumn = styled(Column)`
  flex: 0 1 35%;
  ${p => p.theme.breakpoint.tablet} {
    flex: 1;
  }
`;

export const StatisticRow = styled.div`
  display: flex;
  > div {
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

export const Detail = styled(CommonDetail)`
  margin-bottom: 10px;
  line-height: 1.4em;
  font-size: 14px;
`;

export const SubJournal = styled.div`
  font-size: 11px;
  text-transform: uppercase;
`;

export const Keyword = styled.div`
  display: inline-block;
  background: ${p => p.theme.teal};
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  color: white;
`;

export const Pane = styled(CommonPane)`
  .content {
    font-size: 14px;
  }
`;

export const TitleDiv = styled.div`
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* > div {
    margin-left: 5px;
  } */
`;

export const FullText = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  label {
    font-weight: 500;
    color: ${p => p.theme.black};
  }
  title,
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 15px;
  }
  h3 {
    font-size: 14px;
  }
  table {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const Footer = styled.div``;

export const PrePrintTag = styled.div`
  background: white;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
  color: ${p => p.theme.teal};
  border-color: ${p => p.theme.teal};
  border-style: solid;
  border-width: 1px;
  width: 75px;
  text-align: center;
`;
