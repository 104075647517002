import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { usePost } from 'hooks';
import { getItem } from 'services/api';
import { format } from 'utils/date';
import { Loader } from 'components/common';
import { formatEmail } from 'components/common/Researcher/helpers';
import { setupExternalSearch } from 'actions/purchasing';

import {
  Container,
  Pane,
  Row,
  DetailContainer,
  DetailColumn,
  Detail,
} from './styled';

const Address = ({ address }) => {
  const { lineOne, lineTwo, lineThree, lineFour } = address;
  if (!lineOne) return '-';
  const addressArray = [<div key='address-line-1'>{lineOne}</div>];
  if (lineTwo) addressArray.push(<div key='address-line-2'>{lineTwo}</div>);
  if (lineThree) addressArray.push(<div key='address-line-3'>{lineThree}</div>);
  if (lineFour) addressArray.push(<div key='address-line-4'>{lineFour}</div>);
  return addressArray;
};

const Item = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [{ res: item, loading }] = usePost({ url: getItem(id), onMount: true });
  const { push } = useHistory();
  const emptyLink = null;

  useEffect(() => {
    if (item && item.item) {
      const titleAsArray = item.item.split(' ');
      const firstThree = titleAsArray.slice(0, 3);
      const title =
        titleAsArray.length > 3 ? `${firstThree.join(' ')}...` : item.item;
      document.title = `${title} | SciLeads`;
    }
  }, [item]);

  if (loading)
    return (
      <Container>
        <Row>
          <Pane>
            <Loader />
          </Pane>
        </Row>
        <Row>
          <Pane>
            <Loader />
          </Pane>
          <Pane>
            <Loader />
          </Pane>
          <Pane>
            <Loader />
          </Pane>
          <Pane>
            <Loader />
          </Pane>
        </Row>
      </Container>
    );

  const setupSearch = () => {
    const { poNumber, purchaserName } = item;
    if (!poNumber) return;

    const filters = {
      term: `PoNumber:"${poNumber}"`,
    };
    if (purchaserName) {
      filters.purchaserName = [purchaserName];
    }
    dispatch(setupExternalSearch(filters));
    push('/purchasing');
  };

  return (
    <Container>
      <Row>
        <Pane title='Item Details'>
          <Detail label='Description'>
            {item.item ? item.item : 'No data provided'}
          </Detail>
          <DetailContainer>
            <DetailColumn>
              <Detail label='Date Added'>{format(item.dateAdded)}</Detail>
              <div style={{ display: 'flex' }}>
                <Detail label='Price (USD)' style={{ marginRight: '20%' }}>
                  {item.priceAmount != null
                    ? item.priceAmount
                    : 'The Buyer did not release the price for this Purchase Order but we include the basic data so you know a transaction has occurred.'}
                </Detail>
                {item.originalCurrencyCode !== 'USD' && item.priceAmount ? (
                  <Detail
                    label={`Original Price (${item.originalCurrencyCode})`}
                  >
                    {item.originalPrice
                      ? Number(item.originalPrice).toFixed(2)
                      : item.originalPrice}
                  </Detail>
                ) : (
                  <></>
                )}
              </div>
              <Detail label='Quantity'>{item.quantity}</Detail>
              <Detail label='Date Purchased'>{format(item.date)}</Detail>
            </DetailColumn>
            <DetailColumn>
              <Detail label='PO Number'>
                <a
                  href={emptyLink}
                  rel='noopener noreferrer'
                  onClick={setupSearch}
                >
                  {item.poNumber}
                </a>
              </Detail>
              <Detail label='PO Line'>{item.poLine}</Detail>
              <Detail label='Line Number'>{item.lineNumber}</Detail>
              <div style={{ display: 'flex' }}>
                <Detail label='Line Price (USD)' style={{ marginRight: '20%' }}>
                  {item.linePrice
                    ? '$' + Number(item.linePrice).toFixed(2)
                    : item.linePrice}
                </Detail>
                {item.originalCurrencyCode !== 'USD' ? (
                  <Detail
                    label={`Original Line Price (${item.originalCurrencyCode})`}
                  >
                    {item.originalLinePrice
                      ? Number(item.originalLinePrice).toFixed(2)
                      : item.originalLinePrice}
                  </Detail>
                ) : (
                  <></>
                )}
              </div>
            </DetailColumn>
            <DetailColumn>
              <Detail label='Commodity Code'>{item.commoditycode}</Detail>
              <Detail label='Catalogue Number'>{item.catalogueNumber}</Detail>
              <Detail label='UOM'>{item.uom}</Detail>
              <Detail label='Type'>{item.category}</Detail>
            </DetailColumn>
          </DetailContainer>
        </Pane>
      </Row>
      <Row>
        <Pane title='Purchaser Details'>
          <Detail label='Purchaser Name'>{item.purchaserName}</Detail>
          <Detail label='Purchaser Campus'>{item.purchaserCampus}</Detail>
          <Detail label='Purchaser Country'>{item.purchaserCountry}</Detail>
          <Detail label='Purchaser State'>{item.purchaserState}</Detail>
          <Detail label='Purchaser Zip'>{item.purchaserZip}</Detail>
        </Pane>
        <Pane title='Buyer Details'>
          <Detail label='Buyer First Name'>{item.buyerFirstName}</Detail>
          <Detail label='Buyer Last Name'>{item.buyerLastName}</Detail>
          <Detail label='Buyer Email'>{formatEmail(item.buyerEmail)}</Detail>
          <Detail label='Buyer Phone'>{item.buyerPhone}</Detail>
        </Pane>
        <Pane title='Delivery Details'>
          <Detail label='Delivery Address'>
            <Address
              address={{
                lineOne: item.deliveryAddressLineOne,
                lineTwo: item.deliveryAddressLineTwo,
                lineThree: item.deliveryAddressLineThree,
                lineFour: item.deliveryAddressLineFour,
              }}
            />
          </Detail>
          <Detail label='Delivery City'>{item.deliveryAddressCity}</Detail>
          <Detail label='Delivery State'>{item.deliveryAddressState}</Detail>
          <Detail label='Delivery Zip'>{item.deliveryAddressZip}</Detail>
          <Detail label='Delivery Country'>
            {item.deliveryAddressCountry}
          </Detail>
        </Pane>
        <Pane title='Supplier Details'>
          <Detail label='Supplier Name'>{item.supplierName}</Detail>
          <Detail label='Supplier Phone'>{item.supplierPhone}</Detail>
          <Detail label='Supplier Address'>
            <Address
              address={{
                lineOne: item.supplierAddressLineOne,
                lineTwo: item.supplierAddressLineTwo,
                lineThree: item.supplierAddressLineThree,
                lineFour: item.supplierAddressLineFour,
              }}
            />
          </Detail>
          <Detail label='Supplier City'>{item.supplierCity}</Detail>
          <Detail label='Supplier Zip'>{item.supplierZip}</Detail>
        </Pane>
      </Row>
    </Container>
  );
};

export default Item;
