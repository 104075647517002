import { useEffect, useRef } from 'react';
import { notification } from 'antd';

import { useGet, usePost, usePut } from 'hooks';
import { netPromoterScore, netPromoterScorePrompt } from 'services/api';

import Content from './Content';

const statusEnums = {
  NO_RESPONSE: 1,
  RESPONDED: 2,
  DECLINED: 3,
};

const NOTIFICATION_KEY = 'npsPrompt';

const NetPromoterScore = ({ onDismiss }) => {
  const promptRef = useRef();
  const npsUpdateRef = useRef();
  const npsSubmittedScore = useRef();

  const [{ res: canShowNps }] = useGet({
    url: netPromoterScorePrompt,
    onMount: true,
  });
  const [{ res: npsPrompt }, postNps] = usePost({
    url: netPromoterScore,
  });
  const [{ res: npsUpdate }, putNps] = usePut({
    url: netPromoterScore,
  });

  useEffect(() => {
    const handleNpsScore = npsScore => {
      npsSubmittedScore.current = npsScore;
      putNps({
        id: promptRef.current.id,
        statusId: statusEnums.RESPONDED,
        score: npsScore,
      });
    };

    const handleNpsFeedback = npsFeedback => {
      putNps({
        id: promptRef.current.id,
        statusId: statusEnums.RESPONDED,
        feedbackmessage: npsFeedback,
        score: npsSubmittedScore.current,
      });
      setTimeout(() => {
        notification.close(NOTIFICATION_KEY);
      }, 2000);
    };

    const handleNpsDismiss = () => {
      if (npsUpdateRef.current) {
        return;
      }
      putNps({
        id: promptRef.current.id,
        statusId: statusEnums.DECLINED,
      });
    };

    const handleFeedbackDismiss = () => {
      notification.close(NOTIFICATION_KEY);
      setTimeout(() => {
        onDismiss();
      }, 2000);
    };

    if (canShowNps && !npsPrompt) {
      notification.open({
        placement: 'topRight',
        duration: 0,
        message: (
          <span style={{ fontSize: 15, fontWeight: 500 }}>
            How likely are you to recommend SciLeads?
          </span>
        ),
        description: (
          <Content
            onNpsScore={handleNpsScore}
            onFeedbackMessage={handleNpsFeedback}
            onFeedbackDismiss={handleFeedbackDismiss}
          />
        ),
        key: NOTIFICATION_KEY,
        onClose: handleNpsDismiss,
      });
      postNps();
    }
  }, [canShowNps, npsPrompt, postNps, putNps, promptRef, onDismiss]);

  useEffect(() => {
    let npsTimeout;

    if (npsUpdate) {
      npsTimeout = setTimeout(() => onDismiss(), 2000);
    }

    return () => clearTimeout(npsTimeout);
  }, [npsUpdate, onDismiss]);

  useEffect(() => {
    promptRef.current = npsPrompt;
  }, [npsPrompt]);

  useEffect(() => {
    npsUpdateRef.current = npsUpdate;
  }, [npsUpdate]);

  return null;
};

export default NetPromoterScore;
