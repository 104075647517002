import styled from 'styled-components';

export const AddUserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  > * {
    margin-left: 5px;
  }
  > div:first-of-type {
    flex: 1;
    margin-left: 0px;
  }
`;
