import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Tooltip } from 'components/common';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  button: hover svg,
  button: focus svg,
  button: active svg {
    color: #fff;
  }
  ,
  button: disabled svg {
    color: rgba(0, 0, 0, 0.35);
  }
`;

function Footer({ onCancel, onExport, disabled, tooltip }) {
  return (
    <Container>
      <Button size='middle' onClick={onCancel}>
        Cancel
      </Button>
      <Tooltip title={!disabled ? tooltip : ''}>
        <Button
          size='middle'
          type='primary'
          onClick={onExport}
          disabled={disabled}
          icon={
            <Icon size='sm' icon='download' style={{ marginRight: '6px' }} />
          }
        >
          Export
        </Button>
      </Tooltip>
    </Container>
  );
}

Footer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default Footer;
