import { Icon } from 'components/common';
import { ActionButton } from 'components/ExportHistory/styled';

const ViewSummary = ({ disabled, selectedCRM, setSelectedCRM }) => {
  const openViewSummaryModal = () => {
    setSelectedCRM(selectedCRM);
  };

  return (
    <ActionButton
      disabled={disabled}
      type='primary'
      size='small'
      onClick={openViewSummaryModal}
    >
      <div>
        {'Summary '}
        <Icon icon='list' size='sm' />
      </div>
    </ActionButton>
  );
};

export default ViewSummary;
