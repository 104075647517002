import PropTypes from 'prop-types';

const Logo = ({ width = 214, height = 40 }) => {
  return (
    <svg
      version='1.1'
      id='logo'
      width={width}
      height={height}
      viewBox='0 0 666.66669 116.42667'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs id='defs6' />
      <g id='g8' transform='matrix(1.3333333,0,0,-1.3333333,0,116.42667)'>
        <g id='g10' transform='scale(0.1)'>
          <path
            d='m 4051.81,115.066 c 10.2,0.571 18.24,0.832 26.24,1.532 4.98,0.433 9.93,1.394 14.85,2.343 57.03,11 102.36,40.032 134.65,88.485 25.97,38.965 34.79,82.121 27.13,128.051 -8.24,49.398 -34.29,88.593 -73.82,118.617 -70.21,53.316 -164.68,55.269 -235.29,12.215 -54.4,-33.176 -86.83,-81.379 -93.31,-145.223 -4.43,-43.648 7.27,-83.852 32.75,-119.465 41.32,-57.754 98.96,-84.312 166.8,-86.555 z m 204.42,434.231 c 1.67,4.746 0.76,10.219 0.76,15.5 0.08,80.461 0.03,160.922 0.1,241.383 0.01,6.008 0.43,12.039 1.05,18.019 1.73,16.602 10.22,29.184 23.84,38.371 25.38,17.129 63.82,13.164 84.18,-9.742 8,-9 12.74,-19.195 13.6,-31.148 0.36,-5.008 0.38,-10.047 0.38,-15.075 0.01,-165.449 -0.04,-330.898 0.06,-496.343 0.02,-32.907 -3.73,-65.192 -13.94,-96.578 -12.64,-38.856 -33.13,-73.149 -60.71,-103.168 C 4264.98,66.3516 4216.91,33.5703 4159.34,15.5547 4127.96,5.73438 4095.84,0.847656 4062.9,0.421875 4028.52,-0.0234375 3994.64,2.8125 3961.38,11.8672 c -56.14,15.2773 -104.16,44.3516 -145.22,85.2812 -21.49,21.4256 -39.88,45.1836 -54.29,71.9806 -21.11,39.262 -31.64,81.301 -33,125.699 -1.1,35.957 3.05,71.231 14.7,105.445 14.53,42.668 38.39,79.438 69.91,111.454 45.57,46.296 99.46,77.89 163.2,91.714 39.11,8.485 78.63,9.875 118.44,5.758 56.21,-5.812 107.31,-25.019 153.52,-57.429 1.64,-1.149 3.39,-2.149 5.15,-3.098 0.32,-0.172 0.94,0.219 2.44,0.625'
            style={{
              fill: '#2d353d',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path12'
          />
          <path
            d='m 2761.29,364.434 c 0.84,2.078 0.22,3.906 -0.51,5.828 -11.08,29.367 -28.8,54.32 -52.76,74.195 -60.22,49.953 -128.52,64.07 -203.47,41.731 -43.63,-13 -79.57,-38 -106.33,-75.36 -9.13,-12.746 -16.27,-26.555 -21.47,-41.344 -1.69,-4.812 -0.67,-6.082 4.79,-6.316 2.01,-0.086 4.02,-0.047 6.04,-0.047 121.2,0 242.4,-0.004 363.6,0.043 3.4,0 7,-0.84 10.11,1.27 z M 2375.47,248.82 c 1.5,-7.882 3.86,-13.336 6.39,-18.734 17.18,-36.621 43.36,-65.234 78.39,-85.133 61.44,-34.902 125.47,-39.144 191.35,-13.691 16.03,6.191 30.52,15.312 44.02,25.91 9.67,7.601 20.29,12.633 32.46,14.969 13.4,2.574 26.19,0.722 38.27,-4.727 42.04,-18.976 42.07,-72.8437 12.17,-96.0976 C 2752.96,51.4453 2725.66,34.6992 2695.47,22.9648 2656.66,7.88672 2616.44,0.722656 2574.73,0.371094 2535.71,0.046875 2497.39,3.96094 2460.42,16.6367 c -84.68,29.0391 -148.54,83.0156 -189.08,162.9263 -25.2,49.664 -32.05,103.171 -26.46,158.39 8.43,83.289 49.06,148.555 112.75,200.719 44.07,36.105 94.28,59.23 150.65,67.898 77.6,11.93 152.44,3.668 222.1,-34.691 57.2,-31.5 102.93,-75.063 133.35,-133.465 21.54,-41.355 30.67,-85.687 31.13,-132.195 0.34,-35.133 -30.53,-57.035 -56.2,-57.364 -4.53,-0.058 -9.05,-0.035 -13.57,-0.035 -143.77,0 -287.53,0 -431.29,0 -5.51,0 -11.02,0 -18.33,0'
            style={{
              fill: '#2d353d',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path14'
          />
          <path
            d='m 3310.29,115.188 c 7.64,0.402 14.66,0.757 21.69,1.14 2.5,0.137 5.04,0.149 7.51,0.527 54.91,8.415 100.84,32.946 135.51,76.817 26.07,32.988 39.82,70.719 39.63,112.945 -0.19,42.793 -14.75,80.699 -41.58,113.778 -60.94,75.132 -164.93,95.191 -248.54,57.351 -41.14,-18.617 -73.14,-47.394 -94.42,-87.461 -34.72,-65.402 -26.94,-142.496 21.03,-200.398 41.32,-49.871 95.56,-73.184 159.17,-74.699 z m 202.39,-51.1997 c -4.53,-0.9766 -7.76,-4.3008 -11.42,-6.7461 C 3458.84,28.8516 3412.58,9.91016 3361.89,3.58984 3268.35,-8.07031 3181.7,9.32813 3106.01,68.4336 c -43.09,33.6484 -77.44,74.4374 -98.63,125.4104 -14.66,35.258 -21.38,72.168 -21.57,110.222 -0.23,44.856 8.54,87.84 28.22,128.372 11.94,24.582 27.15,46.984 45.44,67.23 36.99,40.934 80.47,72.562 132.62,91.57 37.68,13.735 76.61,19.825 116.68,20.164 49.45,0.418 97.28,-7.531 142.14,-28.449 74.14,-34.562 130.72,-87.504 164.09,-163.164 14.97,-33.949 22.11,-69.836 22.14,-106.996 0.07,-84.465 -0.48,-168.934 0.35,-253.3867 0.22,-22.3399 -14.21,-47.8086 -40.26,-55.79692 -21.17,-6.4961 -41.83,-4.69922 -60.12,9.29292 -13,9.9415 -20.76,22.961 -22.31,39.4922 -0.36,3.7735 1.2,7.9532 -2.12,11.5938'
            style={{
              fill: '#2d353d',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path16'
          />
          <path
            d='M 254.305,0.828125 C 178.051,1.59766 110.523,25.7969 52.8086,76.3008 36.8203,90.2891 23.2539,106.391 10.8516,123.563 c -22.5938,31.273 -8.14066,73.753 27.0312,87.07 16.168,6.121 32.5235,5.992 48.7266,0.297 10.7265,-3.77 19.1636,-10.813 25.9066,-19.953 4.472,-6.067 8.781,-12.297 13.722,-17.969 27.688,-31.77 62.942,-48.949 104.477,-54.481 44.039,-5.859 84.027,4.321 119.683,30.227 39.34,28.582 61.11,67.695 63.504,116.332 1.782,36.148 -10.422,68.391 -33.754,95.918 -27.812,32.812 -63.597,51.863 -106.152,57.629 -20.926,2.832 -41.965,4.836 -62.144,11.453 -67.247,22.055 -118.145,63.293 -148.7504,127.891 -16.5664,34.964 -21.5235,71.961 -17.7735,110.285 5.7266,58.551 32.7266,105.785 76.4649,144.195 39.008,34.254 84.195,53.938 135.941,58.992 68.231,6.668 129.508,-10.422 183.489,-52.804 19.511,-15.321 36.058,-33.497 49.953,-54.082 10.187,-15.098 12.82,-31.551 8.297,-48.961 -6.371,-24.508 -28.993,-41.657 -55.801,-43.098 -18.039,-0.973 -33.844,4.281 -46.539,17.25 -6.664,6.805 -13.219,13.918 -18.598,21.73 -14.758,21.45 -35.027,34.008 -60.031,39.903 -44.551,10.5 -84.055,0.918 -116.891,-31.035 -49.422,-48.098 -40.711,-114.922 5.649,-155.485 20.801,-18.199 45.433,-26.937 72.879,-29.117 62.156,-4.934 116.73,-28.531 164.089,-68.746 37.172,-31.563 64.254,-70.32 80.704,-116.375 10.988,-30.781 14.617,-62.598 12.836,-95.024 C 535.617,216.434 526.059,179.23 506.836,144.723 494.492,122.566 478.98,102.949 460.902,85.2617 423.098,48.2734 379.02,22.3086 327.594,9.24609 303.582,3.14844 279.199,0.179688 254.305,0.828125'
            style={{
              fill: '#ed5520',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path18'
          />
          <path
            d='M 4742.49,0.324219 C 4636.7,0.316406 4555.94,36.2227 4489.19,104.773 c -17.22,17.676 -21.09,38.77 -12.52,61.266 8.26,21.695 25.69,32.66 48.48,35.363 17.35,2.063 32.87,-1.828 46.12,-13.746 7.1,-6.383 14.77,-12.136 21.79,-18.593 42.25,-38.786 92.57,-54.969 149.2,-53.629 20.7,0.488 41.01,3.586 60.75,9.933 22.8,7.336 43.15,18.985 60.89,35.113 5.29,4.801 9.65,10.137 11.37,17.36 3.32,13.871 -1.48,23.883 -14.9,28.703 -11.81,4.242 -23.87,7.773 -35.86,11.477 -56.6,17.484 -113.22,34.906 -169.83,52.375 -19.2,5.921 -38.38,11.871 -57.55,17.871 -19.7,6.168 -38.82,13.832 -56.83,23.922 -37.77,21.167 -61.32,53.144 -68.77,95.746 -5.73,32.796 0.17,64.062 18.41,92.418 13.77,21.406 31.46,39.097 52.22,53.746 28.63,20.203 60.26,33.867 93.83,43.57 42.02,12.148 84.92,16.141 128.29,12.812 66.4,-5.089 125.91,-28.652 178.25,-70.003 13.83,-10.922 26.48,-23.102 39.13,-35.36 25.49,-24.695 20.86,-76.64 -23.18,-91.832 -21.29,-7.34 -41.64,-5.187 -59.51,9.86 -7.69,6.472 -15.5,12.82 -22.89,19.617 -43.12,39.644 -94.56,55.613 -152.25,53.273 -30.44,-1.23 -59.76,-7.461 -86.83,-22.226 -11.99,-6.536 -22.97,-14.418 -33.05,-23.59 -8,-7.281 -11.18,-16.395 -10.52,-27.078 0.45,-7.153 3.82,-12.485 9.96,-15.825 3.49,-1.902 7.36,-3.195 11.18,-4.375 85.39,-26.339 170.81,-52.574 256.18,-78.961 19.23,-5.945 37.91,-13.429 55.6,-23.035 43.87,-23.816 68.95,-60.48 73.17,-110.449 2.49,-29.473 -4.68,-56.863 -21.26,-81.555 -16.53,-24.5972 -37.98,-44.0035 -62.95,-59.7652 -32.7,-20.6485 -68.39,-33.5781 -106.05,-41.48439 -25.19,-5.28907 -50.62,-7.957035 -66.77,-7.367191'
            style={{
              fill: '#2d353d',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path20'
          />
          <path
            d='m 1543.92,437.719 c 0,120.66 0,241.32 0,361.98 0,4.528 0.11,9.055 0,13.574 -0.86,35.364 31.76,63.106 68.56,59.59 14.34,-1.367 27.12,-6.695 37.84,-16.851 11.56,-10.957 17.7,-24.176 18.4,-39.977 0.2,-4.515 0.05,-9.047 0.05,-13.57 0,-221.715 0,-443.43 0.01,-665.145 0,-5.523 0.09,-11.058 0.45,-16.566 0.13,-2.043 1.58,-3.637 3.92,-3.707 5.02,-0.149 10.04,-0.297 15.06,-0.297 133.73,-0.016 267.46,-0.008 401.2,-0.023 5.52,0 11.08,0.168 16.58,-0.278 29.36,-2.394 53.23,-26.6443 54.73,-54.8279 1.88,-35.5078 -28.38,-58.87891 -53.13,-60.792975 -4,-0.308594 -8.03,-0.269531 -12.05,-0.269531 -162.39,-0.011719 -324.78,-0.007813 -487.17,-0.019531 -7.05,-0.003907 -14.08,-0.015625 -20.96,1.878907 -24.82,6.84375 -41.88,27.86333 -43.41,53.71483 -0.27,4.5078 -0.07,9.0469 -0.07,13.5703 -0.01,122.6719 -0.01,245.3439 -0.01,368.0159'
            style={{
              fill: '#2d353d',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path22'
          />
          <path
            d='M 940.844,1 C 894.848,-0.230469 848.094,8.40625 804.098,28.8125 729.285,63.5078 672.852,117.203 639.422,193.441 c -12.633,28.809 -18.938,59.235 -21.039,90.618 -3.535,52.742 4.902,103.179 28.387,150.789 10.957,22.211 24.714,42.609 41.066,61.195 52.289,59.453 116.496,98.316 195.297,110.633 67.406,10.535 133.317,5.035 196.537,-22.399 26.92,-11.679 51.52,-27.168 74.26,-45.699 14.99,-12.211 23.18,-27.601 22.37,-47.148 -1.27,-30.477 -24.27,-53.235 -55.35,-55.117 -16.68,-1.012 -31.84,3.062 -45.38,13.07 -6.86,5.078 -13.84,10.035 -20.99,14.695 -35.18,22.918 -73.935,33.727 -115.764,32.492 -62.527,-1.851 -115.441,-25.437 -156.179,-73.332 -57.864,-68.031 -57.821,-165.925 -0.211,-234.246 37.922,-44.972 87.066,-68.605 145.469,-72.965 52.816,-3.941 100.555,9.899 142.465,42.59 19.31,15.059 40.83,19.203 63.95,12.371 41.4,-12.242 54.24,-62.168 24.03,-93.0817 C 1145.62,64.8906 1130.2,55.1797 1114.64,45.9141 1061.88,14.5156 1004.66,-0.03125 940.844,1'
            style={{
              fill: '#ed5520',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path24'
          />
          <path
            d='m 1301.47,307.27 c 0,80.957 -0.01,161.91 0.03,242.867 0,6.508 -0.28,12.992 1.81,19.406 6.6,20.223 20.05,33.574 40.36,39.211 28.54,7.918 52.86,-1.883 67.74,-20.871 7.34,-9.371 11.65,-19.895 11.57,-31.981 -0.02,-4.023 0.03,-8.047 0.03,-12.066 0,-158.895 0,-317.789 0,-476.6837 0,-3.0156 -0.1,-6.0351 0.01,-9.0507 1.2,-33.836 -31.6,-57.4766 -56.52,-57.234412 -13.35,0.128906 -26.09,1.296872 -37.56,8.695312 -17.72,11.4336 -27.56,27.1641 -27.52,48.8086 0.13,82.9649 0.05,165.9339 0.05,248.8989'
            style={{
              fill: '#ed5520',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path26'
          />
          <path
            d='m 1359.2,786.594 c 28.93,0.136 49.59,-10.324 64.19,-31.852 16.99,-25.058 13.4,-58.277 -7.45,-80.304 -29.16,-30.793 -74.75,-25.922 -97.77,-8.993 -40.83,30.035 -37.19,89.785 10.46,113.946 10.46,5.308 21.67,7.746 30.57,7.203'
            style={{
              fill: '#ed5520',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id='path28'
          />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Logo;
