import { useState, useEffect } from 'react';

import { Button, Loader, Icon } from 'components/common';
import { colours } from 'utils/theme';

import LargeNetworkWarning from './LargeNetworkWarning';
import { Container, ZoomButtons, NetworkDiagramContainer } from './styled';
import './customiseSigma';

const LARGE_NETWORK_SIZE = 1000;

const getTimeout = nodes => {
  const maxTimeoutDuration = 5000;
  const tidyTimeoutDuration = nodes * 20;

  return tidyTimeoutDuration > maxTimeoutDuration
    ? maxTimeoutDuration
    : tidyTimeoutDuration;
};

const ZoomControls = ({ fullscreen }) => {
  const zoomIn = () => {
    const c = window.sigma.instances()[fullscreen ? 1 : 0].camera;
    window.sigma.misc.animation.camera(
      c,
      { ratio: c.ratio / c.settings('zoomingRatio') },
      { duration: 200 }
    );
  };

  const zoomOut = () => {
    const c = window.sigma.instances()[fullscreen ? 1 : 0].camera;
    window.sigma.misc.animation.camera(
      c,
      { ratio: c.ratio * c.settings('zoomingRatio') },
      { duration: 200 }
    );
  };

  return (
    <ZoomButtons>
      <Button onClick={zoomIn} size='small'>
        <Icon icon='plus' size='xs' color={colours.darkGrey} />
      </Button>
      <Button onClick={zoomOut} size='small'>
        <Icon icon='minus' size='xs' color={colours.darkGrey} />
      </Button>
    </ZoomButtons>
  );
};

const NetworkDiagram = ({
  loading,
  network,
  fullscreen,
  onExportNetwork,
  exportingNetwork,
}) => {
  const [drawing, setDrawing] = useState(true);

  const handleOverNode = ({ data: { node } }, graphInstance) => {
    const { sigma } = window;
    const nodeHoverRenderers = sigma.canvas.hovers;
    const edgeHoverRenderers = sigma.canvas.edgehovers;
    const hoverContext = graphInstance.renderers[0].contexts.hover;
    const options = graphInstance.renderers[0].options;
    const embedSettings = graphInstance.settings.embedObjects(options, {
      prefix:
        graphInstance.renderers[0] instanceof sigma.renderers.webgl
          ? 'cam0:'
          : options.prefix,
    });

    const { id } = node;
    const edges = graphInstance.graph.adjacentEdges(id.toString());

    const nodesToHighlight = {};

    edges.forEach(e => {
      const source = graphInstance.graph.nodes(e.source);
      const target = graphInstance.graph.nodes(e.target);

      nodesToHighlight[source.id] = source;
      nodesToHighlight[target.id] = target;

      if (!e.hidden) {
        (
          edgeHoverRenderers[e.type] ||
          edgeHoverRenderers[embedSettings('defaultEdgeType')] ||
          edgeHoverRenderers.def
        )(e, source, target, hoverContext, embedSettings);
      }
    });

    delete nodesToHighlight[node.id];

    Object.values(nodesToHighlight).forEach(n => {
      if (!n.hidden) {
        (nodeHoverRenderers[n.type] || nodeHoverRenderers.def)(
          n,
          hoverContext,
          embedSettings
        );
      }
    });
    (nodeHoverRenderers[node.type] || nodeHoverRenderers.def)(
      node,
      hoverContext,
      embedSettings
    );
  };

  const handleClickNode = ({ data: { node } }) => {
    if (node.researcherId) {
      window.open(node.researcherId, '_blank');
    }
  };

  useEffect(() => {
    if (!loading) {
      const timeoutDuration = getTimeout(network.nodes.length);
      const timeout = setTimeout(() => setDrawing(false), timeoutDuration);
      return () => clearTimeout(timeout);
    }
  }, [loading, network]);

  useEffect(() => {
    if (loading) setDrawing(true);
  }, [loading]);

  useEffect(() => {
    if (network) {
      const sigma = window.sigma;

      const graphInstance = new sigma({
        graph: network,
        renderer: {
          container: `network-diagram-${fullscreen}`,
        },
        settings: {
          drawEdges: true,
          defaultEdgeColor: '#eee',
          edgeColor: 'default',
          defaultEdgeHoverColor: '#aaa',
          edgeHoverColor: 'default',
          labelThreshold: 5,
        },
      });

      graphInstance.bind('overNode', function(e) {
        handleOverNode(e, graphInstance);
      });

      graphInstance.bind('clickNode', function(e) {
        handleClickNode(e);
      });

      let seed = Math.floor(Math.random() * 100001);

      const randomPosition = () => {
        let s = Math.sin(seed++) * 10000;
        return s - Math.floor(s);
      };
      graphInstance.graph.nodes().forEach(function(node, i, a) {
        node.x = randomPosition();
        node.y = randomPosition();
      });
      sigma.plugins.filter(graphInstance);
      sigma.plugins.relativeSize(graphInstance, 1);

      graphInstance.startForceAtlas2({
        worker: true,
        barnesHutOptimize: false,
        linLogMode: true,
      });

      const timeoutAmount = getTimeout(network.nodes.length);
      const timeout = () => graphInstance.stopForceAtlas2();

      setTimeout(timeout, timeoutAmount);

      return () => clearTimeout(timeout);
    }
  }, [network, fullscreen]);

  if (loading) return <Loader />;

  return (
    <Container>
      {drawing && <Loader className='drawing-loader' />}
      {network.nodes.length > LARGE_NETWORK_SIZE && (
        <LargeNetworkWarning
          onExportNetwork={onExportNetwork}
          exportingNetwork={exportingNetwork}
        />
      )}
      <ZoomControls fullscreen={fullscreen} />
      <NetworkDiagramContainer
        id={`network-diagram-${fullscreen}`}
        fullscreen={fullscreen}
      />
    </Container>
  );
};

export default NetworkDiagram;
