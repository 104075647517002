import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Switch, Tooltip } from 'components/common';
import licenseType, { licenseTypeToStringMap } from 'models/licenseType';
import { licenseExpired } from '../constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-left: 5px;
  }
`;

const isLegacyAcademicConflict = (allocation, selectedLicenses) =>
  (allocation.type === licenseType.legacy &&
    selectedLicenses.some(l => l.type === licenseType.academic)) ||
  (allocation.type === licenseType.academic &&
    selectedLicenses.some(l => l.type === licenseType.legacy));
const EditLicense = ({
  selectedLicenses,
  loading,
  userLicenses,
  allocation,
  onToggle,
}) => {
  const value = selectedLicenses.find(x => x.type === allocation.type);
  const existingLicense = userLicenses.find(x => x.type === allocation.type);

  let available = 0;
  if (value) {
    available = allocation.available - (!existingLicense ? 1 : 0);
  } else {
    available =
      allocation.available +
      (existingLicense && !existingLicense.demoMode ? 1 : 0);
  }

  return (
    <Container key={allocation.type}>
      <Switch
        className='switch'
        disabled={
          loading ||
          (available <= 0 && !value) ||
          (existingLicense && existingLicense.isExpired) ||
          isLegacyAcademicConflict(allocation, selectedLicenses)
        }
        size='small'
        onChange={() => onToggle(allocation.type)}
        checked={!!value}
        label={`${licenseTypeToStringMap[allocation.type]}`}
      />
      <div>
        {available}/{allocation.total} available
      </div>
      {existingLicense && existingLicense.isExpired && (
        <Tooltip title={licenseExpired}>
          <div>
            <Icon className='icon' icon='error' color='#FFBA00' size='1x' />
          </div>
        </Tooltip>
      )}
    </Container>
  );
};

EditLicense.propTypes = {
  selectedLicenses: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool,
  userLicenses: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number.isRequired,
      demoMode: PropTypes.bool.isRequired,
      expiration: PropTypes.string,
      isExpired: PropTypes.bool.isRequired,
    })
  ),
  allocation: PropTypes.shape({
    type: PropTypes.number.isRequired,
    available: PropTypes.number.isRequired,
  }),
  onToggle: PropTypes.func.isRequired,
};

EditLicense.defaultProps = {
  loading: false,
};

export default EditLicense;
