import { useEffect, useState } from 'react';
import { usePost, useGet } from 'hooks';
import { Button, Loader } from 'components/common';
import PasswordChange from '../Reset/PasswordChange';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  verifySetPasswordToken,
  completeSetPassword,
  verifyResetPasswordToken,
  completeReset,
} from 'services/api';
import { Alert } from 'antd';
import React from 'react';

const NewPassword = styled.div`
  display: flex;
  flex-direction: column;
`;

const SetNewPass = ({ code, isTrial }) => {
  const [serverError, setServerError] = useState(null);
  const [customError, setCustomError] = useState(null);
  const { push } = useHistory();

  const expiredCodeMessage = (
    <span>
      That link has been used or has expired.{' '}
      <a href='/login'>Click here to login</a> or talk to us using the chat
      service below.
    </span>
  );

  const [{ res: codeIsValid, loading: verificationLoading }] = useGet({
    url: isTrial
      ? verifySetPasswordToken(code)
      : verifyResetPasswordToken(code),
    onMount: true,
  });

  const handleLogin = () => {
    push('/login');
  };

  const [newPass, setNewPass] = useState('');
  const [
    hasClientSidePasswordErrors,
    setHasClientSidePasswordErrors,
  ] = useState(false);

  const [confirmPass, setConfirmPass] = useState('');

  const [{ res, loading, error }, postReset] = usePost({
    url: isTrial ? completeSetPassword : completeReset,
  });

  const handleReset = () => {
    setServerError(null);
    setCustomError(null);
    if (!hasClientSidePasswordErrors) {
      postReset({ code, password: newPass });
    }
  };

  useEffect(() => {
    if (error) {
      setServerError(error);
    }
  }, [error]);

  useEffect(() => {
    setServerError(null);
    setCustomError(null);
  }, [newPass, setServerError, setCustomError]);

  if (res) {
    return (
      <NewPassword>
        <p>Your password was set successfully.</p>
        <Button onClick={handleLogin}>Go to Login</Button>
      </NewPassword>
    );
  }
  return (
    <React.Fragment>
      {code && !verificationLoading && codeIsValid && (
        <PasswordChange
          serverError={serverError}
          customError={customError}
          newPass={newPass}
          loading={loading}
          setNewPass={setNewPass}
          onChange={setHasClientSidePasswordErrors}
          handleSubmit={handleReset}
          setCustomError={setCustomError}
          confirmPass={confirmPass}
          setConfirmPass={setConfirmPass}
        />
      )}
      {!codeIsValid && !verificationLoading && (
        <React.Fragment>
          <Alert message={expiredCodeMessage} type='error' />
          <Button onClick={handleLogin}>Go to Login</Button>
        </React.Fragment>
      )}
      {verificationLoading && <Loader />}
    </React.Fragment>
  );
};
export default SetNewPass;
