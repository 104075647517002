import * as FromOther from 'reducers/other/selectors';

export const SLICE = 'other';

export const getBreadcrumb = state => FromOther.getBreadcrumb(state[SLICE]);

export const getReloadCrmConnections = state =>
  FromOther.getReloadCrmConnections(state[SLICE]);

export const getRestoringState = state =>
  FromOther.getRestoringState(state[SLICE]);

export const getLoginCarouselContent = state =>
  FromOther.getLoginCarouselContent(state[SLICE]);
