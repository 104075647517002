import { useSelector } from 'react-redux';
import { Statistic } from 'components/common';
import { colours } from 'utils/theme';
import { getSearchRequest } from 'selectors/search';

import { Stats } from '../styled';

const Statistics = () => {
  const { loading, res } = useSelector(getSearchRequest);
  const {
    totalFunding,
    totalPosters,
    totalPublications,
    totalClinicalTrials,
    emailCount,
    organisationCount,
    countryCount,
    distinctSuperResearchersCount,
  } = res;

  return (
    <Stats>
      <div className='gridParent'>
        <Statistic
          icon='award'
          title='Funding Awards'
          iconColor={colours.grants}
          value={loading ? '-' : totalFunding}
        />
        <Statistic
          icon='image'
          title='Tradeshow Sessions'
          iconColor={colours.posters}
          value={loading ? '-' : totalPosters}
        />
        <Statistic
          icon='book'
          title='Published Articles'
          iconColor={colours.publications}
          value={loading ? '-' : totalPublications}
        />
        <Statistic
          icon='trial'
          title='Clinical Trials'
          iconColor={colours.trials}
          value={loading ? '-' : totalClinicalTrials}
        />
        <Statistic
          icon='users'
          title='People'
          value={loading ? '-' : distinctSuperResearchersCount}
        />
        <Statistic
          icon='email'
          title='Emails'
          value={loading ? '-' : emailCount}
        />
        <Statistic
          icon='company'
          title='Organisations'
          value={loading ? '-' : organisationCount}
        />
        <Statistic
          icon='map'
          title='Countries'
          value={loading ? '-' : countryCount}
        />
      </div>
    </Stats>
  );
};

export default Statistics;
