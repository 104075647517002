import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingBar } from 'components/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 0 0 12px;
  .bar {
    margin-bottom: 8px;
  }
`;

const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function SecondaryBars({ style, barHeight }) {
  return (
    <SecondaryContainer style={style}>
      <LoadingBar className='bar' width={389} height={barHeight} />
      <LoadingBar className='bar' width={313} height={barHeight} />
      <LoadingBar className='bar' width={389} height={barHeight} />
      <LoadingBar className='bar' width={313} height={barHeight} />
    </SecondaryContainer>
  );
}

function MessageLoader({ style = {}, barHeight = 18 }) {
  return (
    <Container style={style}>
      <LoadingBar className='bar' width={217} height={barHeight} />
      <SecondaryBars style={{ margin: '12px 0' }} barHeight={barHeight} />
      <SecondaryBars barHeight={barHeight} />
    </Container>
  );
}

MessageLoader.propTypes = {
  style: PropTypes.shape({}),
  barHeight: PropTypes.number,
};

export default MessageLoader;
