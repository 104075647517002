import styled from 'styled-components';
import { Fragment } from 'react';

import { Button, Icon, Table, Tooltip } from 'components/common';
import { colours } from 'utils/theme';
import { format } from 'utils/date';
import { crmTypesToStringMap } from 'utils/helpers/crm';
import { integrationsImageMap } from './LicenseAllocation';
import { actionErrors } from './constants';
import ProgressBar from 'components/Export/Summary/ProgressBar';
import {
  AcademicTableHeader,
  BioPharmaTableHeader,
} from 'components/common/Table/styled';

const getLastActivityDate = date =>
  date ? format(date, 'Do MMM YYYY') : "Hasn't logged in";

const StyledTable = styled(Table)`
  margin-top: 10px;
`;

const tablePerPage = [
  { value: 10, label: '10 per page' },
  { value: 25, label: '25 per page' },
  { value: 50, label: '50 per page' },
  { value: 100, label: '100 per page' },
];

const Help = ({ error }) => {
  return (
    <Tooltip title={error} placement='right' overlayStyle={{ maxWidth: 360 }}>
      <div style={{ cursor: 'pointer' }}>
        <Icon icon='help' color={colours.teal} size='lg' />
      </div>
    </Tooltip>
  );
};

const Actions = ({ user, setModal }) => {
  if (user.currentUser) return null;
  if (!user.enabled) return <Help error={actionErrors.disabled} />;
  if (!user.licenses?.length) return <Help error={actionErrors.noLicenses} />;
  if (user.isLicenseExpired) return <Help error={actionErrors.expired} />;
  return (
    <>
      <Button
        onClick={() => setModal('addUser', user)}
        size='small'
        style={{ marginRight: 10 }}
      >
        Edit
      </Button>
      <Button onClick={() => setModal('password', user)} size='small'>
        Reset Password
      </Button>
    </>
  );
};

const handleSort = (a, b, sort, sortDir) => {
  let aValue = a[sort];
  let bValue = b[sort];

  if (!aValue && bValue) return 1;
  if (aValue && !bValue) return -1;

  if (['academicCredits', 'industryCredits'].includes(sort)) {
    aValue = a[sort]?.used ?? -1;
    bValue = b[sort]?.used ?? -1;
  }

  if (sortDir === 'asc') {
    return aValue > bValue ? 1 : -1;
  } else {
    return aValue > bValue ? -1 : 1;
  }
};

const UserTable = ({
  data,
  setModal,
  creditsEnabled,
  industryShowEmailCreditsToUsers,
  academicShowEmailCreditsToUsers,
}) => {
  const config = {
    headers: [
      { label: 'Status', key: 'status', minWidth: 50, maxContentWidth: 50 },
      { label: 'Name', key: 'fullname', minWidth: 100, maxContentWidth: 200 },
      { label: 'Email', key: 'email', minWidth: 100, maxContentWidth: 300 },
      {
        label: (
          <div style={{ lineHeight: '12px' }}>
            Last Activity<div style={{ fontSize: 9 }}>within last 30 days</div>
          </div>
        ),
        key: 'lastActivityDate',
        testId: 'lastActivityDate',
        minWidth: 100,
        maxContentWidth: 100,
      },
      ...(creditsEnabled && academicShowEmailCreditsToUsers
        ? [
            {
              label: <AcademicTableHeader />,
              key: 'academicCredits',
              minWidth: 150,
              maxContentWidth: 150,
            },
          ]
        : []),
      ...(creditsEnabled && industryShowEmailCreditsToUsers
        ? [
            {
              label: <BioPharmaTableHeader />,
              key: 'industryCredits',
              minWidth: 150,
              maxContentWidth: 150,
            },
          ]
        : []),
      {
        label: 'Licenses',
        key: 'userLicense',
        minWidth: 80,
        maxContentWidth: 100,
      },
      {
        label: 'Features',
        key: 'features',
        minWidth: 80,
        maxContentWidth: 100,
      },
      {
        label: 'Update User',
        key: 'actions',
        minWidth: 80,
        maxContentWidth: 200,
      },
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.email}-${i}`}>
        <div style={{ paddingRight: 0, alignItems: 'center' }}>
          {r.active ? (
            <Icon icon='accept' color={colours.teal} size='1x' />
          ) : (
            <Icon icon='cancel' color={colours.red} size='1x' />
          )}
        </div>
        <div>{r.fullname}</div>
        <Tooltip title={r.email}>
          <div>{r.email}</div>
        </Tooltip>
        <div>{getLastActivityDate(r.lastActivityDate)}</div>
        {creditsEnabled &&
          academicShowEmailCreditsToUsers &&
          (r.academicCredits.valid ? (
            <div style={{ width: '150px' }}>
              <ProgressBar
                title='Academic'
                icon='book'
                showHeader={false}
                used={r.academicCredits.used}
                total={r.academicCredits.total}
                renewDays={r.academicCredits.renewDays}
              />
            </div>
          ) : (
            <div>{r.academicCredits.errorMessage}</div>
          ))}
        {creditsEnabled &&
          industryShowEmailCreditsToUsers &&
          (r.industryCredits.valid ? (
            <div style={{ width: '150px' }}>
              <ProgressBar
                title='Biopharma'
                icon='company'
                used={r.industryCredits.used}
                total={r.industryCredits.total}
                renewDays={r.industryCredits.renewDays}
                showHeader={false}
              />
            </div>
          ) : (
            <div>{r.industryCredits.errorMessage}</div>
          ))}
        <div>{r.userLicense}</div>
        <div>
          {r.integrations?.map(x => (
            <Icon
              key={x}
              title={crmTypesToStringMap[x]}
              icon={integrationsImageMap[x]}
              size='2x'
              color='#56B3C3'
            />
          ))}
        </div>
        <div
          style={{
            paddingRight: 0,
            justifyContent: 'flex-end',
            flex: '0 0 auto',
          }}
        >
          <Actions user={r} setModal={setModal} />
        </div>
      </Fragment>
    ),
  };

  return (
    <StyledTable
      data={data}
      config={config}
      pageSizeOptions={tablePerPage}
      sortable
      paginate
      onSort={handleSort}
    />
  );
};

export default UserTable;
