import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchAreaContainer = styled.div``;

export const ActionButtons = styled.div`
  display: flex;
  margin-left: 10px;
  > * {
    margin-right: 10px;
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
    > * {
      margin-left: 0px;
      margin-bottom: 10px;
      :last-child {
        margin-bottom: 0px;
      }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
`;
