import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import {
  Input,
  Checkbox,
  Category,
  TokenInput,
  DatePicker,
  InputNumber,
  Select,
  Switch,
} from 'components/common';

import { formatNumber } from 'utils/number';

const SwitchFilter = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
  > span {
    margin-left: 8px;
  }
`;

const OrgFilterField = ({
  filterId,
  filterType,
  checkValue,
  organisationId,
  onChange,
  formatter,
  getFilter,
  setFilterGroup,
  allowClear = true,
  ...rest
}) => {
  const value = useSelector(getFilter(organisationId, filterId));
  const dispatch = useDispatch();

  const handleChange = value => {
    if (onChange) {
      return onChange(value);
    }

    if (filterType === 'checkbox' && checkValue && value) {
      dispatch(setFilterGroup(organisationId, filterId, checkValue));
      return;
    }

    dispatch(setFilterGroup(organisationId, filterId, value));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSwitchClick = () => {
    if (onChange) {
      return onChange(!value);
    }
    dispatch(setFilterGroup(organisationId, filterId, !value));
  };

  if (filterType === 'switch') {
    return (
      <SwitchFilter onClick={handleSwitchClick}>
        <Component data-testid={`filter-${filterId}`} checked={value} />
        <span>{rest.children}</span>
      </SwitchFilter>
    );
  }

  const components = {
    input: Input,
    checkbox: ({ value, ...rest }) => <Checkbox {...rest} checked={value} />,
    token: TokenInput,
    datePicker: DatePicker,
    number: ({ formatter = formatNumber, ...rest }) => (
      <InputNumber formatter={formatter} {...rest} />
    ),
    select: Select,
    category: Category,
    switch: Switch,
  };

  const Component = components[filterType];

  return (
    <Component
      {...rest}
      data-testid={`filter-${filterId}`}
      onChange={handleChange}
      value={value}
      allowClear={allowClear}
    />
  );
};

export default OrgFilterField;
