import { Table } from 'components/common';
import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  > * {
    margin-top: 10px;
    margin-right: 10px;
  }
`;

export const ConstrainedTable = styled(Table)`
  max-height: calc(100vh - 280px);
  overflow-y: auto;
`;
