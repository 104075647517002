import { Fragment } from 'react';

import { Pane, Table } from './styled';

const outcomeConfig = {
  headers: ['Measure', 'Timeframe', 'Description'],
  createRow: (r, i) => (
    <Fragment key={`${r.measure}-${i}`}>
      <div>{`${i + 1}. ${r.measure}`}</div>
      <div>{r.timeFrame}</div>
      <div>{r.description || '-'}</div>
    </Fragment>
  ),
};

const Outcomes = ({ study }) => {
  return (
    <>
      {study.primaryOutcomeMeasurements.length > 0 && (
        <Pane title='Primary Outcome Measures' id='primary'>
          <Table
            config={outcomeConfig}
            data={study.primaryOutcomeMeasurements}
          />
        </Pane>
      )}
      {study.secondaryOutcomeMeasurements.length > 0 && (
        <Pane title='Secondary Outcome Measures' id='secondary'>
          <Table
            config={outcomeConfig}
            data={study.secondaryOutcomeMeasurements}
          />
        </Pane>
      )}
      {study.otherOutcomeMeasurements.length > 0 && (
        <Pane title='Other Outcome Measures' id='other'>
          <Table config={outcomeConfig} data={study.otherOutcomeMeasurements} />
        </Pane>
      )}
    </>
  );
};

export default Outcomes;
