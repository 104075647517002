import React from 'react';

import { siNumber } from 'utils/number';
import { format } from 'utils/date';

import GraphBase from './GraphBase';

const Funding = ({ data, hideLable, hideHelpText, animate = true }) => {
  let options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value, index, values) {
              return `$${siNumber(value, 1)}`;
            },
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback: function(value, index, values) {
              return format(value, 'YYYY');
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return `$${siNumber(tooltipItem.yLabel)}`;
        },
        title: function(tooltipItem, data) {
          return format(tooltipItem[0].xLabel, 'YYYY');
        },
      },
    },
  };

  if (!animate) {
    options = { ...options, animation: false };
  }

  return (
    <GraphBase
      data={data}
      label={hideLable ? '' : 'Funding by Year'}
      type='line'
      options={options}
      helpText={
        hideHelpText
          ? ''
          : 'A USD total of all grants that appear in the search (other currencies are standardised to USD)'
      }
    />
  );
};

export default Funding;
