// return 0 - strings are equal, 1 - string a after b, -1 - string a before b
export const compare = (a, b, ignoreCase = true) => {
  const aValue = ignoreCase ? a?.toLowerCase() : a;
  const bValue = ignoreCase ? b?.toLowerCase() : b;
  if (!aValue && bValue) return 1;
  if (aValue && !bValue) return -1;

  if (aValue === bValue) {
    return 0;
  }

  return aValue > bValue ? 1 : -1;
};
