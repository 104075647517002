import styled from 'styled-components';

export const RatingContainer = styled.button`
  flex: 1;
  background-color: white;
  border: 1px solid #e1e1e1;
  border-left: 0px;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease all;
  color: black;
  padding: 2px 0px;
  line-height: 23px;
  :hover {
    background-color: #f4f3f3;
  }
  :first-child {
    border-radius: 3px 0 0 3px;
    border-left: 1px solid #e1e1e1;
  }
  :last-child {
    border-radius: 0px 3px 3px 0px;
  }
`;

export const Row = styled.div`
  color: #a2a2a2;
  padding-top: 3px;
  display: flex;
  justify-content: space-between;
`;

export const Fader = styled.div`
  animation-duration: 0.4s;
  animation-name: ${p => (p.fade ? 'fade' : '')};
  @keyframes fade {
    0% {
      opacity: 100%;
    }
    50% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
`;

export const FeedbackButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;
