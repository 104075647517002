//@ts-check
import { Statistic } from 'components/common';
import React from 'react';
import styled from 'styled-components';
import { DistinctBy, SelectMany } from 'utils/array';
import { siNumber } from 'utils/number';
import { colours } from 'utils/theme';

export const Stats = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-bottom: ${p => p.theme.gutter};
  > div {
    flex: 1;
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

/**
 * @param {{
 * organisation: import('../Funding/index').OrganisationVCFundingDetails,
 * loading: boolean
 * }} props
 */
export default function VCFundingStats({ organisation, loading }) {
  if (loading) return null;

  var hasFundingRounds = !!organisation.superOrganisationFundingRounds?.length;

  return (
    <Stats>
      {hasFundingRounds && (
        <>
          <Statistic
            icon='dollar'
            title='Total Funding Amount'
            value={siNumber(organisation.superOrganisationTotalFundingUsd)}
            iconColor={colours.teal}
          />
          <Statistic
            icon='dollar'
            title='Most Recent Round'
            value={siNumber(
              organisation.superOrganisationLatestFundingRound?.raisedAmountUsd
            )}
            iconColor={colours.teal}
          />
          <Statistic
            icon='sackDollar'
            title='Funding Rounds'
            value={organisation.superOrganisationFundingRounds?.length || 0}
            iconColor={colours.teal}
          />
          <Statistic
            icon='handHoldingDollar'
            title='Investors'
            value={
              DistinctBy(
                SelectMany(
                  organisation.superOrganisationFundingRounds || [],
                  fr => fr.elasticInvestors || []
                ),
                i => i.investorId
              ).filter(x => !!x).length
            }
            iconColor={colours.teal}
          />
        </>
      )}
    </Stats>
  );
}
