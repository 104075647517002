import { formatCurrency } from 'utils/number';

const FundingFooter = ({ allTime, threeYear }) => {
  return (
    <div>
      Funding USD:{' '}
      <span style={{ fontWeight: 'normal' }}>
        ${formatCurrency(allTime)} (All Time){' '}
        <span style={{ marginLeft: 6 }}>
          ${formatCurrency(threeYear)} (3 Year)
        </span>
      </span>
    </div>
  );
};

export default FundingFooter;
