import React from 'react';
import GraphBase from './GraphBase';
import { siNumber } from 'utils/number';
import { useDispatch } from 'react-redux';
import { appendFilter } from 'actions/search';

export default function OrgVCFunding({ data }) {
  const dispatch = useDispatch();

  let excludeIndexes = [];
  data.data = data.data.filter((x, i) => {
    if (!x) {
      excludeIndexes.push(i);
      return false;
    }
    return true;
  });
  data.labels = data.labels.filter((_, i) => !excludeIndexes.includes(i));

  let options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: value => `$${siNumber(value)}`,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: value =>
              value?.length > 16 ? `${value.substr(0, 16)} ...` : value,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return `$${siNumber(tooltipItem.xLabel)}`;
        },
      },
    },
  };

  const handleClick = organisation => {
    dispatch(appendFilter('organisationName', organisation));
  };

  return (
    <>
      <GraphBase
        type='horizontalBar'
        label='Organisation VC Funding'
        data={data}
        options={options}
        helpText='The top organisations in your results ranked by the VC funding they have raised. (This chart includes VC Funding across all time)'
        fullWidth={false}
        onClick={handleClick}
      />
    </>
  );
}
