// @ts-check
import { setFilter, setFilterGroup } from 'actions/search';
import { Tooltip } from 'antd';
import { Token } from 'components/common/TokenInput';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilters, getSearchView } from 'selectors/search';
import styled from 'styled-components';
import {
  mainSearchExcludedFilters,
  nonEditablemainSearchExcludedFilters,
  tokenise,
} from 'utils/filters';
import { ResearcherSearch } from '../helpers';
import { FiltersContainer } from '../styled';
import { GetSearchKeysByView } from 'components/Search/Filters/keys';
import { searchOrigin } from '../constants';

const TokenLister = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  > span {
    font-size: 13px;
    line-height: 13px;
  }
`;

const TooltipTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function SearchFilterTokens({ filter, inputView }) {
  const searchFilters = useSelector(getFilters);
  const view = useSelector(getSearchView);
  const editable = !(filter && inputView);
  const dispatch = useDispatch();

  const researcherSearchFields = GetSearchKeysByView(
    searchOrigin.researcherSearch
  );
  const organisationSearchFields = GetSearchKeysByView(
    searchOrigin.organisationSearch
  );

  /**
   * @param {string} key
   * @param {number=} index
   * @param {string=} filterGroup
   * @returns {void}
   */
  function removeFilterValue(key, index, filterGroup) {
    if (editable) {
      let filterSetter = setFilter;

      if (filterGroup) {
        filterSetter = (key, value) => setFilterGroup(filterGroup, key, value);
      }

      if (index != null) {
        let filters = searchFilters;
        if (filterGroup) {
          filters = searchFilters[filterGroup];
        }
        dispatch(
          filterSetter(
            key,
            filters[key].filter((_, i) => i !== index)
          )
        );
      } else {
        dispatch(filterSetter(key, undefined));
      }
    }
  }

  const tokens = tokenise(
    filter ?? searchFilters,
    // @ts-ignore
    ResearcherSearch(inputView ?? view)
      ? researcherSearchFields
      : organisationSearchFields,
    [
      ...(editable
        ? mainSearchExcludedFilters
        : nonEditablemainSearchExcludedFilters),
    ]
  );

  if (tokens.length <= 0) return null;

  return (
    <FiltersContainer>
      <TokenLister
        style={{
          flexDirection: !editable ? 'column' : 'row',
          alignItems: !editable ? 'start' : 'center',
        }}
      >
        {editable ? (
          <span style={{ marginRight: '10px' }}>Search Filters:</span>
        ) : (
          <></>
        )}
        {tokens.map(filter => (
          /* @ts-ignore */
          <Tooltip
            title={
              <TooltipTitle>
                <div style={{ fontWeight: 'bold' }}>
                  {filter.metaData.sectionTitle}
                </div>
                <div>{filter.metaData.title}</div>
              </TooltipTitle>
            }
            key={`${filter.key}-${filter.index}`}
          >
            {/* @ts-ignore */}
            <Token
              style={{
                margin: 0,
                marginRight: '5px',
                marginTop: '5px',
                marginBottom: '5px',
              }}
              hideDelete={!editable}
              value={filter.value}
              removeToken={() =>
                removeFilterValue(filter.key, filter.index, filter.filterGroup)
              }
              background={filter.color}
            />
          </Tooltip>
        ))}
      </TokenLister>
    </FiltersContainer>
  );
}
