import AddUser from './AddUser';
import ToggleUser from './ToggleUser';
import ResetPassword from './ResetPassword';
import Roles from './Roles';

const Modal = ({
  user,
  modal,
  setModal,
  activeUser,
  fetchUsers,
  licenseAllocation,
  enterpriseAccount,
}) => {
  if (!modal) return null;

  const modals = {
    addUser: AddUser,
    toggle: ToggleUser,
    password: ResetPassword,
    roles: Roles,
  };

  const Component = modals[modal];

  return (
    <Component
      user={user}
      setModal={setModal}
      activeUser={activeUser}
      fetchUsers={fetchUsers}
      licenseAllocation={licenseAllocation}
      enterpriseAccount={enterpriseAccount}
    />
  );
};

export default Modal;
