import { Tag } from './styled';
import { Tooltip } from 'antd';

const BetaTag = ({ helpText }) => {
  if (helpText) {
    return (
      <Tooltip title={helpText} placement='right'>
        <Tag>β</Tag>
      </Tooltip>
    );
  }

  return <Tag>β</Tag>;
};

export default BetaTag;
