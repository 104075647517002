import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = width <= 667;
  const isTablet = width <= 1042;
  const isSmallerLaptop = width <= 1440;
  return { width, isMobile, isTablet, isSmallerLaptop };
};

export default useWindowWidth;
