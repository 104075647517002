import * as FromLists from 'reducers/lists/selectors';

export const SLICE = 'lists';

export const getView = state => FromLists.getView(state[SLICE]);

export const getActiveModal = state => FromLists.getActiveModal(state[SLICE]);

export const getListsRequest = state =>
  FromLists.getListsRequest(state[SLICE][FromLists.getView(state[SLICE])]);
export const getActiveList = state =>
  FromLists.getActiveList(state[SLICE][FromLists.getView(state[SLICE])]);
export const getShowMyListsOnly = state =>
  FromLists.getShowMyListsOnly(state[SLICE][FromLists.getView(state[SLICE])]);
export const getIncludePausedLists = state =>
  FromLists.getIncludePausedLists(
    state[SLICE][FromLists.getView(state[SLICE])]
  );
export const getSelectedMembers = state =>
  FromLists.getSelectedMembers(state[SLICE][FromLists.getView(state[SLICE])]);

export const getPageNumber = state =>
  FromLists.getPageNumber(state[SLICE][FromLists.getView(state[SLICE])]);
export const getPageSize = state =>
  FromLists.getPageSize(state[SLICE][FromLists.getView(state[SLICE])]);
export const getTableSort = state =>
  FromLists.getTableSort(state[SLICE][FromLists.getView(state[SLICE])]);
export const getAddToTarget = state =>
  FromLists.getAddToTarget(state[SLICE][FromLists.getView(state[SLICE])]);
export const getListMembers = state =>
  FromLists.getListMembers(state[SLICE][FromLists.getView(state[SLICE])]);
export const getExportExtraParams = state =>
  FromLists.getExportExtraParams(state[SLICE][FromLists.getView(state[SLICE])]);
export const getIsLoading = state =>
  FromLists.getIsLoading(state[SLICE][FromLists.getView(state[SLICE])]);
export const getRestoringState = state =>
  FromLists.getRestoringState(state[SLICE]);

// Will only ever return partial selections -
// selections for all/none should be handled
// elsewhere
export const getSelectedMemberIds = state => {
  const members = getListMembers(state);
  if (members !== undefined) {
    const allMembers = members.members;
    const selectedMembers = getSelectedMembers(state);
    const allButExcluded =
      selectedMembers.all && Object.keys(selectedMembers.exclude).length > 0;

    let researcherIds;

    if (allButExcluded) {
      researcherIds = allMembers.reduce((acc, member) => {
        if (selectedMembers.exclude[member.researcherId]) {
          return acc;
        }
        return [...acc, member.researcherId].map(x => +x);
      }, []);
    } else {
      researcherIds = Object.keys(selectedMembers.include).map(x => +x);
    }

    return researcherIds.map(x => +x);
  } else return [];
};

export const getAreAllMembersSelected = state => {
  const allMembersCount = getListMembers(state)?.total;
  const selectedMembers = getSelectedMembers(state);

  const areAllSelected =
    selectedMembers.all && Object.keys(selectedMembers.exclude).length === 0;
  const noneAreSelected =
    !selectedMembers.all && Object.keys(selectedMembers.include).length === 0;
  const manualAll =
    !selectedMembers.all &&
    Object.keys(selectedMembers.include).length === allMembersCount;

  return areAllSelected || noneAreSelected || manualAll;
};

export const getAreSomeMembersSelected = state => {
  const allMembersCount = getListMembers(state)?.total;
  const selectedMembers = getSelectedMembers(state);
  const includedeMembersLength = Object.keys(selectedMembers.include).length;
  const excludedMembersLength = Object.keys(selectedMembers.exclude).length;

  const someIncluded =
    includedeMembersLength > 0 && includedeMembersLength < allMembersCount;
  const someExcluded =
    excludedMembersLength > 0 && excludedMembersLength < allMembersCount;

  return someIncluded || someExcluded;
};
