import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import SetNewPass from './SetNewPass';
import { InnerContent } from '../styled';

const SetPassword = () => {
  const { search } = useLocation();
  const { code, isTrial } = qs.parse(search);

  return (
    <>
      <div className='form-header'>'Set a Password'</div>
      <InnerContent>
        <SetNewPass code={code} isTrial={isTrial} />
      </InnerContent>
    </>
  );
};

export default SetPassword;
