import PurchasingSearchArea from './SearchArea';
import PurchasingResults from './Results';
import PurchasingNotice from './PurchasingNotice';

const OrganisationPurchasing = ({ organisation, loading }) => {
  return (
    <>
      <PurchasingSearchArea organisation={organisation} />
      <PurchasingNotice />
      <PurchasingResults organisation={organisation} loading={loading} />
    </>
  );
};

export default OrganisationPurchasing;
