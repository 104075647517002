import styled from 'styled-components';

// import { Icon } from 'components/common';

const Container = styled.div`
  flex: 1;
  width: 100%;
  background: ${p => (p.theme.darkMode ? 'white' : 'white')};
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  font-size: 16px;
  color: ${p => p.theme.black};
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* > div {
    margin-left: 5px;
  } */
`;

const Pane = ({ className, children, headerRight, title, ...rest }) => {
  return (
    <Container className={className} {...rest}>
      {title && (
        <Header data-testid='panel-title'>
          <Title>
            {/* <Icon icon='caret' color='#31302f' size='1x' /> */}
            <div>{title}</div>
          </Title>
          {headerRight}
        </Header>
      )}
      <div className='content'>{children}</div>
    </Container>
  );
};

export default Pane;
