import licenseType from 'models/licenseType';

export const isAcademicFull = user =>
  user?.licenses?.some(x => x.type === licenseType.academic && !x.demoMode);

export const isIndustryFull = user =>
  user?.licenses?.some(x => x.type === licenseType.industry && !x.demoMode);

export const isAcademicTrial = user =>
  user?.licenses?.some(x => x.type === licenseType.academic && x.demoMode);

export const isIndustryTrial = user =>
  user?.licenses?.some(x => x.type === licenseType.industry && x.demoMode);

export const noIndustryLicence = user =>
  !isIndustryFull(user) && !isIndustryTrial(user);
export const noAcademicLicence = user =>
  !isAcademicFull(user) && !isAcademicTrial(user);

export const isFullTrial = user =>
  (isAcademicTrial(user) || isIndustryTrial(user)) && ((isAcademicTrial(user) && !isIndustryFull(user)) || (isIndustryTrial(user) && !isAcademicFull(user)));

export const hasFullLicense = user =>
  isAcademicFull(user) || isIndustryFull(user);
