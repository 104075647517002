import { view, searchOrigin } from './constants';

const researcherViews = [view.researcher, view.map, view.visualisation];
export const ResearcherSearch = currentView => {
  return researcherViews.includes(currentView);
};

const organisationViews = [
  view.organisation,
  view.organisationMap,
  view.organisationInsights,
];
export const OrganisationSearch = currentView => {
  return organisationViews.includes(currentView);
};

export const ActivitySearch = currentView => {
  return currentView === view.activity;
};

// create a map between the view and the search origin
const viewToSearchOrigin = {
  [view.researcher]: searchOrigin.researcherSearch,
  [view.map]: searchOrigin.researcherSearch,
  [view.visualisation]: searchOrigin.researcherSearch,
  [view.organisation]: searchOrigin.organisationSearch,
  [view.organisationInsights]: searchOrigin.organisationSearch,
  [view.organisationMap]: searchOrigin.organisationSearch,
  [view.activity]: searchOrigin.activitySearch,
};

export function getSearchOrigin(viewKey) {
  return viewToSearchOrigin[viewKey] || null;
}

export const IsMap = currentView =>
  [view.map, view.organisationMap].includes(currentView);
