import styled from 'styled-components';

import { Icon } from 'components/common';

export const Container = styled.div``;

export const ActiveOption = styled.div`
  border: 1px solid ${p => p.theme.borderGrey};
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.2s linear;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    border: 1px solid ${p => p.theme.teal};
  }
`;

export const OptionContainer = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  transition: all 0.2s linear;
  background: white;
  .title {
    font-weight: 500;
  }
  .description {
    font-size: 13px;
  }
`;

export const Options = styled.div`
  display: ${p => (p.showOptions ? 'block' : 'none')};
  border: 1px solid ${p => p.theme.borderGrey};
  position: absolute;
  z-index: 2;
  background: white;
  margin-right: 24px;
  > div {
    margin: 5px 0;
    :hover {
      background: rgba(0, 0, 0, 0.025);
    }
    :first-child {
      margin-top: 0px;
    }
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  transition: transform 0.2s linear;
  transform: ${p => (p.active ? 'rotate(270deg)' : 'rotate(90deg)')};
  margin-right: 20px;
`;
