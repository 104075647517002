import { TreeSelect as AntTreeSelect } from 'antd';
import { useRef } from 'react';
import { Icon } from 'components/common';
import './TreeSelect.css';

const { TreeNode } = AntTreeSelect;

const TreeSelect = ({
  treeData,
  'data-testid': dataTestId,
  onCreateNewItem,
  buttonText,
  antSelectRef,
  selectedValues,
  ...rest
}) => {
  const refSelect = useRef();

  const titleDivStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  };

  const isFirefox = () => {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  };

  const getTitleElement = (item, isSelected) => {
    const isParentTitleRegex = /(.*)\((\d+)\)(.*)/;
    const match = item.title.match(isParentTitleRegex);
    const title = match ? match[1] : item.title;
    const numChildren = match ? match[2] : null;

    return (
      <div style={titleDivStyle}>
        <span style={{ fontSize: '14px' }}>
          {title}
          {numChildren && <span style={{ opacity: 0.5 }}>({numChildren})</span>}
        </span>
        {isSelected && (
          <Icon
            icon='check'
            color='#1890ff'
            size='sm'
            style={{ marginLeft: 'auto' }}
          />
        )}
      </div>
    );
  };

  const renderTreeNodes = data =>
    data.map(item => {
      const isSelected = selectedValues
        ? selectedValues.includes(item.key)
        : false;
      const titleElement = getTitleElement(item, isSelected);

      return item.children ? (
        <TreeNode title={titleElement} key={item.key} value={item.value}>
          {renderTreeNodes(item.children)}
        </TreeNode>
      ) : (
        <TreeNode title={titleElement} key={item.key} value={item.value} />
      );
    });

  return (
    <AntTreeSelect
      {...rest}
      value={selectedValues}
      ref={refSelect}
      showCheckedStrategy={AntTreeSelect.SHOW_PARENT}
      data-testid={dataTestId}
      virtual={!isFirefox()}
    >
      {renderTreeNodes(treeData)}
    </AntTreeSelect>
  );
};

export default TreeSelect;
