import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { Button, Tooltip } from 'components/common';
import { usePost, useAuth } from 'hooks';
import { enableList, disableList } from 'services/api';
import {
  setModal,
  setShowMyListsOnly,
  setIncludePausedLists,
  setActiveList,
} from 'actions/lists';
import {
  getShowMyListsOnly,
  getIncludePausedLists,
  getActiveList,
  getView,
} from 'selectors/lists';

import Selector from './Selector';
import { Container, Switches, Switch, Buttons } from './styled';
import { accessLevels } from '../helpers';

const Actions = ({
  researcherLists,
  loading,
  onSelectorChange,
  onRefreshListAfterPause,
  hasIndustryLicense,
}) => {
  const dispatch = useDispatch();
  const showMyListsOnly = useSelector(getShowMyListsOnly);
  const includePausedLists = useSelector(getIncludePausedLists);
  const activeList = useSelector(getActiveList);
  const view = useSelector(getView);

  const { user } = useAuth();
  const { search } = useLocation();
  const { includePausedList } = qs.parse(search);

  const [{ res: enableData, loading: enableLoading }, postEnable] = usePost({
    url: enableList(activeList?.id, view),
  });
  const [{ res: disableData, loading: disableLoading }, postDisable] = usePost({
    url: disableList(activeList?.id, view),
  });

  useEffect(() => {
    if (enableData) {
      onRefreshListAfterPause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableData]);

  useEffect(() => {
    if (disableData) {
      onRefreshListAfterPause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableData]);

  useEffect(() => {
    if (includePausedList && !includePausedLists) {
      dispatch(setIncludePausedLists(true));
    }
  }, [includePausedList, dispatch, includePausedLists]);

  const handleSetModal = modal => {
    dispatch(setModal(modal));
  };

  const handleToggleMyLists = () => {
    if (!loading) {
      dispatch(setShowMyListsOnly(!showMyListsOnly));
    }
  };

  const handleToggleIncludePaused = () => {
    if (!loading) {
      dispatch(setIncludePausedLists(!includePausedLists));
    }
  };

  const handleTogglePause = () => {
    dispatch(setActiveList({ ...activeList, enabled: !activeList.enabled }));
    if (activeList.enabled) {
      postDisable();
    } else {
      postEnable();
    }
  };

  const disableButtons = !activeList.id || loading;

  const pauseListText = activeList.enabled ? 'Pause List' : 'Resume List';
  const disablePause = enableLoading || disableLoading || disableButtons;

  const canModify =
    accessLevels.modifyList.includes(activeList.userAccessLevel) ||
    user.isAccountManager;

  const listOptions =
    researcherLists && researcherLists.length > 0
      ? researcherLists.map(l => ({
          label: l.name,
          value: l.id,
          description: l.description,
        }))
      : [{ label: 'No lists available', value: 'no_lists' }];

  if (listOptions.length === 0) {
    listOptions.push({ label: 'No lists available', value: 'no_lists' });
  }

  return (
    <Container>
      <Selector
        disabled={loading}
        lists={listOptions}
        onSelectorChange={onSelectorChange}
      />
      <Switches>
        <Switch
          onChange={handleToggleMyLists}
          checked={showMyListsOnly}
          label='Only show my lists'
          disabled={loading}
        />
        <Switch
          onChange={handleToggleIncludePaused}
          checked={includePausedLists}
          label='Include paused lists'
          disabled={loading}
        />
      </Switches>
      <div style={{ display: 'flex' }}>
        <Tooltip
          title={
            !canModify && 'You do not have permission to perform this action'
          }
        >
          <Buttons>
            <Button
              disabled={disableButtons || !canModify}
              size='small'
              onClick={() => handleSetModal('edit')}
            >
              Edit List
            </Button>
            <Button
              disabled={disablePause || !canModify}
              size='small'
              onClick={handleTogglePause}
            >
              {pauseListText}
            </Button>
            <Button
              disabled={disableButtons || !canModify}
              size='small'
              onClick={() => handleSetModal('delete')}
            >
              Delete List
            </Button>
          </Buttons>
        </Tooltip>
      </div>
    </Container>
  );
};

export default Actions;
