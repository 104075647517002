//@ts-check
import { Link, Table } from 'components/common';
import dayjs from 'dayjs';
import React from 'react';
import { Fragment } from 'react';
import { format } from 'utils/date';
import { siNumber } from 'utils/number';
import { Title } from '../Funding/index';
import AcquisitionHightlight from './AcquisitionHighlight';

/**
 * @param {{
 *  organisation: import('../Funding/index').OrganisationVCFundingDetails
 *  }} props
 */

export default function OrganisationAcquisitions({ organisation }) {
  const filteredAcquisitions =
    organisation.superOrganisationAcquisitions?.sort((a, b) =>
      dayjs(b.acquiredOn).diff(dayjs(a.acquiredOn))
    ) || [];

  const onSort = (a, b, sort, sortDir) => {
    let aValue = a[sort]?.toLowerCase ? a[sort].toLowerCase() : a[sort];
    let bValue = b[sort]?.toLowerCase ? b[sort].toLowerCase() : b[sort];

    if (sort === 'organisationName') {
      aValue = a.acquisitionMade
        ? a.organisationName
        : organisation.organisationName;
      bValue = b.acquisitionMade
        ? b.organisationName
        : organisation.organisationName;
    } else if (sort === 'transactionName') {
      aValue = createTransactionName(a);
      bValue = createTransactionName(b);
    }

    if (aValue == null && bValue != null) return 1;
    if (aValue != null && bValue == null) return -1;

    if (sortDir === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  };

  /** @param {import('../Funding/index').Acquisition} aq */
  function createTransactionName(aq) {
    return `${
      aq.acquisitionMade ? organisation.organisationName : aq.organisationName
    } completed ${aq.acquisitionType || 'acquisition'} ${
      aq.acquisitionType === 'Merger' ? 'with' : 'of'
    } ${
      aq.acquisitionMade ? aq.organisationName : organisation.organisationName
    }`;
  }

  const acquisitionTableConfig = {
    headers: [
      { label: 'Acquiree Name', minWidth: 150, key: 'organisationName' },
      { label: 'Announcement Date', minWidth: 90, key: 'acquiredOn' },
      { label: 'Price', minWidth: 90, key: 'priceUsd' },
      { label: 'Type', minWidth: 90, key: 'acquisitionType' },
      { label: 'Transaction Name', minWidth: 200, key: 'transactionName' },
    ],
    /** @param {import('../Funding/index').Acquisition} r */
    createRow: r => {
      return (
        <Fragment key={r.acquisitionId}>
          <div>
            {!r.acquisitionMade ? (
              organisation.organisationName
            ) : r.superOrgId ? (
              //@ts-ignore
              <Link to={`/organisation/${r.superOrgId}`} showIconHint>
                {r.organisationName}
              </Link>
            ) : (
              r.organisationName
            )}
          </div>
          <div>{format(r.acquiredOn)}</div>
          <div>{r.priceUsd ? `$${siNumber(r.priceUsd)}` : '-'}</div>
          <div>{r.acquisitionType}</div>
          <div>{createTransactionName(r)}</div>
        </Fragment>
      );
    },
  };

  return (
    <div>
      {!!filteredAcquisitions.length && (
        <>
          <Title>Highlights</Title>
          <AcquisitionHightlight organisation={organisation} />
          <Title>Acquisitions Table</Title>
          <Table
            config={acquisitionTableConfig}
            data={filteredAcquisitions}
            sortable
            className={'researchers-table'}
            paginate
            onSort={onSort}
          />
        </>
      )}
    </div>
  );
}
