import { formatNumber } from 'utils/number';

export const progressState = {
  green: 0,
  orange: 1,
  red: 2,
};

export const getProgressState = progress => {
  if (progress <= 75) return progressState.green;
  if (progress <= 100) return progressState.orange;
  return progressState.red;
};

export const getProgressColor = state => {
  if (state === progressState.green) return '#198038';
  if (state === progressState.orange) return '#F1C21B';
  return '#DA1E28';
};

export const getCreditsString = credits =>
  isUnlimited(credits) ? '∞' : formatNumber(credits);

export const isUnlimited = credits => credits < 0;
