import { Fragment, useEffect, useRef, useState } from 'react';
import { Popover, Tooltip } from 'antd';

import { useWindowWidth } from 'hooks';
import { Table, Link, Icon } from 'components/common';

import {
  Token,
  Container,
  ListsContainer,
  Label,
  TokenContainer,
} from './styled';
import { format } from 'utils/date';
import { views } from 'components/Lists/constants';
import TooltipContent from 'components/common/TooltipContent';

const config = {
  headers: [
    { label: 'Name', key: 'name' },
    { label: 'Added on', key: 'dateAddedUtc' },
    { label: 'Added by', key: 'addedByUser' },
  ],
  createRow: (r, i) => (
    <Fragment key={i}>
      <div>
        <Tooltip
          title={<TooltipContent label={r.name} description={r.description} />}
          overlayStyle={{ maxWidth: '650px' }}
        >
          <Link external to={`/lists/${r.listId}`}>
            {r.name}
          </Link>
        </Tooltip>
      </div>
      <div>{format(r.dateAddedUtc)}</div>
      <div>{r.addedByUser}</div>
    </Fragment>
  ),
};

const Lists = ({
  lists,
  loading,
  lbl,
  isViewOnly,
  type = views.researchers,
}) => {
  const { width } = useWindowWidth();
  const listsRef = useRef();
  const viewAllRef = useRef();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [buttonIndex, setButtonIndex] = useState();

  useEffect(() => {
    if (listsRef.current) {
      const listsContainer = listsRef.current;
      const _isOverflowing =
        listsContainer.scrollHeight > listsContainer.clientHeight + 3;
      if (isOverflowing) {
        const children = [...listsContainer.children].filter(
          c => !c.className.includes('view-all-list')
        );
        const { bottom: parentBottom } = listsContainer.getBoundingClientRect();
        const targetIndex = children.findIndex(child => {
          const { bottom: childBottom } = child.getBoundingClientRect();
          return childBottom > parentBottom;
        });

        setButtonIndex(targetIndex);
      }
      if (isOverflowing !== _isOverflowing) {
        setIsOverflowing(_isOverflowing);
      }
    }
  }, [isOverflowing, width, lists]);

  useEffect(() => {
    if (buttonIndex && viewAllRef.current && listsRef.current) {
      const listsContainer = listsRef.current;
      const { bottom } = listsContainer.getBoundingClientRect();
      const viewAllButton = viewAllRef.current;
      const { bottom: buttonBottom } = viewAllButton.getBoundingClientRect();
      if (buttonBottom > bottom && buttonIndex > 0) {
        setButtonIndex(s => s - 1);
      }
    }
  }, [buttonIndex, width]);

  const handleClick = list => {
    if (!isViewOnly) {
      const url = list.enabled
        ? `/lists/${list.listId}?view=${type}`
        : `/lists/${list.listId}?view=${type}&includePausedList=true`;
      window.open(url, '_blank');
    }
  };

  const content = (
    <div style={{ width: 390, height: 180, overflow: 'auto' }}>
      <Table
        sortable
        loading={loading}
        loaderSize='small'
        config={config}
        sort={'dateAddedUtc'}
        data={lists || []}
      />
    </div>
  );

  let label = 'Lists:';
  if (loading && !lists) {
    label = 'Loading lists....';
  } else if (!lists || lists.length === 0) {
    label = '';
  }

  let tokens = lists?.map((l, i) => (
    <TokenContainer key={i} isViewOnly={isViewOnly}>
      <Tooltip
        title={
          <div>
            <div style={{ fontWeight: 'bold' }}>{l.name}</div>
            {l.description && <div>Description: {l.description}</div>}
          </div>
        }
        overlayStyle={{ maxWidth: '650px' }}
      >
        <Token key={l.listId} onClick={() => handleClick(l)}>
          {l.isOwner && <Icon icon='user' size='xs' />}
          {l.name}
        </Token>
      </Tooltip>
    </TokenContainer>
  ));

  const viewAll = (
    <Popover content={content} trigger='click' key='view-all'>
      <TokenContainer isViewAll>
        <Token className='view-all-lists' ref={viewAllRef}>
          View All Lists
        </Token>
      </TokenContainer>
    </Popover>
  );

  if (isOverflowing && buttonIndex && tokens) {
    tokens = [
      ...tokens.slice(0, buttonIndex),
      viewAll,
      ...tokens.slice(buttonIndex),
    ];
  }

  return (
    <Container>
      <Label>{lbl ?? label}</Label>
      <ListsContainer ref={listsRef}>{tokens}</ListsContainer>
    </Container>
  );
};

export default Lists;
