import { BorderDiv } from 'components/Alerts/SavedAlerts';
import Icon from '../Icon';
import TablePopover from '../TablePopover';
import { Divider } from 'antd';

const SearchTermsDisplay = ({
  searchTerm,
  parentRef,
  previousEmailsExcluded,
}) => {
  return (
    <div>
      {searchTerm ? (
        <TablePopover
          content={
            <div style={{ alignContent: 'center' }}>
              <div>{searchTerm}</div>
              {previousEmailsExcluded ? (
                <div>
                  <Divider style={{ margin: '5px' }} />
                  <div>*previously exported emails excluded</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          parentRef={parentRef}
          widthRatio={0.7}
          color='#d9d9d9'
        >
          <BorderDiv color='#d9d9d9'>
            <Icon size='sm' icon='code' color='#595959' />
          </BorderDiv>
        </TablePopover>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchTermsDisplay;
