//@ts-check
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { format } from 'utils/date';
import { siNumber } from 'utils/number';

export const HighlightContainer = styled.div`
  margin-left: 5px;
  > div {
    font-size: 14px;
  }
`;
/**
 * @param {{
 *  organisation: import('./index').OrganisationVCFundingDetails
 * }} props
 * @returns {JSX.Element}
 **/
const OrganisationFundingHighlights = ({ organisation }) => {
  const last3Years = dayjs().add(-3, 'year');
  const sortedByAnnoundedDate =
    organisation.superOrganisationFundingRounds
      ?.filter(f => dayjs(f.announcedDate) >= last3Years)
      ?.sort((a, b) => dayjs(a.announcedDate).diff(dayjs(b.announcedDate))) ||
    [];

  /**
   * @param {import('./index').Investor[] | null} investors
   * @return {string}
   */
  function parseInvestors(investors) {
    let investorsString = '';
    if (investors?.length === 1) {
      investorsString = `${investors[0].name} was the investor.`;
    } else if (investors?.length && investors.length > 1) {
      investorsString = `${`${investors
        .map(i => i.name)
        .slice(0, -1)
        .join(', ')} and ${investors.slice(-1)[0].name}` ||
        'Unknown'} were the investors.`;
    }

    return investorsString;
  }

  return (
    <HighlightContainer>
      {sortedByAnnoundedDate.length === 0 ? (
        <div>{`${organisation.organisationName} has not raised any recent funding rounds.`}</div>
      ) : (
        sortedByAnnoundedDate.map(fr => (
          <div key={fr.fundingRoundId}>
            {`${
              fr.raisedAmountUsd
                ? `$${siNumber(fr.raisedAmountUsd)}`
                : 'An unknown amount'
            } was raised on 
            ${format(fr.announcedDate)} from a ${fr.investmentType ||
              'Unknown'} round. ${parseInvestors(fr.elasticInvestors)}`}
          </div>
        ))
      )}
    </HighlightContainer>
  );
};

export default OrganisationFundingHighlights;
