import React from 'react';
import PropTypes from 'prop-types';
import {
  academicWarning,
  commonError,
  commonWarning,
  industryWarning,
  academicError,
  industryError,
  allTrial,
  academicTrial,
  industryTrial,
  disabledExport as disabledExportConstant,
  noNewEmails,
  noContacts,
} from './constants';
import { Alert } from 'antd';
import {
  isAcademicFull,
  isAcademicTrial,
  isFullTrial,
  isIndustryFull,
  isIndustryTrial,
} from 'utils/helpers/license';
import { useAuth } from 'hooks';

function Alerts({
  isOnCreditSystem,
  aboutToReachAcademicLimits,
  aboutToReachIndustryLimits,
  reachedAcademicLimits,
  reachedIndustryLimits,
  disabledExport,
  showAcademicCredits,
  showIndustryCredits,
  noExportEmails,
  noExportContacts,
}) {
  const { user } = useAuth();

  const trialInfo = isFullTrial(user)
    ? allTrial
    : isAcademicTrial(user)
    ? academicTrial
    : isIndustryTrial(user)
    ? industryTrial
    : null;

  const limitsWarning =
    aboutToReachAcademicLimits &&
    aboutToReachIndustryLimits &&
    isAcademicFull(user) &&
    isIndustryFull(user) &&
    !showIndustryCredits &&
    !showAcademicCredits
      ? commonWarning(showAcademicCredits, showIndustryCredits)
      : aboutToReachAcademicLimits &&
        isAcademicFull(user) &&
        !showAcademicCredits
      ? academicWarning(showAcademicCredits)
      : aboutToReachIndustryLimits &&
        isIndustryFull(user) &&
        !showIndustryCredits
      ? industryWarning(showIndustryCredits)
      : null;

  const limitsError =
    reachedAcademicLimits &&
    reachedIndustryLimits &&
    isAcademicFull(user) &&
    isIndustryFull(user)
      ? commonError(showAcademicCredits, showIndustryCredits)
      : reachedAcademicLimits && isAcademicFull(user)
      ? academicError(showAcademicCredits)
      : reachedIndustryLimits && isIndustryFull(user)
      ? industryError(showIndustryCredits)
      : null;

  return (
    <>
      {noExportEmails && !noExportContacts && (
        <Alert
          type='info'
          showIcon
          style={{ fontSize: '12px', marginBottom: '15px' }}
          message={noNewEmails}
        />
      )}
      {noExportContacts && (
        <Alert
          type='warning'
          showIcon
          style={{ fontSize: '12px', marginBottom: '15px' }}
          message={noContacts}
        />
      )}
      {limitsWarning && (
        <Alert
          type='warning'
          showIcon
          style={{ fontSize: '12px', marginBottom: '15px' }}
          message={limitsWarning}
        />
      )}
      {limitsError && (
        <Alert
          type='error'
          showIcon
          style={{ fontSize: '12px', marginBottom: '15px' }}
          message={limitsError}
        />
      )}
      {!disabledExport && trialInfo && (
        <Alert
          type='info'
          showIcon
          style={{ fontSize: '12px', marginBottom: '15px' }}
          message={trialInfo}
        />
      )}
      {disabledExport && (
        <Alert
          type='info'
          showIcon
          style={{ fontSize: '12px', marginBottom: '15px' }}
          message={disabledExportConstant}
        />
      )}
    </>
  );
}

Alerts.propTypes = {
  isOnCreditSystem: PropTypes.bool,
  aboutToReachAcademicLimits: PropTypes.bool,
  aboutToReachIndustryLimits: PropTypes.bool,
  reachedAcademicLimits: PropTypes.bool,
  reachedIndustryLimits: PropTypes.bool,
  disabledExport: PropTypes.bool,
};

export default Alerts;
