import { useEffect } from 'react';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { useGet } from 'hooks';
import { downloadExport } from 'services/api';
import { FullPageLoader } from 'components/common';

export const PerformDownload = res => {
  if (res) {
    const fileName = res.req.getResponseHeader('file-name');
    const contentType = res.req.getResponseHeader('content-type');
    const blob = new Blob([res.data], { type: contentType });
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }
};
const DownloadRedirect = () => {
  const { search, pathname } = useLocation();
  const { replace } = useHistory();
  const { download: downloadKey, source: sourceKey } = qs.parse(search);
  const [{ res, loading }, getDownload] = useGet({
    url: `${downloadExport(downloadKey)}?source=${sourceKey}`,
    responseType: 'blob',
  });

  useEffect(() => {
    if (downloadKey && !res && pathname !== '/notfound') {
      getDownload();
    }
  }, [downloadKey, res, getDownload, pathname]);

  useEffect(() => {
    if (res && downloadKey) {
      PerformDownload(res);
      replace(pathname);
    }
  }, [res, pathname, replace, downloadKey]);

  if (loading) {
    return <FullPageLoader />;
  }
  return null;
};

export default DownloadRedirect;
