import { useSelector, useDispatch } from 'react-redux';

import {
  Input,
  Checkbox,
  TokenInput,
  DatePicker,
  InputNumber,
  Select,
} from 'components/common';
import {
  setPurchasingFilter,
  setPurchasingFilterGroup,
} from 'actions/organisation';
import { getPurchasingFilter } from 'selectors/organisation';

const components = {
  input: Input,
  checkbox: ({ value, ...rest }) => <Checkbox {...rest} checked={value} />,
  token: TokenInput,
  datePicker: DatePicker,
  select: Select,
  number: InputNumber,
};

const FilterField = ({ filterId, filterType, filterGroup, ...rest }) => {
  const Component = components[filterType];

  const value = useSelector(getPurchasingFilter(filterId, filterGroup));
  const dispatch = useDispatch();

  const handleChange = value =>
    dispatch(
      filterGroup
        ? setPurchasingFilterGroup(filterGroup, filterId, value)
        : setPurchasingFilter(filterId, value)
    );

  return (
    <Component
      {...rest}
      data-testid={`filter-${filterId}`}
      onChange={handleChange}
      value={value}
    />
  );
};

export default FilterField;
