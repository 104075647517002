import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import {
  getPurchasingSearchRequest,
  getPurchasingPageSize,
  getPurchasingPageNumber,
  getPurchasingSort,
  getPurchasingSortDir,
  getPurchasingLoading,
} from 'selectors/organisation';
import {
  setPurchasingSort,
  setPurchasingPageSize,
  setPurchasingPageNumber,
} from 'actions/organisation';

import { tableConfig } from './helpers';
import { OrgPurchasingSearchContext } from 'components/Organisation';
import { Table } from '../styled';

export const TableContainer = styled.div`
  padding: 0px 7px;
  max-height: 60vh;
  overflow-y: auto;
`;

const PurchasingResults = ({ organisation, loading }) => {
  const postSearch = useContext(OrgPurchasingSearchContext);
  const dispatch = useDispatch();
  const data = useSelector(getPurchasingSearchRequest(organisation.id));
  const isLoading = useSelector(getPurchasingLoading) || loading;
  const pageSize = useSelector(getPurchasingPageSize);
  const pageNumber = useSelector(getPurchasingPageNumber);
  const sort = useSelector(getPurchasingSort);
  const sortDir = useSelector(getPurchasingSortDir);

  const handleSortChange = sort => {
    dispatch(setPurchasingSort(sort, postSearch));
  };

  const handleChangePageSize = size => {
    dispatch(setPurchasingPageSize(size, postSearch));
  };

  const handleChangePageNumber = number => {
    dispatch(setPurchasingPageNumber(number, postSearch));
  };

  return (
    organisation && (
      <Table
        paginate
        sortable
        config={tableConfig}
        data={data ? data.results : []}
        loading={isLoading}
        pageSize={pageSize}
        pageNumber={pageNumber}
        sort={sort}
        sortDir={sortDir}
        onSortChange={handleSortChange}
        onChangePageSize={handleChangePageSize}
        onChangePageNumber={handleChangePageNumber}
        resultSize={data ? data.totalResults : 0}
        className={'researchers-table'}
      />
    )
  );
};

export default PurchasingResults;
