export const frequencyEnums = {
  NoAlert: 0,
  Weekly: 1,
  Monthly: 2,
  Daily: 3,
};

export const frequencyOptions = [
  { value: 'Monthly Alert', label: 'Monthly', enumValue: 2 },
  { value: 'Weekly Alert', label: 'Weekly', enumValue: 1 },
  { value: 'Daily Alert', label: 'Daily', enumValue: 3 },
];

export const frequencyRadioOptions = [
  { value: 'Saved Search', label: 'Saved Search' },
  { value: 'Alert', label: 'Alert' },
];
