const orOperator = ' || ';
const conditionSymbols = [orOperator];

//currently supports only || operator
export const isValidCondition = (needs, user) => {
  let conditions = [];
  conditionSymbols.forEach(condition => {
    if (needs.includes(condition)) {
      conditions = needs.split(condition);
    }
  });

  if (conditions.length === 0 && user[needs]) {
    return true;
  }

  for (let i = 0; i < conditions.length; i++) {
    if (user[conditions[i]] && needs.includes(orOperator)) {
      return true;
    }
  }
  return false;
};

export const isFunction = value => typeof value === 'function';
