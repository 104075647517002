import styled from 'styled-components';
import { Alert } from 'antd';

import licenseType from 'models/licenseType';

import Tradeshows from './Tradeshows';
import Countries from './Countries';
import Organisations from './Organisations';
import Journals from './Journals';
import States from './States';
import FocusedResearchAreas from './FocusedResearchAreas';
import ResearchersByYear from './ResearchersByYear';
import Funding from './Funding';
import Publications from './Publications';
import { useAuth } from 'hooks';
import { ResearcherSearch } from '../helpers';
import { useSelector } from 'react-redux';
import { getSearchView } from 'selectors/search';
import OrgExhibitors from './OrgExhibitors';
import OrgVCFunding from './OrgVCFunding';
import TopInvestors from './TopInvestors';
import Regions from './Regions';

const VisualisationGroup = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StyledAlert = styled(Alert)`
  max-width: 600px;
  width: 100%;
  text-align: center;
  margin: 25px auto;
`;

const ResearcherDataView = ({
  topTradeshows,
  topCountries,
  fundingByYear,
  publicationsByYear,
  researchersByYear,
  topUsStates,
  topMeshTopics,
  regions,
  tables,
}) => {
  const { user } = useAuth();

  return (
    <>
      <VisualisationGroup>
        <Tradeshows
          label={'Researcher Tradeshows'}
          helpText={
            'A summary of tradeshow attendance for the researchers in your results.'
          }
          data={topTradeshows}
          aspectRatio={4}
        />
      </VisualisationGroup>

      <VisualisationGroup>
        <FocusedResearchAreas
          data={topMeshTopics}
          fullWidth={!!user.showAdditionalGraphs === false}
        />
        {!!user.showAdditionalGraphs && (
          <ResearchersByYear data={researchersByYear} />
        )}
      </VisualisationGroup>

      <VisualisationGroup>
        <Regions
          data={regions}
          label={'Researcher Regions'}
          helpText={
            'A summary of regions based on the researchers in your search'
          }
        />
      </VisualisationGroup>
      <VisualisationGroup>
        <Countries
          data={topCountries}
          label={'Researcher Countries'}
          helpText={
            'A summary of countries based on the researchers in your search'
          }
        />
        <States
          data={topUsStates}
          label='Researcher US States'
          helpText={
            'A summary of states based on the researchers in your search'
          }
        />
      </VisualisationGroup>

      {!!user.showAdditionalGraphs && (
        <VisualisationGroup>
          <Funding data={fundingByYear} />
          <Publications data={publicationsByYear} />
        </VisualisationGroup>
      )}
      <VisualisationGroup>
        <Organisations data={tables?.fundingByOrganisation} />
        <Journals data={tables?.topJournals} />
      </VisualisationGroup>
    </>
  );
};

const OrganisationDataView = ({
  topTradeshows,
  topExhibited,
  topVCFunding,
  topCountries,
  topUsStates,
  regions,
  tables,
}) => {
  const { hasLicense } = useAuth();
  const hasIndustryLicense = hasLicense([licenseType.industry], true);

  return (
    <>
      <VisualisationGroup>
        <Tradeshows
          label={'Organisation Tradeshows'}
          helpText={
            'A summary of tradeshow attendance for the organisations in your results.'
          }
          data={topTradeshows}
        />
      </VisualisationGroup>
      <VisualisationGroup>
        {hasIndustryLicense && <OrgVCFunding data={topVCFunding} />}
        <OrgExhibitors data={topExhibited} fullWidth={!hasIndustryLicense} />
      </VisualisationGroup>
      <VisualisationGroup>
        <Regions
          data={regions}
          label={'Organisation Regions'}
          helpText={
            'A summary of regions based on the organisations in your search'
          }
        />
      </VisualisationGroup>
      <VisualisationGroup>
        <Countries
          data={topCountries}
          label={'Organisation Countries'}
          helpText={
            'A summary of countries based on the organisations in your search'
          }
        />
        <States
          data={topUsStates}
          label='Organisation US States'
          helpText={
            'A summary of states based on the organisations in your search'
          }
        />
      </VisualisationGroup>

      <VisualisationGroup>
        <TopInvestors data={tables?.topInvestors} />
      </VisualisationGroup>
    </>
  );
};

const DataView = props => {
  const isResearcherView = ResearcherSearch(useSelector(getSearchView));

  return (
    <>
      {isResearcherView ? (
        <ResearcherDataView {...props} />
      ) : (
        <OrganisationDataView {...props} />
      )}
      <StyledAlert
        message='Want to see more charts and insights? Feel free to drop your suggestions into the live chat!'
        type='info'
      />
    </>
  );
};

export default DataView;
