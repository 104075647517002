import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border: ${p =>
    p.isFocused ? '1px solid #40a9ff' : '1px solid rgba(0, 0, 0, 0.15)'};
  border-radius: 2px;
  flex-wrap: wrap;
  padding: 5px 5px 0px 5px;
  cursor: text;
  position: relative;
  font-size: 14px;
  transition: all 0.3s ease;
  background: ${p => (p.disabled ? '#f5f5f5' : 'white')};
  pointer-events: ${p => (p.disabled ? 'none' : 'all')};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'default')};
  box-shadow: ${p =>
    p.isFocused
      ? '0 0 0 2px rgba(24, 144, 255, 0.2)'
      : '0 0 0 0px rgba(24, 144, 255, 0.2)'};

  > input {
    border: none;
    padding: 0px 6px;
    height: auto;
    flex: 1;
    min-width: 50px;
    margin-bottom: 5px;
    background: ${p => (p.disabled ? '#f5f5f5' : 'white')};
    :focus {
      outline: none;
      border: 0;
      box-shadow: none;
    }
  }
  :hover {
    border: ${p =>
      p.disabled ? '1px solid rgba(0, 0, 0, 0.15)' : '1px solid #40a9ff'};
  }
`;

export const TokenContainer = styled.div`
  display: flex;
  background: ${p => p.background ?? p.theme.teal};
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  padding: 0px 5px;
  border-radius: 3px;
  margin: 0px 5px 5px 0;
  align-items: center;
  word-break: break-word;
  cursor: default;
  > div {
    padding: 3px;
    cursor: pointer;
  }
`;

export const OptionContainer = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  z-index: 2;
`;

export const OptionContent = styled.div`
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  word-break: break-word;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 5px;
  max-height: ${p => (p.scrollable ? '300px' : 'unset')};
  overflow: ${p => (p.scrollable ? 'auto' : 'visible')};
  scrollbar-width: thin;
`;

export const Option = styled.div`
  cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  padding: 5px 10px;
  color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'inherit')};
  :hover {
    background: ${props =>
      props.disabled
        ? props.disabledChangesOnHover
          ? 'rgba(0, 0, 0, 0.05)'
          : 'inherit'
        : 'rgba(0, 0, 0, 0.05)'};
  }
`;
