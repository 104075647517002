import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ImageContainer, TitleContainer, SubTitleContainer } from './styled';

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

function FeatureDetails({ className, style, imageUrl, title, subTitle }) {
  return (
    <div className={className} style={style}>
      <ContentContainer>
        <ImageContainer>
          <img src={imageUrl} alt='feature' />
        </ImageContainer>
        <TitleContainer>{title}</TitleContainer>
        <SubTitleContainer>{subTitle}</SubTitleContainer>
      </ContentContainer>
    </div>
  );
}

FeatureDetails.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default FeatureDetails;
