import React from 'react';
import PropTypes from 'prop-types';
import GooglePlay from './googleplay.png';
import AppStore from './itunes.png';

const imageLinks = {
  ios: AppStore,
  android: GooglePlay,
};

class MobileStoreButton extends React.Component {
  static propTypes = {
    store: PropTypes.oneOf(['ios', 'android']).isRequired,
    url: PropTypes.string.isRequired,
    linkStyles: PropTypes.object,
    linkProps: PropTypes.object,
  };

  render() {
    const { store, url, linkStyles, linkProps, ...props } = this.props;

    const defaultLinkStyles = {
      display: 'inline-block',
      overflow: 'hidden',
      textDecoration: 'none',
      height: '100%',
      width: '50%',
      ...linkStyles,
    };

    return (
      <div style={defaultLinkStyles} {...props}>
        <a href={url} target='_blank' rel='noopener noreferrer' {...linkProps}>
          <img
            src={imageLinks[store]}
            style={{ margin: 0 }}
            width='95%'
            alt='Google Authenticator App Store Links'
          />
        </a>
      </div>
    );
  }
}

export default MobileStoreButton;
