import { Redirect } from 'react-router-dom';

import { useAuth } from 'hooks';
import { Route } from 'components/common';

const AuthedRoute = ({ redirect, children, validate, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={() => {
        if (validate && !validate(user) && !redirect) return null;
        if (validate && !validate(user)) return <Redirect to={redirect} />;
        return children;
      }}
    />
  );
};

export default AuthedRoute;
