import React from 'react';

import Detail from '../Detail';
import Select from '../Select';

const ListCRMLink = ({ options, value, loading, onChange }) => {
  return (
    <Detail label='Target CRM campaign/list' style={{ marginTop: 15 }}>
      <Select
        showSearch
        allowClear
        placeholder='Not Linked'
        options={options}
        style={{ width: '100%' }}
        value={loading ? 'Loading...' : value}
        disabled={!options.length || loading}
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disableTooltip
      />
    </Detail>
  );
};

export default ListCRMLink;
