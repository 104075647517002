import { useState } from 'react';

import { Button, Input } from 'components/common';

import { FeedbackButtons } from './styled';

const Feedback = ({ onSubmit, onDismiss }) => {
  const [npsFeedback, setNpsFeedback] = useState('');

  const handleChange = value => {
    setNpsFeedback(value);
  };

  return (
    <>
      <span style={{ fontSize: 14, fontWeight: 400 }}>
        Can you share any specific feedback?
      </span>
      <Input
        data-testid='feedbackTextArea'
        placeholder='Type feedback here..'
        multiline
        value={npsFeedback}
        onChange={handleChange}
        rows='4'
        maxLength='1000'
        style={{ marginTop: 5 }}
      />
      <FeedbackButtons>
        <Button type='scondary' size='small' onClick={() => onDismiss()}>
          Not now
        </Button>
        <Button
          data-testid='btnSubmitFeedback'
          type='primary'
          style={{ marginLeft: '10px' }}
          size='small'
          onClick={() => onSubmit(npsFeedback)}
          disabled={npsFeedback.length < 1}
        >
          Submit
        </Button>
      </FeedbackButtons>
    </>
  );
};

export default Feedback;
