import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGet, useAuth } from 'hooks';
import { getListsForUser, getOrgListsForUser } from 'services/api';
import {
  getActiveList,
  getIsLoading,
  getSelectedMembers,
} from 'selectors/lists';
import { setModal, setAddToTarget, setExportExtraParams } from 'actions/lists';
import { Button, Tooltip, Select } from 'components/common';
import { LABEL_BUTTON_ADD_NEW_LIST } from 'components/common/labelConstants';

import { ActionsContainer } from './styled';
import { accessLevels } from '../helpers';
import { views } from '../constants';
import listRenderOption from 'components/common/Lists/ListRenderOptions';
import Export from 'components/Export';
import { downloadType } from 'components/Export/Summary/constants';
import useExport from '../Actions/useExport';

const AddToList = ({
  disabled,
  onCreateNewItemList,
  view = views.researchers,
}) => {
  const dispatch = useDispatch();
  const activeList = useSelector(getActiveList);
  const activeListRef = useRef();
  const [{ res: editableLists, loading }, getEditableLists] = useGet({
    url: view === views.researchers ? getListsForUser : getOrgListsForUser,
    query: { onlyEditable: true },
  });

  useEffect(() => {
    if (activeList && activeList.id) {
      if (activeListRef.current && activeListRef.current.id === activeList.id) {
        return;
      }

      getEditableLists();
      activeListRef.current = activeList;
    }
  }, [activeList, getEditableLists]);

  const handleAddTo = id => {
    if (id === 'no_lists') return;
    const targetList = editableLists.find(l => l.id === id);

    dispatch(setAddToTarget(targetList));
    dispatch(setModal('addTo'));
  };

  const addToListOptions =
    editableLists && editableLists.length > 0
      ? editableLists
          .filter(l => l.id !== activeList?.id)
          .map(l => ({
            label: l.name,
            value: l.id,
            description: l.description,
          }))
      : [{ label: 'No lists available', value: 'no_lists' }];

  if (addToListOptions.length === 0) {
    addToListOptions.push({ label: 'No lists available', value: 'no_lists' });
  }
  const isDisabled = disabled || loading;

  return (
    <Select
      showSearch
      size='small'
      placeholder='Add to List'
      value='Add to List'
      onCreateNewItem={onCreateNewItemList}
      buttonText={LABEL_BUTTON_ADD_NEW_LIST}
      options={addToListOptions}
      style={{ width: 150 }}
      renderOption={(o, i) => listRenderOption({ ...o }, i)}
      dropdownStyle={{ minWidth: 300 }}
      filterOption={(input, option) =>
        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={isDisabled}
      onChange={handleAddTo}
      disableTooltip
    />
  );
};

const Actions = ({
  totalMembers,
  disabled,
  loading,
  onCreateNewItemList,
  view = views.researchers,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const activeList = useSelector(getActiveList);
  const selectedMembers = useSelector(getSelectedMembers);
  const getExportBody = useExport();
  const { user } = useAuth();
  let selectedMembersCount = 0;

  if (selectedMembers.all) {
    selectedMembersCount =
      totalMembers - Object.keys(selectedMembers.exclude).length;
  } else {
    selectedMembersCount = Object.keys(selectedMembers.include).length;
  }

  const handleClick = modal => {
    dispatch(setModal(modal));
  };

  const canModify =
    (accessLevels.modifyMembers.includes(activeList.userAccessLevel) ||
      user.isAccountManager) &&
    activeList.enabled;
  const canPush =
    accessLevels.push.includes(activeList.userAccessLevel) ||
    user.isAccountManager;
  const hasCrmIntegration = user.canPushToSalesforce || user.canPushToHubspot;

  const hasPermissionToPushExport = !user.isRateLimited && !user.disableExport;

  const showTotalMembers = totalMembers > 0 && !loading;
  const disableCRMPush =
    disabled ||
    !showTotalMembers ||
    !hasCrmIntegration ||
    !hasPermissionToPushExport ||
    !canPush ||
    (!user.isHubspotAuthenticated && !user.isSalesforceAuthenticated);

  return (
    <ActionsContainer>
      {hasPermissionToPushExport &&
        (view === views.researchers ? (
          <Export
            downloadType={downloadType.researcherList}
            getQueryBody={getExportBody}
            disabled={disabled || !showTotalMembers || isLoading}
            onExport={params => {
              dispatch(setExportExtraParams(params));
              handleClick('export');
            }}
          />
        ) : (
          <Export
            downloadType={downloadType.organisationList}
            getQueryBody={getExportBody}
            disabled={disabled || !showTotalMembers || isLoading}
            onExport={params => {
              dispatch(setExportExtraParams(params));
              handleClick('export');
            }}
            canUseCredits={false}
          />
        ))}
      {view === views.researchers && hasPermissionToPushExport && (
        <Tooltip
          title={
            !canPush && 'You do not have permission to perform this action'
          }
        >
          <div>
            <Export
              downloadType={downloadType.crmPush}
              getQueryBody={getExportBody}
              disabled={disableCRMPush || isLoading}
              onExport={params => {
                dispatch(setExportExtraParams(params));
                handleClick('pushCRM');
              }}
            >
              Push to CRM
            </Export>
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={
          !canModify && 'You do not have permission to perform this action'
        }
      >
        <div>
          <Button
            disabled={disabled || !canModify || !showTotalMembers}
            size='small'
            onClick={() => handleClick('removeFrom')}
          >
            Remove Members
          </Button>
        </div>
      </Tooltip>
      <AddToList
        disabled={disabled}
        onCreateNewItemList={onCreateNewItemList}
        view={view}
      />
      {showTotalMembers && (
        <div style={{ fontSize: 13 }}>
          {selectedMembersCount} of {totalMembers} members selected
        </div>
      )}
      <Tooltip
        title={!canPush && 'You do not have permission to perform this action'}
      ></Tooltip>
    </ActionsContainer>
  );
};

export default Actions;
