import { useState } from 'react';
import { Modal as AntModal } from 'antd';

import { Icon, Input } from 'components/common';
import { colours } from 'utils/theme';

import { ModalContent, FieldOptions, FieldOption } from './styled';

const Modal = ({ field, setField, researcherFields, onOk }) => {
  const [expression, setExpression] = useState(field.mapping.expression);

  const addExpression = mapping => {
    setExpression(s => {
      if (!s) {
        return `{{${mapping.name}}}`;
      } else {
        return s + `{{${mapping.name}}}`;
      }
    });
  };

  return (
    <AntModal
      open
      width={650}
      title={`Edit Mapping for ${field.sfName}`}
      onCancel={() => setField()}
      onOk={() => onOk(expression, field)}
    >
      <ModalContent>
        <FieldOptions>
          {researcherFields.map((r, i) => (
            <FieldOption key={i} onClick={() => addExpression(r)}>
              <div>
                <div className='name'>{r.displayName}</div>
                <div className='description'>{r.description}</div>
              </div>
              <Icon icon='caret' color={colours.grey} size='sm' />
            </FieldOption>
          ))}
        </FieldOptions>
        <Input
          style={{ flex: 1 }}
          multiline
          onChange={setExpression}
          value={expression}
          spellCheck={false}
        />
      </ModalContent>
    </AntModal>
  );
};

export default Modal;
