import { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import copy from 'copy-to-clipboard';

import { Icon, Tooltip } from 'components/common';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  cursor: pointer;
  .copy-value {
    color: #1890ff;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .copy-icon {
    margin-left: 5px;
  }
`;

const Copy = ({ value, style = {} }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = e => {
    e.stopPropagation();
    copy(value);
    setCopied(true);
  };

  const handleTooltipOpen = visible => {
    if (visible) {
      setCopied(false);
    }
  };

  return (
    <Tooltip
      title={copied ? 'Copied' : 'Click to copy'}
      onOpenChange={handleTooltipOpen}
      placement='right'
      mouseLeaveDelay={0}
    >
      <Container onClick={handleCopy} style={style}>
        <div className='copy-value'>{value}</div>
        <Icon className='copy-icon' size='1x' icon='copy' color='#1890ff' />
      </Container>
    </Tooltip>
  );
};

Copy.propTypes = {
  value: PropTypes.string.isRequired,
  style: PropTypes.object,
};

Copy.defaultProps = {
  style: {},
};

export default Copy;
