import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';

import {
  Detail,
  Icon,
  Input,
  LoadingBar,
  Tooltip,
  Button,
} from 'components/common';
import useStreamValue from './useStreamValue';

const Container = styled(Detail)`
  position: relative;
  .loader {
    position: absolute;
    top: 18px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 19px);
    display: flex;
    align-items: center;
    z-index: 2;
    background-color: #fff;
    padding-left: 10px;
  }
`;

const CopyButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  color: #161616;
  button: hover svg,
  button: focus svg,
  button: active svg {
    color: #40a9ff;
  }
  ,
  button: disabled svg {
    color: #d9d9d9;
  }
`;

function Subject({ subject, loading, style, shouldStream, disabled }) {
  const [copied, setCopied] = useState(false);
  const handleCopy = e => {
    copy(subject);
    setCopied(true);
  };

  const handleTooltipOpen = visible => {
    if (visible) {
      setCopied(false);
    }
  };

  const [streamedValue] = useStreamValue(subject, shouldStream);

  return (
    <Container label='Subject' style={style}>
      <Input
        readOnly
        value={streamedValue}
        disabled={disabled}
        style={{ paddingRight: '18px' }}
      />

      {loading && (
        <div
          className='loader'
          style={{
            position: 'absolute',
            top: '18px',
            left: '1px',
            width: 'calc(100% - 2px)',
            height: '30px',
          }}
        >
          <LoadingBar width={389} height={18} />
        </div>
      )}

      <CopyButtonContainer onClick={e => e?.stopPropagation()}>
        <Tooltip
          title={!streamedValue ? '' : copied ? 'Copied' : 'Copy subject'}
          onOpenChange={handleTooltipOpen}
          placement='left'
          mouseLeaveDelay={0}
        >
          <Button
            type='default'
            size='middle'
            disabled={disabled}
            icon={
              <Icon
                icon='copy'
                color='#161616'
                size='lg'
                style={{ marginRight: '8px' }}
              />
            }
            style={{ marginTop: '16px' }}
            onClick={() => {
              if (!loading && !disabled) {
                handleCopy();
              }
            }}
          >
            Copy subject text
          </Button>
        </Tooltip>
      </CopyButtonContainer>
    </Container>
  );
}

Subject.propTypes = {
  style: PropTypes.shape({}),
  subject: PropTypes.string,
  loading: PropTypes.bool,
  shouldStream: PropTypes.bool,
};

export default Subject;
