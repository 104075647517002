import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/common';
const StyledButton = styled(Button)`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  color: rgba(60, 64, 67, 1);
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-family: 'Google Sans', 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-grow: 1;
  letter-spacing: 0.25px;
  padding: 0 12px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f6fafe;
  }

  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`;

const TextWrapper = styled.span`
  flex-grow: 1;
  text-align: center;
  margin-left: 12px;
`;

const MicrosoftLogo = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 23 23'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path fill='#f35325' d='M1 1h10v10H1z' />
    <path fill='#81bc06' d='M12 1h10v10H12z' />
    <path fill='#05a6f0' d='M1 12h10v10H1z' />
    <path fill='#ffba08' d='M12 12h10v10H12z' />
  </svg>
);

const MicrosoftLoginButton = ({ onClick, disabled }) => (
  <StyledButton onClick={onClick} disabled={disabled}>
    <IconWrapper>
      <MicrosoftLogo />
    </IconWrapper>
    <TextWrapper>Sign in with Microsoft</TextWrapper>
  </StyledButton>
);

export default MicrosoftLoginButton;
