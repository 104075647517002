//ts-check
import React from 'react';
import {
  ORGANISATIONACQUISITIONSVIEW,
  ORGANISATIONINVESTMENTSVIEW,
  ORGANISATIONVCFUNDINGVIEW,
} from '../helpers';

/**
 * Shows the crunchbase attribution if the activeTab is vcFunding
 * @param {{
 * activeTab: string
 * }} props
 * @returns
 */
const CrunchbaseAttribution = ({ activeTab }) => {
  const s3Bucket = process.env.REACT_APP_PORTAL_CDN || 'dev-scil-portal-cdn';
  const crunchbaseImgSrc = `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/images/logos/powered_by_crunchbase.png`;

  return (
    <>
      {(activeTab === ORGANISATIONVCFUNDINGVIEW ||
        activeTab === ORGANISATIONACQUISITIONSVIEW ||
        activeTab === ORGANISATIONINVESTMENTSVIEW) && (
        <div style={{ marginTop: '-45px' }}>
          <a
            href='http://www.crunchbase.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              height={50}
              src={crunchbaseImgSrc}
              onError={e => {
                e.target.src =
                  'https://files.readme.io/0f56592-powered_by_crunchbase_1.png';
              }}
              alt='Powered by Crunchbase'
            />
          </a>
        </div>
      )}
    </>
  );
};

export default CrunchbaseAttribution;
