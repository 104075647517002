import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ChartJS from 'chart.js';

import { Loader } from 'components/common';

const Container = styled.div`
  position: inherit;
  width: auto;
  height: ${props => props.height};
  canvas {
    width: 100% !important;
  }
`;

const Graphs = ({
  summaryData,
  showLegend,
  type,
  loading,
  count,
  height,
  labelLength,
}) => {
  const ref = useRef();
  const chartRef = useRef();

  useEffect(() => {
    if (!ref.current) return;

    let slicedDatasets;
    let slicedLabels;
    if (count) {
      slicedDatasets = summaryData.datasets.slice(0, count);
      slicedLabels = summaryData.labels.slice(0, count);
    } else {
      slicedDatasets = summaryData.datasets;
      slicedLabels = summaryData.labels;
    }

    const ctx = ref.current.getContext('2d');
    if (chartRef.current) chartRef.current.destroy();

    chartRef.current = new ChartJS(ctx, {
      type: type,
      data: {
        labels: slicedLabels,
        datasets: slicedDatasets,
      },
      options: {
        legend: {
          display: showLegend,
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                autoSkip: false,
                callback: function(value) {
                  if (typeof value === 'string' && value.length > labelLength)
                    return value.substr(0, labelLength) + '...';
                  else return value;
                },
              },
            },
          ],
        },
      },
    });
  }, [summaryData, showLegend, type, count, labelLength]);

  if (loading) return <Loader />;

  return (
    <Container height={height}>
      <canvas ref={ref} />
    </Container>
  );
};

export default Graphs;
