import { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Pane as CommonPane } from 'components/common';
import { log } from 'services/api';
import refreshToken from 'hooks/xhr/refreshToken';

const Pane = styled(CommonPane)`
  .content {
    font-size: 13px;
  }
`;

const logErrorRequest = (error, location, version) => {
  fetch(log, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      description: `Route ${
        location.pathname
      } - ${error.toString()} - v${version}`,
      action: 'UIError',
      outcome: 'Failure',
    }),
  });
};

class ErrorBoundary extends Component {
  state = { hasError: false, error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    logErrorRequest(error, this.props.location, this.props.version).then(
      async data => {
        if (data.status === 401) {
          await refreshToken();
          logErrorRequest(error, this.props.location, this.props.version);
        }
      }
    );
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.state.hasError
    ) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError)
      return (
        <Pane title='Whoops!' style={{ fontSize: 13 }}>
          <p>
            Something went wrong while processing your request, please refresh
            the page and try again.
          </p>
          <p>
            The issue has been reported to our team, but if you continue to
            receive this error please reach out to us via live chat.
          </p>
        </Pane>
      );
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
