import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notification } from 'antd';

import { StickyPane } from 'components/common';
import { useAuth, usePost } from 'hooks';
import {
  getLastSuccessfulQuery,
  getSearchRequest,
  getPageSize,
  getPageNumber,
  getSort,
  getSortDir,
  getFilters,
} from 'selectors/purchasing';
import { setSort, setPageSize, setPageNumber } from 'actions/purchasing';
import { exportPurchasing, largeWarning } from 'services/api';

import { tableConfig } from './helpers';
import { PurchasingContext } from '../';
import { Table } from '../styled';
import { ewrcaNotification } from 'components/Search/Results/Actions/notifications';
import PurchasingExport from './Export';
import { RemoveNonEmptyDate } from 'components/Search/Filters/keys';

const Results = () => {
  const { user } = useAuth();
  const postSearch = useContext(PurchasingContext);
  const dispatch = useDispatch();
  const { res: data, loading } = useSelector(getSearchRequest);
  const filters = useSelector(getFilters);
  const { tooLargeToDownload } = data;
  const [{ res: exportRes, loading: exporting }, postExport] = usePost({
    url: tooLargeToDownload ? largeWarning('Purchasing') : exportPurchasing,
  });
  const pageSize = useSelector(getPageSize);
  const pageNumber = useSelector(getPageNumber);
  const sort = useSelector(getSort);
  const sortDir = useSelector(getSortDir);
  const lastSuccessfulQuery = useSelector(getLastSuccessfulQuery);

  useEffect(() => {
    if (exportRes) {
      notification.open(
        tooLargeToDownload
          ? ewrcaNotification
          : {
              message: 'Generating Report',
              description:
                'We are generating your report now and will notify you when it is ready.',
              placement: 'bottomLeft',
            }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportRes]);

  const handleSortChange = sort => {
    dispatch(setSort(sort, postSearch));
  };

  const handleChangePageSize = size => {
    dispatch(setPageSize(size, postSearch));
  };

  const handleChangePageNumber = number => {
    dispatch(setPageNumber(number, postSearch));
  };

  const handleExport = (additionalParams) => {
    let tmpFilters = {...filters};
    RemoveNonEmptyDate(tmpFilters);
    postExport({
      query: lastSuccessfulQuery,
      count: pageSize,
      structuredSearch : JSON.stringify(tmpFilters),
      from: pageNumber * pageSize,
      order: sort,
      orderBy: sortDir,
      fileName: additionalParams.fileName
    });
  };

  const { totalResults } = data;

  const canExport = lastSuccessfulQuery && !loading && !exporting;

  return (
    <StickyPane
      title='Search Results'
      headerRight={
        !user.isRateLimited ? (
          <PurchasingExport onExport={handleExport} canExport={canExport} />
        ) : null
      }
    >
      <Table
        paginate
        sortable
        config={tableConfig}
        data={data.results}
        loading={loading}
        pageSize={pageSize}
        pageNumber={pageNumber}
        sort={sort}
        sortDir={sortDir}
        onSortChange={handleSortChange}
        onChangePageSize={handleChangePageSize}
        onChangePageNumber={handleChangePageNumber}
        resultSize={totalResults}
      />
    </StickyPane>
  );
};

export default Results;
