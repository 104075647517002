import React from 'react';
import styled from 'styled-components';
import { Statistic } from 'components/common';
import { colours } from 'utils/theme';
import { useSelector } from 'react-redux';
import {
  getOrganisationResearchersTotals,
  getOrganisationTradeShowTotals,
  getOrganisationClinicalTrialTotals,
  getOrganisationView,
  getOrganisationWorkItemTotals,
} from 'selectors/organisation';

const Stats = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-bottom: ${p => p.theme.gutter};
  > div {
    flex: 1;
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

const ResearcherStatistics = ({
  organisation,
  loading,
  displayWhenZero = 0,
}) => {
  const view = useSelector(getOrganisationView);
  const researcherTotals = useSelector(
    getOrganisationResearchersTotals(organisation.id)
  );
  const workItemTotals = useSelector(
    getOrganisationWorkItemTotals(organisation.id)
  );
  const tradeshowTotals = useSelector(
    getOrganisationTradeShowTotals(organisation.id)
  );
  const clinicalTrialTotals = useSelector(
    getOrganisationClinicalTrialTotals(organisation.id)
  );

  let totals;

  if (view === 'researchers') {
    totals = researcherTotals;
  } else if (view === 'workItems') {
    totals = workItemTotals;
  } else if (view === 'tradeshows') {
    totals = tradeshowTotals;
  } else if (view === 'clinicalTrials') {
    totals = clinicalTrialTotals;
  }

  if (loading) return null;
  return (
    <Stats>
      <Statistic
        icon='users'
        iconColor={colours.posters}
        title='People'
        value={totals ? totals.people : displayWhenZero}
      />
      <Statistic
        icon='email'
        iconColor={colours.posters}
        title='Emails'
        value={totals ? totals.emailCount : displayWhenZero}
      />
      <Statistic
        icon='map'
        iconColor={colours.publications}
        title='Countries'
        value={totals ? totals.countries : displayWhenZero}
      />
      <Statistic
        icon='folderOpen'
        iconColor={colours.publications}
        title='Activities'
        value={totals ? totals.activities : displayWhenZero}
      />
    </Stats>
  );
};

export default ResearcherStatistics;
