import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'components/common';
import { colours } from 'utils/theme';

function DetailsTooltip({ title }) {
  return (
    <Tooltip title={title} placement='right'>
      <span style={{ fontSize: '12px', cursor: 'pointer', marginLeft: '3px' }}>
        <Icon icon='help' color={colours.teal} size='1x' />
      </span>
    </Tooltip>
  );
}

DetailsTooltip.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DetailsTooltip;
