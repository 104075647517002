import { useRef } from 'react';
import { Button } from 'components/common';
import styled from 'styled-components';

import { usePost } from 'hooks';
import { colours } from 'utils/theme';
import { log } from 'services/api';

const Container = styled.div`
  margin-left: auto;
  button {
    color: ${colours.linkBlue};
    font-weight: bold;
    background: transparent;
    &:hover {
      background: white;
      color: ${colours.linkBlue};
    }
    &:active {
      background: transparent;
      color: ${colours.linkBlue};
    }
    &:focus {
      background: transparent;
      color: ${colours.linkBlue};
    }
  }
`;

const referralUrl = 'https://scileads.com/refer-a-friend-to-scileads/';

const Referrals = () => {
  const [, logReferralClick] = usePost({
    url: log,
  });
  const ref = useRef();

  const handleClick = () => {
    ref.current.blur();
    window.open(referralUrl, '_blank');
    logReferralClick({
      description: `User clicked to referral button.`,
      action: 'ReferralClick',
      outcome: 'Success',
    });
  };

  return (
    <Container>
      <Button type='text' onClick={handleClick} ref={ref}>
        Refer a Friend
      </Button>
    </Container>
  );
};

export default Referrals;
