//@ts-check
/**
 * Takes an array and returns the distinct items using the predicate
 * @template T
 * @param {T[]} array
 * @param {(item: T) => any} predicate
 * @returns {T[]}
 */
export function DistinctBy(array, predicate) {
  if (!array?.length) {
    return [];
  }

  const map = new Map();

  for (const item of array) {
    map.set(predicate(item), item);
  }

  return Array.from(map.values());
}

/**
 * Selects an array using the predicate from an input array which is then flattened into a single array
 * @template I, O
 * @param {I[]} arrayOfArrays
 * @param {(inputItem: I) => O[]} predicate
 * @returns {O[]}
 */
export function SelectMany(arrayOfArrays, predicate) {
  if (!arrayOfArrays?.length) {
    return [];
  }

  /** @type {O[]} */
  const flattenedArray = [];

  for (const arrayItem of arrayOfArrays) {
    for (const item of predicate(arrayItem)) {
      flattenedArray.push(item);
    }
  }

  return flattenedArray;
}
