import { useEffect, useState } from 'react';

import { useWindowWidth } from 'hooks';
import { Icon } from 'components/common';
import { colours } from 'utils/theme';

import {
  Container,
  Tabs,
  Tab,
  HorizontalTab,
  Content,
  Text,
  TabExtenstionContainer,
} from './styled';

const ControlledTabbedPanel = ({
  config = {},
  horizontal,
  activeTab,
  onActiveTabChange,
  leftMargin,
  children,
}) => {
  const { isMobile, isTablet, isSmallerLaptop } = useWindowWidth();
  const keys = Object.keys(config);

  const TabComponent = horizontal ? HorizontalTab : Tab;

  const isSmallerScreen =
    (Object.entries(config).length > 3 ? isTablet : isMobile) ||
    (Object.entries(config).length > 6 && isSmallerLaptop);

  return (
    <Container horizontal={horizontal}>
      <TabExtenstionContainer horizontal={horizontal}>
        <Tabs horizontal={horizontal} leftMargin={leftMargin}>
          {Object.entries(config).map(([key, value], i) => (
            <TabComponent
              key={key}
              active={activeTab ? activeTab === key : i === 0}
              onClick={() => onActiveTabChange(key)}
              horizontal={horizontal}
            >
              <Icon
                icon={value.icon}
                size='lg'
                title={value.text}
                color={
                  (activeTab
                  ? activeTab === key
                  : i === 0)
                    ? colours.grey
                    : colours.lightGrey
                }
              />
              {!isSmallerScreen && (
                <Text>{horizontal ? value.text : null}</Text>
              )}
            </TabComponent>
          ))}
        </Tabs>
        {children}
      </TabExtenstionContainer>
      <Content>{config[activeTab || keys[0]]?.content}</Content>
    </Container>
  );
};

const UncontrolledTabbedPanel = props => {
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    setActiveTab(props.view);
  }, [props.view]);

  return (
    <ControlledTabbedPanel
      {...props}
      activeTab={activeTab}
      onActiveTabChange={setActiveTab}
    />
  );
};

const TabbedPanel = props =>
  props.onActiveTabChange ? (
    <ControlledTabbedPanel {...props} />
  ) : (
    <UncontrolledTabbedPanel {...props} />
  );

export default TabbedPanel;
