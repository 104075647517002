import React from 'react';
import AlertProcessingContent from './AlertProcessingContent';
import { notification } from 'antd';

const NotificationProcessing = callback => {
  notification.open({
    message: 'List Updating',
    description: <AlertProcessingContent />,
    placement: 'bottomLeft',
  });
  if (callback) callback();
};

export default NotificationProcessing;
