import styled from 'styled-components';

import { Pane as CommonPane, Detail as CommonDetail } from 'components/common';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: ${p => p.theme.gutter};
`;

export const Pane = styled(CommonPane)`
  margin-right: ${p => p.theme.gutter};
  margin-bottom: ${p => p.theme.gutter};
  :last-child {
    margin-right: 0px;
  }
  ${p => p.theme.breakpoint.mobile} {
    margin-bottom: ${p => p.theme.gutter};
    :last-child {
      margin-bottom: 0px;
    }
  }
  .content {
    margin-top: 5px;
  }
`;

export const Detail = styled(CommonDetail)`
  margin-bottom: 10px;
  line-height: 1.4em;
  font-size: 14px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;
