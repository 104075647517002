import { Fragment } from 'react';

import { Pane, Detail, DetailColumn, DetailContainer, Table } from './styled';

const nestData = data => {
  if (!data) return [];
  let activeParents = [];
  let nested = [];
  const criteria = data[0];
  if (!criteria) return [];
  const baseIndent = JSON.stringify(criteria).search(/\w/);
  data.forEach((item, i) => {
    const activeItem = [item, []];
    const tabLevel = JSON.stringify(item).search(/\w/);
    const nextItem = data[i + 1];
    const hasChild =
      nextItem && JSON.stringify(nextItem).search(/\w/) > tabLevel;
    const reset = nextItem && JSON.stringify(nextItem).search(/\w/) < tabLevel;

    if (activeParents[0]) {
      activeParents[0][1] = [...activeParents[0][1], activeItem];
    }

    if (hasChild) {
      activeParents.unshift(activeItem);
    }

    if (reset && nextItem) {
      for (
        let i = tabLevel;
        i > JSON.stringify(nextItem).search(/\w/) - 2;
        i--
      ) {
        activeParents.shift();
      }
    }

    if (tabLevel === baseIndent) {
      nested.push(activeItem);
    }
  });

  return nested;
};

const renderCell = (row, i) => {
  return (
    <div key={i} style={{ display: 'block' }}>
      <div style={{ marginLeft: 20 * i }}>{row[0]}</div>
      {!!row[1] && row[1].map(x => renderCell(x, i + 1))}
    </div>
  );
};

const config = header => ({
  headers: [`${header} Criteria`],
  createRow: (r, i) => <Fragment key={i}>{renderCell(r, 0)}</Fragment>,
});

const Eligibility = ({ study }) => {
  const inclusionCriteria = study.eligibility?.inclusionCriteria
    .replaceAll('\r', '')
    .split('\n\n');
  const exclusionCriteria = study.eligibility?.exclusionCriteria
    .replaceAll('\r', '')
    .split('\n\n');

  if (inclusionCriteria) inclusionCriteria.shift();
  if (exclusionCriteria) exclusionCriteria.shift();

  const nestedInclusion = nestData(inclusionCriteria);
  const nestedExclusion = nestData(exclusionCriteria);

  return (
    <Pane title='Eligibility Criteria' id='eligibility'>
      <DetailContainer>
        <DetailColumn>
          <Detail label='Ages Eligible for Study'>
            {study.eligibility?.minimumAge || 'N/A'} -{' '}
            {study.eligibility?.maximumAge || 'N/A'}
          </Detail>
          <Detail label='Sexes Eligible for Study'>
            {study.eligibility?.gender}
          </Detail>
          <Detail label='Accepts Healthy Volunteers'>
            {study.eligibility?.healthyVolunteers}
          </Detail>
        </DetailColumn>
        <DetailColumn>
          <Detail label='Sampling Method'>
            {study.eligibility?.samplingMethod}
          </Detail>
          <Detail label='Study Population'>
            {study.eligibility?.population}
          </Detail>
        </DetailColumn>
      </DetailContainer>
      <Table config={config('Inclusion')} data={nestedInclusion} />
      <Table config={config('Exclusion')} data={nestedExclusion} />
    </Pane>
  );
};

export default Eligibility;
