import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';

import { Button } from 'components/common';
import { setFilter, setMapRegion } from 'actions/search';
import { getFilter, getGeoSearchData, getMapRegion } from 'selectors/search';
import useLog from 'hooks/useLog';
import { geoArea, regions } from '../constants';
import {
  formatNumber,
  getClusterFeatures,
  getGeoJsonData,
  getSize,
} from './helpers';
import {
  firstLevelSourceVar,
  secondLevelSourceVar,
  firstLevelLayerVar,
  firstLevelLabelLayerVar,
  secondLevelLayerVar,
  secondLevelLabelLayerVar,
  clusterLayerVar,
  clusterLabelLayerVar,
  firstLevelCircleRadiusRange,
  firstLevelCircleFontSize,
  secondLevelCircleRadiusRange,
  firstLevelCircleFontSizeRange,
} from './config';
import useMap from './useMap';
import {
  geoPosition,
  firstLevelZoomRange,
  secondLevelZoomRange,
} from './coordinates';
import usePopup from './usePopup';
import useMarker from './useMarker';
import { MapInnerContainer as InnerContainer } from './styled';
import { MapContainer as Container } from '../styled';
import { DistinctBy } from 'utils/array';

const regionCountryFilter = 'organisationGeography';
const stateFilter = 'state';
const Map = ({ onError }) => {
  const mapRegion = useSelector(getMapRegion);
  const geoSearchData = useSelector(getGeoSearchData);
  const regionCountry = useSelector(getFilter(regionCountryFilter));
  const state = useSelector(getFilter(stateFilter));
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [showPopup, closePopup] = usePopup(map.current);
  const [showMarker, closeMarker, markerNode] = useMarker(map.current);
  const dispatch = useDispatch();

  useLog('User clicked to Map tab.', 'ViewMapTab', 'Success');
  const [initMap, isMapLoaded, isError] = useMap();
  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError, onError]);

  useEffect(() => {
    if (map.current) return;
    const { coordinates, zoom } = geoPosition[mapRegion];
    map.current = initMap(mapContainer.current, {
      maxBounds: [
        [-180, -90],
        [180, 90],
      ],
      center: coordinates,
      zoom,
    });
    map.current.boxZoom.disable();
    map.current.dragRotate.disable();
    // map.current.dragPan.disable();
    map.current.keyboard.disable();
    map.current.touchZoomRotate.disable();
  }, [initMap, mapRegion]);

  const isSelected = useCallback(
    (filter, values) => {
      let currentValues =
        filter === regionCountryFilter ? regionCountry : state;
      if (!currentValues) {
        currentValues = [];
      }

      if (filter === stateFilter) {
        currentValues = currentValues.map(x => x.stateName);
      }

      return values.filter(v => !currentValues.includes(v)).length === 0;
    },
    [regionCountry, state]
  );

  const toggleGeoFilter = useCallback(
    (filter, values) => {
      let currentValue = filter === regionCountryFilter ? regionCountry : state;
      if (!currentValue) {
        currentValue = [];
      }

      const selected = isSelected(filter, values);

      let nextValue;
      if (filter === stateFilter) {
        if (selected) {
          nextValue = currentValue.filter(x => !values.includes(x.stateName));
        } else {
          const stateFilterValues =
            geoSearchData?.res
              ?.filter(x => values.includes(x.stateFilter?.stateName))
              .map(x => x.stateFilter) ?? [];
          nextValue = DistinctBy(
            [...currentValue, ...stateFilterValues],
            x => x.stateName
          );
        }
      } else {
        nextValue = selected
          ? currentValue.filter(x => !values.includes(x))
          : Array.from(new Set([...currentValue, ...values]));
      }

      dispatch(setFilter(filter, nextValue));
    },
    [dispatch, isSelected, regionCountry, state, geoSearchData]
  );

  useEffect(() => {
    if (map.current) {
      const { coordinates, zoom } = geoPosition[mapRegion];
      map.current.easeTo({ center: coordinates, zoom });
      if (mapRegion === regions.world) {
        map.current.setMinZoom(firstLevelZoomRange[0]);
        map.current.setMaxZoom(firstLevelZoomRange[1] - 0.01);
      } else {
        map.current.setMaxZoom(secondLevelZoomRange[1]);
        map.current.setMinZoom(secondLevelZoomRange[0]);
      }
    }
  }, [mapRegion]);

  useEffect(() => {
    if (isMapLoaded) {
      const onMouseLeaveHandler = () => {
        map.current.getCanvas().style.cursor = '';
        closePopup();
        openedMarker = false;
        tryCloseMarker();
      };

      const onClusterClickHandler = e => {
        const feature = e.features[0];
        const clusterId = feature.properties.cluster_id;
        map.current
          .getSource(secondLevelSourceVar)
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            map.current.easeTo({
              center: feature.geometry.coordinates,
              zoom: zoom + 1,
            });
          });
        onMouseLeaveHandler();
      };
      map.current.on('click', clusterLayerVar, onClusterClickHandler);

      const onClusterMouseEnterHandler = async e => {
        map.current.getCanvas().style.cursor = 'pointer';
        const features = await getClusterFeatures(
          e.point,
          map.current,
          secondLevelSourceVar
        );
        const clusterFeature = e.features[0];
        showPopup(
          clusterFeature.geometry.coordinates,
          features,
          clusterFeature.layer.paint['circle-radius']
        );
        if (!hoverOnMarker) {
          openedMarker = true;
          closeMarker();
          const filter =
            features[0].properties.type === geoArea.state
              ? stateFilter
              : regionCountryFilter;
          showMarker(
            clusterFeature,
            clusterLayerVar,
            isSelected(
              filter,
              features.map(x => x.properties.title)
            ),
            () => {
              toggleGeoFilter(
                filter,
                features.map(x => x.properties.title)
              );
              tryCloseMarker(true);
            }
          );
        }
      };
      map.current.on('mouseenter', clusterLayerVar, onClusterMouseEnterHandler);
      map.current.on('mouseleave', clusterLayerVar, onMouseLeaveHandler);
      map.current.on('wheel', clusterLayerVar, onMouseLeaveHandler);

      let hoverOnMarker;
      let openedMarker;
      let currentTimeoutId;
      const tryCloseMarker = (force = false) => {
        clearTimeout(currentTimeoutId);
        if (force) {
          closeMarker();
          return;
        }
        currentTimeoutId = setTimeout(() => {
          if (!openedMarker && !hoverOnMarker) {
            closeMarker();
          }
        }, 100);
      };

      const onMarkerNodeMouseEnterHandler = () => {
        hoverOnMarker = true;
        markerNode.style.cursor = 'pointer';
      };
      markerNode.addEventListener('mouseenter', onMarkerNodeMouseEnterHandler);

      const onMarkerNodeMouseLeaveHandler = () => {
        hoverOnMarker = false;
        tryCloseMarker();
      };
      markerNode.addEventListener('mouseleave', onMarkerNodeMouseLeaveHandler);

      const onFirstLayerClickHandler = e => {
        const selectedRegion = e.features[0].properties.region;
        dispatch(setMapRegion(selectedRegion));
        onMouseLeaveHandler();
      };
      map.current.on('click', firstLevelLayerVar, onFirstLayerClickHandler);

      const onFirstLayerMouseEnterHandler = e => {
        map.current.getCanvas().style.cursor = 'pointer';
        const feature = e.features[0];
        showPopup(
          feature.geometry.coordinates,
          [feature],
          feature.layer.paint['circle-radius']
        );
        if (!hoverOnMarker) {
          openedMarker = true;
          closeMarker();
          showMarker(
            feature,
            feature.properties.type,
            isSelected(regionCountryFilter, [feature.properties.title]),
            () => {
              toggleGeoFilter(regionCountryFilter, [feature.properties.title]);
              tryCloseMarker(true);
            }
          );
        }
      };
      map.current.on(
        'mouseenter',
        firstLevelLayerVar,
        onFirstLayerMouseEnterHandler
      );

      map.current.on('mouseleave', firstLevelLayerVar, onMouseLeaveHandler);

      const onSecondLayerMouseEneterHandler = e => {
        const feature = e.features[0];
        const { type, title } = feature.properties;
        map.current.getCanvas().style.cursor = 'pointer';
        showPopup(
          feature.geometry.coordinates,
          [feature],
          feature.layer.paint['circle-radius']
        );
        if (!hoverOnMarker) {
          openedMarker = true;
          const filter =
            type === geoArea.state ? stateFilter : regionCountryFilter;
          closeMarker();
          showMarker(
            feature,
            feature.properties.type,
            isSelected(filter, [title]),
            () => {
              toggleGeoFilter(filter, [title]);
              tryCloseMarker(true);
            }
          );
        }
      };
      map.current.on(
        'mouseenter',
        secondLevelLayerVar,
        onSecondLayerMouseEneterHandler
      );

      map.current.on('mouseleave', secondLevelLayerVar, onMouseLeaveHandler);

      return () => {
        clearTimeout(currentTimeoutId);
        map.current.off('click', clusterLayerVar, onClusterClickHandler);
        map.current.off(
          'mouseenter',
          clusterLayerVar,
          onClusterMouseEnterHandler
        );
        map.current.off('mouseleave', clusterLayerVar, onMouseLeaveHandler);
        map.current.off('click', firstLevelLayerVar, onFirstLayerClickHandler);
        map.current.off(
          'mouseenter',
          firstLevelLayerVar,
          onFirstLayerMouseEnterHandler
        );
        map.current.off('mouseleave', firstLevelLayerVar, onMouseLeaveHandler);
        map.current.off(
          'mouseenter',
          secondLevelLayerVar,
          onSecondLayerMouseEneterHandler
        );
        map.current.off('mouseleave', secondLevelLayerVar, onMouseLeaveHandler);
        markerNode.removeEventListener(
          'mouseenter',
          onMarkerNodeMouseEnterHandler
        );
        markerNode.removeEventListener(
          'mouseleave',
          onMarkerNodeMouseLeaveHandler
        );
      };
    }
  }, [
    closePopup,
    dispatch,
    isMapLoaded,
    showPopup,
    markerNode,
    closeMarker,
    showMarker,
    toggleGeoFilter,
    isSelected,
  ]);

  const tryRemoveSecondLayer = useCallback(() => {
    if (map.current.getSource(secondLevelSourceVar)) {
      map.current.removeLayer(secondLevelLayerVar);
      map.current.removeLayer(secondLevelLabelLayerVar);
      map.current.removeLayer(clusterLayerVar);
      map.current.removeLayer(clusterLabelLayerVar);
      map.current.removeSource(secondLevelSourceVar);
    }
  }, []);

  useEffect(() => {
    if (isMapLoaded && geoSearchData.res?.length > 0) {
      // regions layer
      if (!map.current.getSource(firstLevelSourceVar)) {
        map.current.addSource(firstLevelSourceVar, {
          type: 'geojson',
          data: getGeoJsonData(geoSearchData.res, regions.world, [
            geoArea.region,
          ]),
        });
        map.current.addLayer({
          id: firstLevelLayerVar,
          type: 'circle',
          source: firstLevelSourceVar,
          paint: {
            'circle-color': '#ffc0ab',
            'circle-radius': getSize(
              geoSearchData.res,
              [geoArea.region],
              mapRegion,
              firstLevelCircleRadiusRange[0],
              firstLevelCircleRadiusRange[1]
            ),
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
        });
        map.current.addLayer({
          id: firstLevelLabelLayerVar,
          type: 'symbol',
          source: firstLevelSourceVar,
          layout: {
            'text-field': formatNumber('value'),
            'text-font': ['Roboto Medium'],
            'text-size': firstLevelCircleFontSize,
          },
          paint: {
            'text-color': '#595959',
          },
        });
        map.current.setLayerZoomRange(
          firstLevelLayerVar,
          firstLevelZoomRange[0],
          firstLevelZoomRange[1]
        );
        map.current.setLayerZoomRange(
          firstLevelLabelLayerVar,
          firstLevelZoomRange[0],
          firstLevelZoomRange[1]
        );
      }

      tryRemoveSecondLayer();

      map.current.addSource(secondLevelSourceVar, {
        type: 'geojson',
        data: getGeoJsonData(geoSearchData.res, mapRegion, [
          geoArea.country,
          geoArea.state,
        ]),
        cluster: true,
        clusterProperties: {
          sum: ['+', ['get', 'value']],
        },
        clusterRadius: 41,
      });

      // country/state layer
      map.current.addLayer({
        id: secondLevelLayerVar,
        type: 'circle',
        filter: ['has', 'region'],
        source: secondLevelSourceVar,
        paint: {
          'circle-color': '#B0DFE0',
          'circle-radius': getSize(
            geoSearchData.res,
            [geoArea.country, geoArea.state],
            mapRegion,
            secondLevelCircleRadiusRange[0],
            secondLevelCircleRadiusRange[1]
          ),
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      map.current.addLayer({
        id: secondLevelLabelLayerVar,
        type: 'symbol',
        filter: ['has', 'region'],
        source: secondLevelSourceVar,
        layout: {
          'text-field': formatNumber('value'),
          'text-font': ['Roboto Medium'],
          'text-size': getSize(
            geoSearchData.res,
            [geoArea.country, geoArea.state],
            mapRegion,
            firstLevelCircleFontSizeRange[0],
            firstLevelCircleFontSizeRange[1]
          ),
        },
        paint: {
          'text-color': '#595959',
        },
      });

      // clusters layer
      map.current.addLayer({
        id: clusterLayerVar,
        type: 'circle',
        source: secondLevelSourceVar,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#ffc0ab',
          'circle-radius': getSize(
            geoSearchData.res,
            [geoArea.country, geoArea.state],
            mapRegion,
            secondLevelCircleRadiusRange[0],
            secondLevelCircleRadiusRange[1],
            'sum'
          ),
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
      map.current.addLayer({
        id: clusterLabelLayerVar,
        type: 'symbol',
        source: secondLevelSourceVar,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': formatNumber('sum'),
          'text-font': ['Roboto Medium'],
          'text-size': getSize(
            geoSearchData.res,
            [geoArea.country, geoArea.state],
            mapRegion,
            firstLevelCircleFontSizeRange[0],
            firstLevelCircleFontSizeRange[1],
            'sum'
          ),
        },
        paint: {
          'text-color': '#595959',
        },
      });

      map.current.setLayerZoomRange(
        clusterLayerVar,
        secondLevelZoomRange[0],
        secondLevelZoomRange[1]
      );
      map.current.setLayerZoomRange(
        clusterLabelLayerVar,
        secondLevelZoomRange[0],
        secondLevelZoomRange[1]
      );
      map.current.setLayerZoomRange(
        secondLevelLayerVar,
        secondLevelZoomRange[0],
        secondLevelZoomRange[1]
      );
      map.current.setLayerZoomRange(
        secondLevelLabelLayerVar,
        secondLevelZoomRange[0],
        secondLevelZoomRange[1]
      );
    }
  }, [geoSearchData.res, isMapLoaded, mapRegion, tryRemoveSecondLayer]);

  const resetMap = useCallback(() => {
    const { coordinates, zoom } = geoPosition[mapRegion];
    map.current.easeTo({ center: coordinates, zoom });
  }, [mapRegion]);

  if (isError) {
    return null;
  }

  return (
    <Container>
      <InnerContainer>
        <div ref={mapContainer} />
        {isMapLoaded && (
          <Button
            type='secondary'
            data-testid='create-alert'
            icon={<ReloadOutlined />}
            onClick={resetMap}
          >
            Reset Map
          </Button>
        )}
      </InnerContainer>
    </Container>
  );
};

Map.propTypes = {
  onError: PropTypes.func.isRequired,
};

export default Map;
