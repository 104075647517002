import { useEffect } from 'react';
import { Route as BaseRoute } from 'react-router-dom';

const Route = ({ title, ...rest }) => {
  useEffect(() => {
    if (title) {
      document.title = `${title} | SciLeads`;
    }
  }, [title]);

  return <BaseRoute {...rest} />;
};

export default Route;
