import { useCallback, useEffect } from 'react';
import { log as logUrl } from 'services/api';
import refreshToken from './xhr/refreshToken';

export const outcomes = {
  success: 'Success',
};

const logRequest = payload => {
  return fetch(logUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(payload),
  });
};

const useLog = (description, action, outcome, onMount = true) => {
  const postLog = useCallback(payload => {
    logRequest(payload).then(async data => {
      if (data.status === 401) {
        await refreshToken();
        logRequest(payload);
      }
    });
  }, []);

  useEffect(() => {
    if (onMount) {
      postLog({
        description,
        action,
        outcome,
      });
    }
  }, [onMount, postLog, description, action, outcome]);

  return useCallback(
    logPayload => {
      const payload = logPayload || {
        description,
        action,
        outcome,
      };
      postLog(payload);
    },
    [action, description, outcome, postLog]
  );
};

export default useLog;
