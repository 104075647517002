import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { Pane, Icon } from 'components/common';
import { colours } from 'utils/theme';
import OrganisationResearchersFilter from './OrganisationResearchersFilter';
import OrganisationPurchasingFilters from './OrganisationPurchasingFilters';
import styled from 'styled-components';

import {
  getOrganisationResearchersLoading,
  getOrganisationView,
  getOrganisationWorkItemIsLoading,
  getPurchasingLoading,
} from 'selectors/organisation';
import { OrganisationWorkItemFilters } from './OrganisationWorkItemFilters';
import {
  ORGANISATIONPEOPLEVIEW,
  ORGANISATIONPURCHASINGVIEW,
  ORGANISATIONWORKITEMVIEW,
} from '../helpers';

export const Content = styled.div`
  margin-top: 10px;
`;

export const Actions = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const Help = () => (
  <Tooltip
    title='Search filters can help you easily refine your search to better target the results you are after.'
    placement='right'
  >
    <div style={{ cursor: 'pointer' }}>
      <Icon icon='help' color={colours.teal} size='xs' />
    </div>
  </Tooltip>
);

const OrganisationProfileFilters = ({
  organisation,
  onFilterUpdate,
  onworkItemFilterUpdate,
}) => {
  const view = useSelector(getOrganisationView);
  const loadingResearchers = useSelector(
    getOrganisationResearchersLoading(organisation.id)
  );
  const loadingWorkItems = useSelector(
    getOrganisationWorkItemIsLoading(organisation.id)
  );
  const loadingPurchasing = useSelector(getPurchasingLoading);

  let filters = null;

  if (view === ORGANISATIONPEOPLEVIEW) {
    filters = (
      <OrganisationResearchersFilter
        organisationId={organisation.id}
        onFilterUpdate={onFilterUpdate}
        loading={loadingResearchers}
      />
    );
  } else if (view === ORGANISATIONWORKITEMVIEW) {
    filters = (
      <OrganisationWorkItemFilters
        organisationId={organisation.id}
        onFilterUpdate={onworkItemFilterUpdate}
        loading={loadingWorkItems}
      />
    );
  } else if (view === ORGANISATIONPURCHASINGVIEW) {
    filters = (
      <OrganisationPurchasingFilters
        organisation={organisation}
        loading={loadingPurchasing}
      />
    );
  }

  if (!filters) return null;

  return (
    organisation && (
      <Pane
        title={
          view === ORGANISATIONPEOPLEVIEW || view === ORGANISATIONWORKITEMVIEW
            ? 'Organisation Filters'
            : 'Search Filters'
        }
        headerRight={<Help />}
      >
        <Content>{filters}</Content>
      </Pane>
    )
  );
};

export default OrganisationProfileFilters;
