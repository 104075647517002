import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import { getActiveList, getView } from 'selectors/lists';
import { useAuth, useGet, usePost, usePut } from 'hooks';
import { updateAccessLevels, updateList, allUsers } from 'services/api';

import {
  ListNameInput,
  ListDescriptionInput,
  ListPermissionSelect,
  ListExplanation,
  ListUsers,
} from 'components/common';

import { getTruncatedModalTitle } from 'utils/helpers/modals';
import { views } from '../constants';

const Edit = ({ onClose }) => {
  const view = useSelector(getView);
  const activeList = useSelector(getActiveList);
  const clonedList = JSON.parse(JSON.stringify(activeList));
  const { user } = useAuth();
  const [
    { res: updateAccessLevelsRes, loading: savingAccessLevels },
    postUpdateAccessLevels,
  ] = usePost({
    url: updateAccessLevels(activeList.id),
  });
  const [{ res: updateListRes, loading: savingList }, putUpdateList] = usePut({
    url: updateList(activeList.id, view),
  });
  const [{ res: allUsersRes, loading: loadingUsers }] = useGet({
    url: allUsers,
    onMount: true,
  });
  const [list, setList] = useState({
    name: clonedList.name,
    description: clonedList.description,
    defaultAccess: clonedList.defaultAccess,
    users: clonedList.accessLevels,
  });

  useEffect(() => {
    if (
      (updateAccessLevelsRes || view !== views.researchers) &&
      updateListRes
    ) {
      onClose({ fetchMembers: true, fetchLists: true, ...updateListRes });
    }
  }, [updateAccessLevelsRes, updateListRes, onClose, view]);

  const userOptions =
    allUsersRes?.userAccounts.filter(u => u.enabled && !u.isLicenseExpired) ||
    [];

  const handleChange = key => value => {
    setList(s => ({ ...s, [key]: value }));
  };

  const handleSave = () => {
    const accessLevelsPayload = {
      defaultAccess: list.defaultAccess,
      accessLevels: list.users,
      id: activeList.id,
    };
    const description = list.description ? list.description.trim() : null;
    const listPayload = {
      id: activeList.id,
      name: list.name,
      description: description,
      accessLevels: list.users,
      defaultAccess: list.defaultAccess,
    };

    if (view === views.researchers) {
      postUpdateAccessLevels(accessLevelsPayload);
    }

    putUpdateList(listPayload);
  };

  const loading = savingAccessLevels || savingList;

  const isValid = list.name && list.name.trim().length > 0;

  return (
    <Modal
      open
      title={getTruncatedModalTitle(`Edit ${activeList.name}`)}
      onCancel={onClose}
      onOk={handleSave}
      okText='Save'
      okButtonProps={{ disabled: loading || !isValid }}
      cancelButtonProps={{ disabled: loading }}
    >
      <ListNameInput value={list.name} onChange={handleChange('name')} />
      <ListDescriptionInput
        value={list.description}
        onChange={handleChange('description')}
      />
      <ListPermissionSelect
        value={list.defaultAccess}
        onChange={handleChange('defaultAccess')}
      />
      <ListExplanation />
      <ListUsers
        loading={loadingUsers}
        value={list.users}
        onChange={handleChange('users')}
        currentUser={user}
        options={userOptions}
        activeList={activeList}
      />
    </Modal>
  );
};

export default Edit;
