import { types } from 'actions/exportHistory';

const defaultState = {
  refreshStateforExport: null,
  filters: {
    pageSize: 50,
    pageNumber: 0,
    orderBy: 'date',
    order: 'desc',
    searchFilter: '',
  },
  dateOption: null,
};

const REDUCER = {
  [types.SET_SIGNALR_EXPORT_REFRESH]: (state, action) => ({
    ...state,
    refreshStateforExport: { id: action.payload.id },
  }),
  [types.RESET_SIGNALR_EXPORT_REFRESH]: state => ({
    ...state,
    refreshStateforExport: null,
  }),
  [types.SET_EXPORT_HISTORY_FILTERS]: (state, action) => ({
    ...state,
    filters: action.payload.value,
  }),
  [types.SET_DATE_OPTION]: (state, action) => ({
    ...state,
    dateOption: action.payload.value,
  }),
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...state,
        restoringState: false,
      };
    }

    let restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState = {
      ...defaultState,
      ...restoredState,
    };
    restoredState.restoringState = false;

    return restoredState;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
