const AcademiaIcon = ({ onClick, color, style }) => (
  <svg
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 -4 32 32'
    height='32'
    width='32'
    onClick={onClick}
    color={color}
    style={style}
  >
    <path
      fill='currentColor'
      d='M22.033,21.18L13.77,0.459H7.869l1.049,2.623L1.836,21.18C1.574,22.098,0.787,22.23,0,22.361v1.18 h6.82v-1.18l0,0l0,0l0,0l0,0C4.984,22.23,3.934,21.967,4.721,20c0.131-0.131,0.656-1.574,1.311-3.41h8.393l1.18,3.016 c0.131,0.525,0.262,0.918,0.262,1.311c0,1.049-0.918,1.443-2.623,1.443v1.18H24v-1.18C23.082,22.23,22.295,21.967,22.033,21.18z M6.82,14.361c1.311-3.279,2.754-7.082,3.279-8.525l3.41,8.525H6.82z'
    />
  </svg>
);

export default AcademiaIcon;
