import { useEffect, useState } from 'react';

import { Detail, Icon, Input } from 'components/common';
import { colours } from 'utils/theme';
import { usePost } from 'hooks';
import { updateUser } from 'services/api';

import { StyledIcon } from './styled';

const ReadOnlyName = ({ name, setEditing }) => {
  return (
    <>
      <div style={{ marginRight: '5px' }}>{name}</div>
      <StyledIcon
        icon='edit'
        size='sm'
        color={colours.darkGrey}
        onClick={() => setEditing(true)}
      />
    </>
  );
};

const EditableName = ({ disabled, cancelName, name, setName, saveName }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Input
        disabled={disabled}
        onChange={value => setName(value)}
        size='small'
        value={name}
      />
      <Icon
        disabled={disabled}
        onClick={saveName}
        icon='accept'
        color={colours.teal}
        size='1x'
        style={{ marginLeft: 5, cursor: 'pointer' }}
      />
      <Icon
        disabled={disabled}
        onClick={cancelName}
        icon='cancel'
        color={colours.red}
        size='1x'
        style={{ marginLeft: 5, cursor: 'pointer' }}
      />
    </div>
  );
};

const Name = ({ user }) => {
  const [name, setName] = useState(user.userName);
  const [editing, setEditing] = useState(false);
  const [{ res: nameData, loading: nameLoading }, postSaveName] = usePost({
    url: updateUser,
  });

  useEffect(() => {
    if (nameData?.status === 'OK') {
      setEditing(e => !e);
    }
  }, [nameData]);

  const saveName = () => {
    postSaveName({ email: user.userEmail, userName: name });
  };

  const cancelName = () => {
    setName(user.userName);
    setEditing(false);
  };

  return (
    <Detail label='Name'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {editing ? (
          <EditableName
            disabled={nameLoading}
            name={name}
            setName={setName}
            saveName={saveName}
            cancelName={cancelName}
          />
        ) : (
          <ReadOnlyName name={name} setEditing={setEditing} />
        )}
      </div>
    </Detail>
  );
};

export default Name;
