import React, { useState } from 'react';
import { Modal } from 'antd';
import {
  Button,
  DatePicker,
  Detail,
  Tooltip,
  Logo,
  Input,
} from 'components/common';
import { DisabledButtonWrapper } from 'components/common/styled';

import { Content, Label } from '../styled';

const RequestTradeShow = ({ setDisplay, performExport }) => {
  const [requestTradeShow, setRequestTradeshow] = useState({
    date: '',
    name: '',
    location: '',
    website: '',
  });

  const handleCancel = () => {
    setDisplay(false);
  };

  const handleOk = () => {
    performExport(requestTradeShow);
    setDisplay(false);
  };

  const Footer = () => {
    const submit = disabled => {
      return (
        <Button
          type='primary'
          disabled={disabled}
          style={disabled ? { marginLeft: '8px' } : {}}
          onClick={handleOk}
        >
          Submit Request
        </Button>
      );
    };

    return (
      <>
        <Button type='secondary' onClick={handleCancel}>
          Cancel
        </Button>
        {Object.values(requestTradeShow).some(x => !x) ? (
          <Tooltip title='All fields must have a set value before submitting a request.'>
            <DisabledButtonWrapper>{submit(true)}</DisabledButtonWrapper>
          </Tooltip>
        ) : (
          submit(false)
        )}
      </>
    );
  };

  return (
    <Modal
      open
      width='45vw'
      onCancel={handleCancel}
      style={{ top: 30 }}
      footer={<Footer />}
    >
      <div>
        <Logo />
        <Content>
          <Label style={{ marginTop: '20px', fontSize: 16, fontWeight: '500' }}>
            Trade Show Request Form
          </Label>
          <Label style={{ marginTop: '20px' }}>
            To request a trade show, simply enter a few details about the show
            in the form below
          </Label>
          <Detail style={{ marginTop: '20px' }} label='Trade Show Name'>
            <Input
              data-testid={'input-name'}
              placeholder='Name'
              value={requestTradeShow.name}
              onChange={newName =>
                setRequestTradeshow(x => ({ ...x, name: newName }))
              }
            />
          </Detail>
          <Detail style={{ marginTop: '20px' }} label='Trade Show Location'>
            <Input
              data-testid={'input-location'}
              placeholder='Location'
              value={requestTradeShow.location}
              onChange={newLocation =>
                setRequestTradeshow(x => ({ ...x, location: newLocation }))
              }
            />
          </Detail>
          <Detail style={{ marginTop: '20px' }} label='Trade Show Website'>
            <Input
              data-testid={'input-website'}
              placeholder='Website'
              value={requestTradeShow.website}
              onChange={newWebsite =>
                setRequestTradeshow(x => ({ ...x, website: newWebsite }))
              }
            />
          </Detail>
          <Detail style={{ marginTop: '20px' }} label='Trade Show Date'>
            <DatePicker
              data-testid={'date-picker'}
              format={'DD/MM/YYYY'}
              picker={'date'}
              disabledTime={true}
              style={{ width: '100%' }}
              placeholder='Date'
              value={requestTradeShow.date}
              onChange={newDate =>
                setRequestTradeshow(x => ({ ...x, date: newDate }))
              }
            />
          </Detail>
        </Content>
      </div>
    </Modal>
  );
};

RequestTradeShow.defaultProps = {
  setDisplay: () => {},
  performExport: val => {},
};

export default RequestTradeShow;
