export const formatCurrency = amount =>
  amount ? new Intl.NumberFormat('en-US').format(Math.floor(amount)) : amount;

export const formatNumber = (number, allowDecimals) =>
  number
    ? Intl.NumberFormat('en-US').format(
        allowDecimals ? number : Math.floor(number)
      )
    : number;

export const siNumber = (value, precision = 1) => {
  if (value === '-') return '-';
  if (typeof value === 'string') return value;
  const num = Math.round(value * 100) / 100;
  if (+num > 999999999) return `${(num / 1000000000).toFixed(precision)}B`;
  if (+num > 999999) return `${(num / 1000000).toFixed(precision)}M`;
  if (+num > 999) return `${(num / 1000).toFixed(precision)}K`;
  return num;
};

export const formatStringNumber = value => {
  if (!value) {
    return { display: '', value: null };
  }

  value = value.toString().replaceAll(',', '');

  if (value === 'Uncapped') {
    return { display: 'Uncapped', value: 'Uncapped' };
  }

  if (!isNaN(value)) {
    if (value.endsWith('.')) {
      return {
        display: formatNumber(+value, true).toString() + '.',
        value: +value === 0 ? '0' : +value,
      };
    }
    return {
      display: formatNumber(+value, true).toString(),
      value: +value === 0 ? '0' : +value,
    };
  }

  let matches = value.match(/^\d+(\.\d+)?(K|k|M|m|B|b)$/g);

  if (matches) {
    let num = +value.slice(0, -1);
    let multiplier = value.slice(-1).toLowerCase();

    if (multiplier === 'b') {
      num *= 1000000000;
    } else if (multiplier === 'm') {
      num *= 1000000;
    } else if (multiplier === 'k') {
      num *= 1000;
    }
    return { display: formatNumber(num, true).toString(), value: num };
  }

  return { display: '', value: null };
};

export const formatRange = (from, to) => {
  if (!to) {
    return `${siNumber(+from)}+`;
  }
  if (!from) {
    return `1 - ${siNumber(+to)}`;
  }
  return `${siNumber(+from)} - ${siNumber(+to)}`;
};
