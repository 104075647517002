import styled from 'styled-components';

import { Menu } from 'antd';
import { Pane, Table, DatePicker } from 'components/common';
import { colours } from 'utils/theme';

const colors = {
  upcoming: colours.orange,
  pending: colours.teal,
  existing: colours.purple,
};

export const Container = styled(Pane)`
  margin-top: ${p => p.theme.gutter};
`;

export const StyledTable = styled(Table)`
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  margin-top: 10px;

  .header {
    :last-child {
      justify-content: flex-end;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  > * {
    margin-top: 10px;
    margin-left: 10px;
  }
  > *:first-child {
    margin-left: 0px;
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-wrap: wrap;
    > * {
      margin-left: 0px;
    }
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center !important;
  text-transform: uppercase;
  font-size: 11px;
  color: ${p => colors[p.status]};
  font-weight: 500;
`;

export const Content = styled.div`
  > * {
    margin-bottom: 10px;
  }
`;

export const Label = styled.div`
  font-weight: 500;
  margin-right: 5px;
`;

export const UploadMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > svg {
    margin-bottom: 15px;
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 400px;
`;

export const ScrollableMenu = styled(Menu)`
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background: white;
  }

  .ant-menu-item-selected {
    font-weight: bold;
    background-color: #ecf6fe !important;
    color: black;
  }

  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 30px;
    line-height: 30px;
  }

  ant-menu-submenu,
  ant-menu-submenu-vertical,
  ant-menu-submenu-selected {
    font-weight: bold;
    background-color: #ecf6fe !important;
    color: black;
  }

  .ant-menu-submenu {
    .ant-menu {
      max-height: 300px;
      max-width: 100px;

      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
      }
      ::-webkit-scrollbar-track {
        background: white;
      }
    }
  }
`;
