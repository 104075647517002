import { Fragment, createElement, useEffect, useState } from 'react';
import { notification } from 'antd';

import { Pane, Tooltip, Icon, Table, Button, Input } from 'components/common';
import { useGet, usePost } from 'hooks';
import { userKeywords } from 'services/api';
import { theme } from 'utils/theme';
import { format } from 'utils/date';

import { modals } from './Modals';
import { errors, showWarning, Alert } from './helpers';
import useLog from 'hooks/useLog';

const Info = () => (
  <Tooltip
    placement='left'
    title='Use this area to manage keywords of interest'
  >
    <div>
      <Icon icon='help' size='sm' color={theme.teal} />
    </div>
  </Tooltip>
);

const Keywords = () => {
  const [{ res, loading }, getKeywords] = useGet({
    url: userKeywords,
    onMount: true,
  });
  const [
    { res: addRes, loading: addding, error },
    postKeyword,
    setRes,
    setErr,
  ] = usePost({
    url: userKeywords,
  });
  const [keywordFilter, setKeywordFilter] = useState('');
  const [activeModal, setActiveModal] = useState();
  const [activeKeyword, setActiveKeyword] = useState();

  const validKeyword = keywordFilter.trim().length > 0;

  useLog('User clicked to Keyword Management.', 'ViewKeywords', 'Success');

  useEffect(() => {
    if (error && !errors[error.status]) {
      notification.open({
        message: 'Cannot process',
        description: (
          <>
            <div>Failed to add keyword</div>
          </>
        ),
        placement: 'bottomLeft',
      });
    }
  }, [error]);

  useEffect(() => {
    if (addRes) {
      notification.open({
        message: 'Keyword Added',
        description: (
          <div>
            Successfully added <b>"{keywordFilter}"</b> to your list of
            keywords.
          </div>
        ),
        placement: 'bottomLeft',
      });
      setRes();
      setKeywordFilter('');
      getKeywords();
    }
  }, [addRes, getKeywords, keywordFilter, setRes]);

  const getFilteredData = () => {
    if (!res) return [];
    if (!keywordFilter || !validKeyword) return res;

    return res.filter(k =>
      `${k.keyword.toLowerCase()}`.includes(keywordFilter.toLowerCase())
    );
  };

  const handleAdd = () => {
    postKeyword({ keyword: keywordFilter.trim() });
  };

  const handleKeywordChange = value => {
    if (error) {
      setErr();
    }
    setKeywordFilter(value);
  };

  const handleKeyDown = e => {
    if (!/^[a-z0-9-+ *]+$/i.test(e.key)) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.keyCode === 13 && keywordFilter) {
      handleAdd();
    }
  };

  const handleEdit = keyword => {
    setActiveKeyword(keyword);
    setActiveModal('edit');
  };

  const handleDelete = keyword => {
    setActiveKeyword(keyword);
    setActiveModal('delete');
  };

  const handleCloseModal = () => {
    setActiveModal();
    setActiveKeyword();
  };

  const handleModalSuccess = () => {
    handleCloseModal();
    getKeywords();
  };

  const config = {
    headers: [
      { label: 'Keyword', key: 'keyword' },
      { label: 'Last Modified By', key: 'operator' },
      { label: 'Last Modified On', key: 'operationTime' },
      '',
    ],
    createRow: (r, i) => (
      <Fragment key={i}>
        <div>{r.keyword}</div>
        <div>{r.operator ? `${r.operator} (${r.operation})` : '-'}</div>
        <div>{format(r.operationTime)}</div>
        <div style={{ justifyContent: 'flex-end' }}>
          <Button
            size='small'
            onClick={() => handleEdit(r)}
            style={{ marginRight: 10 }}
          >
            Edit
          </Button>
          <Button size='small' onClick={() => handleDelete(r)}>
            Delete
          </Button>
        </div>
      </Fragment>
    ),
  };

  return (
    <Pane title='Keyword Management' headerRight={<Info />}>
      <div>
        <div style={{ display: 'flex', marginTop: 15 }}>
          <Input
            disabled={loading || addding}
            onChange={handleKeywordChange}
            onKeyDown={handleKeyDown}
            value={keywordFilter}
            placeholder='Add New/Filter'
            style={{ marginRight: 15 }}
          />
          <Button
            disabled={loading || addding || !keywordFilter || !validKeyword}
            type='primary'
            onClick={handleAdd}
          >
            Add
          </Button>
        </div>
        {error && errors[error.status]}
        {showWarning(keywordFilter) && <Alert />}
        <Table
          config={config}
          data={getFilteredData()}
          loading={loading}
          style={{ marginTop: 15 }}
          paginate
          sortable
        />
      </div>
      {activeModal &&
        createElement(modals[activeModal], {
          activeKeyword,
          onCloseModal: handleCloseModal,
          onSuccess: handleModalSuccess,
        })}
    </Pane>
  );
};

export default Keywords;
