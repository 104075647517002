import { useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

const ResizeObserver = window.ResizeObserver || Polyfill;

const Container = styled.div`
  height: ${p => (p.state.isCollapsed ? 0 : p.state.height)}px;
  overflow: ${p => p.state.overflow};
  transition: 0.2s height linear;
  box-sizing: border-box;
`;

const CollapsiblePanel = ({
  isCollapsed,
  children,
  className = undefined,
  style = undefined,
}) => {
  const [height, setHeight] = useState(0);
  const [overflow, setOverflow] = useState('hidden');
  const [visible, setVisible] = useState(!isCollapsed);
  const ref = useRef(null);

  const handleResize = observed => {
    const contentHeight = observed[0].target.clientHeight;
    setHeight(contentHeight);
  };

  useLayoutEffect(() => {
    let timeout;
    if (isCollapsed) {
      setOverflow('hidden');
      timeout = setTimeout(() => setVisible(false), 200);
    } else {
      setVisible(true);
      timeout = setTimeout(() => setOverflow('visible'), 200);
    }

    return () => clearTimeout(timeout);
  }, [isCollapsed]);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(handleResize);

    if (ref.current.children[0]) {
      observer.observe(ref.current.children[0]);
    }
    return () => observer.disconnect();
  }, [visible]);

  return (
    <Container
      data-testid='collapsible-panel'
      ref={ref}
      state={{ height, isCollapsed, overflow }}
      className={className}
      style={style}
    >
      {visible && children}
    </Container>
  );
};

export default CollapsiblePanel;
