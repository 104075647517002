import { useCallback, useEffect, useRef, useState, memo } from 'react';
import ChartJS from 'chart.js';

import { HelpTooltip } from 'components/common';

import { getColour } from './helpers';
import { Container, GraphLabel } from './styled';

const GraphBase = memo(
  ({ label, data, type, options, onClick, helpText, fullWidth, className }) => {
    data = data || { data: [], labels: [] };
    const ref = useRef();
    const isCursorPointer = useRef(!!onClick);
    const backgroundColor = useRef(getColour(data.data, type));
    const graphRef = useRef();
    const [hasMounted, setHasMounted] = useState();

    const handleClick = useCallback(
      e => {
        const item = graphRef.current.getElementsAtEvent(e);
        if (!item.length) return;
        const index = item[0]._index;
        const label = data.labels[index];
        const value = data.data[index];

        onClick?.(label, value, index);
      },
      [onClick, data]
    );

    useEffect(() => {
      let timeout;
      if (!hasMounted) {
        timeout = setTimeout(() => setHasMounted(true), 1000);
      }
      return () => clearTimeout(timeout);
    }, [hasMounted]);

    useEffect(() => {
      if (!ref.current) return;
      const ctx = ref.current.getContext('2d');
      ctx.clearRect(0, 0, ref.current.width, ref.current.height);

      const defaultOptions = {
        maintainAspectRatio: true,
        responsive: true,
        onClick: handleClick,
        animation: {
          duration: hasMounted ? 0 : 1000,
        },
      };
      if (isCursorPointer.current) {
        defaultOptions.onHover = e => {
          const item = graphRef.current.getElementsAtEvent(e);
          if (item.length) {
            e.target.style.cursor = 'pointer';
            return;
          }

          e.target.style.cursor = 'default';
        };
      }

      graphRef.current = new ChartJS(ctx, {
        type,
        data: {
          labels: data.labels,
          datasets: [
            {
              label: null,
              data: data.data,
              backgroundColor: backgroundColor.current,
              borderWidth: 1,
            },
          ],
        },
        options: {
          ...defaultOptions,
          ...options,
        },
      });

      return () => graphRef.current.destroy();
    }, [data, type, options, onClick, handleClick, hasMounted]);

    return (
      <Container fullWidth={fullWidth} className={className}>
        <GraphLabel>
          {label}
          {helpText && <HelpTooltip>{helpText}</HelpTooltip>}
        </GraphLabel>
        <canvas ref={ref} />
      </Container>
    );
  },
  () => true
);

GraphBase.defaultProps = {
  onClick: null,
};

export default GraphBase;
