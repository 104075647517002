import styled from 'styled-components';
import { useRef, useState, useEffect } from 'react';

import AddToList from './AddToList';
import Lists from './Lists';
import {
  manageMembers,
  organisationsLists,
  researchersLists,
} from 'services/api';
import { usePatch, usePost } from 'hooks';
import {
  NotificationFailure,
  NotificationSuccessAssignOneResearcherToList,
} from 'components/Lists/Notifications';
import { views } from 'components/Lists/constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > * {
    margin-right: ${p => (p.organisationView ? '0px' : '10px')};
  }
`;

const Actions = ({ type = views.researchers, ...data }) => {
  const ref = useRef();
  const [{ res, error }, addToList, setRes] = usePatch({});
  const {
    memberId,
    onCreateNewItemList,
    listOptions,
    loadingListGroupByMembers,
    listAssociatedByMember,
  } = data;

  const [lists, setLists] = useState(listAssociatedByMember);

  const [membersListsRes, postMembersLists] = usePost({
    url: type === views.researchers ? researchersLists : organisationsLists,
  });

  useEffect(() => {
    if (res) {
      // TODO: Here we need to add the notification call.
      NotificationSuccessAssignOneResearcherToList(null, type);
      setRes(null);
      postMembersLists({ researcherIds: [memberId], memberIds: [memberId] });
    }
  }, [memberId, postMembersLists, res, setRes, type]);

  useEffect(() => {
    if (error && error.status === 400) {
      NotificationFailure(error.message, false, type);
    }
  }, [error, type]);

  useEffect(() => {
    if (
      membersListsRes &&
      (membersListsRes.res != null ||
        membersListsRes.error ||
        membersListsRes.loading)
    ) {
      const listAux = membersListsRes.res;
      if (listAux != null) {
        setLists(listAux[memberId]);
      }
    }
  }, [membersListsRes, listAssociatedByMember, memberId]);

  useEffect(() => {
    if (listAssociatedByMember) setLists(listAssociatedByMember);
  }, [listAssociatedByMember]);

  const callApiAddToList = listId => {
    addToList({
      url: manageMembers(listId, type),
      body: { researcherIds: [memberId], memberIds: [memberId] },
    });
  };

  const handleAddToList = id => {
    callApiAddToList(id);
  };

  const UpdateListComponent = (listIdCreated, onFetch) => {
    callApiAddToList(listIdCreated);
    onFetch();
  };

  return (
    <Container ref={ref} organisationView={type === views.organisations}>
      <Lists lists={lists} loading={loadingListGroupByMembers} type={type} />
      <AddToList
        onCreateNewItemList={() => {
          onCreateNewItemList({ UpdateListComponent });
        }}
        listOptions={listOptions}
        onAddToList={handleAddToList}
      />
    </Container>
  );
};

export default Actions;
