import { useEffect } from 'react';
import { Modal as AntModal } from 'antd';

import { usePost } from 'hooks';
import { toggleUser } from 'services/api';

const ToggleUser = ({ activeUser, setModal, fetchUsers }) => {
  const [{ res }, postToggleUser] = usePost({ url: toggleUser });

  useEffect(() => {
    if (res) {
      setModal();
      fetchUsers();
    }
  }, [res, setModal, fetchUsers]);

  const handleOk = () => {
    postToggleUser({
      newUserId: activeUser.userId,
      enabled: !activeUser.enabled,
    });
  };

  return (
    <AntModal
      open
      title={`${activeUser.enabled ? 'Disable' : 'Enable'} User`}
      onCancel={() => setModal(null)}
      onOk={handleOk}
    >
      <div>
        Do you want to {activeUser.enabled ? 'disable' : 'enable'}{' '}
        {activeUser.fullname}?
      </div>
    </AntModal>
  );
};

export default ToggleUser;
