import styled from 'styled-components';

export const OrgCategoryContainer = styled.div`
  display: inline-flex;
  .ant-select-selection-item {
    background: none;
    border: none;
    align-items: center;
    padding-top: 1px;
  }
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
  .ant-select-selection-overflow-item-rest {
    position: absolute;
    right: 0px;
  }
  width: 100%;
`;

export const OrgCategorySection = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-size: 13px;
  }
  > * {
    margin-right: 8px;
  }
  width: 100%;
  max-width: 580px;
  min-width: 380px;
`;
