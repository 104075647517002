import { Fragment, useEffect, useState, useMemo } from 'react';
import { notification, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/common';
import { format, isAfter } from 'utils/date';
import { usePost, useDel, useAuth } from 'hooks';
import { tradeshowCalendar, tradeshowDownload } from 'services/api';
import {
  setExternalSearch,
  setView,
  setFilterSectionIsCollapsed,
} from 'actions/search';
import { setSort } from 'actions/calendar';
import { getFilters, getTableSort } from 'selectors/calendar';

import { headerKeys } from './constants';
import sortFunc from './sort';
import { StyledTable, Status } from './styled';
import licenseType from 'models/licenseType';
import Export from 'components/Export';
import { downloadType } from 'components/Export/Summary/constants';
import { handleFailedExportNotification } from 'components/Search/Results/Actions/notifications';

const getStatus = (date, available) => {
  if (available) {
    return <Status status='existing'>In Platform</Status>;
  }
  if (isAfter(date, new Date().toString())) {
    return (
      <Status status='upcoming'>
        <span>Upcoming</span>
      </Status>
    );
  }
  return <Status status='pending'>Pending</Status>;
};

const ActionButton = ({
  id,
  availableToDownload,
  name,
  date,
  isRegistered,
  hasKeywords,
  rowData,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const filters = useSelector(getFilters);
  const tradeshow = {
    label: date ? `${name} - ${format(date, 'YYYY')}` : name,
    date,
  };

  const [{ res: registeredSuccess, registering }, postInterest] = usePost({
    url: tradeshowCalendar,
    body: { id },
  });
  const [{ res: deregisteredSuccess, deregistering }, delInterest] = useDel({
    url: tradeshowCalendar,
    query: { id },
  });
  const [
    { res: tradeshowDownloadTriggered, error: tradeshowDownloadError },
    postTradeshowDownload,
  ] = usePost({
    url: tradeshowDownload,
  });

  const [registered, setRegistered] = useState(isRegistered);

  const label = availableToDownload
    ? 'Search Tradeshow'
    : registered
    ? 'Un-Register Interest'
    : 'Register Interest';
  const disableButton = !availableToDownload && (registering || deregistering);

  useEffect(() => {
    if (registeredSuccess) {
      notification.open({
        message: 'Interest Registered',
        description: (
          <div>
            Thank you for registering your interest in this event.
            <br />
            <br />
            We will contact you when we have further information.
          </div>
        ),
        placement: 'bottomLeft',
      });
      setRegistered(true);
      rowData.isUserRegistered = true;
    }
  }, [registeredSuccess, rowData]);

  useEffect(() => {
    if (deregisteredSuccess) {
      notification.open({
        message: 'Interest Removed',
        description: (
          <div>Your interest in this Tradeshow has been cancelled.</div>
        ),
        placement: 'bottomLeft',
      });
      setRegistered(false);
      rowData.isUserRegistered = false;
    }
  }, [deregisteredSuccess, rowData]);

  useEffect(() => {
    if (tradeshowDownloadTriggered) {
      notification.open({
        message: 'Tradeshow Download Triggered',
        description: <div>Your tradeshow download has been requested.</div>,
        placement: 'bottomLeft',
      });
    }
  }, [tradeshowDownloadTriggered]);

  useEffect(() => {
    if (tradeshowDownloadError) {
      handleFailedExportNotification(tradeshowDownloadError.status);
    }
  }, [tradeshowDownloadError]);

  const handleRegister = () => {
    postInterest();
  };

  const handleDeregister = () => {
    delInterest();
  };

  const { hasLicense, user } = useAuth();
  const canDownloadTradeshow = availableToDownload && !user.disableExport;

  const handleSearch = () => {
    if (
      hasLicense(
        [
          licenseType.temporaryOld,
          licenseType.legacy,
          licenseType.academic,
          licenseType.industry,
        ],
        true
      )
    ) {
      dispatch(setView('researcher'));
    }

    const search = {
      term: '*',
      tradeshow: [tradeshow],
      category: { funding: true, tradeshows: true, publications: true },
      thisShowOnly: true,
    };
    dispatch(setExternalSearch(search));
    dispatch(setFilterSectionIsCollapsed('tradeshows', false));
    push('/');
  };

  const getExportBody = () => ({
    tradeshowName: name,
    tradeshowYear: format(date, 'YYYY'),
    structuredSearch: JSON.stringify({
      ...filters,
      term: tradeshow.label,
      name: name,
    }),
  });

  const handleDownload = params => {
    const body = getExportBody();
    postTradeshowDownload({
      body: { ...body, ...params },
    });
  };

  const handleKeywordsRedirect = () => {
    push('/settings/keywords');
  };

  const tooltipText = !user.isAccountManager ? (
    <div style={{ textAlign: 'center' }}>
      Your account has no keywords. Please contact your account manager to add
      them for you.
    </div>
  ) : (
    <div>
      Your account has no keywords. Please go to the{' '}
      <span
        onClick={handleKeywordsRedirect}
        style={{ cursor: 'pointer', color: 'rgb(24, 144, 255)' }}
      >
        keywords page
      </span>{' '}
      and add them before downloading a tradeshow.
    </div>
  );

  return (
    <div className='rightAlign' {...rest}>
      <Button
        disabled={disableButton}
        onClick={
          availableToDownload
            ? handleSearch
            : registered
            ? handleDeregister
            : handleRegister
        }
        size='small'
      >
        {label}
      </Button>
      {canDownloadTradeshow && (
        <Tooltip title={!hasKeywords ? tooltipText : null}>
          <span>
            <Export
              downloadType={downloadType.tradeshowSearch}
              getQueryBody={getExportBody}
              disabled={!hasKeywords}
              onExport={handleDownload}
              style={{
                marginLeft: '14px',
                pointerEvents: hasKeywords ? 'auto' : 'none',
              }}
            >
              Export Custom Tradeshow
            </Export>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

const config = {
  headers: [
    { label: 'Status', minWidth: 80 },
    {
      label: 'Name',
      key: headerKeys.name,
      minWidth: 180,
      maxContentWidth: 350,
    },
    { label: 'Date', key: headerKeys.startDate, minWidth: 120 },
    { label: 'Location', key: headerKeys.location, minWidth: 150 },
    {
      label: 'Registered interest',
      key: headerKeys.isUserRegistered,
      testId: 'actions',
      minWidth: 150,
    },
  ],
  createRow: r => (
    <Fragment key={r.id}>
      {getStatus(r.startDate, r.availableToDownload)}
      <div>{r.name}</div>
      <div>{format(r.startDate)}</div>
      <div>{r.location}</div>
      <ActionButton
        id={r.id}
        name={r.name}
        availableToDownload={r.availableToDownload}
        date={r.startDate}
        isRegistered={r.isUserRegistered}
        hasKeywords={r.hasKeywords}
        rowData={r}
      />
    </Fragment>
  ),
};

const Table = ({ data, loading }) => {
  const dispatch = useDispatch();
  const sort = useSelector(getTableSort);
  const handleSortChange = sortKey => {
    dispatch(setSort(sortKey));
  };

  const sortedData = useMemo(
    () =>
      data ? [...data].sort((a, b) => sortFunc(a, b, sort.by, sort.dir)) : [],
    [data, sort]
  );
  return (
    <StyledTable
      config={config}
      data={sortedData}
      loading={loading}
      paginate
      sortable
      onSortChange={handleSortChange}
      sort={sort.by}
      sortDir={sort.dir}
      onSort={sortFunc}
    />
  );
};

export default Table;
