import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;
const Content = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${p => p.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Clamp = ({ lines, text, className, overflowComponent, ...rest }) => {
  return (
    <Container title={text} className={className}>
      <Content lines={lines} {...rest}>
        {text}
      </Content>
    </Container>
  );
};

Clamp.defaultProps = {
  lines: 1,
};

export default Clamp;
