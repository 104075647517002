import styled from 'styled-components';

import { Icon } from 'components/common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: flex-start;
  margin: 5px 0;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: row;
    > div {
      margin-right: 15px;
    }
  }
  ${p => p.theme.breakpoint.mobile} {
    flex-direction: column;
    > div {
      margin-right: 0px;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  transition: all 0.1s linear;
  :hover {
    color: ${p => p.theme.teal};
  }
`;
