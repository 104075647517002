import { Select as AntSelect } from 'antd';
const { Option } = AntSelect;

export const renderOption = (o, i) => (
  <Option
    title={o}
    key={`${o}-${i}`}
    value={o}
    details={o}
    label={o}
    style={{ color: '#595959' }}
  >
    {o}
  </Option>
);
