import emailIcon from './Icon-Emails.png';
import fundingIcon from './Icon-Funding.png';
import clinicalIcon from './Icon-Clinical.png';
import articlesIcon from './Icon-Articles.png';
import tradeshowsIcon from './Icon-Tradeshows.png';
import organisationIcon from './Icon-Organizations.png';
import scileadsIcon from './Scileads.png';

const SciLeadsLogo = height => (
  <img
    alt='SciLeads Logo'
    src={scileadsIcon}
    height={`${height}px`}
    width={`${height * 5.7}px`}
  />
);
const EmailIcon = () => (
  <img alt='Email Icon' src={emailIcon} height='60px' width={'60px'} />
);
const FundingIcon = () => (
  <img alt='Funding Icon' src={fundingIcon} height='60px' width={'60px'} />
);
const ClinicalIcon = () => (
  <img alt='Clinical Icon' src={clinicalIcon} height='60px' width={'60px'} />
);
const ArticlesIcon = () => (
  <img alt='Articles Icon' src={articlesIcon} height='60px' width={'60px'} />
);
const TradeshowIcon = () => (
  <img alt='Tradeshow Icon' src={tradeshowsIcon} height='60px' width={'60px'} />
);
const OrganisationIcon = () => (
  <img
    alt='Organisation Icon'
    src={organisationIcon}
    height='60px'
    width={'60px'}
  />
);

export {
  SciLeadsLogo,
  EmailIcon,
  FundingIcon,
  ClinicalIcon,
  ArticlesIcon,
  TradeshowIcon,
  OrganisationIcon,
};
