import { Tooltip } from 'antd';

import { Badge, IconText, Clamp } from 'components/common';
import { diff } from 'utils/date';

import { generateChangedText } from './helpers';
import licenseType from 'models/licenseType';
import { useAuth } from 'hooks';
import { setViewAndResetState } from 'actions/organisation';
import { useDispatch } from 'react-redux';
import { ORGANISATIONPEOPLEVIEW } from 'components/Organisation/helpers';

const Organisation = ({
  name,
  previousName,
  startDate,
  iconTextComponent = IconText,
  superOrganisationId,
}) => {
  const dispatch = useDispatch();
  const { hasLicense } = useAuth();
  const hasOrgLicense = hasLicense(
    [licenseType.industry, licenseType.academic],
    true
  );

  const setOrgProfileView = () => {
    dispatch(setViewAndResetState(ORGANISATIONPEOPLEVIEW));
  };

  const monthDiff = diff(startDate);
  const monthDiffBy3 = Math.ceil(monthDiff / 3) * 3;

  const IconTextComponent = iconTextComponent;

  const organisationProfile = `/organisation/${superOrganisationId}`;
  const organisationIconText = (
    <IconTextComponent
      link={superOrganisationId && hasOrgLicense && organisationProfile}
      value={<Clamp text={name} />}
      icon='company'
      onClick={() => setOrgProfileView()}
      internalLink
    />
  );

  if (!startDate || monthDiff > 6) {
    return organisationIconText;
  }

  const changedText = generateChangedText(
    'organisation',
    monthDiffBy3,
    previousName
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {organisationIconText}
      <Tooltip title={changedText}>
        <Badge>{monthDiffBy3}</Badge>
      </Tooltip>
    </div>
  );
};

export default Organisation;
