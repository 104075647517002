import styled from 'styled-components';

import { colours } from 'utils/theme';

import Stat from '../Stat';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  ${p => p.theme.breakpoint.tablet} {
    margin-left: 90px;
  }
  ${p => p.theme.breakpoint.mobile} {
    margin-left: 0px;
    flex-wrap: wrap;
    flex: 0;
  }
`;

const getPercent = (value, total) => (value / total) * 100;

const Stats = props => {
  const handleClick = tab => e => props.onStatClick(tab, e);

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <Stat
          onClick={handleClick('funding')}
          label={`Grants\nAwarded`}
          value={props.totalFundedProjects}
          color={colours.grants}
          percent={getPercent(
            props.totalFundedProjects,
            props.researchersTotalFundedProjects
          )}
          total={props.researchersTotalFundedProjects}
        />
        <Stat
          onClick={handleClick('posters')}
          label={`Tradeshow\nSessions`}
          color={colours.posters}
          value={props.totalTradeshowSessions}
          percent={getPercent(
            props.totalTradeshowSessions,
            props.researchersTotalTradeShowSessions
          )}
          total={props.researchersTotalTradeShowSessions}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Stat
          onClick={handleClick('publications')}
          label={`Published\nArticles`}
          color={colours.publications}
          value={props.totalPublications}
          percent={getPercent(
            props.totalPublications,
            props.researchersTotalPublications
          )}
          total={props.researchersTotalPublications}
        />
        <Stat
          onClick={handleClick('clinicalTrials')}
          label={`Clinical\nTrials`}
          color={colours.trials}
          value={props.totalClinicalTrials}
          percent={getPercent(
            props.totalClinicalTrials,
            props.researchersTotalClinicalTrials
          )}
          total={props.researchersTotalClinicalTrials}
        />
      </div>
    </Container>
  );
};

export default Stats;
