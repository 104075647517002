import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDel, usePatch } from 'hooks';

import {
  getSelectedMemberIds,
  getAreAllMembersSelected,
  getSelectedMembers,
} from 'selectors/lists';
import { deleteList, manageMembers } from 'services/api';
import { setActiveList, setView } from 'actions/lists';
import { NotificationFailure, NotificationSuccess } from '../Notifications';

const useAddMembersAndRefetch = (
  sourceListId,
  fetchLists,
  type,
  shouldDeleteList = false
) => {
  const dispatch = useDispatch();
  const selectedMemberIds = useSelector(getSelectedMemberIds);
  const selectedMembers = useSelector(getSelectedMembers);
  const allSelected = useSelector(getAreAllMembersSelected);
  const [list, setList] = useState();

  const activeList = useRef(null);

  const [
    {
      res: addingMembersToListResponse,
      loading: loadingAddingMembers,
      error: addingMembersToListError,
    },
    patchMembers,
  ] = usePatch({
    url: '',
  });

  const [, delList] = useDel({
    url: '',
  });

  useEffect(() => {
    if (addingMembersToListResponse) {
      fetchLists();
      dispatch(setView(type));
      dispatch(setActiveList(activeList.current));
      setList(activeList.current);
      NotificationSuccess(null, type);
    }
  }, [addingMembersToListResponse, dispatch, fetchLists, type]);

  useEffect(() => {
    if (addingMembersToListError) {
      fetchLists();
      const url = deleteList(activeList.id, type);
      if (shouldDeleteList) {
        delList({
          url,
        });
      } else {
        dispatch(setActiveList(activeList.current));
      }

      NotificationFailure(addingMembersToListError?.message, false, type);
    }
  }, [
    addingMembersToListError,
    delList,
    dispatch,
    fetchLists,
    shouldDeleteList,
    type,
  ]);

  const handle = useCallback(
    (list, memberId) => {
      activeList.current = list;
      const url = manageMembers(list.id, type);
      if (memberId) {
        patchMembers({
          url: url,
          body: { memberIds: [+memberId] },
        });
        return;
      }

      if (!sourceListId) {
        fetchLists();
        dispatch(setActiveList(list.id));
        return;
      }

      if (allSelected || selectedMembers.all) {
        patchMembers({
          url: url,
          body: {
            addFromListId: sourceListId,
            excludedFromAllMemberIds: selectedMembers.exclude
              ? Object.keys(selectedMembers.exclude).map(x => +x)
              : null,
          },
        });
        return;
      }

      patchMembers({
        url: url,
        body: { memberIds: selectedMemberIds.map(x => +x) },
      });
    },
    [
      allSelected,
      dispatch,
      fetchLists,
      patchMembers,
      selectedMemberIds,
      selectedMembers.all,
      selectedMembers.exclude,
      sourceListId,
      type,
    ]
  );

  return [loadingAddingMembers, handle, list];
};

export default useAddMembersAndRefetch;
