import styled from 'styled-components';

export const Stats = styled.div`
  padding: 0px 30px 30px 30px;
  border-radius: 25px;
  border: 4px solid #f6f3ee;
  .statistics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
    row-gap: 25px;
    margin-top: 10px;
  }
`;
