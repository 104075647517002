import PropTypes from 'prop-types';
import { PopupRow as Row } from './styled';
import { siNumber } from 'utils/number';

const Popup = ({ features }) => {
  return (
    <>
      {features.map(f => (
        <Row key={f.title}>
          <div className='cell title'>{f.title}</div>
          <div className='cell'>{siNumber(f.value)}</div>
        </Row>
      ))}
    </>
  );
};

Popup.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
};

export default Popup;
