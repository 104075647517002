import styled from 'styled-components';

import { Pane, Table, Icon } from 'components/common';

export const TableActions = styled.div`
  justify-content: flex-end;
  display: flex;
  button {
    margin-left: 5px;
  }
`;

export const Container = styled(Pane)`
  margin-top: ${p => p.theme.gutter};
`;

export const StyledTable = styled(Table)`
  margin-top: 10px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
`;

export const StyledIcon = styled(Icon)`
  transition: transform 0.2s linear;
  transform: ${p => (!p.active ? 'rotate(0deg)' : 'rotate(-180deg)')};
`;

export const SharedAlertUserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 10px;
`;
export const SharedAlertUserListTitle = styled.div`
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  padding: 0px 0px 10px 0px;
  align-self: center;
`;
export const SharedAlertUser = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SharedAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

export const SharedAlertHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  gap: 15px;
  cursor: pointer;
`;

export const SharedAlertHeaderTitle = styled.div`
  color: #161616;
  font-size: 16px;
  font-weight: 500;
`;

export const SharedAlertPanel = styled.div`
  flex-direction: column;
  padding: 0px 15px 15px 15px;
  gap: 15px;
`;

export const SharedAlertInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
`;

export const SharedAlertWarning = styled.div`
  display: flex;
  border-radius: 2px;
  padding: 9px 16px;
  border: 1px solid #ffe58f;
  background: #fffbe6;
  gap: 10px;
  align-items: center;
  font-size: 12px;
`;
