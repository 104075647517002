import styled from 'styled-components';

import {
  Pane as CommonPane,
  IconText as CommonIconText,
} from 'components/common';

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const IdentityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  ${p => p.theme.breakpoint.tablet} {
    padding: 0px;
  }
`;

export const DetailsContainer = styled.div``;

export const Pane = styled(CommonPane)`
  margin-bottom: ${p => p.theme.gutter};
`;

export const Name = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  margin-top: 5px;
`;

export const IconText = styled(CommonIconText)`
  color: ${p => p.theme.black};
  font-size: 14px;
  margin: 3px 0;
  .icon {
    font-size: 18px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  margin: 5px 0px;
  > * {
    margin: 0px 5px;
  }
`;

export const SocialMediaLinksContainer = styled.div`
  display: flex;
  > * {
    margin: 0 5px 5px;
    cursor: pointer;
  }
`;

export const FollowContentContainer = styled.div`
  > span {
    cursor: pointer;
    color: #2d9aff;
  }
`;
