import { Fragment } from 'react';
import { Checkbox, Link, Tooltip } from 'components/common';
import { format } from 'utils/date';
import { formatRange } from 'utils/number';
import { makeHttpsUrl } from 'utils/helpers/externalLink';
import SocialMediaLink from 'components/Researcher/Profile/SocialMediaLink';

const getConfig = (
  listId,
  selectedMembers,
  someSelected,
  handleToggleAll,
  handleToggleMember
) => ({
  headers: [
    {
      testId: 'checkbox',
      label: (
        <Checkbox
          checked={selectedMembers.all}
          indeterminate={someSelected}
          onClick={handleToggleAll}
        />
      ),
      minWidth: 20,
    },
    { label: 'Organisation', key: 'name', minWidth: 100, maxContentWidth: 250 },
    { label: 'State', key: '', minWidth: 100 },
    { label: 'Country', key: '', minWidth: 100 },
    { label: 'Category', key: '', minWidth: 100 },
    { label: 'Size', key: 'sizeRangeLowerBound', minWidth: 100 },
    { label: 'Founded Date', key: 'foundedYear', minWidth: 100 },
    { label: 'LinkedIn', key: '', minWidth: 50 },
    { label: 'Added On', key: 'dateAddedUtc', minWidth: 100 },
    { label: 'Added By', key: 'addedbyuser', minWidth: 100 },
  ],
  createRow: (r, i) => (
    <Fragment key={`${r.name}-${i}`}>
      <div>
        <Checkbox
          checked={
            !selectedMembers.exclude[r.id] &&
            (selectedMembers.include[r.id] || selectedMembers.all)
          }
          onClick={() => handleToggleMember(r.id)}
        />
      </div>
      <div>
        <Link to={`/organisation/${r.id}`}>{r.name}</Link>
      </div>
      <div>{r.state}</div>
      <div>{r.country}</div>
      <div>
        <Tooltip title={r.category} placement='right'>
          <div
            style={{
              maxWidth: 150,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {r.category}
          </div>
        </Tooltip>
      </div>
      <div>
        {(r.sizeRangeLowerBound > 0 || r.sizeRangeUpperBound > 0) &&
          formatRange(r.sizeRangeLowerBound, r.sizeRangeUpperBound)}
      </div>
      <div>{r.foundedYear}</div>
      <div>
        <SocialMediaLink
          network={{
            type: 'linkedin',
            displayName: 'LinkedIn',
          }}
          title={r.linkedIn ? 'View on LinkedIn' : 'Search for LinkedIn'}
          url={
            r.linkedIn
              ? makeHttpsUrl(r.linkedIn)
              : `http://www.google.com/search?q=${r.name} site:linkedin.com`
          }
          isMuted={!r.linkedIn}
          logDescription={{
            entityType: 'Organisation',
            location: `Lists:${listId}`,
          }}
        />
      </div>
      <div>{format(r.dateAddedUtc)}</div>
      <div>{r.addedByUser}</div>
    </Fragment>
  ),
});

export default getConfig;
