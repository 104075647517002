import { types } from 'actions/ai';
import { keywordTypes } from 'components/AiMessage/config';

const defaultState = {
  productName: '',
  keywordType: keywordTypes.default,
  keywords: [],
  restoringState: true,
};

const REDUCER = {
  [types.SAVE_FORM_VALUES]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...state,
        restoringState: false,
      };
    }

    let restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState = {
      ...defaultState,
      ...restoredState,
    };
    restoredState.restoringState = false;

    return restoredState;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
