import { useState } from 'react';
import styled from 'styled-components';

import {
  Link,
  IconText,
  ResearcherJobTitle,
  ResearcherOrganisation,
  ResearcherEmail,
  Button,
  SparklesIcon,
} from 'components/common';
import EmailExplanation from 'components/common/Researcher/EmailExplanation';
import AiMessage from 'components/AiMessage';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';

import SummaryTerms from './SummaryTerms';

const ProfileContainer = styled.div`
  font-size: 15px;
  line-height: 1.75em;
  color: ${p => p.theme.black};
  flex: 1;
  padding-bottom: 15px;
`;

const Profile = ({
  researcherFirstName = '',
  researcherLastName = '',
  organisationName,
  researcherEmail,
  organisationCity,
  organisationCountry,
  organisationStartDate,
  jobTitle,
  jobStartDate,
  researcherId,
  previousJobTitle,
  previousOrganisationName,
  emailShared,
  emailPubliclySourced,
  emailStatusCategory,
  emailLastAliveCheckDate,
  superOrganisationId,
  totalMatches,
  organisationCategoryGroup,
  posters,
  ...rest
}) => {
  const { hasLicense } = useAuth();
  const hasPermissionToAI = hasLicense(
    [licenseType.academic, licenseType.industry],
    true
  );

  const [showAIMessage, setShowAIMessage] = useState(false);
  const disableLink = totalMatches === 0;
  const showTermsTooltip =
    rest.researcherSummary || rest.researcherTerms?.length > 0;

  return (
    <ProfileContainer>
      <>
        <Link
          disableLink={disableLink}
          size='large'
          to={`/researcher/${researcherId}`}
          showIconHint
        >
          {researcherFirstName} {researcherLastName}
        </Link>
      </>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '15px' }}>
          <ResearcherJobTitle
            title={jobTitle}
            startDate={jobStartDate}
            previousTitle={previousJobTitle}
          />
          <ResearcherOrganisation
            name={organisationName}
            previousName={previousOrganisationName}
            startDate={organisationStartDate}
            superOrganisationId={superOrganisationId}
          />
          <ResearcherEmail
            allowFind
            dataToPost={{
              sciLeadsResearcherId: researcherId,
              location: 'ResearcherSearch',
            }}
            email={researcherEmail}
            isEmailShared={emailShared}
            isEmailPubliclySourced={emailPubliclySourced}
            emailStatusCategory={emailStatusCategory}
            emailLastAlive={emailLastAliveCheckDate}
            buttonSize='small'
            notFoundIconSize='1x'
            style={{ display: 'inline-flex' }}
            explanationComponent={() => (
              <EmailExplanation
                email={researcherEmail}
                organisationCategoryGroup={organisationCategoryGroup}
              />
            )}
          />
        </div>
        <div>
          <IconText value={organisationCity} icon='map' />
          <IconText value={organisationCountry} icon='globe' />
        </div>
      </div>
      {hasPermissionToAI && (
        <Button
          type='primary'
          size='small'
          onClick={e => {
            e?.stopPropagation();
            setShowAIMessage(true);
          }}
          icon={<SparklesIcon style={{ marginRight: '6px' }} />}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Draft Message with AI
        </Button>
      )}
      {showTermsTooltip && (
        <div style={{ marginTop: '5px' }}>
          <SummaryTerms {...rest} />
        </div>
      )}
      {showAIMessage && (
        <AiMessage
          requestLocation='search'
          researcherId={researcherId}
          city={organisationCity}
          researcherTradeshows={posters.map(p => ({
            id: p.sciLeadsTradeShowId,
            name: p.show,
            date: p.date,
          }))}
          onClose={e => {
            e?.stopPropagation();
            setShowAIMessage(false);
          }}
        />
      )}
    </ProfileContainer>
  );
};

export default Profile;
