import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  commonDateRanges,
  fundingRoundInvestors,
  fundingRoundTypes,
  lowerFundingAmounts,
  upperFundingAmounts,
} from 'services/inputTokens';
import { formatStringNumber } from 'utils/number';
import { getActiveFilters, getFilter } from 'selectors/search';
import FilterGroup from './FilterGroup';
import { ventureCapitalKeys } from './keys';
import { Filter } from '../helpers';
import { tagRender } from 'components/common/TagRender';
import { renderOption } from 'components/common/OptionRender';

export const typeNumberExamples = '(eg. type 5K or 7M or 2.1B)';

function VentureCapital({ loading }) {
  const announcedDateSelection = useSelector(
    getFilter(ventureCapitalKeys.announcedDateSelection.key)
  );

  let activeFilters = useSelector(
    getActiveFilters(Object.keys(ventureCapitalKeys))
  );
  if (announcedDateSelection === 'custom') {
    activeFilters = activeFilters.filter(af => af !== 'announcedDateSelection');
  }

  const isLatestFundingOnly = useSelector(getFilter('latestFundingRoundsOnly'));

  const latestTogglePrefix = isLatestFundingOnly ? 'Latest ' : '';
  const minAmountText = 'Min Amount ($)';
  const maxAmountText = 'Max Amount ($)';

  return (
    <FilterGroup
      icon='sackDollar'
      title='Venture Capital'
      activeKeys={activeFilters}
      sectionKey='vcFunding'
    >
      <>
        <Filter
          filterType='checkbox'
          filterId={ventureCapitalKeys.latestFundingRoundsOnly.key}
          helpText="Only Search the Company's latest funding Round"
          disabled={loading}
          labelText="Only Search the Company's latest funding Round"
        />
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          options={fundingRoundTypes}
          placeholder={`${latestTogglePrefix}Funding Round`}
          filterId={ventureCapitalKeys.fundingType.key}
          noLimit
          scrollable={true}
          disabled={loading}
          remoteSearchOnClick
        />
        <Filter
          style={{ width: '100%' }}
          filterType='select'
          filterId={ventureCapitalKeys.announcedDateSelection.key}
          placeholder={`${latestTogglePrefix}Announced Date`}
          options={commonDateRanges}
          disabled={loading}
          showSearch
        />
        {announcedDateSelection === 'custom' && (
          <Filter
            placeholder={[
              `${latestTogglePrefix}Announced From`,
              `${latestTogglePrefix}Announced To`,
            ]}
            type='range'
            filterType='datePicker'
            filterId={ventureCapitalKeys.announcedDatePicked.key}
            disabled={loading}
          />
        )}
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          filterId={ventureCapitalKeys.investors.key}
          options={fundingRoundInvestors}
          placeholder={`${latestTogglePrefix}Investors`}
          minInputSize={1}
          emptyOption={input => `No results matching '${input}' found`}
          disabled={loading}
        />
        <>
          <div>{`${latestTogglePrefix}Funding Round Amount`}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Filter
              filterType='autoComplete'
              filterId={ventureCapitalKeys.minFundingAmount.key}
              placeholder={minAmountText}
              formatter={formatStringNumber}
              options={lowerFundingAmounts}
              helpText={`Lowest Funding Round Amount ${typeNumberExamples}`}
              disabled={loading}
            />
            <div style={{ padding: '0 5px' }}>-</div>
            <Filter
              filterType='autoComplete'
              filterId={ventureCapitalKeys.maxFundingAmount.key}
              placeholder={maxAmountText}
              formatter={formatStringNumber}
              options={upperFundingAmounts}
              helpText={`Highest Funding Round Amount ${typeNumberExamples}`}
              disabled={loading}
            />
          </div>
        </>
        <>
          <div>Total Funding Raised</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Filter
              filterType='autoComplete'
              filterId={ventureCapitalKeys.minTotalFundingRaised.key}
              placeholder={minAmountText}
              formatter={formatStringNumber}
              options={lowerFundingAmounts}
              helpText={`Lowest Total Funding Amount ${typeNumberExamples}`}
              disabled={loading}
            />
            <div style={{ padding: '0 5px' }}>-</div>
            <Filter
              filterType='autoComplete'
              filterId={ventureCapitalKeys.maxTotalFundingRaised.key}
              placeholder='Max Amount ($)'
              formatter={formatStringNumber}
              options={upperFundingAmounts}
              helpText={`Highest Total Funding Amount ${typeNumberExamples}`}
              disabled={loading}
            />
          </div>
        </>
      </>
    </FilterGroup>
  );
}

VentureCapital.propTypes = {
  loading: PropTypes.bool,
};

export default VentureCapital;
