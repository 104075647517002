export const licenseExpired =
  'This user license is expired. To reenable them please email support@scileads.com or message in the live chat.';
export const actionErrors = {
  disabled:
    'This user is disabled. To reenable them please email support@scileads.com or message in the live chat.',
  expired:
    'This user is expired. To reenable them please email support@scileads.com or message in the live chat.',
  noLicenses:
    'This user does not have any license. To reenable them please email support@scileads.com or message in the live chat.',
};
export const allocationChanged =
  'License allocation has been updated before you completed user creation, please try again';
export const disableInActive =
  'This user has an expired license. To disable them please email support@scileads.com or message in the live chat.';
