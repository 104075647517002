import { Link } from 'components/common';

import { FullText } from './styled';

const FullTextData = ({ fullTextArticle }) => {
  if (fullTextArticle?.html)
    return (
      <FullText
        data-testid='full-text'
        dangerouslySetInnerHTML={{
          __html: fullTextArticle.html
            .replace(/title>/g, 'h1>')
            .replace(/italic>/g, 'i>'),
        }}
      />
    );

  if (fullTextArticle?.upwPdfUrl)
    return (
      <FullText data-testid='full-text'>
        <Link
          data-testid='upw-link'
          inline
          external
          to={fullTextArticle.upwPdfUrl}
        >
          Click here
        </Link>
      </FullText>
    );

  return (
    <FullText data-testid='full-text'>
      <p>Not available</p>
    </FullText>
  );
};

export default FullTextData;
