import styled from 'styled-components';

import { colours } from 'utils/theme';

import Tooltip from './Tooltip';
import Icon from './Icon';

const IconContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 0px;
  cursor: pointer;
`;

const HelpTooltip = ({ children }) => (
  <Tooltip title={children} placement='right'>
    <IconContainer>
      <Icon icon='help' color={colours.teal} size='xs' />
    </IconContainer>
  </Tooltip>
);

export default HelpTooltip;
