import { refreshToken as refreshTokenUrl } from 'services/api';

const obtainNewAccessToken = async () => {
  const token = localStorage.getItem('token');
  const resp = await fetch(refreshTokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await resp.json();
  return resp.ok ? json : Promise.reject(json);
};

const refreshExpiredTokenClosure = () => {
  let runningPromise;
  return () => {
    if (runningPromise) {
      return runningPromise;
    } else {
      runningPromise = obtainNewAccessToken().then(resp => {
        runningPromise = null;
        localStorage.setItem('token', resp?.token);
        return resp;
      });
      return runningPromise;
    }
  };
};

const refreshToken = refreshExpiredTokenClosure();
export default refreshToken;
