import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Body = styled.div`
  flex: 1;
  padding: ${p => p.theme.gutter} ${p => p.theme.gutter} ${p => p.theme.gutter}
    0;
  margin-left: calc(5rem + ${p => p.theme.gutter});
  width: calc(100vw - 5rem - ${p => p.theme.gutter});
  ${p => p.theme.breakpoint.mobile} {
    margin-left: calc(5rem + ${p => p.theme.gutter});
    width: calc(100vw - 5rem - ${p => p.theme.gutter});
  }
  *::placeholder,
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.35) !important;
    opacity: 1;
  }
`;
