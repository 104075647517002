import Detail from '../Detail';
import Select from '../Select';

const ListCrmTypes = ({ options, value, loading, onChange }) => {
  return (
    <Detail label='Target CRM Integration' style={{ marginTop: 15 }}>
      <Select
        showSearch
        allowClear
        placeholder='Not connected'
        options={options}
        style={{ width: '100%' }}
        value={loading ? 'Loading...' : value}
        disabled={!options.length || loading || options.length === 1}
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disableTooltip
      />
    </Detail>
  );
};

export default ListCrmTypes;
