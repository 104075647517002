import React, { useMemo } from 'react';
import styled from 'styled-components';
import GraphBase from './GraphBase';

const PaddedGraphBase = styled(GraphBase)`
  padding-right: 15px;
`;

export default function FocusedResearchAreas({ data, fullWidth }) {
  const invertedDataRank = useMemo(() => {
    if (!data?.data) return null;
    return { ...data, data: data.data.map((_, i) => data.data.length - i) };
  }, [data]);

  if (!invertedDataRank) return null;

  let options = baseOptions;
  if (fullWidth) {
    options = { ...baseOptions, aspectRatio: 4 };
  }

  return (
    <PaddedGraphBase
      type='horizontalBar'
      label='Top Ranked Focused Research Areas'
      data={invertedDataRank}
      options={options}
      helpText='Top keywords associated with the researchers in the search. These keywords cover the specific concepts within each individual scientific paper that researchers publish'
      fullWidth={fullWidth}
    />
  );
}

const baseOptions = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          callback: function() {
            return null;
          },
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: value =>
            value?.length > 16 ? `${value.substr(0, 16)} ...` : value,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: () => '',
    },
  },
};
