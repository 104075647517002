import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  Input,
  Checkbox,
  TokenInput,
  DatePicker,
  InputNumber,
  Select,
  Switch,
  TreeSelect,
} from 'components/common';

import { formatNumber } from 'utils/number';
import { setFilter, setFilterGroup } from 'actions/search';
import { getFilter } from 'selectors/search';
import AutoComplete from 'components/common/AutoComplete';
import { useEffect } from 'react';
import { getSectionTitleFromKey } from '../Filters/keys';
import Category from 'components/common/Category';

const SwitchFilter = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
  > span {
    margin-left: 8px;
  }
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const components = {
  input: Input,
  checkbox: ({ value, ...rest }) => <Checkbox {...rest} checked={value} />,
  token: TokenInput,
  datePicker: DatePicker,
  category: Category,
  number: ({ formatter = formatNumber, ...rest }) => (
    <InputNumber formatter={formatter} {...rest} />
  ),
  select: Select,
  switch: Switch,
  treeSelect: TreeSelect,
  autoComplete: StyledAutoComplete,
};

const FilterField = React.forwardRef(
  (
    {
      filterId,
      filterType,
      filterGroup,
      onChange,
      formatter,
      allowClear = true,
      activeOnly,
      horizontalTitle,
      overrideValue,
      sectionTitle,
      ...rest
    },
    ref
  ) => {
    const Component = components[filterType];

    const value = useSelector(getFilter(filterId, filterGroup));
    const dispatch = useDispatch();
    const staticPlaceholder = Array.isArray(rest.placeholder)
      ? rest.placeholder.join('')
      : rest.placeholder;
    if (!sectionTitle) {
      sectionTitle = getSectionTitleFromKey(filterGroup || filterId);
    }

    useEffect(() => {
      let title = horizontalTitle || staticPlaceholder;
      if (
        !title &&
        filterType === 'checkbox' &&
        typeof rest.children === 'string'
      ) {
        title = rest.children;
      } else if (!title && rest.helpText) title = rest.helpText;
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dispatch,
      filterId,
      horizontalTitle,
      staticPlaceholder,
      filterType,
      rest.children,
      filterGroup,
      sectionTitle,
    ]);

    if (activeOnly && value == null) {
      return null;
    }

    const handleChange = value => {
      if (onChange) {
        return onChange(value);
      }
      dispatch(
        filterGroup
          ? setFilterGroup(filterGroup, filterId, value)
          : setFilter(filterId, value)
      );
    };

    const handleSwitchClick = () => {
      if (onChange) {
        return onChange(!value);
      }
      dispatch(setFilter(filterId, !value));
    };

    if (filterType === 'switch') {
      return (
        <SwitchFilter onClick={handleSwitchClick}>
          <Component
            data-testid={`filter-${filterId}`}
            checked={value}
            {...rest}
          />
          <span>{rest.children}</span>
        </SwitchFilter>
      );
    }

    if (filterType === 'autoComplete') {
      return (
        <Component
          {...rest}
          data-testid={`filter-${filterId}`}
          onChange={handleChange}
          formatter={formatter}
          value={value}
          ref={ref}
          allowClear={allowClear}
        />
      );
    }

    return (
      <Component
        {...rest}
        data-testid={`filter-${filterId}`}
        onChange={handleChange}
        value={overrideValue ? overrideValue : value}
        allowClear={allowClear}
      />
    );
  }
);

export default FilterField;
