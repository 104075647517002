import { Pane, Row, Keyword } from './styled';

const Keywords = ({ study }) => {
  return (
    <Row>
      <Pane title='Keywords' id='keywords'>
        <div>
          {study.keywords.length
            ? study.keywords.map(k => <Keyword key={k.id}>{k.name}</Keyword>)
            : 'Not available'}
        </div>
      </Pane>
      <Pane title='Focused Research Areas' id='mesh'>
        <div>
          {study.meshTerms.length
            ? study.meshTerms.map((m, i) => (
                <Keyword key={`${m.meshTerm}-${i}`}>{m.meshTerm}</Keyword>
              ))
            : 'Not available'}
        </div>
      </Pane>
    </Row>
  );
};

export default Keywords;
