import { Route, Redirect } from 'react-router-dom';

const LegacyRedirect = ({ oldRoute, newRoute, idKey = 'id', ...rest }) => {
  return (
    <Route
      {...rest}
      path={oldRoute}
      render={({ location: { search } }) => {
        return <Redirect to={newRoute + search.split(`?${idKey}=`)[1]} />;
      }}
    ></Route>
  );
};

const LegacyRedirects = () => {
  return (
    <>
      <LegacyRedirect
        oldRoute='/publications/publication'
        newRoute='/publication/'
      />
      <LegacyRedirect
        oldRoute='/trade-shows/poster-details'
        newRoute='/poster/'
      />
      <LegacyRedirect
        oldRoute='/project'
        newRoute='/project/'
        idKey='projectId'
        exact
      />
      <LegacyRedirect oldRoute='/clinicaltrials/trial' newRoute='/trial/' />
      <LegacyRedirect oldRoute='/purchasing/item' newRoute='/item/' />
      <LegacyRedirect oldRoute='/researcher/profile' newRoute='/researcher/' />
    </>
  );
};

export default LegacyRedirects;
