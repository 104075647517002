import styled from 'styled-components';

import { Button, Switch } from 'components/common';

const StyledSwitch = styled(Switch)`
  font-size: 13px;
  margin-right: 10px;
  min-width: 150px;
`;

const Actions = ({
  showInactive,
  setShowInactive,
  setModal,
  canAdd,
  style = {},
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      <StyledSwitch
        size='small'
        checked={showInactive}
        onChange={setShowInactive}
        label='Show inactive users'
      />
      <Button
        size='small'
        type='primary'
        onClick={() => setModal('addUser')}
        disabled={!canAdd}
      >
        Add User
      </Button>
    </div>
  );
};

export default Actions;
