import Publication from './Publication';
import Funding from './Funding';
import Trial from './Trial';
import Tradeshow from './Tradeshow';

const Result = ({ 'data-testid': dataTestId, ...rest }) => {
  let Component;

  switch (rest.category.toLowerCase()) {
    case 'publication':
      Component = Publication;
      break;
    case 'tradeshow':
      Component = Tradeshow;
      break;
    case 'funding':
      Component = Funding;
      break;
    case 'trial':
      Component = Trial;
      break;
    default:
      Component = null;
  }

  if (!Component) {
    return null;
  }
  return <Component {...rest} />;
};

export default Result;
