import { Checkbox as AntCheckbox } from 'antd';

import { Tooltip } from 'components/common';

const Checkbox = ({ helpText, labelText, onChange, ...rest }) => {
  const handleChange = e => {
    onChange && onChange(e.target.checked);
  };

  return (
    <AntCheckbox
      {...rest}
      style={{ marginLeft: '0px' }}
      onChange={handleChange}
    >
      <Tooltip title={helpText} placement='right'>
        {labelText}
      </Tooltip>
    </AntCheckbox>
  );
};

Checkbox.defaultProps = {
  'data-testid': 'checkbox',
};

export default Checkbox;
