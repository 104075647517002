import { useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import ChartJS from 'chart.js';
import { Empty as AntEmpty } from 'antd';

import { Loader, Pane, Radio, Select } from 'components/common';
import { getKeywords } from 'services/api';
import { usePost } from 'hooks';

const Container = styled.div`
  position: inherit;
  width: auto;
  canvas {
    height: 335px !important;
    width: 100% !important;
    display: ${p => (p.hideChart ? 'none' : 'block')};
  }
`;

const ChartActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const Empty = styled(AntEmpty)``;

const selectOptions = [
  { value: 'All', label: 'All Categories' },
  { value: 'General', label: 'General' },
  { value: 'Accession', label: 'Accession' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Diseases', label: 'Diseases' },
  { value: 'Genes&Proteins', label: 'Genes & Proteins' },
  { value: 'GO-Terms', label: 'GO Terms' },
  { value: 'Organisms', label: 'Organisms' },
];

const KeywordData = ({ loading, citationId }) => {
  const [citationHasKeywords, setHasKeywords] = useState(false);
  const [maxKeywords, setMaxKeywords] = useState(5);
  const [category, setCategory] = useState('All');
  const [{ res: keywordData, loading: loadingKeywords }] = usePost({
    url: getKeywords,
    onMount: true,
    body: { citationId, category },
  });

  const ref = useRef();
  const chartData = useMemo(() => {
    return keywordData || { labels: [] };
  }, [keywordData]);

  const { largestNumber } = chartData;

  useEffect(() => {
    if (!citationHasKeywords && keywordData && keywordData.largestNumber > 0) {
      setHasKeywords(true);
    }
  }, [citationHasKeywords, keywordData]);

  useEffect(() => {
    if (!ref.current) return;
    const ctx = ref.current.getContext('2d');
    ctx.clearRect(0, 0, ref.current.width, ref.current.height);
    if (!largestNumber) {
      return;
    }

    new ChartJS(ctx, {
      type: 'bar',
      data: {
        labels: chartData.labels.slice(0, maxKeywords),
        datasets: chartData.datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                stepSize: 1,
              },
            },
          ],
        },
      },
    });
  }, [chartData, maxKeywords, largestNumber]);

  if (loading) return <Loader />;

  const options = [
    { label: 'Top 5', value: 5, disabled: loadingKeywords || !largestNumber },
    {
      label: 'Top 10',
      value: 10,
      disabled: loadingKeywords || largestNumber <= 5,
    },
    {
      label: 'Top 15',
      value: 15,
      disabled: loadingKeywords || largestNumber <= 10,
    },
  ];

  return (
    <Pane title={`Top ${maxKeywords} Keywords`}>
      <Container hideChart={!largestNumber}>
        {loadingKeywords && <Loader />}
        {!largestNumber && !loadingKeywords && (
          <Empty image={AntEmpty.PRESENTED_IMAGE_SIMPLE} />
        )}
        <canvas ref={ref} key={`keyword-chart-${maxKeywords}-${category}`} />
        <ChartActions>
          <Radio
            value={maxKeywords}
            onChange={setMaxKeywords}
            options={options}
            optionType='button'
          />
          <Select
            disabled={loadingKeywords || !citationHasKeywords}
            style={{ width: 150 }}
            options={selectOptions}
            value={category}
            onChange={setCategory}
            disableTooltip
          />
        </ChartActions>
      </Container>
    </Pane>
  );
};

export default KeywordData;
