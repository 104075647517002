import styled from 'styled-components';
import { useState } from 'react';

import { CollapsiblePanel, Icon } from 'components/common';
import { colours } from 'utils/theme';

const Header = styled.div`
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-top: 10px;
  user-select: none;
  cursor: pointer;

  svg {
    transform: rotate(${p => (p.isCollapsed ? '0deg' : '90deg')});
    transition: 0.2s all linear;
  }
`;

const Group = ({ children, title, isCollapsed }) => {
  const [_isCollapsed, setIsCollapsed] = useState(isCollapsed);

  const toggleExpand = () => setIsCollapsed(s => !s);

  return (
    <div>
      <Header onClick={toggleExpand} isCollapsed={_isCollapsed}>
        <Icon
          icon='caret'
          size='sm'
          color={colours.black}
          style={{ marginRight: 5 }}
        />
        {title}
      </Header>
      <CollapsiblePanel isCollapsed={_isCollapsed}>
        <div>{children}</div>
      </CollapsiblePanel>
    </div>
  );
};

export default Group;
