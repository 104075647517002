import styled from 'styled-components';

export const TokenContainer = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  margin-right: 5px;
  color: white;
  padding: 0px 6px;
  border-radius: 3px;
  background: ${p => (p.isViewAll ? p.theme.linkBlue : p.theme.teal)};
  cursor: ${p => (p.isViewOnly ? '' : 'pointer')};
  white-space: nowrap;
  margin-bottom: 3px;
`;

export const Token = styled.div`
  overflow: hidden;
  max-width: 150px;
  min-width: 50px;
  height: 18px;
  text-overflow: ellipsis;
  > svg {
    margin-right: 5px;
    height: 0.8em;
  }
}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  align-items: center;
`;

export const ListsContainer = styled.div`
  flex: 1;
  max-height: 42px;
  overflow: hidden;
`;

export const Label = styled.div`
  font-weight: 500;
  margin-right: 5px;
`;
