export const firstLevelSourceVar = 'firstLevelSource';
export const secondLevelSourceVar = 'secondLevelSource';
export const firstLevelLayerVar = 'firstLevelLayer';
export const firstLevelLabelLayerVar = 'firstLevelLabelLayer';
export const secondLevelLayerVar = 'secondLevelLayer';
export const secondLevelLabelLayerVar = 'secondLevelLabelLayer';
export const clusterLayerVar = 'clusters';
export const clusterLabelLayerVar = 'clustersLabel';

export const firstLevelCircleRadiusRange = [35, 45];
export const firstLevelCircleFontSize = 16;

export const secondLevelCircleRadiusRange = [18, 28];
export const firstLevelCircleFontSizeRange = [12, 15];

export const clusterRadius = 41;
