import PropTypes from 'prop-types';
import { setUserSetting } from 'actions/userSettings';
import { Alert } from 'antd';
import { getUserSettingsFromState } from 'components/Settings/UserSettings/userSettingsType';
import { Button, Icon, Tooltip } from 'components/common';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { avoidChars, boolValues } from 'models/searchErrorKeys';

const AlertDescription = styled.div`
  display: flex;
  width: 100%;
`;

const NoWrapDiv = styled.div`
  white-space: nowrap;
`;

export const PanelButton = styled(Button)`
  margin-right: 5px;
`;

export const BorderDiv = styled.div`
  margin-right: 5px;
  margin-top: 1px;
  border: 1px solid #d9d9d9;
  height: 22px;
  width: 25px;
  justify-content: center;
  background-color: white;
  align-items: center;
  display: flex;
`;

function BoolErrorMessage({
  updateSearchQuery,
  searchTermRef,
  setShowBoolError,
  hasBoolFailed,
  hasParseFailed,
  term,
  performSearch,
}) {
  const alertRef = useRef();
  const getTerm = useSelector(term);

  const [searchTerm, setSearchTerm] = useState('');
  const [maxWidth, setMaxWidth] = useState('100%');
  const [fixedTerm, setFixedTerm] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setSearchTerm(getTerm);
    if (setShowBoolError && searchTerm !== '') setShowBoolError(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTerm]);

  useEffect(() => {
    setFixedTerm(fixTermErrors());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBoolFailed, hasParseFailed, searchTerm]);

  useEffect(() => {
    if (searchTermRef && searchTermRef.current) {
      if (searchTermRef?.current) {
        setMaxWidth(searchTermRef.current.offsetWidth);
        const handleResize = debounce(() => {
          setMaxWidth(searchTermRef.current.offsetWidth);
        }, 100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }
  }, [searchTermRef]);

  const displayFixedTermsUI = () => {
    if (fixedTerm !== '') {
      let splitTerms = fixedTerm.split(' ').filter(x => x !== '');

      let ret = splitTerms.map((x, i) => {
        if (hasBoolFailed && boolValues.includes(x)) {
          return (
            <div>
              <b>{x}</b>&nbsp;
            </div>
          );
        }
        if (hasParseFailed && x.startsWith('"')) {
          let cutQuote = x.slice(1);
          return (
            <NoWrapDiv>
              <b>"</b>
              <>{cutQuote}&nbsp;</>
            </NoWrapDiv>
          );
        }
        if (hasParseFailed && x.endsWith('"')) {
          let cutQuote = x.slice(0, -1);
          return (
            <NoWrapDiv>
              <>{cutQuote}</>
              <b>"&nbsp;</b>
            </NoWrapDiv>
          );
        }
        if (
          avoidChars.includes(x) ||
          (i + 1 < splitTerms.length && avoidChars.includes(splitTerms[i + 1]))
        ) {
          return <NoWrapDiv>{x}</NoWrapDiv>;
        } else return <NoWrapDiv>{x}&nbsp;</NoWrapDiv>;
      });
      return ret;
    } else {
      return <div>{fixedTerm}</div>;
    }
  };

  const fixTermErrors = () => {
    let splitTerms = [];
    let term = searchTerm;
    if (hasParseFailed) {
      term = term.replace(/[“”]/g, '"');
    }
    let splitByWord = term.split(' ').filter(x => x !== '');

    splitByWord.forEach(x => {
      let lastSplit = 0;
      for (let i = 0; i < x.length; i++) {
        if (avoidChars.includes(x[i])) {
          let char = x[i];
          let fetchedWord = x.slice(lastSplit, i);
          if (fetchedWord.length && fetchedWord.length > 1) {
            splitTerms.push(fetchedWord);
          }
          lastSplit = i + 1;
          splitTerms.push(char);
        }
      }
      if (lastSplit < x.length) splitTerms.push(x.slice(lastSplit));
    });

    splitTerms.forEach((x, i) => {
      if (boolValues.some(y => y === x.toUpperCase())) {
        splitTerms[i] = x.toUpperCase();
      }
    });

    for (let i = 0; i < splitTerms.length - 1; i++) {
      // Ensures that current value is a word, that isnt bool
      if (
        splitTerms[i] !== '"' &&
        !boolValues.includes(splitTerms[i].toUpperCase())
      ) {
        if (hasParseFailed) {
          let wordCount = 1;
          let firstValue = i;
          while (
            i < splitTerms.length - 1 &&
            !avoidChars.includes(splitTerms[i]) &&
            !avoidChars.includes(splitTerms[i + 1]) &&
            !boolValues.includes(splitTerms[i + 1].toUpperCase())
          ) {
            wordCount++;
            i++;
          }
          if (wordCount > 1) {
            if (firstValue === 0) {
              splitTerms.splice(0, 0, '"');
              firstValue++;
              i++;
            }
            if (i === splitTerms.length - 1) {
              splitTerms.push('"');
            }
            if (
              !(splitTerms[firstValue - 1] === '"' && splitTerms[i + 1] === '"')
            ) {
              if (splitTerms[firstValue - 1] !== '"') {
                splitTerms.splice(firstValue, 0, '"');
                i++;
              }
              if (splitTerms[i + 1] !== '"') {
                splitTerms.splice(i + 1, 0, '"');
                i++;
              }
            }
          }
        }
      }
    }
    let retValue = '';
    splitTerms.forEach((x, i) => {
      if (boolValues.includes(x)) {
        if (
          i < splitTerms.length - 1 &&
          avoidChars.includes(splitTerms[i + 1])
        ) {
          retValue += ' ' + x + ' ';
        } else {
          retValue += ' ' + x;
        }
      } else if (
        i === 0 ||
        avoidChars.includes(splitTerms[i - 1]) ||
        avoidChars.includes(splitTerms[i])
      ) {
        retValue += x;
      } else retValue += ' ' + x;
    });
    return retValue;
  };

  const onFixTerm = () => {
    updateSearchQuery(fixedTerm);
    performSearch();
    setShowBoolError(false);
  };

  const onDontFixTerm = () => {
    performSearch();
    setShowBoolError(false);
  };

  const onDontShowAgain = () => {
    if (hasBoolFailed)
      dispatch(
        setUserSetting(getUserSettingsFromState.DisplayBoolErrorMessage, false)
      );
    if (hasParseFailed)
      dispatch(
        setUserSetting(
          getUserSettingsFromState.DisplayPhraseErrorMessage,
          false
        )
      );

    setShowBoolError(false);
  };

  const getTitle = () => {
    if (hasBoolFailed && hasParseFailed)
      return <b style={{ minWidth: '155px' }}>Boolean & Phrase Alert!</b>;
    if (hasBoolFailed && !hasParseFailed)
      return <b style={{ minWidth: '95px' }}>Boolean Alert!</b>;
    if (!hasBoolFailed && hasParseFailed)
      return <b style={{ minWidth: '90px' }}>Phrase Alert!</b>;
  };
  const getTooltip = () => {
    if (hasBoolFailed && hasParseFailed)
      return 'Boolean operators must be capitalised & phrases must be in quotations';
    if (hasBoolFailed && !hasParseFailed)
      return 'Boolean operators must be capitalised';
    if (!hasBoolFailed && hasParseFailed)
      return 'Phrases must be in quotations';
  };
  const parseSearchTerm = () => {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ minWidth: '90px' }}>Did you mean:</div>
        <div style={{ display: 'flex', overflowX: 'auto' }} ref={alertRef}>
          {displayFixedTermsUI()} ?
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginTop: 5, maxWidth: maxWidth + 'px' }}>
      <div>
        <Alert
          style={{ height: '100%', paddingBottom: '7px', paddingTop: '7px' }}
          description={
            <AlertDescription>
              {getTitle()}
              {parseSearchTerm()}
            </AlertDescription>
          }
          type='error'
          action={
            <div
              style={{
                display: 'flex',
                marginLeft:
                  hasBoolFailed && hasParseFailed
                    ? '155px'
                    : hasBoolFailed
                    ? '95px'
                    : '90px',
              }}
            >
              <PanelButton size='small' onClick={onFixTerm}>
                Yes
              </PanelButton>
              <PanelButton size='small' onClick={onDontFixTerm}>
                No
              </PanelButton>
              <PanelButton
                size='small'
                style={{ marginLeft: 5 }}
                onClick={onDontShowAgain}
              >
                Don't ask again
              </PanelButton>
              <Tooltip
                placement='bottom'
                title={getTooltip()}
                overlayStyle={{ maxWidth: 300 }}
              >
                <BorderDiv>
                  <Icon size='1.5x' icon='help' color='black'></Icon>
                </BorderDiv>
              </Tooltip>
            </div>
          }
        ></Alert>
      </div>
    </div>
  );
}

BoolErrorMessage.propTypes = {
  updateSearchQuery: PropTypes.func.isRequired,
  setShowBoolError: PropTypes.func.isRequired,
  hasBoolFailed: PropTypes.bool.isRequired,
  hasParseFailed: PropTypes.bool.isRequired,
  term: PropTypes.string.isRequired,
  performSearch: PropTypes.func.isRequired,
};

export default BoolErrorMessage;
