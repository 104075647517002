import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/common';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  input {
    width: 50px;
    height: 50px;
  }
  justify-content: space-between;
`;

const length = 6;
const getCode = arr => {
  let code = '';
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === '') {
      return '';
    }

    code += arr[i];
  }

  return code;
};

function TfaInput({ value, isLoading, onChange, style = {} }) {
  const [nextInputFocus, setNextInputFocus] = useState(0);
  const [codeDigits, setCodeDigits] = useState(
    [...Array(length).keys()].map(x => '')
  );
  const refs = useRef([...Array(length).keys()].map(_ => null));
  const [code, setCode] = useState('');
  const handleChange = useCallback((index, value) => {
    setCodeDigits(c => {
      const newValues = [...c];
      if (/^[0-9]$/i.test(value) || value === '') {
        newValues[index] = value;
      }

      let nextIndex = value === '' ? index - 1 : index + 1;
      const newCode = getCode(newValues);
      if (!(newCode && nextIndex === length)) {
        setNextInputFocus(_ => (nextIndex === -1 ? 0 : nextIndex));
      }
      setCode(nextIndex === length ? newCode : '');

      return newValues;
    });
  }, []);

  const handleKeyDown = useCallback((index, e) => {
    // arrow left
    if (e.keyCode === 37) {
      setNextInputFocus(index - 1);
    }
    // arrow right
    if (e.keyCode === 39) {
      setNextInputFocus(index + 1);
    }
  }, []);

  useEffect(() => {
    const index = nextInputFocus < 0 ? nextInputFocus + length : nextInputFocus;
    refs.current[index % length]?.focus();
    refs.current[index % length]?.setSelectionRange(0, 1);
  }, [nextInputFocus, onChange]);

  useEffect(() => {
    if (code) {
      onChange(code);
    }
  }, [code, onChange]);

  useEffect(() => {
    // clean up the code
    if (!value) {
      setCodeDigits([...Array(length).keys()].map(x => ''));
      setCode(c => (!value ? '' : c));
    }
  }, [value]);

  return (
    <Container style={style}>
      {[...Array(6).keys()].map(i => (
        <Input
          ref={el => (refs.current[i] = el)}
          disabled={isLoading}
          onChange={val => null}
          onInput={e => handleChange(i, e.target.value)}
          onKeyDown={e => handleKeyDown(i, e)}
          value={codeDigits[i]}
        />
      ))}
    </Container>
  );
}

TfaInput.propTypes = {
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

export default TfaInput;
