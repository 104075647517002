import { downloadExport } from 'services/api';
import { useGet } from 'hooks';
import { useEffect } from 'react';
import { PerformDownload } from 'components/Redirects/Download';
import { ActionButton } from 'components/ExportHistory/styled';
import { Icon } from 'components/common';

const Download = ({ disabled, downloadGuid }) => {
  const [{ res }, getDownload] = useGet({
    responseType: 'blob',
  });

  useEffect(() => {
    if (res) {
      PerformDownload(res);
    }
  }, [res]);

  const handleDownload = () => {
    getDownload({
      url: `${downloadExport(downloadGuid)}?source=ExportHistory`,
    });
  };

  return (
    <ActionButton
      onClick={handleDownload}
      type='primary'
      disabled={disabled}
      size='small'
    >
      <div>
        {'Download '}
        <Icon icon='download' size='sm' color='inherit' />
      </div>
    </ActionButton>
  );
};

export default Download;
