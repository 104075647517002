import styled from 'styled-components';

import { Statistic } from 'components/common';
import { colours } from 'utils/theme';

const Stats = styled.div`
  display: flex;
  flex: 0;
  justify-content: space-around;
  margin-bottom: ${p => p.theme.gutter};
  > div {
    flex: 1;
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

const Statistics = ({ loading, researcher }) => {
  if (loading) return null;
  const { projects, tradeShows, publications, clinicalTrials } = researcher;
  return (
    <Stats>
      <Statistic
        icon='award'
        iconColor={colours.grants}
        title='Funding Awards'
        value={projects.length}
      />
      <Statistic
        icon='image'
        iconColor={colours.posters}
        title='Posters'
        value={tradeShows.length}
      />
      <Statistic
        icon='book'
        iconColor={colours.publications}
        title='Publications'
        value={publications.length}
      />
      <Statistic
        icon='trial'
        iconColor={colours.trials}
        title='Clinical Trials'
        value={clinicalTrials.length}
      />
    </Stats>
  );
};

export default Statistics;
