export const messageTypes = {
  generic: 1,
  tradeshow: 2,
  meeting: 3,
};

export const messageTypesConfig = [
  {
    id: messageTypes.generic,
    title: 'Generic outreach',
    icon: 'message',
  },
  {
    id: messageTypes.tradeshow,
    title: 'Meet at a tradeshow',
    icon: 'store',
  },
  {
    id: messageTypes.meeting,
    title: 'Meet in the researcher’s city',
    icon: 'locationDot',
  },
];

export const keywordTypes = {
  default: 0,
  custom: 1,
  custonAndDefault: 2,
};
