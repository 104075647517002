import storage from 'services/storage';

export const types = {
  SAVE_FORM_VALUES: 'AI_SAVE_FORM_VALUES',
  RESTORE_STATE: 'AI_RESTORE_STATE',
};

export const persistState = async getState => {
  const state = getState();
  const toSave = JSON.parse(JSON.stringify(state)).ai;
  await storage.setItem('ai', toSave);
};

export const saveFormValues = values => (dispatch, getState) => {
  dispatch({
    type: types.SAVE_FORM_VALUES,
    payload: values,
  });
  persistState(getState);
};

export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});
