function Circle(props) {
  return (
    <svg
      width='160'
      height='160'
      viewBox='0 0 160 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='80' cy='80' r='80' fill='#556872' />
      <circle cx='80' cy='80' r='70' fill='#68868D' />
      <circle cx='80' cy='80' r='60' fill='#86B2B6' />
      <circle cx='80' cy='80' r='50' fill='#9BD1D3' />
      <circle cx='80' cy='80' r='40' fill='#9FD9D9' />
    </svg>
  );
}

export default Circle;
