//@ts-check
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getActiveFilters, getFilter } from 'selectors/search';
import {
  acquisitionAcquirers,
  acquisitionTypes,
  commonDateRanges,
  lowerFundingAmounts,
  upperFundingAmounts,
} from 'services/inputTokens';
import { formatStringNumber } from 'utils/number';
import { Filter } from '../helpers';
import { typeNumberExamples } from './VentureCapital';
import FilterGroup from './FilterGroup';
import { acquisitionOnlyKeys } from './keys';

export default function Acquisitions({ loading }) {
  const acquisitionDateSelection = useSelector(
    getFilter('acquisitionDateSelection')
  );

  let acquisitionOnlyActiveFilters = useSelector(
    getActiveFilters(Object.keys(acquisitionOnlyKeys))
  );

  const showAcquiredCompanies = useSelector(
    getFilter(acquisitionOnlyKeys.showAcquiredCompanies.key)
  );
  const showAcquiringCompanies = useSelector(
    getFilter(acquisitionOnlyKeys.showAcquiringCompanies.key)
  );
  const acquisitionFiltersActive =
    showAcquiringCompanies || showAcquiredCompanies;

  const minAmountText = 'Min Amount ($)';
  const maxAmountText = 'Max Amount ($)';

  if (acquisitionDateSelection === 'custom') {
    acquisitionOnlyActiveFilters = acquisitionOnlyActiveFilters.filter(
      af => af !== 'acquisitionDateSelection'
    );
  }

  return (
    <FilterGroup
      icon='handShake'
      title='Acquisitions'
      activeKeys={acquisitionOnlyActiveFilters}
      sectionKey='acquisitions'
    >
      <>
        {/* @ts-ignore */}
        <Filter
          filterType='checkbox'
          filterId={acquisitionOnlyKeys.showAcquiredCompanies.key}
          helpText='Your results will show companies that have been sold'
          disabled={loading}
          labelText='Show Acquired Companies'
        />
        {/* @ts-ignore */}
        <Filter
          filterType='checkbox'
          filterId={acquisitionOnlyKeys.showAcquiringCompanies.key}
          helpText='Your results will show companies that make acquisitions (buyers)'
          disabled={loading}
          labelText='Show Companies that Acquire'
        />
        {/* @ts-ignore */}
        <Filter
          //@ts-ignore
          style={{ width: '100%' }}
          filterType='select'
          filterId={acquisitionOnlyKeys.acquisitionDateSelection.key}
          placeholder='Acquisition Date'
          options={commonDateRanges}
          showSearch
          disabled={!acquisitionFiltersActive || loading}
        />
        {acquisitionDateSelection === 'custom' && (
          //@ts-ignore
          <Filter
            //@ts-ignore
            placeholder={[`Acquired From`, `Acquired To`]}
            type='range'
            filterType='datePicker'
            filterId={acquisitionOnlyKeys.acquisitionDatePicked.key}
            disabled={!acquisitionFiltersActive || loading}
          />
        )}
        {/* @ts-ignore */}
        <Filter
          //@ts-ignore
          filterType='token'
          options={acquisitionTypes}
          placeholder={'Acquisition Type'}
          filterId={acquisitionOnlyKeys.acquisitionType.key}
          onTheFly={false}
          noLimit
          scrollable={true}
          disabled={!acquisitionFiltersActive || loading}
        />
        <>
          <div>{'Price'}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* @ts-ignore */}
            <Filter
              //@ts-ignore
              filterType='autoComplete'
              filterId={acquisitionOnlyKeys.minAcquisitionPrice.key}
              placeholder={minAmountText}
              formatter={formatStringNumber}
              options={lowerFundingAmounts}
              helpText={`Lowest Acquisition Amount ${typeNumberExamples}`}
              disabled={!acquisitionFiltersActive || loading}
            />
            <div style={{ padding: '0 5px' }}>-</div>
            {/* @ts-ignore */}
            <Filter
              //@ts-ignore
              filterType='autoComplete'
              filterId={acquisitionOnlyKeys.maxAcquisitionPrice.key}
              placeholder={maxAmountText}
              formatter={formatStringNumber}
              options={upperFundingAmounts}
              helpText={`Highest Acquisition Amount ${typeNumberExamples}`}
              disabled={!acquisitionFiltersActive || loading}
            />
          </div>
        </>
        {/* @ts-ignore */}
        <Filter
          //@ts-ignore
          filterType='token'
          options={acquisitionAcquirers}
          placeholder={'Acquirer'}
          filterId={acquisitionOnlyKeys.acquirers.key}
          onTheFly={false}
          minInputSize={1}
          emptyOption={input => `No results matching '${input}' found`}
          disabled={!showAcquiredCompanies || loading}
        />
      </>
    </FilterGroup>
  );
}

Acquisitions.propTypes = {
  loading: PropTypes.bool,
};
