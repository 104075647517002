import { useState, useEffect } from 'react';

import BaseTable from '../Base';
import Footer from './Footer';

const perPage = [
  { value: 50, label: '50 per page' },
  { value: 100, label: '100 per page' },
  { value: 250, label: '250 per page' },
  { value: 500, label: '500 per page' },
];

export const ControlledPagination = ({
  pageSize,
  pageNumber,
  data,
  onChangePageNumber,
  resultSize,
  ...rest
}) => {
  const skip = pageNumber * pageSize;

  useEffect(() => {
    if (skip >= resultSize && resultSize > 0) {
      const lastPage = Math.ceil(resultSize / pageSize) - 1;
      onChangePageNumber(lastPage);
    }
  }, [resultSize, skip, pageNumber, pageSize, onChangePageNumber]);

  const footer = (
    <Footer
      pageSize={pageSize}
      pageNumber={pageNumber}
      rowCount={data.length}
      onChangePageNumber={onChangePageNumber}
      resultSize={resultSize}
      {...rest}
    />
  );

  return (
    <BaseTable
      data={data}
      pageSize={pageSize}
      pageNumber={pageNumber}
      footer={footer}
      onChangePageNumber={onChangePageNumber}
      resultSize={resultSize}
      {...rest}
    />
  );
};

export const UncontrolledPagination = ({ pageSizeOptions, data, ...rest }) => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);
  const [pageNumber, setPageNumber] = useState(0);
  const skip = pageNumber * pageSize;

  useEffect(() => {
    if (skip >= data.length && data.length > 0) {
      const lastPage = Math.ceil(data.length / pageSize) - 1;
      setPageNumber(lastPage);
    }
  }, [data.length, skip, pageNumber, pageSize]);

  const dataToRender = data.slice(skip, skip + pageSize);

  return (
    <ControlledPagination
      {...rest}
      pageSize={pageSize}
      onChangePageSize={setPageSize}
      pageNumber={pageNumber}
      onChangePageNumber={setPageNumber}
      resultSize={data.length}
      data={dataToRender}
      pageSizeOptions={pageSizeOptions}
    />
  );
};

const Pagination = ({
  paginate,
  onChangePageSize,
  pageSizeOptions = perPage,
  ...rest
}) => {
  if (paginate && !onChangePageSize)
    return (
      <UncontrolledPagination {...rest} pageSizeOptions={pageSizeOptions} />
    );
  if (paginate && onChangePageSize)
    return (
      <ControlledPagination
        {...rest}
        pageSizeOptions={pageSizeOptions}
        onChangePageSize={onChangePageSize}
      />
    );
  return <BaseTable {...rest} />;
};

export default Pagination;
