import { setUserSetting } from 'actions/userSettings';
import { Alert } from 'antd';
import { getUserSettingsFromState } from 'components/Settings/UserSettings/userSettingsType';
import { Button, Icon, Tooltip } from 'components/common';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const AlertDescription = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
`;

export const PanelButton = styled(Button)`
  margin-right: 5px;
`;

export const BorderDiv = styled.div`
  margin-right: 5px;
  margin-top: 1px;
  border: 1px solid #d9d9d9;
  height: 22px;
  width: 25px;
  justify-content: center;
  background-color: white;
  align-items: center;
  display: flex;
`;

function PreviouslyExportedMessage({ setShowPrevouslyExportedMessage }) {
  const dispatch = useDispatch();

  const onYes = () => {
    dispatch(
      setUserSetting(
        getUserSettingsFromState.ExcludePreviouslyExportedEmailsByDefault,
        true
      )
    );

    setShowPrevouslyExportedMessage(false);
  };

  const onNo = () => {
    dispatch(
      setUserSetting(
        getUserSettingsFromState.ExcludePreviouslyExportedEmailsByDefault,
        false
      )
    );

    setShowPrevouslyExportedMessage(false);
  };

  return (
    <div style={{ marginTop: 5 }}>
      <div>
        <Alert
          style={{ height: '100%', paddingBottom: '7px', paddingTop: '7px' }}
          description={
            <div>
              <AlertDescription>
                Do you want to exclude previously exported emails selected by
                default?
              </AlertDescription>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                }}
              >
                <PanelButton size='small' onClick={onYes}>
                  Yes
                </PanelButton>
                <PanelButton size='small' onClick={onNo}>
                  No
                </PanelButton>
                <Tooltip
                  placement='bottom'
                  title='You can always change this later in settings'
                  overlayStyle={{ maxWidth: 300 }}
                >
                  <BorderDiv>
                    <Icon size='1x' icon='help' color='black'></Icon>
                  </BorderDiv>
                </Tooltip>
              </div>
            </div>
          }
          type='error'
        ></Alert>
      </div>
    </div>
  );
}

PreviouslyExportedMessage.propTypes = {};

export default PreviouslyExportedMessage;
