import useWindowWidth from 'hooks/useWindowWidth';
import { useLayoutEffect, useState } from 'react';

function newColumnWidthsAfterRemoval(widths) {
  const lastWidth = widths[widths.length - 1];
  const totalWidth = widths
    .filter((_, i) => i < widths.length - 1)
    .reduce((sum, c) => sum + c, 0);

  return widths
    .filter((_, i) => i < widths.length - 1)
    .map(w => (w / totalWidth) * lastWidth + w);
}

function newColumnWidthsAfterAddition(newMinWidth, widths, totalWidth) {
  let takenWidth = (newMinWidth + totalWidth) / (widths.length + 1);
  let newWidths = widths.map(w => w - (w / totalWidth) * takenWidth);
  newWidths.push(takenWidth);

  return newWidths;
}

function areWidthsValid(widths, minWidths) {
  for (let i = 0; i < widths.length; i++) {
    if (widths[i] < minWidths[i]) {
      return false;
    }
  }

  return true;
}

export const useResizeableHeaders = ({ headers, headerRef, loading }) => {
  const { width } = useWindowWidth();
  const [headerCount, setHeaderCount] = useState(headers.length);

  useLayoutEffect(() => {
    if (headers.every(x => x.minWidth) && headerRef?.current && !loading) {
      const minWidths = headers.map(h => h.minWidth);
      let totalWidth = Array.from(headerRef.current.children).reduce(
        (sum, c) => sum + c.offsetWidth,
        0
      );

      let widths = Array.from(headerRef.current.children).map(
        c => c.offsetWidth
      );

      if (areWidthsValid(widths, minWidths)) {
        if (headerRef.current.children.length < headers.length) {
          let newHeaderCount = headerCount;

          let shouldUpdateCount = false;
          while (
            areWidthsValid(widths, minWidths) &&
            newHeaderCount <= headers.length
          ) {
            newHeaderCount++;
            if (newHeaderCount < headers.length) {
              widths = newColumnWidthsAfterAddition(
                headers[newHeaderCount - 1].minWidth,
                widths,
                totalWidth
              );
            }
            shouldUpdateCount = true;
          }

          if (shouldUpdateCount) {
            setHeaderCount(newHeaderCount - 1);
          }
        }
      } else {
        if (headerRef.current.children.length > 0) {
          let newHeaderCount = headerCount;

          while (!areWidthsValid(widths, minWidths) && newHeaderCount > 1) {
            newHeaderCount--;
            widths = newColumnWidthsAfterRemoval(widths);
          }

          // see if we can add 1 back in
          if (newHeaderCount < headers.length) {
            widths = newColumnWidthsAfterAddition(
              headers[newHeaderCount - 1].minWidth,
              widths,
              totalWidth
            );
            if (areWidthsValid(widths, minWidths)) {
              newHeaderCount++;
            }
          }

          setHeaderCount(newHeaderCount);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef, width, headers, loading]);

  return headerCount;
};
