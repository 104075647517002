import styled from 'styled-components';
import { Alert } from 'antd';

const ExportLink = styled.span`
  cursor: pointer;
  color: ${p => p.theme.linkBlue};
`;

const LargeNetworkWarning = ({ onExportNetwork, exportingNetwork }) => {
  const handleExport = () => {
    if (exportingNetwork) return;
    onExportNetwork();
  };

  return (
    <Alert
      style={{ margin: '10px 20px', textAlign: 'center' }}
      type='warning'
      message={
        <span>
          This is a large network, we recommend{' '}
          <ExportLink onClick={handleExport}>exporting it</ExportLink> for
          easier analysis
        </span>
      }
    />
  );
};

export default LargeNetworkWarning;
