import { useDispatch, useSelector } from 'react-redux';

import { Input, Select, DatePicker } from 'components/common';
import { setSearchBuilderFields } from 'actions/search';
import { getSearchBuilderFields } from 'selectors/search';
import { format } from 'utils/date';

import Group from '../Group';
import Warning from '../Warning';
import { InputsContainer, TokenInput } from './styled';

const fieldOptions = [
  {
    label: 'Has Researcher Phone',
    value: 'Phone',
    type: 'textBoolean',
  },
  {
    label: 'Has Researcher Email',
    value: 'Email',
    type: 'emailTextBooleanInput',
  },
  {
    label: 'Email Publicly Sourced',
    value: 'EmailPubliclySourced',
    type: 'boolean',
  },
  {
    label: 'Broad Research Categories',
    value: 'JournalTopCategories',
    error: 'Enter at least one broad research category',
  },
  {
    label: 'Author Last Name',
    value: 'Authors.LastName',
    error: "Enter at least one author's last name",
  },
  {
    label: 'Author First Name',
    value: 'Authors.FirstName',
    error: "Enter at least one author's first name",
  },
  {
    label: 'Publication Title',
    value: 'ArticleTitle',
    error:
      "Enter at least one word/phrase to be included in a publication's title",
  },
  {
    label: 'Publication Abstract',
    value: 'AbstractText.Abstract',
    error:
      "Enter at least one word/phrase to be included in a publication's abstract",
  },
  {
    label: 'Publication Keywords',
    value: 'PublicationKeywords.Value',
    error:
      "Enter at least one word/phrase to be included in a publication's keywords",
  },
  {
    label: 'Focused Research Areas',
    value: 'MeshHeadings.Name',
    error:
      "Enter at least one word/phrase to be included in a researcher's Focused Research Areas",
  },
  {
    label: 'Funding Source',
    value: 'FundingSource',
    error: 'Enter at least one funding source',
  },
  {
    label: 'Funding Amount',
    value: 'FundingAmount',
    type: 'number',
    error: 'Enter a funding amount',
  },
  {
    label: '3 Year Total Funding',
    value: 'ThreeYearTotalFunding',
    type: 'number',
    error: 'Enter a 3 year total funding amount',
  },
  {
    label: 'All Time Total Funding',
    value: 'AllTimeTotalFunding',
    type: 'number',
    error: 'Enter an all time total funding amount',
  },
  {
    label: '3 Year HIndex Average',
    value: 'ThreeYearHIndexAverage',
    type: 'number',
    error: 'Enter a 3 year HIndex average',
  },
  {
    label: '3 Year HIndex Total',
    value: 'ThreeYearHIndexTotal',
    type: 'number',
    error: 'Enter a 3 year HIndex total',
  },
  {
    label: '3 Year SJR Average',
    value: 'ThreeYearSJRAverage',
    type: 'number',
    error: 'Enter a 3 Year SJR average',
  },
  {
    label: '3 Year SJR Total',
    value: 'ThreeYearSJRTotal',
    type: 'number',
    error: 'Enter a 3 Year SJR total',
  },
  {
    label: 'All Time HIndex Average',
    value: 'AllTimeHIndexAverage',
    type: 'number',
    error: 'Enter an all time HIndex average',
  },
  {
    label: 'All Time HIndex Total',
    value: 'AllTimeHIndexTotal',
    type: 'number',
    error: 'Enter an all time HIndex total',
  },
  {
    label: 'All Time SJR Average',
    value: 'AllTimeSJRAverage',
    type: 'number',
    error: 'Enter an all time SJR average',
  },
  {
    label: 'All Time SJR Total',
    value: 'AllTimeSJRTotal',
    type: 'number',
    error: 'Enter an all time SJR total',
  },
  {
    label: 'Researcher Date Added',
    value: 'ResearcherDateAdded',
    type: 'date',
    error: 'Enter a researcher added date',
  },
  {
    label: 'Current Pre Print',
    value: 'IsPrePrint',
    type: 'boolean',
  },
  {
    label: 'All historical papers ever in a Preprint status',
    value: 'PreviouslyPrePrint',
    type: 'boolean',
  },
];

const sortedFieldOptions = fieldOptions.sort((a, b) =>
  ('' + a.label).localeCompare(b.label)
);

const DefaultInput = ({ value, onChange }) => {
  const handleChange = v => {
    const query = `(${v.map(x => `"${x}"`).join(' OR ')})`;
    onChange(v, query);
  };

  return <TokenInput value={value?.value} onChange={handleChange} />;
};

const BooleanInput = ({ value, onChange }) => {
  const handleChange = v => {
    onChange(v, `${v}`);
  };

  return (
    <Select
      style={{ flex: 1 }}
      value={value?.value}
      onChange={handleChange}
      options={[
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ]}
      disableTooltip
    />
  );
};

const EmailTextBooleanInput = ({ value, onChange }) => {
  const handleChange = v => {
    onChange(
      v,
      v === 'True' ? '(*) AND NOT EmailStatusCategory:("Undeliverable”)' : ``
    );
  };

  return (
    <Select
      style={{ flex: 1 }}
      value={value?.value}
      onChange={handleChange}
      options={[
        { label: 'True', value: 'True' },
        { label: 'False', value: 'False' },
      ]}
    />
  );
};

const TextBooleanInput = ({ value, onChange }) => {
  const handleChange = v => {
    onChange(v, v === 'True' ? '(*)' : `(NOT *)`);
  };

  return (
    <Select
      style={{ flex: 1 }}
      value={value?.value}
      onChange={handleChange}
      options={[
        { label: 'True', value: 'True' },
        { label: 'False', value: 'False' },
      ]}
      disableTooltip
    />
  );
};

const NumberInput = ({ value, onChange }) => {
  const buildQuery = (value = {}) => {
    const operator = value.operator || '>';
    const term = value.term || 0;

    return `${operator}${term}`;
  };

  const handleOperatorChange = operator => {
    const nextValue = {
      ...value.value,
      operator,
    };
    const query = buildQuery(nextValue);
    onChange(nextValue, query);
  };

  const handleTermChange = term => {
    const nextValue = {
      ...value.value,
      term,
    };
    const query = buildQuery(nextValue);
    onChange(nextValue, query);
  };

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Select
        style={{ width: 120 }}
        defaultValue='>'
        value={value?.value?.operator}
        onChange={handleOperatorChange}
        options={[
          { label: 'greater than', value: '>' },
          { label: 'less than', value: '<' },
          { label: 'equal to', value: '=' },
        ]}
        disableTooltip
      />
      <Input
        style={{ marginLeft: 10, flex: '1' }}
        value={value?.value?.term}
        onChange={handleTermChange}
      />
    </div>
  );
};

const DateInput = ({ value, onChange }) => {
  const handleChange = dateRange => {
    if (!dateRange) {
      return onChange([], null);
    }
    const from = format(dateRange[0], 'YYYY-MM-DD');
    const to = format(dateRange[1], 'YYYY-MM-DD');
    const query = `[${from} TO ${to}]`;
    onChange(dateRange, query);
  };

  return (
    <DatePicker
      placeholder={['From', 'To']}
      type='range'
      containerStyle={{ flex: 1 }}
      value={value?.value}
      onChange={handleChange}
    />
  );
};

const AdvancedField = ({ value, onChange }) => {
  const handleChangeESField = (esField, option) => {
    if (!esField) {
      onChange();
    } else {
      const { type, error } = option.details;
      if (type !== value?.type) {
        onChange({ esField, type, error });
      } else {
        onChange({ ...value, esField, type, error });
      }
    }
  };

  const handleChangeValue = (v, query) => {
    onChange({ ...value, value: v, query });
  };

  const components = {
    default: DefaultInput,
    boolean: BooleanInput,
    number: NumberInput,
    date: DateInput,
    textBoolean: TextBooleanInput,
    emailTextBooleanInput: EmailTextBooleanInput,
  };

  const Component = components[value?.type] || components.default;

  let warningMessage;

  if (value?.value?.length === 0 || value?.value?.term === '') {
    warningMessage = value.error;
  }

  return (
    <InputsContainer>
      <Select
        allowClear
        placeholder='Field'
        value={value?.esField}
        onChange={handleChangeESField}
        style={{ width: 200, marginRight: 10 }}
        options={sortedFieldOptions}
        disableTooltip
      />
      <Component onChange={handleChangeValue} value={value} />
      {warningMessage && <Warning message={warningMessage} />}
    </InputsContainer>
  );
};

const AdvancedFields = ({ id }) => {
  const dispatch = useDispatch();
  const fields = useSelector(getSearchBuilderFields(id));
  const value = fields.advanced || [];

  const handleChange = i => v => {
    let nextValue = [...value];
    if (value[i]?.esField && !v) {
      nextValue.splice(i, 1);
    } else {
      nextValue[i] = v;
    }
    dispatch(setSearchBuilderFields('advanced', nextValue, id));
  };

  const inputCount = value?.length + 1;

  return (
    <Group title='Advanced Fields' isCollapsed={inputCount === 1}>
      {Array.from(Array(inputCount)).map((v, i) => (
        <AdvancedField
          key={i}
          value={value[i]}
          onChange={handleChange(i)}
          allValues={value}
        />
      ))}
    </Group>
  );
};

export default AdvancedFields;
