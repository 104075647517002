import React from 'react';

import { Link, IconText, Tooltip } from 'components/common';
import { formatRange } from 'utils/number';
import { formatLocation } from 'utils/geo';
import FollowOrganisationProfile from './FollowOrganisationProfile';
import About from '../Statistics/about';
import SocialMediaLink from 'components/Researcher/Profile/SocialMediaLink';
import {
  ActionsContainer,
  OrgDetailsContainer,
  OrgInfoContainer,
  ProfileHeaderContainer,
  LogoFlexContainer,
  ProfileNameContainer,
} from '../styled';
import AcquiredIcon from 'components/Search/Results/Result/Organisation/AcquiredIcon';
import Actions from '../Actions';

const Profile = ({ organisation }) => {
  const { city, state, country, logoUrl } = organisation;

  const makeCustomSearchUrl = organisation =>
    `http://www.google.com/search?q=${organisation.organisationName} site:linkedin.com`;

  const s3Bucket = process.env.REACT_APP_PORTAL_CDN || 'dev-scil-portal-cdn';
  const avatarImg = `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/images/logos/superorganisation/Avatar_40_40.png`;

  const makeImageUrl = () => {
    return `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/${logoUrl}_100x100`;
  };

  const addDefaultSrc = ev => {
    if (ev.target.src !== avatarImg) {
      ev.target.src = avatarImg;
    }
  };

  return (
    <>
      <ProfileHeaderContainer>
        <LogoFlexContainer
          style={{
            marginBottom: '4px',
          }}
        >
          {logoUrl ? (
            <img
              src={makeImageUrl()}
              onError={addDefaultSrc}
              alt={'Company Logo'}
            />
          ) : (
            <img src={avatarImg} alt={'Company Logo'} />
          )}
          <FollowOrganisationProfile organisation={organisation} />
        </LogoFlexContainer>
        <ProfileNameContainer style={{ display: 'flex', alignItems: 'center' }}>
          <Link size='large'>{organisation.organisationName}</Link>
          <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
            <AcquiredIcon
              size='lg'
              acquisitions={organisation.superOrganisationAcquisitions}
            />
          </div>
        </ProfileNameContainer>
      </ProfileHeaderContainer>
      <OrgDetailsContainer>
        <OrgInfoContainer>
          {(city || state || country) && (
            <IconText
              value={formatLocation(city, state, country)}
              icon='map'
              style={{ marginRight: 15 }}
            />
          )}
          {(organisation.sizeRangeLowerBound > 0 ||
            organisation.sizeRangeUpperBound > 0) && (
            <Tooltip
              title={
                'This number represents the count of employees at the company'
              }
            >
              <span>
                <IconText
                  value={formatRange(
                    organisation.sizeRangeLowerBound,
                    organisation.sizeRangeUpperBound
                  )}
                  icon='user'
                  style={{ marginRight: 15 }}
                />
              </span>
            </Tooltip>
          )}
          {organisation.foundedYear !== 0 && (
            <Tooltip title={'This represents when the company was founded'}>
              <span>
                <IconText
                  value={organisation.foundedYear}
                  icon='rocket'
                  style={{ marginRight: 15 }}
                />
              </span>
            </Tooltip>
          )}
          {!!organisation.organisationCategory && (
            <span style={{ display: 'inherit' }}>
              <IconText
                value={organisation.organisationCategory}
                icon='category'
              />
              &nbsp;
              <Actions
                organisation={organisation.id}
                modalTitle={'Report Organisation Category'}
                isAiIcon={true}
                placeholder={'Organisation Category Suggestion'}
              />
            </span>
          )}
          {organisation.superOrganisationPreviousName && (
            <IconText
              value={`Previously called ${organisation.superOrganisationPreviousName}`}
              icon='certificate'
              style={{ marginRight: 15 }}
            />
          )}
          <div style={{ marginTop: '10px' }}>
            <Actions
              organisation={organisation.id}
              modalTitle={'Report Organisation'}
              isAiIcon={false}
              placeholder={'Report'}
            />
          </div>
        </OrgInfoContainer>

        <ActionsContainer>
          <SocialMediaLink
            network={{
              type: 'linkedin',
              displayName: 'LinkedIn',
            }}
            title={
              organisation.linkedIn ? 'View on LinkedIn' : 'Search for LinkedIn'
            }
            url={
              organisation.linkedIn
                ? `https://${organisation.linkedIn}`
                : makeCustomSearchUrl(organisation)
            }
            isMuted={organisation.linkedIn ? false : true}
            logDescription={{
              entityType: 'Organisation',
              location: 'OrganisationPage',
            }}
          />
        </ActionsContainer>
      </OrgDetailsContainer>
      {organisation && <About descriptions={organisation.description} />}
    </>
  );
};

export default Profile;
