import { useSelector, useDispatch } from 'react-redux';

import {
  Input,
  Checkbox,
  TokenInput,
  DatePicker,
  InputNumber,
  Select,
} from 'components/common';
import { setFilter, setFilterGroup } from 'actions/purchasing';
import { getFilter } from 'selectors/purchasing';

const components = {
  input: Input,
  checkbox: ({ value, ...rest }) => <Checkbox {...rest} checked={value} />,
  token: TokenInput,
  datePicker: DatePicker,
  select: Select,
  number: InputNumber,
};

const FilterField = ({
  filterId,
  filterType,
  filterGroup,
  allowClear = true,
  ...rest
}) => {
  const Component = components[filterType];

  const value = useSelector(getFilter(filterId, filterGroup));
  const dispatch = useDispatch();

  const handleChange = value =>
    dispatch(
      filterGroup
        ? setFilterGroup(filterGroup, filterId, value)
        : setFilter(filterId, value)
    );

  return (
    <Component
      {...rest}
      data-testid={`filter-${filterId}`}
      onChange={handleChange}
      value={value}
      allowClear={allowClear}
    />
  );
};

export default FilterField;
