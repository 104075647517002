import { types } from 'actions/rateLimits';

const defaultState = {
  needPassCaptcha: false,
  restoringState: false,
};

const REDUCER = {
  [types.SET_NEED_TO_PASS_CAPTCHA]: (state, action) => {
    return {
      ...state,
      needPassCaptcha: action.payload,
    };
  },
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...state,
        restoringState: false,
      };
    }
    let restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState = {
      ...defaultState,
      ...restoredState,
    };
    restoredState.restoringState = false;

    return restoredState;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
