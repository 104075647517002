import { useEffect } from 'react';
import { Modal, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveList, getAreAllMembersSelected } from 'selectors/lists';
import { usePost } from 'hooks';
import { exportMembers } from 'services/api';
import { setIsLoading } from 'actions/lists';
import { handleFailedExportNotification } from 'components/Search/Results/Actions/notifications';
import { views } from '../constants';
import useExport from '../Actions/useExport';

const Export = ({ onClose, type = views.researchers }) => {
  const dispatch = useDispatch();
  const activeList = useSelector(getActiveList);
  const allSelected = useSelector(getAreAllMembersSelected);
  const getExportBody = useExport();
  const [{ res: exportRes, error, loading }, postExport] = usePost({
    url: exportMembers(activeList.id, type),
  });

  useEffect(() => {
    if (exportRes) {
      dispatch(setIsLoading(false));
      onClose();
      notification.open({
        message: 'Generating Export',
        description:
          'We are generating your list export and will notify you when it is ready.',
        placement: 'bottomLeft',
      });
    }
  }, [exportRes, onClose, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(setIsLoading(false));
      onClose();
      handleFailedExportNotification(error.status);
    }
  }, [error, onClose, dispatch]);

  const handleExport = () => {
    const body = getExportBody();
    dispatch(setIsLoading(true));
    postExport(body);
  };

  const allOrSelectedText = allSelected ? 'all' : 'the selected';

  return (
    <Modal
      open
      title={`Export ${activeList.name}`}
      onCancel={onClose}
      onOk={handleExport}
      okText='Yes'
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      Would you like to export {allOrSelectedText} members of {activeList.name}?
    </Modal>
  );
};

export default Export;
