import { useState, useRef } from 'react';

import { Icon, Input, Tooltip } from 'components/common';

import Options from './Options';
import { Container, TokenContainer } from './styled';

export const Token = ({
  value,
  hideDelete,
  removeToken,
  displayKey,
  ...rest
}) => {
  return (
    <TokenContainer {...rest}>
      {displayKey ? value[displayKey] : value}
      {!hideDelete ? (
        <div data-testid='token-delete' onClick={removeToken}>
          <Icon icon='delete' size='sm' />
        </div>
      ) : (
        <div></div>
      )}
    </TokenContainer>
  );
};

const TokenInput = ({
  placeholder,
  options,
  value = [],
  onChange = () => {},
  className,
  optionKey,
  displayKey,
  disabledKey,
  disabledTooltipText,
  disabledChangesOnHover,
  readOnly = false,
  onTheFly = true,
  helpText,
  limit,
  noLimit,
  disabled,
  commitOnSpace,
  minInputSize = 0,
  emptyOption,
  'data-testid': dataTestId = 'token-input',
  scrollable,
  tokenLimit,
  otherRemoteParams,
  validator,
  maxTotalCharactersLength,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef(null);
  const ref = useRef(null);

  const createToken = tokenValue => {
    const toAdd = tokenValue || inputValue;

    if (validator && !validator(toAdd)) {
      return;
    }

    if (tokenLimit && value.length >= tokenLimit) return;
    if (
      (optionKey && value.some(v => v[optionKey] === toAdd[optionKey])) ||
      value.includes(toAdd)
    ) {
      return;
    }

    if (maxTotalCharactersLength) {
      const totalLength = value.reduce(
        (prev, current) => prev + current.length,
        0
      );
      if (totalLength + (toAdd?.length ?? 0) > maxTotalCharactersLength) {
        return;
      }
    }

    if (toAdd) {
      onChange([...value, toAdd]);
      setInputValue('');
    }

    if (containerRef.current) {
      containerRef.current.dispatchEvent(
        new Event('mouseout', { bubbles: true })
      );
    }
  };

  const removeToken = (index, e) => {
    value.splice(index, 1);
    onChange([...value]);
    e && e.stopPropagation();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleKeyDown = e => {
    const triggers = ['Tab', 'Enter'];
    if (commitOnSpace && inputValue) {
      triggers.push(' ');
    }
    if (triggers.includes(e.key) && onTheFly) {
      createToken();
      if (e.key === ' ') {
        e.stopPropagation();
        e.preventDefault();
      }
    } else if (inputValue === '' && e.key === 'Backspace') {
      removeToken(value.length - 1);
    }
  };

  const handleBlur = e => {
    if (onTheFly) {
      createToken();
    }
    setIsFocused(false);
    setInputValue('');
  };

  const focusInput = () => {
    ref.current.focus();
  };

  return (
    <Tooltip title={value.length ? placeholder : helpText} placement='right'>
      <Container
        onClick={focusInput}
        className={className}
        isFocused={isFocused}
        disabled={disabled}
        ref={containerRef}
        {...rest}
      >
        {value.map((token, i) => (
          <Token
            data-testid={`token-${i}`}
            key={`${token}-${i}`}
            value={token}
            removeToken={e => removeToken(i, e)}
            displayKey={displayKey}
          />
        ))}
        <Input
          data-testid={dataTestId}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onChange={value => (!readOnly ? setInputValue(value) : () => {})}
          onFocus={handleFocus}
          value={inputValue}
          placeholder={!value.length ? placeholder : ''}
          ref={ref}
          type='search'
        />
        {options && isFocused && (
          <Options
            data-testid='token-options'
            options={inputValue.length >= minInputSize ? options : []}
            filterTerm={inputValue}
            onOptionClick={createToken}
            optionKey={optionKey}
            displayKey={displayKey}
            disabledKey={disabledKey}
            disabledTooltipText={disabledTooltipText}
            disabledChangesOnHover={disabledChangesOnHover}
            emptyOption={
              emptyOption && inputValue.length >= minInputSize
                ? emptyOption(inputValue)
                : ''
            }
            limit={limit}
            scrollable={scrollable}
            noLimit={noLimit}
            otherRemoteParams={otherRemoteParams}
          />
        )}
      </Container>
    </Tooltip>
  );
};

export default TokenInput;
