import React from 'react';
import PropTypes from 'prop-types';
import { crmTypesToStringMap } from 'utils/helpers/crm';
import { Switch } from 'components/common';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const EditFeature = ({
  loading,
  integration,
  selectedIntegrations,
  userIntegrations,
  onToggle,
}) => {
  const value = selectedIntegrations.find(x => x === integration.type);
  const existingIntegration = userIntegrations.find(
    x => x === integration.type
  );

  let available = 0;
  if (value) {
    available = integration.available - (!existingIntegration ? 1 : 0);
  } else {
    available = integration.available + (existingIntegration ? 1 : 0);
  }
  return (
    <Container>
      <Switch
        className='switch'
        disabled={loading || (integration.available <= 0 && !value)}
        size='small'
        onChange={onToggle}
        checked={!!value}
        label={crmTypesToStringMap[integration.type]}
      />
      <div>
        {available}/{integration.total} available
      </div>
    </Container>
  );
};

EditFeature.propTypes = {
  loading: PropTypes.bool,
  integration: PropTypes.shape({
    type: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
  }).isRequired,
  selectedIntegrations: PropTypes.arrayOf(PropTypes.string).isRequired,
  userIntegrations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggle: PropTypes.func.isRequired,
};

EditFeature.defaultProps = {
  loading: false,
};

export default EditFeature;
