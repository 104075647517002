export const permissions = {
  NoAccess: 'No Access',
  Read: 'Read Access',
  Write: 'Write Access',
  Push: 'Push Access',
  Admin: 'Admin Access',
};

export const descriptions = {
  NoAccess: 'Users on your account will have no access to this list.',
  Read:
    'Users on your account will be able to view this list but not modify it.',
  Write: 'Users on your account will be able to read and modify this list.',
  Push:
    'Users on your account will be able to read, modify, and push this list to CRM.',
  Admin:
    'Users on your account will be able to read, modify, push to CRM, and delete this list.',
};
