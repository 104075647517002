import React, { useCallback, useEffect, useMemo } from 'react';
import { useState, useContext } from 'react';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setView, performSearch, setFilter } from 'actions/search';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';
import { getFilter, getFilters } from 'selectors/search';
import { searchOrigin, view as viewConstants } from './constants';

import { SearchContext } from '../';
import { OrganisationSearch, ResearcherSearch } from './helpers';
import { Icon } from 'components/common';
import styled from 'styled-components';
import { GetSearchKeysByView } from '../Filters/keys';

const SeparatorIcon = styled(Icon)`
  border: 2px solid #6298fd;
  background: white;
  borderradius: 50%;
  color: #6298fd;
  z-index: 999;
`;

export const RadioGroupStyle = styled(Radio.Group)`
  alignitems: center;
  fontweight: normal;
  justifyitems: center;
  z-index: 0;
  :hover ${SeparatorIcon} {
    border: 2px solid white;
    background: #6298fd;
    color: white;
  }
`;

const RESEARCHERVIEW = 'RESEARCHERVIEW';
const ORGANISATIONVIEW = 'ORGANISATIONVIEW';
const ACTIVITYVIEW = 'ACTIVITYVIEW';

const ignoredDateValues = ['all', 'custom'];

const Title = ({ loading, view }) => {
  const { hasLicense } = useAuth();
  const dispatch = useDispatch();
  const tradeshowValue = useSelector(getFilter('tradeshow'));
  const termValue = useSelector(getFilter('term'));
  const dateValue = useSelector(getFilter('date'));
  const getFiltersValue = useSelector(getFilters);

  const { postSearch, postGeoSearch } = useContext(SearchContext);

  const topView = ResearcherSearch(view)
    ? RESEARCHERVIEW
    : OrganisationSearch(view)
    ? ORGANISATIONVIEW
    : ACTIVITYVIEW;
  const [titleViewValue, setTitleViewValue] = useState(topView);

  const handleChangeView = useCallback(
    value => {
      let allFilterKeys = [
        ...new Set(
          Object.keys(
            GetSearchKeysByView(
              value === RESEARCHERVIEW
                ? searchOrigin.researcherSearch
                : value === ORGANISATIONVIEW
                ? searchOrigin.organisationSearch
                : searchOrigin.activitySearch
            )
          )
            .filter(x => !x.includes('category.') && x !== 'date')
            .map(x => x.split('.')[0])
        ),
      ];
      let getFilterKeys = Object.keys(getFiltersValue);
      if (value === RESEARCHERVIEW) {
        dispatch(setView(viewConstants.researcher));
        if (
          getFilterKeys.some(x => allFilterKeys.includes(x)) ||
          termValue ||
          (dateValue && !ignoredDateValues.includes(dateValue))
        )
          dispatch(performSearch(postSearch, postGeoSearch));
      } else if (value === ORGANISATIONVIEW) {
        dispatch(setView(viewConstants.organisation));
        if (tradeshowValue) {
          // call setfilter action to set tradeshow filters correctly
          dispatch(setFilter('tradeshow', tradeshowValue));
        }

        if (
          getFilterKeys.some(x => allFilterKeys.includes(x)) ||
          termValue ||
          (dateValue && !ignoredDateValues.includes(dateValue))
        )
          dispatch(performSearch(postSearch, postGeoSearch));
      } else if (value === ACTIVITYVIEW) {
        dispatch(setView(viewConstants.activity));
        if (
          getFilterKeys.some(x => allFilterKeys.includes(x)) ||
          termValue ||
          (dateValue && !ignoredDateValues.includes(dateValue))
        )
          dispatch(performSearch(postSearch, postGeoSearch));
      }
      setTitleViewValue(value);
    },
    [
      dispatch,
      postGeoSearch,
      postSearch,
      tradeshowValue,
      termValue,
      getFiltersValue,
      dateValue,
    ]
  );

  const viewOptions = useMemo(() => {
    const options = [];
    if (
      hasLicense(
        [
          licenseType.temporaryOld,
          licenseType.legacy,
          licenseType.academic,
          licenseType.industry,
        ],
        true
      )
    ) {
      options.push({ label: 'Researchers', value: RESEARCHERVIEW });
    }

    if (hasLicense([licenseType.industry, licenseType.academic], true)) {
      options.push({
        label: 'Organisations',
        value: ORGANISATIONVIEW,
      });
      options.push({ label: 'Activities', value: ACTIVITYVIEW });
    }

    return options;
  }, [hasLicense]);

  useEffect(() => {
    // user doesn't have a license to see current view
    if (
      !viewOptions.some(x => x.value === titleViewValue) &&
      viewOptions.length
    ) {
      handleChangeView(viewOptions[0].value);
    }
  }, [handleChangeView, titleViewValue, viewOptions]);

  return (
    <RadioGroupStyle
      buttonStyle='solid'
      disabled={loading}
      onChange={x => handleChangeView(x.target.value)}
      value={titleViewValue}
      zIndex='0'
      optionType='button'
      style={{
        display: viewOptions.length > 1 ? 'inline-block' : 'none',
        marginRight: '10px',
      }}
      size='middle'
      defaultOpen={RESEARCHERVIEW}
    >
      <Separator options={viewOptions} />
    </RadioGroupStyle>
  );
};

export const Separator = ({ options }) => {
  return (
    <>
      {options.map((x, i) => (
        <Radio.Button
          key={i}
          style={{ textAlign: 'center', width: '130px', fontWeight: '400' }}
          value={x.value}
        >
          {x.label} <div></div>
        </Radio.Button>
      ))}
    </>
  );
};

export default Title;
