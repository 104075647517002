import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Tooltip } from 'components/common';
import { formatNumber } from 'utils/number';
import { getProgressColor, getProgressState, isUnlimited } from './helpers';

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .title {
    font-weight: 500;
  }
  .stats-left {
    font-size: 10px;
    color: #6f6f6f;
    float: left;
  }
  .stats-right {
    font-size: 10px;
    color: #6f6f6f;
    float: right;
    textalign: right;
  }
`;

const Progress = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  position: relative;
  .progress {
    display: absolute;
    height: 100%;
    border-radius: 2px 0px 0px 2px;
    width: 0%;
    transition: ${p => (!p.blockTimeout ? '.5s width ease-in-out' : '')};
    float: left;
  }
  .requiredProgress {
    display: absolute;
    height: 100%;
    border-radius: 0px 2px 2px 0px;
    width: 0%;
    transition: ${p => (!p.blockTimeout ? '.5s width ease-in-out' : '')};
    opacity: 0.5;
    float: left;
  }
`;

function ProgressBar({
  icon,
  title,
  used,
  required,
  total,
  blockTimeout,
  showHeader = true,
  renewDays,
}) {
  const hideRequired = !required;
  const [totalProgress, setTotalProgress] = useState(null);
  const [usedProgress, setUsedProgress] = useState(null);
  const [requiredProgress, setRequiredProgress] = useState(null);

  useEffect(() => {
    let timeoutId;
    if (!isUnlimited(total)) {
      let p = Math.ceil(((used + (required ?? 0)) / total) * 100);
      let uP = Math.ceil((used / total) * 100);
      let rP = Math.ceil(((required ?? 0) / total) * 100);

      if (p > 100) {
        uP = 100;
        rP = -1;
      }

      if (blockTimeout === true) {
        setTotalProgress(p);
        setUsedProgress(uP);
        setRequiredProgress(rP);
      } else {
        timeoutId = setTimeout(() => {
          setUsedProgress(uP);
          setRequiredProgress(rP);
          setTotalProgress(p);
        }, 100);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required, total, used]);

  return (
    <ProgressBarContainer>
      <div className='title'>
        {showHeader && (
          <>
            <Icon size='sm' icon={icon} color='#000000' className='icon' />{' '}
            {title}
          </>
        )}
        {total >= 0 && (
          <div
            className={showHeader ? 'stats-right' : 'stats-left'}
          >{`${formatNumber(total)} limit${
            renewDays
              ? ` (Renews in ${renewDays} ${renewDays === 1 ? 'day' : 'days'})`
              : ''
          }`}</div>
        )}
        {isUnlimited(total) && (
          <div className={showHeader ? 'stats-right' : 'stats-left'}>
            ∞ credits
          </div>
        )}
      </div>

      <Progress blockTimeout={blockTimeout}>
        <Tooltip title='Credits used'>
          <div
            className='progress'
            style={{
              width: `${usedProgress}%`,
              backgroundColor: getProgressColor(
                getProgressState(totalProgress)
              ),
              borderRadius: requiredProgress ? '2px 0px 0px 2px' : '2px',
            }}
          />
        </Tooltip>
        {!hideRequired && requiredProgress >= 0 && (
          <Tooltip title='Credits this export will use'>
            <div
              className='requiredProgress'
              style={{
                width: `${requiredProgress}%`,
                backgroundColor: getProgressColor(
                  getProgressState(totalProgress)
                ),
              }}
            />
          </Tooltip>
        )}
      </Progress>
      <div>
        {!isUnlimited(total) && (
          <div className='stats-left'>{`${formatNumber(
            used
          )} credits used`}</div>
        )}
        {isUnlimited(total) && (
          <div className='stats-left' style={{ height: '10px' }}>{` `}</div>
        )}
        {!hideRequired && !isUnlimited(total) && (
          <div className='stats-right'>
            {isUnlimited(total)
              ? '∞ unlimited'
              : `This export will use ${formatNumber(required)} credits`}
          </div>
        )}
      </div>
    </ProgressBarContainer>
  );
}

ProgressBar.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  used: PropTypes.number,
  required: PropTypes.number,
  total: PropTypes.number,
  period: PropTypes.string,
};

export default ProgressBar;
