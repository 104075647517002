import dayjs from 'dayjs';
import styled from 'styled-components';
import { Alert } from 'antd';

const StyledAlert = styled(Alert)`
  margin-bottom: ${p => p.theme.gutter};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-right: 10px;
  }
`;

const Message = ({ inactiveDate, inactiveUpdateDate }) => {
  return (
    <MessageContainer>
      <span>
        It has been determined that this organisation stopped being operational
        on {inactiveDate}. This status was updated on {inactiveUpdateDate}.
      </span>
    </MessageContainer>
  );
};

const DefunctNotice = ({ organisation }) => {
  if (
    !organisation ||
    (!organisation.superOrganisationInactiveDate &&
      !organisation.superOrganisationInactiveUpdateDate)
  )
    return null;

  const formattedInactiveDate = dayjs(
    organisation.superOrganisationInactiveDate
  ).format('MMMM Do, YYYY');
  const formattedInactiveUpdatedDate = dayjs(
    organisation.superOrganisationInactiveUpdateDate
  ).format('MMMM Do, YYYY');

  return (
    <StyledAlert
      type='info'
      message={
        <Message
          inactiveDate={formattedInactiveDate}
          inactiveUpdateDate={formattedInactiveUpdatedDate}
        />
      }
    />
  );
};

export default DefunctNotice;
