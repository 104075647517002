import { Detail, Link } from 'components/common';

const Grants = ({ grants }) => {
  if (!grants || !grants.length) return <Detail label='Grants'></Detail>;
  const content = grants.reduce((acc, grant, i) => {
    if (grant.projectDetailsId)
      return [
        ...acc,
        <div key={`${grant.projectDetailsId}-${i}`}>
          {grant.grantInstitution.fullName} |{' '}
          <Link to={`/project/${grant.projectDetailsId}`} inline>
            {grant.grant.grantId}
          </Link>
        </div>,
      ];
    return [
      ...acc,
      <div key={`${grant.grantId}-${i}`}>
        {grant.grantInstitution.fullName} | {grant.grant.grantId}
      </div>,
    ];
  }, []);

  return <Detail label='Grants'>{content}</Detail>;
};

export default Grants;
