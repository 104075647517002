import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Table } from '../styled';
import styled from 'styled-components';
import { format } from 'utils/date';
import { siNumber } from 'utils/number';
import { HelpTooltip } from 'components/common';
import GraphBase from '../Graphs';
import { Loader } from '../../common';

import {
  getOrganisationExhibitorData,
  getOrganisationLoadingExhibitorData,
} from 'selectors/organisation';
import { GraphLabel } from '../Graphs/styled';

const generateShortName = name =>
  name
    .split(' ')
    .map(x => x[0])
    .join('');

export const GraphContainer = styled.div`
  margin-bottom: 20px;
`;

const LongTextDiv = styled.div`
  overflow-y: auto;
  max-height: 20vh;
  word-break: break-word;
`;

const OrganisationTradeshows = ({ organisation, loading }) => {
  const isLoading =
    useSelector(getOrganisationLoadingExhibitorData(organisation.id)) ||
    loading;

  const organisationTradeshowData = useSelector(
    getOrganisationExhibitorData(organisation.id)
  );

  const showLoader = isLoading || !organisationTradeshowData;

  const perPage = [
    { value: 10, label: '10 per page' },
    { value: 25, label: '25 per page' },
    { value: 50, label: '50 per page' },
  ];

  const config = {
    headers: [
      {
        key: 'showName',
        label: 'Tradeshow Name',
        minWidth: 200,
        maxContentWidth: 450,
      },
      { key: 'showYear', label: 'Tradeshow Year', minWidth: 80 },
      { key: 'exhibitedDate', label: 'Tradeshow Date', minWidth: 100 },
      { key: 'location', label: 'Tradeshow Location', minWidth: 150 },
      { key: 'booth', label: 'Booth Number', minWidth: 80 },
    ],
    createRow: r => {
      return (
        <Fragment key={r.id}>
          <div>{r.showName}</div>
          <div>{r.showYear}</div>
          <div>{format(r.exhibitedDate)}</div>
          <div>{r.location}</div>
          <LongTextDiv>{r.booth}</LongTextDiv>
        </Fragment>
      );
    },
  };

  const options = {
    legend: {
      display: false,
    },
    aspectRatio: 4,
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return siNumber(value, 1);
            },
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback: function(show) {
              if (show.length <= 6) return show;

              const shortMatches = show.match(/\((.*?)\)/);
              const lastShort = shortMatches
                ? shortMatches[shortMatches.length - 1]
                : generateShortName(show);

              return lastShort;
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return siNumber(tooltipItem.yLabel);
        },
      },
    },
  };

  if (showLoader) return <Loader />;

  return (
    <>
      <GraphContainer>
        <GraphBase
          data={organisationTradeshowData?.visualisations?.topTradeshows ?? []}
          label='Tradeshow Presentations'
          type='bar'
          options={options}
          helpText='A summary of Tradeshows attended for the company in your search'
          fullWidth
        />
      </GraphContainer>

      <GraphLabel>
        Tradeshows Exhibited
        <HelpTooltip>
          A summary of exhibitions where the company in your search has been an
          exhibitor
        </HelpTooltip>
      </GraphLabel>
      <Table
        config={config}
        className={'researchers-table'}
        data={
          organisationTradeshowData?.visualisations?.tables?.exhibitedItems ??
          []
        }
        loading={isLoading}
        pageSizeOptions={perPage}
        paginate
        sortable
      />
    </>
  );
};

export default OrganisationTradeshows;
