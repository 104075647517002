import { Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Button, Link } from 'components/common';
import {
  getOrganisationFilter,
  getOrganisationToUnfollow,
} from 'selectors/alerts';
import {
  setOrganisationFilter,
  setOrganisationToUnfollow,
} from 'actions/alerts';
import { formatRange } from 'utils/number';
import { useAuth } from 'hooks';

import { setOrgWorkItemFilterGroups, setView } from 'actions/organisation';
import { StyledTable, TableActions } from './styled';
import { UnfollowModal } from './Modal';
import { tablePerPage } from './helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RecentResults } from './TablePopover/RecentResults';
import { LogoFlexContainer } from 'components/Organisation/styled';
import SocialMediaLink from 'components/Researcher/Profile/SocialMediaLink';
import { makeHttpsUrl } from 'utils/helpers/externalLink';

export const OrganisationAlerts = ({
  data,
  getOrganisationsToFollow,
  loading,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const organisationFilter = useSelector(getOrganisationFilter);
  const ref = useRef();
  const { push } = useHistory();
  const organisationToUnfollow = useSelector(getOrganisationToUnfollow);

  const getFilteredData = () => {
    if (!data) return [];
    if (!organisationFilter) return data;

    return data.filter(k =>
      `${k.organisationName.toLowerCase()}`.includes(
        organisationFilter.toLowerCase()
      )
    );
  };

  const s3Bucket = process.env.REACT_APP_PORTAL_CDN || 'dev-scil-portal-cdn';
  const avatarImg = `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/images/logos/superorganisation/Avatar_40_40.png`;

  const makeImageUrl = r => {
    return `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/${r.logoUrl}_100x100`;
  };

  const addDefaultSrc = ev => {
    ev.target.src = avatarImg;
  };

  const handleUnfollowSuccess = () => {
    dispatch(setOrganisationToUnfollow());
    getOrganisationsToFollow({
      query: { UserId: user.userId, shouldLog: false },
    });
  };

  const handleUnfollowCancel = () => {
    dispatch(setOrganisationToUnfollow());
  };

  const organisationConfig = {
    headers: [
      { label: 'New', key: 'recentItemsResults', minWidth: 50 },
      { label: ' ', key: '', minWidth: 20 },
      {
        label: 'Organisation',
        key: 'name',
        minWidth: 200,
        maxWidth: 400,
      },
      { label: 'State', key: '', minWidth: 40 },
      { label: 'Country', key: '', minWidth: 50 },
      { label: 'Category', key: 'category', minWidth: 50 },
      { label: 'Size', key: 'SizeRangeLowerBound', minWidth: 25 },
      { label: 'Founded Date', key: 'foundedYear', minWidth: 25 },
      { label: 'LinkedIn', key: '', minWidth: 30 },
      { testId: 'actions', minWidth: 50 },
    ],
    createRow: organisation => (
      <Fragment key={organisation.organisationId}>
        <div style={{ display: 'flex', marginBottom: '8px' }}>
          <RecentResults
            onClick={last30DaysClicked}
            alert={organisation}
            ref={ref}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <LogoFlexContainer
            style={{
              marginBottom: '4px',
              marginRight: '10px',
              height: '25px',
              width: '25px',
            }}
          >
            {organisation.logoUrl ? (
              <img
                src={makeImageUrl(organisation)}
                onError={addDefaultSrc}
                alt={'Company Logo'}
              />
            ) : (
              <img src={avatarImg} alt={'Company Logo'} />
            )}
          </LogoFlexContainer>
        </div>

        <div>
          <Link to={`/organisation/${organisation.organisationId}`}>
            {organisation.organisationName}
          </Link>
        </div>
        <div>{organisation.state}</div>
        <div>{organisation.country}</div>
        <div>
          <div
            style={{
              maxWidth: 150,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {organisation.category}
          </div>
        </div>
        <div>
          {(organisation.sizeRangeLowerBound > 0 ||
            organisation.sizeRangeUpperBound > 0) &&
            formatRange(
              organisation.sizeRangeLowerBound,
              organisation.sizeRangeUpperBound
            )}
        </div>
        <div>{organisation.foundedYear}</div>
        <div>
          <SocialMediaLink
            network={{
              type: 'linkedin',
              displayName: 'LinkedIn',
            }}
            title={
              organisation.linkedIn ? 'View on LinkedIn' : 'Search for LinkedIn'
            }
            url={
              organisation.linkedIn
                ? makeHttpsUrl(organisation.linkedIn)
                : `http://www.google.com/search?q=${organisation.name} site:linkedin.com`
            }
            isMuted={!organisation.linkedIn}
            logDescription={{
              entityType: 'Organisation',
              location: `FollowedOrganisation:${organisation.id}`,
            }}
          />
        </div>
        <TableActions className='rightAlign'>
          <Button
            type='primary'
            size='small'
            onClick={() => dispatch(setOrganisationToUnfollow(organisation))}
          >
            Unfollow
          </Button>
        </TableActions>
      </Fragment>
    ),
  };

  const last30DaysClicked = org => {
    dispatch(
      setOrgWorkItemFilterGroups(org.organisationId, 'fromRecentItems', true)
    );
    dispatch(setView('workItems'));
    push(`/organisation/${org.organisationId}`);
  };

  const handleOrganisationChange = value => {
    dispatch(setOrganisationFilter(value));
  };
  return (
    <div ref={ref}>
      <Input
        placeholder='Filter Organisations'
        style={{ marginRight: 15, marginTop: 15 }}
        onChange={handleOrganisationChange}
        value={organisationFilter}
        allowClear
      />
      <StyledTable
        config={organisationConfig}
        pageSizeOptions={tablePerPage}
        sortable
        paginate
        data={getFilteredData()}
        loading={loading}
        testId='organisation-table'
      />
      <UnfollowModal onUnfollowSuccess={handleUnfollowSuccess} />
      {organisationToUnfollow && (
        <UnfollowModal
          onUnfollowSuccess={handleUnfollowSuccess}
          onUnfollowCancel={handleUnfollowCancel}
        />
      )}
    </div>
  );
};

export default OrganisationAlerts;
