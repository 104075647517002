import styled from 'styled-components';

import { Pane as CommonPane } from 'components/common';

export const Container = styled.div`
  margin-top: ${p => p.theme.gutter};
  display: flex;
  flex-direction: column;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex: 1 1 ${p => p.width || 'auto'};
  display: flex;
  flex-direction: column;
  ${p => p.theme.breakpoint.tablet} {
    width: 100%;
  }
`;

export const LeftColumn = styled(Column)`
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  margin-right: ${p => p.theme.gutter};
  ${p => p.theme.breakpoint.tablet} {
    flex: 0 1 400px;
    width: 100%;
  }
  ${p => p.theme.breakpoint.mobile} {
    margin-right: 0px;
    flex: 1;
  }
`;

export const GraphColumn = styled(Column)`
  width: 50%;
  ${p => p.theme.breakpoint.tablet} {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: ${p => (p.alignTop ? 'flex-start' : 'stretch')};
  ${p => p.theme.breakpoint.mobile} {
    flex-wrap: wrap;
  }
  > * {
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

export const Pane = styled(CommonPane)`
  margin-bottom: ${p => p.theme.gutter};
`;

export const KeyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Dot = styled.div`
  flex: 0 0 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 7px;
`;

export const OrangeDot = styled(Dot)`
  background: ${p => p.theme.orange};
`;

export const TealDot = styled(Dot)`
  background: ${p => p.theme.teal};
`;
