import { useDispatch, useSelector } from 'react-redux';

import { TokenInput as CommonTokenInput, Input } from 'components/common';
import { setSearchBuilderFields } from 'actions/search';
import { getSearchBuilderFields } from 'selectors/search';

import Group from './Group';
import Warning from './Warning';
import { formatters } from './helpers';

const TokenInput = ({ label, hint, value, type, ...rest }) => {
  const _hint = formatters[type](value);
  return (
    <div style={{ marginBottom: 10, flex: 1 }}>
      <span>{label}</span>
      <CommonTokenInput value={value} {...rest} style={{ marginTop: 5 }} />
      <span style={{ fontWeight: 500, opacity: 0.4, fontSize: 11 }}>
        {_hint}
      </span>
    </div>
  );
};

const ProximityInput = ({ value, onChange }) => {
  const handleChangeTokens = tokens => {
    onChange({ ...value, tokens });
  };

  const handleChangeProximity = proximity => {
    onChange({ ...value, proximity });
  };

  const _hint = formatters.proximity(value);

  const _value = value || {};

  let warningMessage = '';
  if (value) {
    if (value.tokens?.length < 2) {
      warningMessage = 'Please enter at least two words';
    }

    if (!value.proximity) {
      warningMessage += warningMessage
        ? ' and a proximity'
        : 'Please enter a proximity';
    }

    if (value.tokens?.length === 0 && !value.proximity) {
      warningMessage = '';
    }
  }

  return (
    <div style={{ marginBottom: 10, flex: 1 }}>
      <span style={{ marginBottom: 5, display: 'block' }}>
        Include two or more words that should appear near each other
      </span>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CommonTokenInput
          value={_value.tokens}
          onChange={handleChangeTokens}
          style={{ flex: 1 }}
          commitOnSpace
        />
        <span style={{ margin: '0px 10px' }}>appear within</span>
        <Input
          type='not'
          value={_value.proximity}
          onChange={handleChangeProximity}
          style={{ width: 40 }}
        />
        <span style={{ margin: '0px 10px' }}>words</span>
        {warningMessage && <Warning message={warningMessage} />}
      </div>
      <span style={{ fontWeight: 500, opacity: 0.4, fontSize: 11 }}>
        {_hint}
      </span>
    </div>
  );
};

const BasicFields = ({ id }) => {
  const dispatch = useDispatch();
  const fields = useSelector(getSearchBuilderFields(id));

  const handleChange = type => value => {
    dispatch(setSearchBuilderFields(type, value, id));
  };

  return (
    <Group title='Boolean Logic'>
      <TokenInput
        label={
          <span>
            Include <span style={{ fontWeight: 700 }}>all</span> of the
            following words or phrases (These are your AND searches)
          </span>
        }
        type='and'
        value={fields.and}
        onChange={handleChange('and')}
      />
      <TokenInput
        label={
          <span>
            Include <span style={{ fontWeight: 700 }}>at least one</span> of the
            following words or phrases (These are your OR searches)
          </span>
        }
        type='or'
        value={fields.or}
        onChange={handleChange('or')}
      />
      <ProximityInput
        value={fields.proximity}
        onChange={handleChange('proximity')}
      />
      <TokenInput
        label={
          <span>
            Exclude <span style={{ fontWeight: 700 }}>all</span> of the
            following words or phrases
          </span>
        }
        type='not'
        value={fields.not}
        onChange={handleChange('not')}
      />
    </Group>
  );
};

export default BasicFields;
