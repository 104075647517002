import {
  useEffect,
  useContext,
  useState,
  useReducer,
  useCallback,
} from 'react';
import { Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import {
  performSearch,
  setListsRequest,
  incrementPage,
  loadMore,
  setShowRefreshDialog,
  setListAssignedToResearchers,
  performSearchListAssignedToResearchers,
  performSearchListAssignedToOrganisations,
  setListAssignedToOrganisatinos,
  resetAdddMembersToListFromSearchError,
} from 'actions/search';
import { Loader, StickyPane } from 'components/common';
import {
  getListsForUser,
  researchersLists,
  deleteList,
  addSearchToList,
  getOrgListsForUser,
  addSearchToOrgList,
  organisationsLists,
} from 'services/api';
import { useGet, usePost, useDel } from 'hooks';
import {
  getSearchRequest,
  getNextResultsForRefresh,
  getSearchView,
  getLastSuccessfulSearch,
  getListAssignedToResearcher,
  getListAssignedToOrganisation,
  getAddMembersToListFromSearchError,
} from 'selectors/search';

import Actions from './Actions';
import QuickFilters from './QuickFilters';
import List from './List';
import { Container, LoadMore } from './styled';

import { Create } from 'components/common/Lists/Modals';
import { getActiveModal } from 'selectors/lists';
import { setModal } from 'actions/lists';
import ResultsView from './ResultsView';

import {
  NotificationFailure,
  NotificationSuccess,
  NotificationProcessing,
} from 'components/Lists/Notifications';
import { TITLE_POPUP_CREATE_LIST } from 'components/common/labelConstants';
import { SearchContext } from '../';
import { view as viewConstants } from './constants';
import PdfReport from './Visualisations/Exports/Pdf';
import SearchFilterTokens from './SearchFilters';
import { views } from 'components/Lists/constants';
import { IsMap, ResearcherSearch, ActivitySearch } from './helpers';

const modals = {
  create: Create,
};

const REDUCER_TYPE_CHANGE_VIEW = 'SEARCH_REDUCER_TYPE_CHANGE_VIEW';
const REDUCER_TYPE_CREATE_NEW_LIST = 'SEARCH_REDUCER_TYPE_CREATE_NEW_LIST';
const REDUCER_TYPE_CREATE_NEW_LIST_FOR_BULK =
  'SEARCH_REDUCER_TYPE_CREATE_NEW_LIST_FOR_BULK';

const REDUCER_TYPE_ADD_MEMBER_TO_LIST_SUCCESS =
  'SEARCH_REDUCER_TYPE_ADD_MEMBER_TO_LIST_SUCCESS';
const REDUCER_TYPE_ADD_MEMBER_TO_LIST_IN_PROGRESS =
  'SEARCH_REDUCER_TYPE_ADD_MEMBER_TO_LIST_IN_PROGRESS';
const REDUCER_TYPE_ADD_MEMBER_TO_LIST_FAILED =
  'SEARCH_REDUCER_TYPE_ADD_MEMBER_TO_LIST_FAILED';

const REDUCER_TYPE_DELETE_LIST_SUCCESS =
  'SEARCH_REDUCER_TYPE_DELETE_LIST_SUCCESS';
const REDUCER_TYPE_ADD_MEMBERS_FROM_SEARCH_SIGNALR_FAILED =
  'SEARCH_REDUCER_TYPE__ADD_MEMBERS_FROM_SEARCH_SIGNALR_FAILED';

const dispatchFormStateHandler = (state, action) => {
  switch (action.type) {
    case REDUCER_TYPE_CHANGE_VIEW:
      return { ...state, view: action.view, Type: action.type };
    default:
      return { ...state, ...action.val, Type: action.type };
  }
};

const Results = () => {
  const dispatch = useDispatch();
  const { postSearch, postGeoSearch } = useContext(SearchContext);
  const activeModal = useSelector(getActiveModal);
  const { res: data, loading } = useSelector(getSearchRequest);
  const nextResults = useSelector(getNextResultsForRefresh);
  const view = useSelector(getSearchView);
  const addMembersToListFromSearchError = useSelector(
    getAddMembersToListFromSearchError
  );

  const lastSuccessfulSearch = useSelector(getLastSuccessfulSearch);
  const [combinedLoading, setCombinedLoading] = useState(false);
  const [hasMapError, setHasMapError] = useState(false);

  // Add All To List: Execute the API to add a new Researcher or Organisation to the List.
  const [
    { res: AddToListResponse, error: AddToListError },
    addAllToList,
  ] = usePost({});

  const handleAddAllToList = (
    type,
    id,
    searchQuery,
    resCount,
    removeListInCaseOfLimitError
  ) => {
    let {
      query,
      lists,
      excludeLists,
      organisationLists,
      organisationExcludeLists,
      nestedQueries,
    } = searchQuery;
    addAllToList({
      url: ResearcherSearch(type)
        ? addSearchToList(id)
        : addSearchToOrgList(id),
      body: {
        search: query,
        researcherCount: resCount,
        removeListInCaseOfLimitError,
        filters: {
          lists,
          excludeLists,
          organisationLists,
          organisationExcludeLists,
        },
        nestedQueries,
      },
    });
  };
  // Fetch List => Get and Set the List in the Select Box.
  const [
    { res: fetchListResponse, loading: fetchListLoading },
    fetchListsRequest,
  ] = useGet({
    url: ResearcherSearch(view) ? getListsForUser : getOrgListsForUser,
    query: { onlyEditable: true },
    onMount: !ActivitySearch(view),
  });

  const fetchLists = useCallback(
    type => {
      fetchListsRequest({
        url: ResearcherSearch(type) ? getListsForUser : getOrgListsForUser,
        query: { onlyEditable: true },
        onMount: true,
      });
    },
    [fetchListsRequest]
  );

  // Delete List: Call API to Delete List.
  const [{ res: deleteRes }, delList] = useDel({
    url: '',
  });

  const [researchersListsRes, postResearchersLists] = usePost({
    url: researchersLists,
  });

  const [organisationsListsRes, postOrganisationsLists] = usePost({
    url: organisationsLists,
  });

  const [formState, dispatchFormState] = useReducer(dispatchFormStateHandler, {
    view,
    Actions: {
      ActivateForm: () => {
        setTimeout(() => dispatch(setModal('create')), 400);
      },
      CloseForm: () => {
        dispatch(setModal(''));
      },
      FetchList: type => {
        fetchLists(type);
      },
      DeleteList: (listId, type = views.researchers) => {
        delList({
          url: deleteList(listId, type),
        });
      },
      AddAllToList: (
        type,
        listId,
        searchQuery,
        resCount,
        removeListInCaseOfError
      ) => {
        handleAddAllToList(
          type,
          listId,
          searchQuery,
          resCount,
          removeListInCaseOfError
        );
      },
      ShowNotificationSuccessfully: (type = views.researchers) => {
        NotificationSuccess(null, type);
      },
      ShowNotificationFailure: message => {
        NotificationFailure(message);
      },
      ShowNotificationFailureForBulkProcess: (
        message,
        type = views.researchers
      ) => {
        NotificationFailure(message, true, type);
      },
      ShowNotificationInProgress: () => {
        NotificationProcessing();
      },
      PostResearchersLists: postResearchersLists,
      PostOrganisationsLists: postOrganisationsLists,
    },
  });

  useEffect(() => {
    dispatchFormState({
      type: REDUCER_TYPE_CHANGE_VIEW,
      view,
    });
  }, [view]);

  const {
    res: lstGroupedByResearcherId,
    loading: loadingListGroupByResearchers,
  } = useSelector(getListAssignedToResearcher);

  const {
    res: lstGroupedByOrganisationId,
    loading: loadingListGroupByOrganisations,
  } = useSelector(getListAssignedToOrganisation);

  const [listOptions, setListOptions] = useState(
    Array.isArray(fetchListResponse)
      ? fetchListResponse.map(l => ({
          label: l.name,
          value: l.id,
          description: l.description,
        }))
      : []
  );

  useEffect(() => {
    setCombinedLoading(false);
  }, [
    lstGroupedByResearcherId,
    loadingListGroupByResearchers,
    lstGroupedByOrganisationId,
    loadingListGroupByResearchers,
  ]);

  useEffect(() => {
    dispatch(setListsRequest(fetchListResponse || []));

    if (Array.isArray(fetchListResponse)) {
      setListOptions(
        fetchListResponse.map(l => ({
          label: l.name,
          value: l.id,
          description: l.description,
        }))
      );
    } else {
      setListOptions([]);
    }
  }, [fetchListResponse, dispatch]);

  useEffect(() => {
    if (
      researchersListsRes &&
      (researchersListsRes.res != null ||
        researchersListsRes.error ||
        researchersListsRes.loading)
    ) {
      dispatch(setListAssignedToResearchers(researchersListsRes));
    }
  }, [researchersListsRes, dispatch]);

  useEffect(() => {
    if (
      organisationsListsRes &&
      (organisationsListsRes.res != null ||
        organisationsListsRes.error ||
        organisationsListsRes.loading)
    ) {
      dispatch(setListAssignedToOrganisatinos(organisationsListsRes));
    }
  }, [organisationsListsRes, dispatch]);

  useEffect(() => {
    if (AddToListResponse) {
      if (AddToListResponse === 1) {
        dispatchFormState({
          type: REDUCER_TYPE_ADD_MEMBER_TO_LIST_SUCCESS,
        });
      } else {
        dispatchFormState({
          type: REDUCER_TYPE_ADD_MEMBER_TO_LIST_IN_PROGRESS,
        });
      }
      return;
    }
    if (AddToListError && AddToListError.status === 400) {
      dispatchFormState({
        type: REDUCER_TYPE_ADD_MEMBER_TO_LIST_FAILED,
        val: {
          ErrorMessage: AddToListError.message,
        },
      });
    }
  }, [AddToListResponse, AddToListError]);

  useEffect(() => {
    if (deleteRes) {
      dispatchFormState({
        type: REDUCER_TYPE_DELETE_LIST_SUCCESS,
      });
    }
  }, [deleteRes]);

  useEffect(() => {
    if (addMembersToListFromSearchError) {
      dispatchFormState({
        type: REDUCER_TYPE_ADD_MEMBERS_FROM_SEARCH_SIGNALR_FAILED,
        val: {
          ErrorMessage: addMembersToListFromSearchError,
          DeleteListInCaseOfError: false,
        },
      });
      dispatch(resetAdddMembersToListFromSearchError());
    }
  }, [addMembersToListFromSearchError, dispatch]);

  useEffect(() => {
    if (formState.Type === REDUCER_TYPE_ADD_MEMBER_TO_LIST_SUCCESS) {
      formState.Actions.FetchList(formState.view);
      if (formState.view === views.researchers) {
        dispatch(
          performSearchListAssignedToResearchers(
            formState.Actions.PostResearchersLists
          )
        );
      } else {
        dispatch(
          performSearchListAssignedToOrganisations(
            formState.Actions.PostOrganisationsLists
          )
        );
      }
      dispatch(
        performSearchListAssignedToResearchers(
          formState.Actions.PostResearchersLists
        )
      );
      formState.Actions.ShowNotificationSuccessfully();
      return;
    }

    if (formState.Type === REDUCER_TYPE_ADD_MEMBER_TO_LIST_IN_PROGRESS) {
      formState.Actions.FetchList(formState.view);
      formState.Actions.ShowNotificationInProgress();
      return;
    }

    if (formState.Type === REDUCER_TYPE_ADD_MEMBER_TO_LIST_FAILED) {
      if (!formState.DeleteListInCaseOfError) {
        formState.Actions.ShowNotificationFailure(formState.ErrorMessage);
      } else {
        formState.Actions.DeleteList(formState.ListIdCreated, formState.view);
      }
      return;
    }

    if (
      formState.Type === REDUCER_TYPE_DELETE_LIST_SUCCESS ||
      formState.Type === REDUCER_TYPE_ADD_MEMBERS_FROM_SEARCH_SIGNALR_FAILED
    ) {
      formState.Actions.ShowNotificationFailureForBulkProcess(
        formState.ErrorMessage,
        formState.view
      );
      formState.Actions.FetchList(formState.view);
      return;
    }

    if (
      formState.Type === REDUCER_TYPE_CREATE_NEW_LIST ||
      formState.Type === REDUCER_TYPE_CREATE_NEW_LIST_FOR_BULK
    ) {
      formState.Actions.ActivateForm();
      return;
    }
  }, [formState, dispatch]);

  useEffect(() => {
    setHasMapError(false);
  }, [view]);

  const handleSearch = () => {
    setCombinedLoading(true);
    dispatch(performSearch(postSearch, postGeoSearch));
  };

  const handleLoadMore = useCallback(() => {
    if (!combinedLoading || (ActivitySearch(view) && !loading)) {
      setCombinedLoading(true);
      if (nextResults) {
        dispatch(setShowRefreshDialog(true));
      } else {
        dispatch(incrementPage);
        dispatch(loadMore(postSearch));
      }
    }
  }, [dispatch, nextResults, postSearch, combinedLoading, view, loading]);

  const handleCreateNew = caller => {
    dispatchFormState({
      type: REDUCER_TYPE_CREATE_NEW_LIST,
      val: {
        ResearcherComponent: caller,
        DeleteListInCaseOfError: false,
      },
    });
  };

  const handleCreateNewBulk = () => {
    dispatchFormState({
      type: REDUCER_TYPE_CREATE_NEW_LIST_FOR_BULK,
      val: {
        DeleteListInCaseOfError: true,
      },
    });
  };

  const changeAddAllToListHandler = id => {
    formState.Actions.AddAllToList(
      view,
      id,
      lastSuccessfulSearch,
      data?.distinctSuperResearchersCount,
      formState.DeleteListInCaseOfError
    );
  };

  const handleCloseModal = (options = { fetch: false, id: null }) => {
    formState.Actions.CloseForm();
    if (options.id && options.id !== null) {
      dispatchFormState({
        val: {
          ListIdCreated: parseInt(options.id),
        },
      });
      if (formState.Type === REDUCER_TYPE_CREATE_NEW_LIST) {
        formState.ResearcherComponent.UpdateListComponent(options.id, () =>
          formState.Actions.FetchList(view)
        );
      } else {
        formState.Actions.AddAllToList(
          view,
          options.id,
          lastSuccessfulSearch,
          data?.distinctSuperResearchersCount,
          formState.DeleteListInCaseOfError
        );
      }
    }
  };

  const showLoadMore =
    data?.hasMore &&
    view !== viewConstants.visualisation &&
    !IsMap(view) &&
    view !== viewConstants.organisationInsights;
  const hasVisualisations = data.visualisations;
  const allowLoadMore = showLoadMore && !loading;

  const Modal = modals[activeModal];

  return (
    <Container>
      <StickyPane
        title={<span style={{ marginRight: 15 }}>Search Results</span>}
        headerRight={
          view !== viewConstants.activity && (
            <Actions
              onCreateNewItemList={handleCreateNewBulk}
              onChangeAddAllToList={changeAddAllToListHandler}
              listOptions={listOptions}
              fetchListLoading={fetchListLoading}
            />
          )
        }
        secondRow={
          view !== viewConstants.activity && (
            <ResultsView
              onSearch={handleSearch}
              loading={loading}
              view={view}
              hasVisualisations={hasVisualisations}
            />
          )
        }
        thirdRow={
          <QuickFilters
            view={view}
            loading={loading}
            hasMapError={hasMapError}
          />
        }
        fourthRow={<SearchFilterTokens />}
      >
        {(data?.totalResults > 0 || (!loading && !data)) && (
          <List
            data={data}
            loadMore={handleLoadMore}
            loading={loading}
            onCreateNewItemList={handleCreateNew}
            fetchListLoading={fetchListLoading}
            listOptions={listOptions}
            listGroupByMembers={
              ResearcherSearch(view)
                ? lstGroupedByResearcherId
                : lstGroupedByOrganisationId
            }
            loadingListGroupByMembers={
              ResearcherSearch(view)
                ? loadingListGroupByResearchers
                : loadingListGroupByOrganisations
            }
            onMapError={() => setHasMapError(true)}
          />
        )}
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '25px 0',
            }}
          >
            <Loader />
          </div>
        )}
        {IsMap(view) && hasMapError && (
          <Empty
            style={{ marginTop: 70, fontSize: 15 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='Maps are temporarily unavailable, please try again later.'
          />
        )}
        {(data?.totalResults === 0 || (!loading && !data)) && (
          <Empty
            style={{ marginTop: 70, fontSize: 15 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='There are no results available for your search, please adjust your search and try again.'
          />
        )}
        {Object.keys(data).length === 0 && !loading && (
          <Empty
            style={{ marginTop: 70, fontSize: 15 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='Perform a search to see matching results'
          />
        )}
      </StickyPane>
      {allowLoadMore && <LoadMore onClick={handleLoadMore}>Load More</LoadMore>}
      {activeModal && (
        <Modal
          title={TITLE_POPUP_CREATE_LIST}
          buttonOkText='Create and Add'
          onClose={handleCloseModal}
          type={
            ResearcherSearch(view) ? views.researchers : views.organisations
          }
        />
      )}
      <PdfReport />
    </Container>
  );
};
export default Results;
