import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 15px 15px;
  border-bottom: 1px solid ${p => p.theme.lightGrey};
`;

export const OrgContainer = styled.div`
  padding: 0px 15px 0px;
  border-bottom: 1px solid ${p => p.theme.lightGrey};
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${p => (p.organisation ? '70px' : '100px')};
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  ${p => p.theme.breakpoint.mobile} {
    margin-left: 0px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 15px;
  padding-top: 25px;
  cursor: pointer;
`;

export const OrgTopContent = styled.div`
  padding-bottom: 15px;
  padding-top: 15px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const StatsContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
