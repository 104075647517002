import { LABEL_BUTTON_ADD_NEW_LIST } from 'components/common/labelConstants';
import { Select } from 'components/common';
import listRenderOption from 'components/common/Lists/ListRenderOptions';

const AddToList = ({ onCreateNewItemList, listOptions, onAddToList }) => {
  const selectLabel = 'Add to List';

  const options =
    listOptions && listOptions.length === 0
      ? [{ label: 'No lists available', value: 'no_lists' }]
      : listOptions;

  const handleSelectChange = (value, option) => {
    if (value === 'no_lists') {
      return;
    }
    onAddToList(value, option);
  };

  return (
    <Select
      value={selectLabel}
      size='small'
      options={options}
      onCreateNewItem={onCreateNewItemList}
      buttonText={LABEL_BUTTON_ADD_NEW_LIST}
      onChange={handleSelectChange}
      dropdownStyle={{ minWidth: '250px' }}
      renderOption={(o, i) => listRenderOption({ ...o }, i)}
      style={{ width: 105 }}
      filterOption={(input, option) =>
        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      placement='bottomRight'
      disableTooltip
    />
  );
};

export default AddToList;
