import React, { useState } from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { OrgAboutContainer, WrappedDescription } from '../styled';
import CrunchbaseLogo from 'components/common/CrunchbaseLogo';

const MAX_LENGTH = 1100;
const MAX_PREVIEW_LENGTH = 500;

const truncateToLastWord = (text, maxLength) => {
  const nextSpaceIndex = text.indexOf(' ', maxLength - 30);
  return nextSpaceIndex !== -1
    ? text.substring(0, nextSpaceIndex) + '...'
    : text.substring(0, maxLength - 3) + '...';
};

const AboutDescription = ({ description, showFull }) => {
  if (!description) return null;

  const text = description.descriptionValue;
  const isLongDescription = text.length > MAX_LENGTH;
  const displayText =
    showFull || !isLongDescription
      ? text
      : truncateToLastWord(text, MAX_PREVIEW_LENGTH);

  return (
    <WrappedDescription>
      <h3>Description</h3>
      {description.scrapedSource === 'Crunchbase' && <CrunchbaseLogo />}
      <p className={'about'}>{displayText}</p>
    </WrappedDescription>
  );
};

const About = ({ descriptions }) => {
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const description = descriptions[0];
  if (!description) return null;

  const isLongDescription = description.descriptionValue.length > MAX_LENGTH;

  const onShowMoreOrLessClicked = () => {
    setShowMoreClicked(!showMoreClicked);
  };

  return (
    <OrgAboutContainer>
      {description && (
        <AboutDescription
          description={description}
          showFull={showMoreClicked}
        />
      )}
      {isLongDescription && (
        <Button
          onClick={onShowMoreOrLessClicked}
          icon={!showMoreClicked ? <DownOutlined /> : <UpOutlined />}
        >
          {!showMoreClicked ? 'View More' : 'View Less'}
        </Button>
      )}
    </OrgAboutContainer>
  );
};

export default About;
