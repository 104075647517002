import styled from 'styled-components';
import { forwardRef } from 'react';

import { Icon, Tooltip } from 'components/common';
import TablePopover from 'components/common/TablePopover';

const InfoItem = styled.div`
  display: flex;
  position: absolute;
  border: 1px solid red;
  border-radius: 100%;
  top: -5px;
  height: 18px;
  width: 18px;
  background: red;
  color: white;
  font-size: 9px;
  font-weight: 600;
  right: -7px;
  justify-content: center;
  align-items: center;
`;

export const RecentResults = forwardRef(
  ({ onClick, alert, disabled, parsedSearch }, ref) => {
    const isDisabled =
      disabled ||
      (!alert.structuredSearch && alert.nestedQueries) ||
      (parsedSearch && parsedSearch.date && Array.isArray(parsedSearch.date));

    const NewAlertsContent = alert => {
      const emptyLink = null;
      if (alert.listMaxTermsError && !isDisabled) {
        return (
          <div>{`This search has failed due to exceeding the maximum number of 65,000 list members.`}</div>
        );
      }
      if (alert.recentItemsResults > 1 && !isDisabled) {
        return (
          <div>
            Roughly{' '}
            {alert.recentItemsResults < 5
              ? 1
              : Math.floor(alert.recentItemsResults / 5) * 5}{' '}
            result{alert.recentItemsResults >= 5 ? <>s</> : <></>} from the past
            month, with daily updates of items and contacts.{' '}
            <a
              href={emptyLink}
              rel='noopener noreferrer'
              onClick={() => onClick(alert)}
            >
              View here.
            </a>
          </div>
        );
      } else if (alert.recentItemsResults === 1 && !isDisabled) {
        return (
          <div>
            1 result from the last month, with daily updates of items and
            contacts.{' '}
            <a
              href={emptyLink}
              rel='noopener noreferrer'
              onClick={() => onClick(alert)}
            >
              View here.
            </a>
          </div>
        );
      } else {
        return <div>Nothing new here.</div>;
      }
    };
    return (
      <div>
        {
          <TablePopover
            content={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  style={{ marginRight: '10px', marginLeft: '-9px' }}
                  size='sm'
                  icon='bell'
                  color={
                    alert.recentItemsResults > 0 && !isDisabled
                      ? '#595959'
                      : '#d9d9d9'
                  }
                />
                {NewAlertsContent(alert)}
              </div>
            }
            parentRef={ref}
            widthRatio={0.7}
            color={
              alert.recentItemsResults > 0 && !isDisabled
                ? '#e80404'
                : '#d9d9d9'
            }
          >
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                alignItems: 'center',
                justifyItems: 'center',
              }}
            >
              <div
                style={{
                  marginTop: '5px',
                  height: '25px',
                  width: '25px',
                  border: '2px solid #d9d9d9',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  size='sm'
                  icon='bell'
                  color={
                    alert.recentItemsResults > 0 && !isDisabled
                      ? '#595959'
                      : '#d9d9d9'
                  }
                />
              </div>
              {alert.recentItemsResults > 0 && !isDisabled ? (
                <Tooltip placement='right'>
                  <InfoItem>
                    {alert.recentItemsResults > 0
                      ? alert.recentItemsResults > 99
                        ? 99
                        : alert.recentItemsResults < 5
                        ? 1
                        : Math.floor(alert.recentItemsResults / 5) * 5
                      : 1}
                    {alert.recentItemsResults > 1 ? '+' : ''}
                  </InfoItem>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          </TablePopover>
        }
      </div>
    );
  }
);
