import { Icon, Link, Clamp, Highlight } from 'components/common';
import { Container } from '../styled';
import { colours } from 'utils/theme';
import { format } from 'utils/date';
import {
  InnerContainerRow,
  ActivityData,
  ActivityDetail,
  ContentWrapper,
  MetadataLabel,
  IconWrapper,
  MetadataValue,
} from './styled';
import OwnersDetails from './OwnersDetail';

const Tradeshow = ({
  sciLeadsPosterId,
  show,
  title,
  sessionType,
  date,
  highlights,
  researchers,
  organisations,
}) => {
  return (
    <Container>
      <InnerContainerRow>
        <IconWrapper>
          <Icon color={colours.posters} size='1x' icon='image' />
        </IconWrapper>
        <ContentWrapper>
          <Link to={`poster/${sciLeadsPosterId}`} showIconHint>
            <Clamp text={title} />
          </Link>

          <ActivityDetail>
            <Highlight highlights={highlights} />
            <InnerContainerRow>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='calendar'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Date:
                </MetadataLabel>
                <MetadataValue>{format(date, 'D MMM YYYY')}</MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='image'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Show:
                </MetadataLabel>
                <MetadataValue>{show}</MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='image'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Session Type:
                </MetadataLabel>
                <MetadataValue>{sessionType}</MetadataValue>
              </ActivityData>
            </InnerContainerRow>
          </ActivityDetail>
          <OwnersDetails
            researchers={researchers}
            organisations={organisations}
          />
        </ContentWrapper>
      </InnerContainerRow>
    </Container>
  );
};

export default Tradeshow;
