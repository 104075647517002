import React, { useState } from 'react';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';
import { IconText } from './styled';
import styled from 'styled-components';
import { Icon } from 'components/common';
import { colours } from 'utils/theme';

const AddressDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  max-height: ${({ isCollapsed }) => (isCollapsed ? '0' : '500px')};
  opacity: ${({ isCollapsed }) => (isCollapsed ? '0' : '1')};
  transition: max-height 0.3s ease, opacity 0.3s ease;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }

  span {
    color: ${p => p.theme.black};
    font-size: 14px;
    margin-left: 19px;
  }
`;

const RotatingIconWithProps = ({ up, children, ...rest }) => (
  <div {...rest}>{children}</div>
);

export const RotatingIcon = styled(RotatingIconWithProps)`
  margin-left: 4px;
  align-self: flex-start;
  transform: ${p => (p.up ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.2s linear;
`;

const ResearcherAddress = ({ researcherProfile }) => {
  const { hasLicense } = useAuth();
  const hasAddressPermission = hasLicense(
    [licenseType.academic, licenseType.industry],
    false
  );
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const addressItems = [
    { label: 'Address:', value: researcherProfile?.address },
    { label: 'City:', value: researcherProfile?.city },
    { label: 'State:', value: researcherProfile?.state },
    { label: 'Postcode:', value: researcherProfile?.postcode },
    { label: 'Country:', value: researcherProfile?.country },
  ];

  if (!researcherProfile?.country) return null;

  const filteredAddressItems = addressItems.filter(item => item.value);
  const [firstAvailableItem, ...remainingAddressItems] = filteredAddressItems;

  const hasAddressDetails =
    researcherProfile?.address ||
    researcherProfile?.state ||
    researcherProfile?.postcode;

  return (
    <div>
      {hasAddressPermission && hasAddressDetails ? (
        <div
          onClick={toggleCollapse}
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <IconText
            value={
              isCollapsed
                ? researcherProfile.country
                : firstAvailableItem
                ? firstAvailableItem.value
                : researcherProfile.country
            }
            icon='globe'
            iconStyle={{
              alignSelf: 'flex-start',
              marginTop: '3px',
            }}
          />
          {hasAddressPermission && hasAddressDetails && (
            <RotatingIcon up={!isCollapsed}>
              <Icon
                icon={'chevronDown'}
                size='sm'
                color={colours.darkGrey}
                style={{
                  alignSelf: 'flex-start',
                }}
              />
            </RotatingIcon>
          )}
        </div>
      ) : (
        <IconText value={researcherProfile.country} icon='globe' />
      )}

      <AddressDetailsContainer isCollapsed={isCollapsed}>
        {remainingAddressItems.map((item, index) => (
          <div key={index}>
            <span>{item.value}</span>
          </div>
        ))}
      </AddressDetailsContainer>
    </div>
  );
};

export default ResearcherAddress;
