import Chart from 'react-google-charts';

import { Pane } from 'components/common';

const WorldMap = ({ data }) => {
  const chartData = data.reduce(
    (acc, curr) => {
      return [...acc, [curr.country, curr.popularity]];
    },
    [['Country', 'Popularity']]
  );

  return (
    <Pane title='Author World View'>
      <Chart
        style={{ marginTop: 15 }}
        data={chartData}
        chartType='GeoChart'
        mapsApiKey='AIzaSyAg60O98Ovfa38NWasVyzrgLC7XtVZQhJo'
        options={{ legend: 'none' }}
      />
    </Pane>
  );
};

export default WorldMap;
