import { Icon } from 'components/common';
import { useResizeableHeaders } from 'hooks/layout/useResizeableHeaders';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';
import { colours } from 'utils/theme';
import SortOptions from './SortOptions';

export const renderSortIcon = (key, sort, sortDir) => {
  if (!key) return;
  const color = key !== sort ? 'rgba(0,0,0,0)' : colours.black;
  const icon = sortDir === 'asc' ? 'sortAsc' : 'sortDesc';
  return <Icon icon={icon} color={color} />;
};

const SortableHeaders = ({ sort, sortDir, onSortChange, headers }) => {
  const handleClick = key => {
    if (!key) return;
    onSortChange(key);
  };

  return (
    <>
      {headers.map((h, i) => (
        <div
          key={`${h}-${i}`}
          className='header'
          onClick={h.label ? () => handleClick(h.key) : null}
          style={h.label && { cursor: 'pointer', whiteSpace:'pre-wrap' }}
        >
          {h.label}
          {renderSortIcon(h.key, sort, sortDir)}
        </div>
      ))}
    </>
  );
};

const StandardHeaders = ({ headers }) => {
  return headers.map((h, i) => (
    <div key={`${h}-${i}`} className='header' style={h.style}>
      {h.label ? h.label : h}
    </div>
  ));
};

const ResizeableHeaders = ({
  sortable,
  headers,
  loading,
  onColumnChange,
  headerNode,
  hideExtraColumn,
  ...rest
}) => {
  let headerRef = useRef(null);

  const headerCount = useResizeableHeaders({ headers, headerRef, loading });

  useLayoutEffect(() => {
    if (onColumnChange) {
      onColumnChange(headerCount);
    }
  }, [headerCount, onColumnChange]);

  return (
    <>
      {!hideExtraColumn && (
        <div className='header'>
          {sortable && (
            <SortOptions
              {...rest}
              options={headers.filter((_, i) => i > headerCount - 1)}
            />
          )}
        </div>
      )}
      <div style={{ display: 'contents' }} ref={headerRef}>
        {headerNode({
          ...rest,
          headers: headers.filter((_, i) => i < headerCount),
        })}
      </div>
    </>
  );
};

const Headers = ({ sortable, ...rest }) => {
  const headerNode = useCallback(
    props =>
      sortable ? (
        <SortableHeaders {...props} />
      ) : (
        <StandardHeaders {...props} />
      ),
    [sortable]
  );

  if (
    rest.headers &&
    rest.headers.some(x => x.minWidth) &&
    !rest.headers.every(x => x.minWidth)
  ) {
    console.error(
      'Only some headers have minWidth properties. To enable the reponsive table all headers must have minWidth set.'
    );
  }

  return (
    <>
      {rest.headers?.every(x => x.minWidth) ? (
        <ResizeableHeaders
          {...rest}
          headerNode={headerNode}
          sortable={sortable}
        />
      ) : (
        headerNode(rest)
      )}
    </>
  );
};

export default Headers;
