import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Modal, notification } from 'antd';

import { usePost } from 'hooks';
import { Input } from 'components/common';
import { changePassword } from 'services/api';
import PasswordChange from '../../Login/Reset/PasswordChange';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  > *,
  form > * {
    margin-bottom: 15px;
    :last-child {
      margin-bottom: 0px;
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 20px;
  }
`;

const ChangePassword = ({ showModal, setShowModal }) => {
  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [
    hasClientSidePasswordErrors,
    setHasClientSidePasswordErrors,
  ] = useState(false);
  const [customError, setCustomError] = useState(null);
  const [serverError, setServerError] = useState(null);

  const [confirmPass, setConfirmPass] = useState('');

  const [{ res, loading, error }, postReset] = usePost({ url: changePassword });

  const closeModal = useCallback(() => {
    setCurrentPass('');
    setNewPass('');
    setConfirmPass('');
    setShowModal(false);
    setHasClientSidePasswordErrors(false);
  }, [setCurrentPass, setNewPass, setShowModal]);

  useEffect(() => {
    if (res) {
      closeModal();
      notification.open({
        message: 'Success',
        description: <div>Password changed successfully</div>,
        placement: 'bottomLeft',
      });
    }
    if (error) {
      setServerError(error);
    }
  }, [res, error, closeModal]);

  const handleOk = async () => {
    setServerError(null);
    if (!hasClientSidePasswordErrors) {
      await postReset({ oldPassword: currentPass, newPassword: newPass });
    }
  };

  const disableOk =
    currentPass === '' ||
    confirmPass === '' ||
    newPass === '' ||
    loading ||
    hasClientSidePasswordErrors;

  useEffect(() => {
    setServerError(null);
  }, [currentPass, newPass, setServerError]);

  return (
    <Modal
      title='Change Password'
      onCancel={() => closeModal()}
      onOk={handleOk}
      open={showModal}
      okButtonProps={{ disabled: disableOk }}
    >
      <Content>
        <Input
          password
          placeholder='Current Password'
          value={currentPass}
          onChange={setCurrentPass}
        />
        <PasswordChange
          serverError={serverError}
          customError={customError}
          newPass={newPass}
          confirmPass={confirmPass}
          loading={loading}
          setNewPass={setNewPass}
          setConfirmPass={setConfirmPass}
          onChange={setHasClientSidePasswordErrors}
          handleSubmit={handleOk}
          setCustomError={setCustomError}
          hideChangePasswordButton={true}
        />
      </Content>
    </Modal>
  );
};

export default ChangePassword;
