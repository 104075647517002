import React from 'react';
import { Icon } from 'components/common';

const CharacterLimitReached = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      fontSize: '13px',
      paddingTop: '5px',
    }}
  >
    <Icon icon='error' style={{ color: 'red', marginRight: '5px' }} size='1x' />
    <span>Character limit reached</span>
  </div>
);

export default CharacterLimitReached;
