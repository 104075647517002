import styled from 'styled-components';

// Used to show tooltips for disabled buttons
export const DisabledButtonWrapper = styled.div`
  display: inline-block;
  cursor: not-allowed;
  > button {
    pointer-events: none;
  }
`;
