import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useAuth, usePost } from 'hooks';
import { unfollowOrganisation, userOrganisationAlerts } from 'services/api';
import { Button } from 'components/common';
import { setView } from 'actions/alerts';
import { FollowContentContainer } from '../styled';

const FollowContent = ({ organisationName, push, dispatch }) => {
  const goToAlerts = () => {
    dispatch(setView('followedOrganisations'));
    push('/searches');
  };

  return (
    <FollowContentContainer>
      Alert created for {organisationName}
      <br />
      <br />
      <span onClick={goToAlerts}>Click here</span> to manage followed
      organisations
    </FollowContentContainer>
  );
};

const FollowOrganisationProfile = ({ organisation }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { push } = useHistory();
  const { isFollowedByUser } = organisation || {};
  const [isFollowed, setIsFollowed] = useState(isFollowedByUser);

  const [
    { res: followRes, loading: loadingFollow, error: followError },
    postFollow,
  ] = usePost({
    url: userOrganisationAlerts,
  });
  const [
    { res: unfollowRes, error: unfollowError, loading: loadingUnfollow },
    postUnfollow,
  ] = usePost({ url: unfollowOrganisation });

  useEffect(() => {
    setIsFollowed(isFollowedByUser);
  }, [isFollowedByUser]);

  useEffect(() => {
    if (followRes || followError?.status === 409) {
      setIsFollowed(true);

      notification.open({
        message: 'Organisation followed',
        description: (
          <FollowContent
            organisationName={organisation.organisationName}
            push={push}
            dispatch={dispatch}
          />
        ),
        placement: 'bottomLeft',
      });
    }
  }, [followRes, followError, dispatch, push, organisation.organisationName]);

  useEffect(() => {
    if (unfollowRes || unfollowError?.status === 410) {
      setIsFollowed(false);
    }
  }, [unfollowRes, unfollowError]);

  const handleFollow = () => {
    postFollow({
      monthlyKeywords: `(SuperOrganisationId:${organisation.id})`,
      userId: user.userId,
      frequency: 3, // Daily
      organisationId: organisation.id,
    });
  };

  const handleUnfollow = () => {
    postUnfollow({
      organisationId: organisation.id,
    });
  };

  if (organisation.superOrganisationInactiveDate && !isFollowed) {
    return null;
  }

  return (
    <Button
      disabled={loadingFollow || loadingUnfollow}
      type='primary'
      size='small'
      onClick={isFollowed ? handleUnfollow : handleFollow}
      icon={<PlusOutlined />}
    >
      {isFollowed ? 'Unfollow Profile' : 'Follow Profile'}
    </Button>
  );
};

export default FollowOrganisationProfile;
