import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar, Button } from 'components/common';
import { usePost, useAuth } from 'hooks';
import { unfollowResearcher, userAlerts } from 'services/api';
import { setView } from 'actions/alerts';

import {
  Name,
  ActionsContainer,
  IdentityContainer,
  FollowContentContainer,
} from './styled';

const FollowContent = ({ researcherName, push, dispatch }) => {
  const goToAlerts = () => {
    dispatch(setView('followedResearchers'));
    push('/searches');
  };

  return (
    <FollowContentContainer>
      Alert created for {researcherName}
      <br />
      <br />
      <span onClick={goToAlerts}>Click here</span> to manage followed
      researchers
    </FollowContentContainer>
  );
};

const Identity = ({ researcher }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { push } = useHistory();
  const { researcherProfile, isFollowedByUser } = researcher || {};
  const [isFollowed, setIsFollowed] = useState(isFollowedByUser);

  const [
    { res: followRes, loading: loadingFollow, error: followError },
    postFollow,
  ] = usePost({
    url: userAlerts,
  });
  const [
    { res: unfollowRes, error: unfollowError, loading: loadingUnfollow },
    postUnfollow,
  ] = usePost({ url: unfollowResearcher });

  useEffect(() => {
    setIsFollowed(isFollowedByUser);
  }, [isFollowedByUser]);

  useEffect(() => {
    if (followRes || followError?.status === 409) {
      setIsFollowed(true);

      notification.open({
        message: 'Researcher followed',
        description: (
          <FollowContent
            researcherName={researcherProfile.researcherFullName}
            push={push}
            dispatch={dispatch}
          />
        ),
        placement: 'bottomLeft',
      });
    }
  }, [
    followRes,
    followError,
    dispatch,
    push,
    researcherProfile.researcherFullName,
  ]);

  useEffect(() => {
    if (unfollowRes || unfollowError?.status === 410) {
      setIsFollowed(false);
    }
  }, [unfollowRes, unfollowError]);

  const handleFollow = () => {
    postFollow({
      monthlyKeywords: `(SciLeadsSuperResearcherId:${researcherProfile.id})`,
      userId: user.userId,
      frequency: 3, // Daily
      researcherId: researcherProfile.id,
    });
  };

  const handleUnfollow = () => {
    postUnfollow({ researcherId: researcherProfile.id });
  };

  return (
    <IdentityContainer>
      <Avatar size='large' centered />
      <Name>{researcherProfile.researcherFullName}</Name>
      {(isFollowed || !researcherProfile.researcherInactiveUpdateDate) && (
        <ActionsContainer>
          <Button
            disabled={loadingFollow || loadingUnfollow}
            type='primary'
            size='small'
            onClick={isFollowed ? handleUnfollow : handleFollow}
          >
            {isFollowed ? 'Unfollow Profile' : 'Follow Profile'}
          </Button>
        </ActionsContainer>
      )}
    </IdentityContainer>
  );
};

export default Identity;
