import { setListMembers } from 'actions/lists';
import { useGet } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveList,
  getListMembers,
  getPageNumber,
  getPageSize,
  getTableSort,
} from 'selectors/lists';
import { getMembersForList } from 'services/api';
import { views } from '../constants';

import Actions from './Actions';
import Table from './Table';

const Members = ({
  onCreateNewItemList,
  loading,
  view = views.researchers,
}) => {
  const dispatch = useDispatch();
  const activeList = useSelector(getActiveList);
  const activeListIdRef = useRef();
  const pageNumber = useSelector(getPageNumber);
  const pageSize = useSelector(getPageSize);
  const { dir, by } = useSelector(getTableSort);
  const listMembers = useSelector(getListMembers);
  const [{ res, loading: internalLoading }, fetchListMembers] = useGet({
    url: getMembersForList(activeList.id, view),
    query: {
      pageSize,
      pageNumber,
      orderBy: by,
      isAscending: dir === 'asc',
    },
  });

  useEffect(() => {
    if (res) {
      dispatch(setListMembers(res));
    }
  }, [dispatch, res]);

  const loadingStatus = loading || internalLoading;

  useEffect(() => {
    // refetch list members only on filters change,
    // on list change members are loaded at Lists component
    if (!activeList?.id || activeList.id !== activeListIdRef.current) {
      activeListIdRef.current = activeList?.id;
      return;
    }

    if (activeList?.id === -1) {
      return;
    }

    fetchListMembers();
  }, [fetchListMembers, activeList?.id]);

  return (
    <>
      <Actions
        disabled={loadingStatus}
        totalMembers={listMembers?.total}
        loading={loadingStatus}
        onCreateNewItemList={onCreateNewItemList}
        view={view}
      />
      <Table
        members={listMembers?.members}
        totalMembers={listMembers?.total}
        loading={loadingStatus}
        view={view}
      />
    </>
  );
};

export default Members;
