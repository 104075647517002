//@ts-check
import GraphBase from 'components/Search/Results/Visualisations/GraphBase';
import React from 'react';
import styled from 'styled-components';
import { format } from 'utils/date';
import { siNumber } from 'utils/number';
import OrganisationFundingHighlights from './hightlights';
import dayjs from 'dayjs';
import { HelpTooltip, Link, Table } from 'components/common';
import { Fragment } from 'react';

const GraphContainer = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  margin: 20px 0 5px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex: 1;
  position: relative;
`;

const FundingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * @typedef {{
 * acquisitionId: number,
 * acquisitionType: string,
 * organisationName: string,
 * superOrgId: number?,
 * priceUsd: number?,
 * acquisitionMade: boolean,
 * acquiredOn: string,
 * firstScrapedDate: string
 * }} Acquisition
 * @typedef {{
 *  investorId: number,
 *  name: string,
 *  type: string,
 *  superOrgId: ?number
 * }} Investor
 * @typedef {{
 * fundingRoundId: number,
 * announcedDate: string,
 * cbUrl: string,
 * investmentType: string,
 * investorCount: number,
 * isLatest: boolean,
 * name: string,
 * raisedAmountUsd: number,
 * elasticInvestors: Investor[] | null
 * }} FundingRound
 * @typedef {{
 * announcedDate: string,
 * fundingRoundId: number,
 * investeeName: string,
 * investeeSuperOrgId: number | null,
 * investmentType: string,
 * name: string,
 * raisedAmountUsd: number,
 * allInvestors: Investor[] | null
 * }} Investment
 * @typedef {{
 *  superOrganisationFundingRounds: FundingRound[] | null,
 *  superOrganisationLatestFundingRound: FundingRound | null,
 *  organisationName: string,
 *  superOrganisationTotalFundingUsd: number,
 *  superOrganisationAcquisitions: Acquisition[] | null,
 *  superOrganisationInvestments: Investment[] | null,
 * }} OrganisationVCFundingDetails
 * @param {{
 *  organisation: OrganisationVCFundingDetails
 * }} props
 * @returns {JSX.Element}
 **/
const OrganisationFundingRounds = ({ organisation }) => {
  const sortedByAnnoundedDate =
    organisation.superOrganisationFundingRounds?.sort((a, b) =>
      dayjs(a.announcedDate).diff(dayjs(b.announcedDate))
    ) || [];

  const graphCutOff = -15;

  const data = sortedByAnnoundedDate
    .map(fr => fr.raisedAmountUsd)
    .slice(graphCutOff);
  /**
   * @typedef {{
   *  investmentType: string,
   *  announcedDate: string,
   *  investors: string[]
   * }} label
   * @type {label[]}
   **/
  const labels = sortedByAnnoundedDate
    .map(fr => ({
      investmentType: fr.investmentType,
      announcedDate: fr.announcedDate,
      investors: fr.elasticInvestors?.map(i => i.name) || [],
    }))
    .slice(graphCutOff);

  const options = {
    legend: {
      display: false,
    },
    aspectRatio: 3,
    scales: {
      yAxes: [
        {
          ticks: {
            /**
             * @param {label} value
             * @param {number} index
             * @returns {string}
             */
            callback: function(value, index) {
              return `$${siNumber(value, 1)}`;
            },
            beginAtZero: true,
            min: 0,
            maxTicksLimit: (data?.length || 1) + 1,
          },
          type: data?.filter(d => d).length > 1 ? 'logarithmic' : undefined,
        },
      ],
      xAxes: [
        {
          ticks: {
            /**
             * @param {label} label
             * @returns
             */
            callback: function(label) {
              return [
                label.investmentType || 'Unknown',
                format(label.announcedDate),
              ];
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: function(tooltipItems) {
          return tooltipItems[0]?.xLabel?.investors?.length
            ? tooltipItems[0].xLabel.investors
            : 'Investors Unknown';
        },
        label: function(tooltipItem) {
          return tooltipItem.yLabel ? `$${siNumber(tooltipItem.yLabel)}` : '-';
        },
      },
    },
  };

  /**
   * @param {FundingRound} a
   * @param {FundingRound} b
   * @param {string} sort
   * @param {'asc' | 'desc' | undefined} sortDir
   * @returns
   */
  const onSort = (a, b, sort, sortDir) => {
    let aValue = a[sort]?.toLowerCase ? a[sort].toLowerCase() : a[sort];
    let bValue = b[sort]?.toLowerCase ? b[sort].toLowerCase() : b[sort];

    if (sort === 'elasticInvestors') {
      /**
       * @param {Investor} v
       * @returns {string}
       */
      const lambda = v => v.name;

      aValue = aValue?.map(lambda)?.join('');
      bValue = bValue?.map(lambda)?.join('');
    }

    if (aValue == null && bValue != null) return 1;
    if (aValue != null && bValue == null) return -1;

    if (sortDir === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  };

  return (
    <FundingContainer>
      <Title>Highlights</Title>
      <OrganisationFundingHighlights organisation={organisation} />
      <Title>
        Funding Graph
        <HelpTooltip>The amount raised at each funding round</HelpTooltip>
      </Title>
      <GraphContainer>
        <GraphBase
          // @ts-ignore
          data={{ data: data, labels: labels }}
          type='bar'
          options={options}
          fullWidth
        />
      </GraphContainer>
      <Title>Funding Table</Title>
      <Table
        config={fundingRoundTableConfig}
        data={sortedByAnnoundedDate.reverse()}
        sortable
        onSort={onSort}
        className={'researchers-table'}
        paginate
      />
    </FundingContainer>
  );
};

const fundingRoundTableConfig = {
  headers: [
    { label: 'Announced On', minWidth: 90, key: 'announcedDate' },
    { label: 'Funding Type', minWidth: 90, key: 'investmentType' },
    { label: 'Number of Investors', minWidth: 90, key: 'investorCount' },
    { label: 'Amount Raised', minWidth: 90, key: 'raisedAmountUsd' },
    {
      label: 'Investors',
      minWidth: 200,
      maxContentWidth: 500,
      key: 'elasticInvestors',
    },
    { label: 'Transaction Name', minWidth: '150', key: 'name' },
  ],
  /** @param {FundingRound} r */
  createRow: r => {
    return (
      <Fragment key={r.fundingRoundId}>
        <div>{format(r.announcedDate)}</div>
        <div>{r.investmentType || 'Unknown'}</div>
        <div>{r.investorCount || '-'}</div>
        <div>{r.raisedAmountUsd ? `$${siNumber(r.raisedAmountUsd)}` : '-'}</div>
        <div>
          {r.elasticInvestors?.length
            ? r.elasticInvestors
                ?.sort((a, b) => ('' + a.name).localeCompare(b.name))
                ?.map(i =>
                  i.superOrgId ? (
                    //@ts-ignore
                    <Link
                      key={i.investorId}
                      to={`/organisation/${i.superOrgId}?initialView=investments`}
                      showIconHint
                    >
                      {i.name}
                    </Link>
                  ) : (
                    <div key={i.investorId}>{i.name}</div>
                  )
                ) || ''
            : 'Investors Unknown'}
        </div>
        <div>{r.name}</div>
      </Fragment>
    );
  },
};

export default React.memo(OrganisationFundingRounds);
