import dayjs from 'dayjs';
import styled from 'styled-components';
import { Alert } from 'antd';

const StyledAlert = styled(Alert)`
  margin-bottom: ${p => p.theme.gutter};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-right: 10px;
  }
`;

const Message = ({ inactiveUpdateDate }) => {
  return (
    <MessageContainer>
      <span>
        It has been determined that this researcher is no longer working. This
        status was updated on {inactiveUpdateDate}.
      </span>
    </MessageContainer>
  );
};

const InactiveNotice = ({ researcherProfile }) => {
  if (!researcherProfile || !researcherProfile.researcherInactiveUpdateDate)
    return null;

  const formattedInactiveUpdatedDate = dayjs(
    researcherProfile.researcherInactiveUpdateDate
  ).format('MMMM Do, YYYY');

  return (
    <StyledAlert
      type='info'
      message={<Message inactiveUpdateDate={formattedInactiveUpdatedDate} />}
    />
  );
};

export default InactiveNotice;
