import { useSelector } from 'react-redux';
import { Select } from 'components/Lists/Actions/styled';
import {
  getSearchRequest,
  getHasResults,
  getLastSuccessfulSearch,
} from 'selectors/search';
import listRenderOption from 'components/common/Lists/ListRenderOptions';

const ListAction = props => {
  const lastSuccessfulSearch = useSelector(getLastSuccessfulSearch);
  const { loading: searching } = useSelector(getSearchRequest);
  const hasResults = useSelector(getHasResults);

  const selectLabel = 'Add All to List';

  const {
    onCreateNewItemList,
    onChangeAddAllToList,
    listOptions,
    fetchListLoading,
  } = props;

  const options =
    listOptions && listOptions.length === 0
      ? [{ label: 'No lists available', value: 'no_lists' }]
      : listOptions;

  const handleSelectChange = value => {
    if (value === 'no_lists') {
      return;
    }
    onChangeAddAllToList(value);
  };

  return (
    <Select
      value={selectLabel}
      size='small'
      disabled={
        searching ||
        fetchListLoading ||
        !lastSuccessfulSearch.query ||
        !hasResults
      }
      options={options}
      onChange={handleSelectChange}
      dropdownStyle={{ minWidth: '225px' }}
      style={{ marginTop: '2px', width: 125 }}
      onCreateNewItem={onCreateNewItemList}
      renderOption={(o, i) => listRenderOption({ ...o }, i)}
      buttonText='Create New List'
      placement='bottomRight'
      filterOption={(input, option) =>
        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      disableTooltip
    />
  );
};

export default ListAction;
