import { useEffect } from 'react';
import { Modal as AntModal } from 'antd';

import { usePost } from 'hooks';
import { resetPassword } from 'services/api';

const ResetPassword = ({ activeUser, setModal }) => {
  const [{ res, loading }, postResetPassword] = usePost({ url: resetPassword });

  useEffect(() => {
    if (res) {
      setModal();
    }
  }, [res, setModal]);

  const handleOk = () => {
    postResetPassword({ email: activeUser.email });
  };

  return (
    <AntModal
      open
      title='Reset Password'
      onCancel={() => setModal(null)}
      onOk={handleOk}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <div>Do you want to reset {activeUser.fullname}'s password?</div>
    </AntModal>
  );
};

export default ResetPassword;
