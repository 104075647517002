import { regions } from '../constants';

export const geoPosition = {
  [regions.world]: { coordinates: [0, 26.5], zoom: 0 },
  [regions.usa]: { coordinates: [-95, 41.48], zoom: 3.43 },
  [regions.canada]: { coordinates: [-98.8, 58.9], zoom: 3.27 },
  [regions.europe]: { coordinates: [13.1, 54.1], zoom: 3 },
  [regions.asia]: { coordinates: [106, 5], zoom: 2 },
  [regions.africa]: { coordinates: [25, -1], zoom: 2.5 },
  [regions.southAmerica]: { coordinates: [-44.57, -26.6], zoom: 2.5 },
};

export const firstLevelZoomRange = [0, 2];
export const secondLevelZoomRange = [2, 24];
