import qs from 'query-string';
import { Redirect, useLocation } from 'react-router-dom';

const ExternalNotFound = () => {
  const { search } = useLocation();
  const { downloadexpired } = qs.parse(search);

  if (downloadexpired) {
    return <Redirect to={`/notfound?download=${downloadexpired}`} />;
  }

  return null;
};

export default ExternalNotFound;
