import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import { Pane, Button } from 'components/common';
import { setActiveList, setModal, setView } from 'actions/lists';
import {
  getView,
  getShowMyListsOnly,
  getIncludePausedLists,
} from 'selectors/lists';
import { useAuth, useGet } from 'hooks';
import { getOrgListsForUser, getListsForUser } from 'services/api';
import licenseType from 'models/licenseType';
import OrganisationLists from './OrganisationLists';
import { views } from './constants';
import ListsTab from '.';
import { useParams, useHistory } from 'react-router-dom';
import { RadioGroupStyle, Separator } from 'components/Search/Results/Title';

const Title = ({ hasOrgLicense, loading }) => {
  const dispatch = useDispatch();
  const view = useSelector(getView);
  const handleChangeView = view => {
    dispatch(setView(view.target.value));
  };

  const viewOptions = useMemo(() => {
    const options = [{ label: 'Researchers', value: views.researchers }];

    if (hasOrgLicense) {
      options.push({ label: 'Organisations', value: views.organisations });
    }

    return options;
  }, [hasOrgLicense]);

  return (
    <RadioGroupStyle
      disabled={loading}
      buttonStyle='solid'
      value={view}
      optionType='button'
      onChange={handleChangeView}
      style={{
        display: viewOptions.length > 1 ? 'inline-block' : 'none',
        marginRight: '10px',
      }}
      size='middle'
      defaultOpen={views.researchers}
      data-testid='radio-view-options'
    >
      <Separator options={viewOptions} />
    </RadioGroupStyle>
  );
};

export const CreateNewListButton = ({ display = true, style }) => {
  const dispatch = useDispatch();

  return (
    <Button
      disabled={!display}
      style={style}
      onClick={() => {
        dispatch(setModal('create'));
      }}
      type='primary'
      size='small'
    >
      Create New List
    </Button>
  );
};

const Lists = () => {
  const { hasLicense } = useAuth();

  const hasOrgLicense = useMemo(
    () => hasLicense([licenseType.industry, licenseType.academic], true),
    [hasLicense]
  );
  const hasIndustryLicense = useMemo(
    () => hasLicense([licenseType.industry], true),
    [hasLicense]
  );
  const dispatch = useDispatch();
  const view = useSelector(getView);
  const { push } = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const { search, state } = location;
  const { setDefaultList } = state || {};
  const { view: externalView } = qs.parse(search);
  const [viewToLoad, setViewToLoad] = useState();
  const includeDisabled = useSelector(getIncludePausedLists);
  const onlyShowMine = useSelector(getShowMyListsOnly);
  const [
    { res: fetchingResearcherListResponse, loading },
    fetchResearcherLists,
  ] = useGet({
    url: getListsForUser,
    query: {
      IncludeDisabled: includeDisabled,
      onlyShowMine,
    },
    onMount: false,
  });
  const [
    { res: fetchingOrgListResponse, loading: orgListsLoading },
    fetchOrgLists,
  ] = useGet({
    url: getOrgListsForUser,
    query: {
      IncludeDisabled: includeDisabled,
      onlyShowMine,
    },
    onMount: false,
  });

  useEffect(() => {
    if (view === views.organisations && !hasOrgLicense) {
      dispatch(setView(views.researchers));
    }
  }, [dispatch, hasOrgLicense, view]);

  useEffect(() => {
    if (
      externalView &&
      Object.keys(views)
        .map(k => views[k])
        .includes(externalView) &&
      (externalView !== views.organisations || hasOrgLicense)
    ) {
      dispatch(setView(externalView));
    }
  }, [externalView, dispatch, hasOrgLicense]);

  useEffect(() => {
    if (externalView || id) {
      return;
    }

    setViewToLoad(v => (v !== view ? view : v));
  }, [view, externalView, id]);

  useEffect(() => {
    if (!viewToLoad) {
      return;
    }

    if (viewToLoad === views.researchers) {
      fetchResearcherLists();
    }

    if (viewToLoad === views.organisations && hasOrgLicense) {
      fetchOrgLists();
    }
  }, [fetchResearcherLists, fetchOrgLists, viewToLoad, hasOrgLicense]);

  useEffect(() => {
    if (!id) return;
    if (!externalView) {
      dispatch(setView(views.researchers));
      push('/lists');
      return;
    }

    // it's handled in researchers lists component
    if (
      view !== views.organisations &&
      (!externalView || externalView !== views.organisations)
    ) {
      push('/lists');
      return;
    }

    if (!hasOrgLicense) {
      push('/lists');
      return;
    }

    if (id) {
      dispatch(setActiveList({ id: +id }));
      push('/lists');
    }
  }, [
    dispatch,
    fetchingOrgListResponse,
    id,
    push,
    view,
    externalView,
    hasOrgLicense,
  ]);
  return (
    <Pane
      title={
        hasOrgLicense ? (
          <Title
            hasOrgLicense={hasOrgLicense}
            loading={loading || orgListsLoading}
          />
        ) : (
          <></>
        )
      }
      headerRight={hasIndustryLicense ? <CreateNewListButton /> : <></>}
    >
      {view === views.researchers && (
        <ListsTab
          fetchLists={fetchResearcherLists}
          fetchingListResponse={fetchingResearcherListResponse}
          loading={loading}
          hasOrgLicense={hasOrgLicense}
          setDefaultList={setDefaultList}
        />
      )}
      {view === views.organisations && hasOrgLicense && (
        <OrganisationLists
          lists={fetchingOrgListResponse}
          fetchLists={fetchOrgLists}
          loading={orgListsLoading}
        />
      )}
    </Pane>
  );
};

Lists.propTypes = {};

export default Lists;
