import Sortable from './Sortable';

const Table = props => {
  return <Sortable {...props} />;
};

Table.defaultProps = {
  data: [],
};

export default Table;
