import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'components/common';
import {
  ImageContainer,
  LogoContainer,
  QuoteContainer,
  QuoteContentContainer,
  InitialsContainer,
  LogoInitialsContainer,
  CirlceContainer,
} from './styled';
import Circle from './Circle';

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

function CustomerQuote({
  className,
  style,
  imageUrl,
  quote,
  customerLogo,
  customerEmployeeName,
  customerEmployeePosition,
  quoteBackground,
  quoteBackgroundCircle,
}) {
  return (
    <div className={className} style={style}>
      <ContentContainer>
        {imageUrl && (
          <ImageContainer>
            <img src={imageUrl} alt='feature' />
          </ImageContainer>
        )}
        <QuoteContainer>
          <QuoteContentContainer
            style={{ background: quoteBackground || 'none' }}
          >
            <Icon icon='quoteLeft' color='#ED5520' size='lg' />
            <div className='quote'>
              {quote}
              <LogoInitialsContainer>
                <LogoContainer>
                  <img
                    src={customerLogo}
                    alt='customer logo'
                    className='logo'
                  />
                </LogoContainer>
                <InitialsContainer>
                  <div>{customerEmployeeName}</div>
                  <div className='position'>{customerEmployeePosition}</div>
                </InitialsContainer>
              </LogoInitialsContainer>
            </div>
          </QuoteContentContainer>
          {quoteBackgroundCircle && (
            <CirlceContainer>
              <Circle />
            </CirlceContainer>
          )}
        </QuoteContainer>
      </ContentContainer>
    </div>
  );
}

CustomerQuote.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  imageUrl: PropTypes.string,
  quote: PropTypes.string.isRequired,
  customerLogo: PropTypes.string.isRequired,
  customerEmployeeName: PropTypes.string,
  customerEmployeePosition: PropTypes.string,
  quoteBackground: PropTypes.string,
  quoteBackgroundCircle: PropTypes.bool,
};

export default CustomerQuote;
