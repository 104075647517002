import { Fragment } from 'react';
import { Table } from 'components/common';
import styled from 'styled-components';
import { formatNumber, formatCurrency } from 'utils/number';

export const Progress = styled.div`
  padding: 12px;
  width: 100%;
`;

export const Column = styled.div`
  padding: 7px 7px 7px 15px !important;
`;

export const HorizontalTable = styled(Table)`
  color: #5a5a5a;
  .cell {
    border-top: 1px solid #ccc;
  }
`;

const textClamp = value => {
  if (value.length > 70) {
    return value.slice(0, 67) + '...';
  }

  return value;
};

const HorizontalReportTable = ({
  reportData,
  type,
  countLabel,
  currency = false,
  excludeCount = false,
  excludeRank = false,
}) => {
  const config = {
    headers: [
      {
        label: type,
        key: 'title',
        style: { backgroundColor: 'white', borderTop: 'none' },
      },
      {
        label: '-',
        key: 'totalPercentage',
        style: { backgroundColor: 'white', borderTop: 'none', opacity: '0' },
      },
    ],
    createRow: (r, i) => (
      <Fragment key={i}>
        {!excludeRank && <Column>{i + 1}</Column>}
        <Column
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {textClamp(r.title) || '-'}
        </Column>
        <Column>
          <Progress
            style={{
              backgroundColor: r.totalPercentageColor,
              width: `${r.totalPercentage}%`,
            }}
          >
            {' '}
          </Progress>
        </Column>
        {!excludeCount && (
          <Column style={{ justifyContent: 'right' }}>
            {currency ? `$${formatCurrency(r.count)}` : formatNumber(r.count)}
          </Column>
        )}
      </Fragment>
    ),
  };

  if (!excludeRank) {
    config.headers.splice(0, 0, {
      label: 'Rank',
      key: 'rank',
      style: {
        backgroundColor: 'white',
        borderTop: 'none',
        justifyContent: 'left',
      },
    });
  }

  if (!excludeCount) {
    config.headers.push({
      label: countLabel,
      key: 'count',
      style: {
        backgroundColor: 'white',
        borderTop: 'none',
        textAlign: 'right',
        justifyContent: 'right',
      },
    });
  }

  return (
    <HorizontalTable
      paginate={false}
      sortable={false}
      config={config}
      data={reportData}
    />
  );
};

export default HorizontalReportTable;
