import { regions } from '../constants';

export const getGeoJsonData = (geoData, region, geoAreas) => {
  const features = [];
  geoData.forEach(x => {
    if (
      geoAreas.includes(x.type) &&
      (region === regions.world || region === x.region)
    ) {
      features.push({
        type: 'Feature',
        properties: {
          region: x.region,
          type: x.type,
          title: x.key,
          value: x.value,
        },
        geometry: {
          coordinates: x.coordinates,
          type: 'Point',
        },
      });
    }
  });
  return {
    type: 'FeatureCollection',
    features,
  };
};

export const getSize = (
  data,
  types,
  region,
  minVal,
  maxVal,
  key = 'value',
  parts = 3
) => {
  if (!data) return;
  const values = data
    .filter(
      x =>
        types.includes(x.type) &&
        (region === regions.world || region === x.region)
    )
    .map(x => x.value);
  const max = Math.max(...values);
  const min = Math.min(...values);
  if (max === min) {
    return maxVal;
  }

  const res = ['step', ['get', key]];

  for (let i = 0; i < parts; i++) {
    if (i === 0) {
      res.push(minVal);
      res.push(min + 0.01);
    } else {
      res.push(minVal + i * ((maxVal - minVal) / parts));
      res.push(min + i * ((max - min) / parts));
    }
  }
  res.push(maxVal);
  return res;
};

export const formatNumber = key => [
  'case',
  ['all', ['>=', ['abs', ['get', key]], 1e9]],
  [
    'concat',
    [
      'to-string',
      [
        'number-format',
        ['/', ['number', ['get', key]], ['number', 1e9]],
        { 'min-fraction-digits': 1, 'max-fraction-digits': 1 },
      ],
    ],
    'B',
  ],
  ['all', ['>=', ['abs', ['get', key]], 1e6]],
  [
    'concat',
    [
      'to-string',
      [
        'number-format',
        ['/', ['number', ['get', key]], ['number', 1e6]],
        { 'min-fraction-digits': 1, 'max-fraction-digits': 1 },
      ],
    ],
    'M',
  ],
  ['all', ['>=', ['abs', ['get', key]], 1e3]],
  [
    'concat',
    [
      'to-string',
      [
        'number-format',
        ['/', ['number', ['get', key]], ['number', 1e3]],
        { 'min-fraction-digits': 1, 'max-fraction-digits': 1 },
      ],
    ],
    'K',
  ],
  ['get', key],
];

export const getClusterFeatures = (point, map, sourceName) => {
  const features = map.queryRenderedFeatures(point, {
    layers: ['clusters'],
  });

  const clusterId = features[0].properties.cluster_id;
  const pointCount = features[0].properties.point_count;
  const clusterSource = map.getSource(sourceName);
  return new Promise((resolve, reject) => {
    clusterSource.getClusterLeaves(
      clusterId,
      pointCount,
      0,
      (error, features) => {
        if (!error) {
          return resolve(features);
        }

        return resolve([]);
      }
    );
  });
};
