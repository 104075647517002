import { format } from 'utils/date';
import { Detail, SubJournal } from './styled';

const JournalInfo = ({ data }) => {
  let journalDetails = [];
  if (data.journal?.publishedDate)
    journalDetails.push(
      `pub: ${format(data.journal.publishedDate, 'YYYY MMM D')}`
    );
  if (data.journal?.volume) {
    if (data.journal.issue)
      journalDetails.push(
        `vol: ${data.journal.volume} (${data.journal.issue})`
      );
    else journalDetails.push(`vol: ${data.journal.volume}`);
  }
  if (data.article.paginationMedlinePgn)
    journalDetails.push(`pg: ${data.article.paginationMedlinePgn}`);
  if (data.article.electronicVersionDate)
    journalDetails.push(
      `epub: ${format(data.article.electronicVersionDate, 'YYYY MMM D')}`
    );
  return (
    <Detail label='Journal/Preprint Repository'>
      <div>{data.journal?.title}</div>
      <SubJournal>{journalDetails.join(' | ')}</SubJournal>
    </Detail>
  );
};

export default JournalInfo;
