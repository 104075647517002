import React, { useEffect, useState, useMemo } from 'react';
import { Alert } from 'antd';

const PasswordError = ({
  newPass,
  confirmPass,
  onChange,
  shouldRender,
  serverError,
}) => {
  const [serverErrorMessage, setServerErrorMessage] = useState(null);

  const passwordStrength = useMemo(() => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+[\]{};':"\\|,.<>?]/;

    let numTrue = 0;
    if (uppercaseRegex.test(newPass)) numTrue++;
    if (lowercaseRegex.test(newPass)) numTrue++;
    if (numberRegex.test(newPass)) numTrue++;
    if (specialCharRegex.test(newPass)) numTrue++;

    return {
      passwordTooShortError: newPass && newPass.length < 8,
      hasUppercase: uppercaseRegex.test(newPass),
      hasLowercase: lowercaseRegex.test(newPass),
      hasNumber: numberRegex.test(newPass),
      hasSpecialChar: specialCharRegex.test(newPass),
      numCriteriaTrue: numTrue,
      passwordTooWeakError: numTrue < 3,
    };
  }, [newPass]);

  const passwordsNotMatchingError =
    newPass && confirmPass && newPass !== confirmPass;

  const hasClientSidePasswordErrors =
    passwordsNotMatchingError ||
    passwordStrength.passwordTooShortError ||
    passwordStrength.passwordTooWeakError;

  function getServerError(serverError) {
    switch (serverError) {
      case 'PASSWORD_TOO_SHORT':
      case 'PASSWORD_TOO_WEAK':
        return 'Make sure that the new password meets the requirements.';
      case 'PASSWORD_NOT_SECURE':
        return 'This password is not secure. Please choose a different password.';
      case 'BAD_CURRENT_PASSWORD':
        return 'Make sure that the current password entry is correct.';
      default:
        return 'It was not possible to change your password, please try again later.';
    }
  }

  useEffect(() => {
    if (newPass === '' || !shouldRender) {
      setServerErrorMessage(null);
      return;
    }
    if (serverError) {
      setServerErrorMessage(getServerError(serverError.message));
    } else {
      setServerErrorMessage(null);
    }
    if (hasClientSidePasswordErrors && shouldRender) {
      setServerErrorMessage(null);
    }
    onChange(
      hasClientSidePasswordErrors,
      passwordsNotMatchingError,
      passwordStrength.passwordTooShortError,
      passwordStrength.passwordTooWeakError
    );
  }, [
    newPass,
    serverError,
    onChange,
    hasClientSidePasswordErrors,
    passwordStrength,
    passwordsNotMatchingError,
    passwordStrength.passwordTooShortError,
    passwordStrength.passwordTooWeakError,
    setServerErrorMessage,
    shouldRender,
  ]);

  if (!shouldRender || !serverErrorMessage) {
    return null;
  }
  return (
    <React.Fragment>
      {serverErrorMessage && (
        <Alert message={serverErrorMessage} type='error' />
      )}
    </React.Fragment>
  );
};

export default PasswordError;
