import { notification } from 'antd';
import { useAuth } from 'hooks/useAuth';
import qs from 'query-string';
import { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { tfaHasBeenEnabled } from 'components/Login/tfaConstants';
import refreshExpiredToken from './refreshToken';
import { useDispatch } from 'react-redux';
import { setNeedPassCaptcha } from 'actions/rateLimits';

const states = {
  UNSENT: 0,
  OPENED: 1,
  HEADERS_RECEIVED: 2,
  LOADING: 3,
  DONE: 4,
};

const useXHR = onMount => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const auth = useAuth();
  const { pathname, search } = useLocation();
  const [state, setState] = useState({
    loading: onMount,
    res: null,
    error: null,
  });
  const isRetry = useRef(false);
  const req = useMemo(() => new XMLHttpRequest(), []);

  const handleRequestStart = () => {
    setState({ res: null, loading: true, error: null });
  };

  const handleError = ({ status, message, statusText }) => {
    let parsedMessage;
    try {
      parsedMessage = message ? JSON.parse(message) : null;
    } catch (e) {
      parsedMessage = message || null;
    }
    let errors = parsedMessage?.errors ?? {};
    parsedMessage = parsedMessage?.responseStatus?.message
      ? parsedMessage.responseStatus.message
      : parsedMessage?.title ?? parsedMessage;
    setState(s => ({
      ...s,
      loading: false,
      error: { status, statusText, message: parsedMessage, errors },
    }));
  };

  const handleRequestDone = async () => {
    if (req.status >= 200 && req.status < 300) {
      isRetry.current = false;
      let res;
      if (req.response) {
        try {
          res = JSON.parse(req.response);
        } catch (e) {
          res = { status: 'OK', data: req.response, req };
        }
      } else {
        res = { status: 'OK' };
      }

      if (res === 'OK') {
        res = { status: 'OK' };
      }

      // if (res && typeof res === 'object' && !Array.isArray(res)) {
      //   res._uuid = Date.now();
      // }
      setState({
        loading: false,
        res,
        error: null,
      });
    } else if (req.status === 429) {
      dispatch(setNeedPassCaptcha(true));
      return;
    } else if (req.status === 401 && !pathname.includes('/login')) {
      // try refresh token
      if (!isRetry.current) {
        isRetry.current = true;
        try {
          const { token } = await refreshExpiredToken();

          req.open(originalRequest.current.method, originalRequest.current.url);
          if (originalRequest.current.responseType) {
            req.responseType = originalRequest.current.responseType;
          }
          const headers = originalRequest.current.headers || [];
          for (let i = 0; i < headers.length; i++) {
            const header = headers[i];
            req.setRequestHeader(header.key, header.value);
          }

          req.setRequestHeader('Authorization', `Bearer ${token}`);
          req.send(originalRequest.current.body);
          return;
        } catch (err) {
          if (err?.tfaEnabled) {
            notification.warning({
              message: tfaHasBeenEnabled,
              placement: 'bottomLeft',
            });
          }
        }
      }

      let res;
      try {
        res = JSON.parse(req.response);
      } catch {}

      // to avoid multiple redirects,
      // if user is authed we can clear user data and redirect will be handled by AuthedRoute, logout by login component
      if (auth?.isAuthed) {
        auth.softLogOut();
      } else {
        const parsedQueryParams = qs.parse(search);
        const queryParams = { redirect: pathname, ...parsedQueryParams };
        if (pathname === '/') {
          delete queryParams.redirect;
        }

        const redirectString = qs.stringify(queryParams, { encode: false });

        const redirectPath = `/login?${redirectString}`;
        push(redirectPath);
      }

      if (res?.tfaEnabled) {
        notification.warning({
          message: tfaHasBeenEnabled,
          placement: 'bottomLeft',
        });
      }
    } else if (req.status === 404) {
      const parsedQueryParams = qs.parse(search);
      const queryParams = { from: pathname, ...parsedQueryParams };
      if (pathname === '/') {
        delete queryParams.from;
      }
      const redirectString = qs.stringify(queryParams, { encode: false });
      handleError({
        status: req.status,
        message: req.response,
        statusText: req.statusText,
      });
      push(`/notfound?${redirectString}`);
    } else {
      handleError({
        status: req.status,
        message: req.response,
        statusText: req.statusText,
      });
    }
  };

  const handleReadyStateChange = () => {
    switch (req.readyState) {
      case states.OPENED:
        handleRequestStart();
        break;
      case states.DONE:
        handleRequestDone();
        break;
      default:
        break;
    }
  };

  const originalRequest = useRef(null);
  const setOriginalRequest = useCallback(
    value => (originalRequest.current = value),
    []
  );
  const setRes = value => setState(s => ({ ...s, res: value }));
  const setErr = value => setState(s => ({ ...s, error: value }));

  useEffect(() => {
    req.addEventListener('readystatechange', handleReadyStateChange);
    req.addEventListener('error', handleError);

    return () => {
      req.removeEventListener('readystatechange', handleReadyStateChange);
      req.removeEventListener('error', handleError);
    };
  });

  return [state, req, setRes, setErr, setOriginalRequest];
};

export default useXHR;
