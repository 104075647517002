import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const TitleText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
`;

const maxModalTitleLength = 70;

export const getTruncatedModalTitle = name => {
  return name.length > maxModalTitleLength ? (
    <Tooltip title={name} placement='top'>
      <TitleText>{name}</TitleText>
    </Tooltip>
  ) : (
    name
  );
};
