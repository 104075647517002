import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import QRCodeStyling from 'qr-code-styling';

import { useAuth } from 'hooks';
import { Form, Button } from 'components/common';

import TfaInput from './TfaInput';

const qrCode = new QRCodeStyling({
  width: 250,
  height: 250,
  type: 'svg',
  shape: 'shape',
  dotsOptions: {
    color: '#000000',
    type: 'rounded',
  },
  imageOptions: {
    margin: 0,
  },
});

const CodeForm = ({ code, setCode, isLoading, handleVerifyCode }) => {
  const {
    user: { tfaCode, userEmail, tfaActivated },
  } = useAuth();
  const qrCodeEl = useRef(null);
  const verifyRef = useRef(null);

  useEffect(() => {
    if (!tfaActivated) {
      const url = `otpauth://totp/SciLeads:${encodeURIComponent(
        userEmail
      )}?secret=${tfaCode}&issuer=SciLeads`;
      qrCode.update({
        data: url,
      });
      qrCode.append(qrCodeEl.current);
    }
  }, [tfaActivated, userEmail, tfaCode]);

  useEffect(() => {
    if (code) {
      verifyRef.current?.focus();
    }
  }, [code]);

  const onCodeChange = useCallback(
    val => {
      setCode(val);
      verifyRef.current?.focus();
    },
    [setCode]
  );

  return (
    <>
      {!tfaActivated && (
        <>
          <p>
            To keep your account secure, we require two-factor authentication.
            We recommend using the{' '}
            <a
              href='https://support.google.com/accounts/answer/1066447'
              target='_blank'
              rel='noopener noreferrer'
            >
              Google Authenticator app
            </a>{' '}
            on your mobile, but any authenticator app will work.
          </p>
          <p>Scan the QR code in your app</p>
          <div className='qr-code' ref={qrCodeEl} />
        </>
      )}
      <Form onSubmit={handleVerifyCode}>
        <p style={{ margin: '10px 0 30px 0' }}>
          Enter the six digit code from your authentication app
        </p>
        <TfaInput value={code} onChange={onCodeChange} />
      </Form>
      <Button
        ref={verifyRef}
        disabled={isLoading || !code}
        onClick={handleVerifyCode}
        type='primary'
      >
        Verify
      </Button>
    </>
  );
};

CodeForm.propTypes = {
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleVerifyCode: PropTypes.func.isRequired,
};

CodeForm.defaultProps = {
  isLoading: false,
};

export default CodeForm;
