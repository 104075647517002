import { useState, useEffect } from 'react';
import { Modal, notification } from 'antd';

import { reportOrganisation } from 'services/api';
import { usePost } from 'hooks';
import { Input } from 'components/common';

const Report = ({
  superOrganisationId,
  onToggleModal,
  modalTitle,
  placeholder,
  isAiIcon,
}) => {
  const [reason, setReason] = useState();
  const [{ res, loading }, postReport] = usePost({ url: reportOrganisation });

  let displayText = isAiIcon
    ? 'Help train the algorithm. Please make your suggestion below.'
    : 'Please enter a reason for reporting.';

  useEffect(() => {
    if (res) {
      notification.open({
        message: 'Report Submitted',
        description: `Thank you for reporting this organisation ${
          isAiIcon ? 'category' : 'profile'
        } to us for review.`,
        placement: 'bottomLeft',
      });
      onToggleModal();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res, onToggleModal]);

  const handleSubmit = () => {
    postReport({
      superOrganisationId,
      reason,
      issue: isAiIcon ? 'Organisation Category' : 'Organisation Profile',
    });
  };

  return (
    <Modal
      open
      title={modalTitle}
      onOk={handleSubmit}
      onCancel={onToggleModal}
      okButtonProps={{ disabled: loading || !reason }}
      cancelButtonProps={{ disabled: loading }}
    >
      {displayText}
      <Input
        placeholder={placeholder}
        disabled={loading}
        value={reason}
        onChange={setReason}
        style={{ marginTop: 10 }}
        multiline
      />
    </Modal>
  );
};

export default Report;
