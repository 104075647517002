import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Carousel as AntCarousel } from 'antd';
import FeatureDetails from './FeatureDetails';
import CustomerQuote from './CustomerQuote';
import BackgroundLogo from './background.png';
import { Container } from './styled';

const contentStyle = {
  margin: 0,
  height: '100%',
  background: `url(${BackgroundLogo})`,
  backgroundSize: 'cover',
  color: 'rgba(0, 0, 0, 1)',
};

function Carousel({ content }) {
  return (
    <Container>
      <AntCarousel draggable autoplay autoplaySpeed={12000}>
        {content?.featureDetails?.map(c => (
          <Fragment key={c.title}>
            <FeatureDetails
              className='slide'
              style={{
                ...contentStyle,
                background: c.background || contentStyle.background,
                backgroundSize: 'cover',
                color: c.color || contentStyle.color,
              }}
              imageUrl={c.imageUrl}
              title={c.title}
              subTitle={c.subTitle}
            />
          </Fragment>
        ))}
        {content?.customerQuotes?.map(c => (
          <Fragment key={c.quote}>
            <CustomerQuote
              className='slide'
              style={{
                ...contentStyle,
                background: c.background || contentStyle.background,
                backgroundSize: 'cover',
                color: c.color || contentStyle.color,
              }}
              imageUrl={c.imageUrl}
              quote={c.quote}
              customerLogo={c.customerLogo}
              customerEmployeeName={c.customerEmployeeName}
              customerEmployeePosition={c.customerEmployeePosition}
              quoteBackground={c.quoteBackground}
              quoteBackgroundCircle={c.quoteBackgroundCircle}
            />
          </Fragment>
        ))}
      </AntCarousel>
    </Container>
  );
}

Carousel.propTypes = {
  content: PropTypes.shape({
    featureDetails: PropTypes.arrayOf(
      PropTypes.shape({
        background: PropTypes.string,
        color: PropTypes.string,
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
      })
    ).isRequired,
    customerQuotes: PropTypes.arrayOf(
      PropTypes.shape({
        quote: PropTypes.string.isRequired,
        customerLogo: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
        customerEmployeeName: PropTypes.string,
        customerEmployeePosition: PropTypes.string,
        quoteBackground: PropTypes.string,
        quoteBackgroundCircle: PropTypes.bool,
      })
    ).isRequired,
  }),
};

export default Carousel;
