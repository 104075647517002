import styled from 'styled-components';

export const Container = styled.div`
  display: ${p => (p.inline ? 'inline-flex' : 'flex')};
  align-items: center;

  a {
    .linkIcon {
      margin-left: 5px;
    }
  }

  .linkIcon {
    font-size: 9px;
    margin-bottom: 2px;
    transition: 0.1s linear all;
    pointer-events: none;
    opacity: ${p => (p.showIconHint ? '1' : '0')};
    color: ${p => (p.showIconHint ? '#bfbfbf' : 'white')};
  }

  .linkText {
    overflow: hidden;
  }

  .linkIconContainer {
    ${p => p.inline && 'display:none'};
    padding: 0px 3px;
  }

  :hover {
    .linkIconContainer {
      display: inline-block;
    }

    .linkIcon {
      color: ${p => p.theme.linkBlue};
      opacity: 1;
    }
  }
`;

export const LargeContainer = styled(Container)`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
  a {
    .linkIcon {
      margin-left: 5px;
    }
  }
  .linkText {
    color: ${p => p.theme.black};
  }
  .linkIconContainer {
    padding: 0px 5px;
  }
  .linkIcon {
    font-size: 12px;
  }
  :hover {
    .linkText {
      color: ${p => p.theme.teal};
    }
    .linkIcon {
      color: ${p => p.theme.teal};
    }
  }
`;
