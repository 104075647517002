import Icon from 'components/common/Icon';
import { OptionContent } from 'components/common/TokenInput/styled';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { renderSortIcon } from './Headers';
import { SortDropDown, SortOption, SortOptionContainer } from '../styled';

export default function SortOptions({ options, onSortChange, sort, sortDir }) {
  let optionsRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  function openOptions() {
    setShowOptions(true);
  }

  function closeOptions() {
    setShowOptions(false);
  }

  function onSortOptionClick(option) {
    onSortChange(option.key);
    optionsRef?.current?.blur();
  }

  const filteredOptions = options.filter(option => option.key && option.label);

  return (
    <>
      {filteredOptions.some(x => x) && (
        <SortDropDown
          ref={optionsRef}
          tabIndex={0}
          onFocus={openOptions}
          onBlur={closeOptions}
          title='Sort By'
        >
          <div style={{ padding: '0 2px' }}>
            <Icon size='sm' color='black' icon={'options'} />
          </div>
          <div
            style={{
              position: 'absolute',
              overflow: 'visible',
            }}
          >
            {showOptions && (
              <SortOptionContainer>
                <OptionContent>
                  {filteredOptions.map((option, i) => (
                    <SortOption
                      key={option + i}
                      onMouseDown={e => onSortOptionClick(option, e)}
                      data-testid={`sort-option-${i}`}
                    >
                      {option.label}
                      {renderSortIcon(option.key, sort, sortDir)}
                    </SortOption>
                  ))}
                </OptionContent>
              </SortOptionContainer>
            )}
          </div>
        </SortDropDown>
      )}
    </>
  );
}
