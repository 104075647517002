// @ts-check
import dayjs from 'dayjs';
import {
  commonDateRanges,
  organisationFoundationYear,
} from 'services/inputTokens';
import { SelectMany } from './array';
import { formatNumber } from './number';

const negativeTokenColor = 'rgba(255, 37, 67, 0.7)';

/**
 * @typedef {{key: string, value: string | number, index?: number, metaData: import('actions/search').FilterMetaData, color?: string, filterGroup?: string}} FilterToken
 */

/**
 * @param {Object} filters
 * @param {{[filterId: string]: import('actions/search').FilterMetaData}} filterFields
 * @param {string[]=} filtersToExclude
 * @returns {FilterToken[]}
 */
export function tokenise(filters, filterFields, filtersToExclude = []) {
  let tokens = SelectMany(
    Object.keys(filterFields)
      .map(key => {
        let splitKey = key.split('.');
        return {
          totalKey: key,
          key: splitKey[1] || splitKey[0],
          group: splitKey[1] ? splitKey[0] : undefined,
        };
      })
      .filter(
        k =>
          (filters[k.key] != null || (k.group && filters[k.group] != null)) &&
          !filtersToExclude.includes(k.key) &&
          !(k.group && filtersToExclude.includes(k.group))
      )
      .map(k =>
        getTokeniser(filters, k.totalKey)(
          filters[k.group || k.key],
          k.key,
          GetMetaData(k.totalKey, filterFields, filters),
          k.group
        )
      ),
    x => x
  );
  return tokens;
}

function GetMetaData(key, filterFields, filters) {
  if (filterFields[key].getTitle !== null) {
    return { ...filterFields[key], title: filterFields[key].getTitle(filters) };
  } else {
    return filterFields[key];
  }
}

function isValidDayjs(input) {
  if (typeof input === 'string') {
    // a 10-digit string is considered a unix timestamp, but we don't want to treat it as a date
    if (/^\d{10}$/.test(input)) return false;
    if (dayjs(input, 'YYYY-MM-DD').isValid()) return true;
  }
  if (typeof input === 'number' && input.toString().length === 10) {
    return false;
  }
  return dayjs.isDayjs(input);
}

/**
 * @param {any} filter
 * @param {string} key
 * @param {number=} index
 * @param {string=} filterGroup
 * @param {import("actions/search").FilterMetaData} metaData
 * @returns {FilterToken[]}
 */
// defaultTokeniser does not handle sub objects objects
function defaultTokeniser(filter, key, metaData, filterGroup, index) {
  if (filter == null) {
    return [];
  }

  if (filterGroup) {
    filter = filter[key];
    if (filter == null) {
      return [];
    }
  }

  if (Array.isArray(filter)) {
    return SelectMany(
      filter.map((f, i) => defaultTokeniser(f, key, metaData, filterGroup, i)),
      x => x
    );
  } else if (typeof filter === 'object') {
    return [];
  } else if (isValidDayjs(filter)) {
    return [
      {
        value: dayjs(filter).format('YYYY-MM-DD'),
        key,
        index,
        metaData,
        filterGroup,
      },
    ];
  } else if (typeof filter === 'number') {
    return [
      {
        key,
        value: formatNumber(filter),
        index,
        metaData,
        filterGroup,
      },
    ];
  } else if (typeof filter === 'boolean') {
    return metaData.title
      ? [
          {
            key,
            value: metaData.title,
            metaData,
            filterGroup,
          },
        ]
      : [];
  }
  return [
    {
      key,
      value: filter,
      index,
      metaData,
      filterGroup,
    },
  ];
}

export const mainSearchExcludedFilters = [
  'term',
  'date',
  'category',
  'organisationCategory',
  'latestFundingRoundsOnly',
];

export const nonEditablemainSearchExcludedFilters = [
  'term',
  'date',
  'organisationCategory',
  'latestFundingRoundsOnly',
  'lists',
  'excludeLists',
  'organisationLists',
  'organisationExcludeLists',
];

export const nonEditableAlertSearchExcludedFilters = [
  'term',
  'latestFundingRoundsOnly',
  'lists',
  'excludeLists',
  'organisationLists',
  'organisationExcludeLists',
];

export const nonEditableExportHistorySearchExcludedFilters = [
  'term',
  'latestFundingRoundsOnly',
];

/**
 * @param {string} key
 * @returns {(filter:any, key: string, metaData: import("actions/search").FilterMetaData, filterGroup?: string) => FilterToken[]}
 */
function getTokeniser(filters, key) {
  if (key === 'phaseStartDate' && !filters.hasOwnProperty('phase')) {
    return () => [];
  }

  let tokeniser = tokenisers[key];
  if (!tokeniser) {
    tokeniser = defaultTokeniser;
  }

  return tokeniser;
}

const dateRange =
  /**
   * @param {string[]} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) => [
    {
      key,
      value: filter.map(date => dayjs(date).format('YYYY-MM-DD')).join(' TO '),
      metaData,
    },
  ];

const displayTitle = (filter, key, metaData) => [
  {
    key,
    value: metaData.title,
    metaData,
  },
];

const superOrganisationFoundedYear =
  /**
   * @param {number} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) => [
    {
      key,
      value:
        organisationFoundationYear.find(x => x.value === filter)?.label || '',
      metaData,
    },
  ];

const commonDateSelection =
  /**
   * @param {string} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) =>
    filter === 'custom' || filter === 'all'
      ? []
      : [
          {
            key,
            value: commonDateRanges.find(x => x.value === filter)?.label || '',
            metaData,
          },
        ];

const dateSortSelection =
  /**
   * @param {string | string[]} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) =>
    Array.isArray(filter)
      ? [
          {
            key,
            value: filter
              ?.map(date => dayjs(date).format('YYYY-MM-DD'))
              .join(' TO '),
            metaData,
          },
        ]
      : filter === 'custom'
      ? []
      : [
          {
            key,
            value: commonDateRanges.find(x => x.value === filter)?.label || '',
            metaData,
          },
        ];

const tradeshow =
  /**
   * @param {{label: string}[]} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) =>
    filter.map((f, i) => ({
      key,
      value: f.label,
      metaData,
      index: i,
    }));

const lists =
  /**
   * @param {{name: string}[]} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) =>
    filter.map((f, i) => ({
      key,
      value: f.name,
      metaData,
      index: i,
      color: ['excludeLists', 'organisationExcludeLists'].includes(key)
        ? negativeTokenColor
        : undefined,
    }));

const states =
  /**
   * @param {import("models/filters").StateFilter[]} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) =>
    filter.map((f, i) => ({
      key,
      value: f.displayName,
      metaData,
      index: i,
    }));

const journalsAndPreprints =
  /**
   * @param {string[]} filter
   * @param {string} key
   * @param {import("actions/search").FilterMetaData} metaData
   * @returns {FilterToken[]}
   */
  (filter, key, metaData) =>
    filter.map((f, i) => ({
      key,
      value: f,
      metaData,
      index: i,
    }));

/**
 * @type {{[key: string]: (filter:any, key: string, metaData: import("actions/search").FilterMetaData) => FilterToken[]}}
 */
const tokenisers = {
  date: dateSortSelection,
  dateAwarded: dateRange,
  phaseStartDate: dateRange,
  superOrganisationFoundedYear,
  announcedDateSelection: commonDateSelection,
  announcedDatePicked: dateRange,
  tradeshow,
  startDate: dateRange,
  lists,
  excludeLists: lists,
  organisationLists: lists,
  organisationExcludeLists: lists,
  acquisitionDateSelection: commonDateSelection,
  acquisitionDatePicked: dateRange,
  state: states,
  purchaserState: states,
  journal: journalsAndPreprints,
  sponsorSuperOrgId: displayTitle,
};
