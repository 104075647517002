import { Alert } from 'antd';
import { Button, Icon, Tooltip } from 'components/common';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const AlertDescription = styled.div`
  display: flex;
  width: 100%;
`;

export const PanelButton = styled(Button)`
  margin-right: 5px;
`;

export const BorderDiv = styled.div`
  margin-right: 5px;
  margin-top: 1px;
  border: 1px solid #d9d9d9;
  height: 22px;
  width: 25px;
  justify-content: center;
  background-color: white;
  align-items: center;
  display: flex;
`;

function ClauseLimitErrorMessage({ searchTermRef }) {
  const [maxWidth, setMaxWidth] = useState('100%');

  useEffect(() => {
    if (searchTermRef && searchTermRef.current) {
      if (searchTermRef?.current) {
        setMaxWidth(searchTermRef.current.offsetWidth);
        const handleResize = debounce(() => {
          setMaxWidth(searchTermRef.current.offsetWidth);
        }, 100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }
  }, [searchTermRef]);

  const getTitle = () => {
    return <b style={{ minWidth: '90px' }}>Search Limit!</b>;
  };
  const getTooltip = () => {
    return 'Your search is too complex to process. Please simplify it by using fewer search terms, combining similar words with quotes, or reducing the number of OR conditions. For example, try using "project management" instead of project OR management.';
  };

  return (
    <div style={{ marginTop: 5, maxWidth: maxWidth + 'px' }}>
      <div>
        <Alert
          style={{ height: '100%', paddingBottom: '7px', paddingTop: '7px' }}
          description={
            <AlertDescription>
              {getTitle()}
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', overflowX: 'auto' }}>
                  Search contains too many conditions - please simplify your
                  query
                </div>
              </div>
            </AlertDescription>
          }
          type='warning'
          action={
            <div
              style={{
                display: 'flex',
                marginLeft: '90px',
              }}
            >
              <Tooltip
                placement='bottom'
                title={getTooltip()}
                overlayStyle={{ maxWidth: 300 }}
              >
                <BorderDiv>
                  <Icon size='sm' icon='help' color='black'></Icon>
                </BorderDiv>
              </Tooltip>
            </div>
          }
        ></Alert>
      </div>
    </div>
  );
}

ClauseLimitErrorMessage.propTypes = {};

export default ClauseLimitErrorMessage;
