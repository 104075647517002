import styled from 'styled-components';

import { colours } from 'utils/theme';

import Tooltip from './Tooltip';
import Icon from './Icon';

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AiIcon = ({ children }) => (
  <Tooltip title={children} placement='right'>
    <IconContainer>
      <Icon icon='sparkles' color={colours.linkBlue} />
    </IconContainer>
  </Tooltip>
);

export default AiIcon;
