import { useEffect, useState } from 'react';
import { Modal, notification } from 'antd';

import { Input, Detail } from 'components/common';
import { usePut, useDel } from 'hooks';
import { userKeywords } from 'services/api';
import { showWarning, Alert, errors } from './helpers';

export const EditModal = ({ activeKeyword, onCloseModal, onSuccess }) => {
  const [{ res, loading, error }, putKeyword] = usePut({ url: userKeywords });
  const [keyword, setKeyword] = useState(activeKeyword.keyword);

  useEffect(() => {
    if (res) {
      onSuccess();
      notification.open({
        message: 'Keyword Updated',
        description: (
          <div>
            Successfully set keyword to <b>"{keyword}"</b>.
          </div>
        ),
        placement: 'bottomLeft',
      });
    }
  }, [res, onSuccess, keyword]);

  const handleOk = () => {
    if (keyword.trim() === activeKeyword.keyword.trim()) {
      return onCloseModal();
    }
    putKeyword({ id: activeKeyword.id, keyword: keyword.trim() });
  };

  return (
    <Modal
      open
      title='Edit Keyword'
      onOk={handleOk}
      onCancel={onCloseModal}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Input
        placeholder='Keyword'
        disabled={loading}
        value={keyword}
        onChange={setKeyword}
      />
      {error && errors[error.status]}
      {showWarning(keyword) && <Alert />}
    </Modal>
  );
};

export const DeleteModal = ({ activeKeyword, onCloseModal, onSuccess }) => {
  const [{ res, loading }, deleteKeyword] = useDel({ url: userKeywords });

  useEffect(() => {
    if (res) {
      onSuccess();
      notification.open({
        message: 'Keyword Deleted',
        description: (
          <div>
            Successfully removed <b>"{activeKeyword.keyword}"</b> from your list
            of keywords.
          </div>
        ),
        placement: 'bottomLeft',
      });
    }
  }, [res, onSuccess, activeKeyword]);

  const handleOk = () => {
    deleteKeyword({ query: { id: activeKeyword.id } });
  };

  return (
    <Modal
      open
      title='Delete Keyword'
      onOk={handleOk}
      onCancel={onCloseModal}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      Are you sure you want to delete this keyword?
      <Detail style={{ marginTop: 15 }} label='Keyword'>
        {activeKeyword.keyword}
      </Detail>
    </Modal>
  );
};

export const modals = {
  edit: EditModal,
  delete: DeleteModal,
};
