import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { getSearchView } from 'selectors/search';

import Visualisations from './Visualisations';
import { Researcher, Organisation, Activity } from './Result';
import { ResultsContainer } from './styled';
import Map from './Map';
import { view as viewConstants } from './constants';
import { ActivitySearch, getSearchOrigin, IsMap } from './helpers';

const List = ({ data, loading, onMapError, ...rest }) => {
  const view = useSelector(getSearchView);
  const parentRef = useRef(null);

  if (!data.groupedResults) return null;

  if (
    view !== viewConstants.researcher &&
    view !== viewConstants.organisation &&
    view !== viewConstants.activity &&
    loading
  )
    return null;

  if (
    view === viewConstants.visualisation ||
    view === viewConstants.organisationInsights
  ) {
    return <Visualisations />;
  }

  if (IsMap(view)) {
    return <Map onError={onMapError} />;
  }

  const searchOrigin = getSearchOrigin(view);
  let Result;
  if (searchOrigin === 'ResearcherSearch') {
    Result = Researcher;
  } else if (searchOrigin === 'OrganisationSearch') {
    Result = Organisation;
  } else if (searchOrigin === 'ActivitySearch') {
    Result = Activity;
  }

  const memberIdKey =
    view === viewConstants.researcher ? 'researcherId' : 'superOrganisationId';
  const { listGroupByMembers } = rest;

  return (
    <ResultsContainer ref={parentRef}>
      {data.groupedResults.map((r, i) => (
        <Result
          data-testid={`search-result-${i}`}
          key={`${r.researcherId}-${i}`}
          listAssociatedByMember={
            !ActivitySearch(view) && listGroupByMembers
              ? listGroupByMembers[r[memberIdKey]]
              : []
          }
          memberId={r[memberIdKey]}
          parentRef={parentRef}
          {...rest}
          {...r}
        />
      ))}
    </ResultsContainer>
  );
};

export default List;
