import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';

import {
  Detail as CommonDetail,
  Pane as CommonPane,
  Table as CommonTable,
} from 'components/common';

export const DetailContainer = styled.div`
  display: flex;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const Detail = styled(CommonDetail)`
  margin-bottom: 10px;
  line-height: 1.4em;
  font-size: 14px;
`;

export const DetailColumn = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  margin-right: 15px;
  :last-child {
    margin-right: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${p => p.theme.gutter};
`;
export const Row = styled.div`
  display: flex;
  > div {
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: ${p => p.theme.gutter};
  :last-child {
    margin-right: 0px;
  }
`;

export const RelatedColumn = styled(Column)`
  flex: 0 1 25%;
`;

export const StatsColumn = styled(Column)`
  flex: 0 1 25%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${p => p.theme.gutter};
  grid-column-gap: ${p => p.theme.gutter};
  margin-bottom: ${p => p.theme.gutter};
`;

export const Pane = styled(CommonPane)`
  margin-bottom: ${p => p.theme.gutter};
  .content {
    font-size: 14px;
  }
`;

export const Alert = styled(AntAlert)`
  margin-top: 15px;
  font-size: 13px;
`;

export const Keyword = styled.div`
  display: inline-block;
  background: ${p => p.theme.teal};
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  color: white;
`;

export const Table = styled(CommonTable)`
  margin-top: 15px;
`;
