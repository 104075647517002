import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import { useAuth, usePost } from 'hooks';
import { Button, NetPromoterScore } from 'components/common';
import { largeWarning, exportSearch } from 'services/api';
import {
  getSearchRequest,
  getHasResults,
  getLastSuccessfulSearch,
  getSearchView,
  getBlockedForExport,
  isPdfExportRunning,
  getFilters,
} from 'selectors/search';

import { setPdfExportStatus } from 'actions/search';
import { DisabledButtonWrapper } from 'components/common/styled.js';
import Export from 'components/Export';
import { downloadType } from 'components/Export/Summary/constants';
import ListAction from './List';
import { ActionsContainer } from '../styled';
import {
  handleExportNotification,
  handleFailedExportNotification,
} from './notifications';
import { searchOrigin, view as viewConstants } from '../constants';
import { OrganisationSearch, ResearcherSearch } from '../helpers';
import { RemoveNonEmptyDate } from 'components/Search/Filters/keys';
import { hasFullLicense } from 'utils/helpers/license';
import {
  ewrcaDescription,
  ewrcaOrganisationDescription,
} from 'reducers/notifications';

const Actions = props => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [canUseNps, setCanUseNps] = useState(false);
  const [{ res, loading, error }, postExport] = usePost({});
  const { res: searchRes, loading: searching } = useSelector(getSearchRequest);
  const hasResults = useSelector(getHasResults);
  const isPdfExportStarted = useSelector(isPdfExportRunning);
  const lastSuccessfulSearch = useSelector(getLastSuccessfulSearch);
  const view = useSelector(getSearchView);
  const blockedForExport = useSelector(getBlockedForExport);
  const allFilters = useSelector(getFilters);
  const searchResRef = useRef({});

  const tmpFilters = { ...allFilters };
  RemoveNonEmptyDate(tmpFilters);

  const handleOpenNps = () => {
    setCanUseNps(true);
  };

  const handleDismissNps = () => {
    setCanUseNps(false);
  };

  const handleExport = additionalParams => {
    searchResRef.current = searchRes;
    const { largeExport, organisationOnlyExportEwrca } = searchRes;
    const {
      query,
      lists,
      excludeLists,
      nestedQueries,
      organisationLists,
      organisationExcludeLists,
      excludeRecentlyExportedEmails,
    } = lastSuccessfulSearch;

    let ewrca = searchRes.ewrca;
    if (additionalParams.isOrganisationOnlyExport) {
      ewrca = organisationOnlyExportEwrca;
    }

    postExport({
      url: ewrca ? largeWarning('UniversalSearch') : exportSearch,
      body: {
        query,
        orderBy: 'desc',
        largeExport,
        filters: {
          lists,
          excludeLists,
          organisationLists,
          organisationExcludeLists,
          excludeRecentlyExportedEmails,
        },
        isOrganisationExport: OrganisationSearch(view),
        structuredSearch: JSON.stringify(tmpFilters),
        nestedQueries,
        searchOrigin: !ResearcherSearch(view)
          ? searchOrigin.organisationSearch
          : searchOrigin.researcherSearch,
        ...additionalParams,
      },
    });
  };

  useEffect(() => {
    if (res) {
      const { largeExport, profileEmailCount } = searchResRef.current;
      let ewrca = searchResRef.current.ewrca;
      if (res.isOrganisationOnlyExport)
        ewrca = searchResRef.current.organisationOnlyExportEwrca;

      handleExportNotification(
        ewrca,
        largeExport,
        handleOpenNps,
        profileEmailCount > 0
      );
    }
  }, [res]);

  useEffect(() => {
    if (error) {
      handleFailedExportNotification(error.status);
    }
  }, [error]);

  const handlePdfExport = () => {
    dispatch(setPdfExportStatus(true));
  };

  const exportButton = (
    <Export
      disabled={
        loading ||
        searching ||
        !lastSuccessfulSearch.query ||
        !hasResults ||
        blockedForExport ||
        searchRes.ewrca
      }
      ewrca={!blockedForExport && searchRes.ewrca}
      downloadType={
        !ResearcherSearch(view)
          ? downloadType.organisationSearch
          : downloadType.researcherSearch
      }
      onExport={handleExport}
      style={{ marginTop: '2px' }}
      ewrcaTooltip={ewrcaDescription}
    >
      Export Data
    </Export>
  );

  const exportOrganisationsButton = (
    <Export
      style={{ marginLeft: 5 }}
      onExport={params =>
        handleExport({ ...params, isOrganisationOnlyExport: true })
      }
      disabled={
        loading ||
        searching ||
        !lastSuccessfulSearch.query ||
        !hasResults ||
        blockedForExport ||
        searchRes.organisationOnlyExportEwrca
      }
      downloadType={downloadType.organisationOnlyExport}
      ewrca={!blockedForExport && searchRes.organisationOnlyExportEwrca}
      canUseCredits={false}
      ewrcaTooltip={ewrcaOrganisationDescription}
    >
      Export Organisations
    </Export>
  );

  const pdfExportButton = (
    <Button
      style={{ marginLeft: 5 }}
      size='small'
      loading={isPdfExportStarted}
      onClick={handlePdfExport}
      disabled={
        !hasFullLicense(user) ||
        loading ||
        searching ||
        !lastSuccessfulSearch.query ||
        (!hasResults && hasResults) ||
        blockedForExport ||
        isPdfExportStarted
      }
    >
      {isPdfExportStarted ? ' Generating PDF...' : 'Export PDF'}
    </Button>
  );

  const canExport = !user.isRateLimited && !user.disableExport;

  return (
    <ActionsContainer>
      <ListAction {...props} />
      {canExport &&
        (blockedForExport ? (
          <div className='exportOptions'>
            <Tooltip
              placement='topRight'
              title='You need to update the search to reactivate export'
            >
              <DisabledButtonWrapper>{exportButton}</DisabledButtonWrapper>
            </Tooltip>
            {view === viewConstants.organisation && (
              <Tooltip
                placement='topRight'
                title='You need to update the search to reactivate export'
              >
                <DisabledButtonWrapper>
                  {exportOrganisationsButton}
                </DisabledButtonWrapper>
              </Tooltip>
            )}
          </div>
        ) : (
          <div className='exportOptions'>
            {exportButton}
            {view === viewConstants.organisation && exportOrganisationsButton}
            {(view === viewConstants.visualisation ||
              view === viewConstants.organisationInsights) &&
              pdfExportButton}
          </div>
        ))}
      {canUseNps && <NetPromoterScore onDismiss={handleDismissNps} />}
    </ActionsContainer>
  );
};

export default Actions;
