import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  background-size: cover;
  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100% !important;
  }
  .slick-slide > div:first-child {
    height: 100% !important;
  }
  .slide {
    height: 100%
  }
  .ant-carousel .slick-dots li {
    width: 8px;
    height: 8px;
    button {
      background: #C4C4C4;
      height: 8px;
      border-radius: 8px;
    }
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #000000;
  }
}
`;

export const maxWidth = 428;

export const ImageContainer = styled.div`
  max-width: ${maxWidth}px;
  max-height: 280px;
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const QuoteContainer = styled.div`
  position: relative;
`;

export const QuoteContentContainer = styled.div`
  display: flex;
  gap: 22px;
  margin-top: 40px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 100;
  .quote {
    font-weight: 500;
    font-size: 22px;
    max-width: ${maxWidth}px;
  }
`;

export const CirlceContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: -20px;
  right: -50px;
`;

export const LogoInitialsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const LogoContainer = styled.div`
  height: 40px;
  display: flex;
  .logo {
    background-size: cover;
    height: 40px;
  }
`;

export const InitialsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  font-size: 16px;
  .position {
    font-weight: 400;
  }
`;

export const TitleContainer = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-top: 70px;
  max-width: ${maxWidth}px;
`;

export const SubTitleContainer = styled.div`
  font-weight: 500;
  font-size: 16px;
  max-width: ${maxWidth}px;
  margin-top: 20px;
  text-align: center;
`;
