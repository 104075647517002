import React from 'react';
import { Icon } from 'components/common';

import styled from 'styled-components';
import TablePopover from 'components/common/TablePopover';

const BorderDiv = styled.div`
  border: 2px solid ${p => p.color};
  border-radius: ${p => (p.radius ? p.radius : '100%')};
  height: 23px;
  width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function RebrandIcon({ previousName, size = 'xs', parentRef, style = {} }) {
  if (!previousName) {
    return null;
  }

  return (
    <div style={style}>
      <TablePopover
        content={
          <div style={{ marginLeft: '-12px' }}>
            <Icon
              size='1x'
              icon='certificate'
              color='#595959'
              style={{ marginRight: '5px' }}
            />
            Previously called {previousName}
          </div>
        }
        parentRef={parentRef}
        widthRatio={0.7}
        style={{
          color: '#d9d9d9',
          borderRadius: '13px',
        }}
      >
        <BorderDiv color='#d9d9d9'>
          <Icon size={size} icon='certificate' color='#595959' />
        </BorderDiv>
      </TablePopover>
    </div>
  );
}

RebrandIcon.propTypes = {};

export default RebrandIcon;
