import styled from 'styled-components';
import { geoArea } from '../constants';

export const MapInnerContainer = styled.div`
  position: relative;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
  }
  button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .mapboxgl-popup-tip {
    border-top-color: rgba(0, 0, 0, 0.8);
  }
  .mapboxgl-popup-content {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-weight: 500;
  }
`;

export const PopupRow = styled.div`
  display: table-row;
  .cell {
    text-align: left;
    display: table-cell;
    padding-right: 5px;
  }
  .title {
    font-weight: 700;
  }
  .cell:nth-of-type(2) {
    text-align: right;
    padding-right: 0px;
  }
`;

export const MarkerContainer = styled.div`
  font: 500 11px Roboto;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  background-color: #f1f1f1;
  border: 2px solid
    ${props =>
      props.type === geoArea.country || props.type === geoArea.state
        ? '#B0DFE0'
        : '#ffc0ab'};
  border-radius: 6px;
  svg {
    margin-right: 4px;
    color: #595959;
  }
  :hover {
    color: #fff;
    border: 2px solid #f1f1f1;
    background-color: #448ef7;
    svg {
      color: #fff;
    }
  }
`;
