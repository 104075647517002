import { useDispatch } from 'react-redux';

import { siNumber } from 'utils/number';
import { appendFilter } from 'actions/search';

import GraphBase from './GraphBase';

const States = ({ data, label, helpText }) => {
  const dispatch = useDispatch();
  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function(value) {
              return siNumber(value, 1);
            },
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return siNumber(tooltipItem.xLabel);
        },
      },
    },
  };

  const handleClick = (label, value, index) => {
    dispatch(appendFilter('state', data.states[index]));
  };

  if (!data) return null;
  return (
    <GraphBase
      type='horizontalBar'
      label={label}
      data={data}
      options={options}
      onClick={handleClick}
      helpText={helpText}
    />
  );
};

export default States;
