import React from 'react';

import Detail from '../Detail';
import Input from '../Input';
import CharacterLimitReached from './CharacterLimitReached';

const ListDescriptionInput = ({ value, onChange }) => {
  const maxLength = 450;
  const listDescriptionReached = value && value.length >= maxLength;
  return (
    <Detail label='Description (optional)'>
      <>
        <Input
          showCount
          multiline
          rows={4}
          placeholder='List Description'
          value={value}
          onChange={onChange}
          data-testid='list-description-input'
          maxLength={maxLength}
          status={listDescriptionReached ? 'error' : ''}
        />
        {listDescriptionReached && <CharacterLimitReached />}
      </>
    </Detail>
  );
};

export default ListDescriptionInput;
