import { Fragment } from 'react';
import { Checkbox, Link, ResearcherEmail } from 'components/common';
import { format } from 'utils/date';

import CRMStatus from './CRMStatus';

const getConfig = (
  user,
  selectedMembers,
  someSelected,
  handleToggleAll,
  handleToggleMember,
  canVisitOrgPage
) => {
  const config = {
    headers: [
      {
        testId: 'checkbox',
        label: (
          <Checkbox
            checked={selectedMembers.all}
            indeterminate={someSelected}
            onClick={handleToggleAll}
          />
        ),
        minWidth: 20,
      },
      { label: 'Researcher', key: 'name', minWidth: 100, maxContentWidth: 250 },
      {
        label: 'Organisation',
        key: 'organisationname',
        minWidth: 150,
        maxContentWidth: 300,
      },
      { label: 'Email', key: 'email', minWidth: 120, maxContentWidth: 250 },
      { label: 'Added On', key: 'dateAddedUtc', minWidth: 100 },
      { label: 'Added By', key: 'addedbyuser', minWidth: 100 },
      { label: 'CRM Status', key: 'crmstatus', minWidth: 100 },
    ],
    createRow: (r, i) => (
      <Fragment key={`${r.name}-${i}`}>
        <div>
          <Checkbox
            checked={
              !selectedMembers.exclude[r.researcherId] &&
              (selectedMembers.include[r.researcherId] || selectedMembers.all)
            }
            onClick={() => handleToggleMember(r.researcherId)}
          />
        </div>
        <div>
          {!r.profileOnly && (
            <div>
              <Link to={`/researcher/${r.researcherId}`}>{r.name}</Link>
            </div>
          )}
          {r.profileOnly && (
            <div>
              <span>{r.name}</span>
            </div>
          )}
        </div>
        <div>
          {r.organisationId > 0 && canVisitOrgPage && (
            <div>
              <Link to={`/organisation/${r.organisationId}`}>
                {r.organisationName}
              </Link>
            </div>
          )}
          {(r.organisationId === 0 || !canVisitOrgPage) && (
            <div>
              <span>{r.organisationName}</span>
            </div>
          )}
        </div>
        {!user.hideResearcherEmail && (
          <div>
            <ResearcherEmail
              email={r.email}
              emailStatusCategory={r.emailVerificationCategory}
              hideIcon
            />
          </div>
        )}
        <div>{format(r.dateAddedUtc)}</div>
        <div>{r.addedByUser || 'Deleted User'}</div>
        <div>
          <CRMStatus crmPushDetails={r.crmPushDetails} />
        </div>
      </Fragment>
    ),
  };

  if (user.hideResearcherEmail)
    config.headers = config.headers.filter(p => p.key !== 'email');

  return config;
};

export default getConfig;
