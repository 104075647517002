import styled from 'styled-components';

import { TokenInput as CommonTokenInput } from 'components/common';

export const TokenInput = styled(CommonTokenInput)`
  flex: 1;
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
