import { Link } from 'components/common';

import { FullText } from './styled';

const UPWLink = ({ fullTextArticle }) => {
  if (fullTextArticle?.upwPdfUrl)
    return (
      <FullText data-testid='upw-full-text'>
        <div>
          Below you will see the abstract for this published paper. We have also
          made the remaining full text searchable (excluding citations) but it
          is not displayed. This means that if you found this paper via a search
          you may not see your keyword on this page. Instead you can visit the
          full text by clicking&nbsp;
          <Link
            data-testid='upw-link'
            inline
            external
            to={fullTextArticle.upwPdfUrl}
          >
            here
          </Link>
        </div>
      </FullText>
    );

  return null;
};

export default UPWLink;
