import React from 'react';
import { notification } from 'antd';
import AlertFailureContent from './AlertFailureContent';
import { views } from '../constants';

const NotificationFailure = (
  message,
  isBulkListInsert,
  type = views.researchers
) => {
  let body;
  try {
    body = JSON.parse(message);
  } catch {}
  if (!body) {
    return;
  }

  notification.open({
    message: 'Invalid List Operation',
    description: (
      <AlertFailureContent
        message={message}
        isBulkManage={isBulkListInsert}
        type={type}
      />
    ),
    placement: 'bottomLeft',
  });
};

export default NotificationFailure;
