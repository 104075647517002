import { useEffect } from 'react';
import { Modal as AntModal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { usePost } from 'hooks';
import { Detail } from 'components/common';
import { unfollowOrganisation, unfollowResearcher } from 'services/api';
import {
  getOrganisationToUnfollow,
  getResearcherToUnfollow,
} from 'selectors/alerts';

const UnfollowModal = ({ onUnfollowSuccess, onUnfollowCancel }) => {
  const dispatch = useDispatch();
  const researcherToUnfollow = useSelector(getResearcherToUnfollow);
  const organisationToUnfollow = useSelector(getOrganisationToUnfollow);

  const [{ res: success, loading, error }, postUnfollow] = usePost({
    url: unfollowResearcher,
  });

  const [
    {
      res: successOrganisationUnfollow,
      loadingOrganisation,
      errorOrganisation,
    },
    postUnfollowOrganisation,
  ] = usePost({
    url: unfollowOrganisation,
  });

  useEffect(() => {
    if (success || error?.status === 410) {
      onUnfollowSuccess();
    }
  }, [success, onUnfollowSuccess, dispatch, error]);

  useEffect(() => {
    if (successOrganisationUnfollow || errorOrganisation?.status === 410) {
      onUnfollowSuccess();
    }
  }, [
    successOrganisationUnfollow,
    onUnfollowSuccess,
    dispatch,
    errorOrganisation,
  ]);

  const handleUnfollow = () => {
    if (researcherToUnfollow)
      postUnfollow({ researcherId: researcherToUnfollow.superResearcherId });
    if (organisationToUnfollow)
      postUnfollowOrganisation({
        organisationId: organisationToUnfollow.organisationId,
        organisationType: organisationToUnfollow.organisationType,
      });
  };

  const handleCancel = () => {
    onUnfollowCancel();
  };

  if (!researcherToUnfollow && !organisationToUnfollow) return null;

  const isResearcher = !!researcherToUnfollow;

  return (
    <AntModal
      visible
      title={`Unfollow ${isResearcher ? 'Researcher' : 'Organisation'}`}
      onCancel={handleCancel}
      onOk={handleUnfollow}
      okButtonProps={{
        disabled: loading || loadingOrganisation,
        'data-testid': 'unfollow-ok-button',
      }}
      cancelButtonProps={{
        disabled: loading,
        'data-testid': 'unfollow-cancel-button',
      }}
    >
      {`Do you want to unfollow this ${
        isResearcher ? 'Researcher' : 'Organisation'
      }?`}
      {isResearcher && (
        <Detail style={{ marginTop: 10 }} label='Researcher'>
          {researcherToUnfollow.name}
        </Detail>
      )}
      {!isResearcher && (
        <Detail style={{ marginTop: 10 }} label='Organisation'>
          {organisationToUnfollow.organisationName}
        </Detail>
      )}
    </AntModal>
  );
};

export default UnfollowModal;
