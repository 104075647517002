import { useEffect } from 'react';
import { Alert, Modal as AntModal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { useDel, useAuth } from 'hooks';
import { Detail, Icon } from 'components/common';
import { userAlerts } from 'services/api';
import { getAlertToDelete } from 'selectors/alerts';
import { setAlertToDelete } from 'actions/alerts';
import { frequencyOptions } from 'models/frequency';
import { SharedAlertWarning } from '../styled';

const DeleteModal = ({ onDeleteSuccess }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const alertToDelete = useSelector(getAlertToDelete);

  const [{ res: success, loading, error }, deleteAlert] = useDel({
    url: userAlerts,
  });

  useEffect(() => {
    if (success) {
      onDeleteSuccess();
    }
  }, [success, onDeleteSuccess, dispatch]);

  const handleDelete = () => {
    deleteAlert({
      query: {
        UserId: user.userId,
        id: alertToDelete.id,
      },
    });
  };

  const handleCancel = () => {
    dispatch(setAlertToDelete());
  };

  if (!alertToDelete) return null;

  const frequency =
    (alertToDelete.frequency &&
      frequencyOptions.find(op => op.enumValue === alertToDelete.frequency)
        .value) ||
    'No Alert';

  const sharedUsers = alertToDelete.sharedWith.concat(
    alertToDelete.sharedWithInactive
  );

  return (
    <AntModal
      open
      title='Delete Search'
      onCancel={handleCancel}
      onOk={handleDelete}
      okButtonProps={{ disabled: loading, 'data-testid': 'delete-ok-button' }}
      cancelButtonProps={{
        disabled: loading,
        'data-testid': 'delete-cancel-button',
      }}
    >
      Do you want to delete this search?
      <Detail label='Name'>{alertToDelete.name}</Detail>
      <Detail label='Alert Frequency'>{frequency}</Detail>
      {sharedUsers.length > 0 && (
        <Detail label='Shared With'>
          {sharedUsers.map(u => u.userName).join(', ')}
        </Detail>
      )}
      {sharedUsers.length > 0 && (
        <SharedAlertWarning style={{ padding: '6px 16px' }}>
          <Icon icon='circleInfo' color='#FAAD14' size='1x' />
          Deleting a shared alert will also delete it for all shared users
        </SharedAlertWarning>
      )}
      {error && <Alert message={error.statusText} type='error' />}
    </AntModal>
  );
};

export default DeleteModal;
