import React, { useCallback, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Modal } from 'antd';
import { getNeedPassCaptcha, getRestoringState } from 'selectors/rateLimits';
import { setNeedPassCaptcha } from 'actions/rateLimits';
import { usePost } from 'hooks';
import { resetRateLimits } from 'services/api';
import { FullPageLoader, Logo } from 'components/common';
import { Container } from './styled';

const Captcha = ({ children }) => {
  const [{ res, error }, resetRateLimitsPost] = usePost({
    url: resetRateLimits,
  });

  const dispatch = useDispatch();
  const restoringRateLimitsState = useSelector(getRestoringState);
  const needPassCaptcha = useSelector(getNeedPassCaptcha);

  useEffect(() => {
    if (res) {
      dispatch(setNeedPassCaptcha(false));
      setTimeout(() => window.location.reload(), 50);
    }
  }, [dispatch, res]);

  useEffect(() => {
    if (error) {
    }
  }, [error]);

  const handleCaptchaChange = useCallback(
    token => {
      if (token) {
        resetRateLimitsPost({
          body: {
            token,
          },
        });
      }
    },
    [resetRateLimitsPost]
  );

  const openChat = useCallback(() => {
    const { fcWidget } = window;
    if (!fcWidget?.isOpen()) {
      fcWidget?.open();
    }
  }, []);

  const showLoader = restoringRateLimitsState;
  if (showLoader) return <FullPageLoader />;

  const emptyLink = null;
  return (
    <>
      {children}
      {needPassCaptcha && (
        <Modal
          open
          title={null}
          closable={false}
          footer={null}
          onCancel={() => null}
          getContainer={false}
          centered
          width={360}
        >
          <Container>
            <Logo />
            <ReCAPTCHA
              sitekey={
                process.env.REACT_APP_MAP_BOX_API_KEY ||
                '6Le1n2opAAAAAL64yJU_jg9Oa2McOa8bfqmJNr4_'
              }
              onChange={handleCaptchaChange}
            />
            <Alert
              type='error'
              style={{ width: '302px', marginTop: '10px' }}
              message={
                <span>
                  Looks like you're blazing through our platform at the speed of
                  light!&nbsp;
                  <a
                    href={emptyLink}
                    rel='noopener noreferrer'
                    onClick={openChat}
                  >
                    Click here to learn more
                  </a>
                </span>
              }
            />
          </Container>
        </Modal>
      )}
    </>
  );
};

Captcha.propTypes = {};

export default Captcha;
