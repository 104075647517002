import { ThemeProvider } from 'styled-components';

import { useWindowWidth, useDarkMode } from 'hooks';
import theme, { GlobalStyle } from 'utils/theme';

const Theme = ({ children }) => {
  const { width } = useWindowWidth();
  const [darkMode] = useDarkMode();

  return (
    <>
      <GlobalStyle darkMode={darkMode} />
      <ThemeProvider theme={theme(width, darkMode)}>{children}</ThemeProvider>
    </>
  );
};

export default Theme;
