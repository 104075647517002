import { Loader } from 'components/common';

import Details from './Details';
import Identity from './Identity';
import SocialMediaLinks from './SocialMediaLinks';
import Actions from './Actions';

import { Container } from './styled';

const Profile = ({ researcher, loading, fetchResearcher }) => {
  if (loading) return <Loader />;

  return (
    <Container>
      <Identity researcher={researcher} />
      <Details researcher={researcher} />
      <SocialMediaLinks researcher={researcher} />
      <Actions researcher={researcher} />
    </Container>
  );
};

export default Profile;
