import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Icon } from 'components/common';
import Copy from 'components/common/Copy';
import { colours } from 'utils/theme';
import { emailRequestStatus } from './models';

const pendingVerification = 'Pending Verification';
const badEmailError = 'This email failed to pass our verification process';
export const Status = ({ status, email }) => {
  if (status === emailRequestStatus.InProgress) {
    return <>Research in progress</>;
  }

  if (status === emailRequestStatus.NotFound) {
    return <>A valid email address cannot be found</>;
  }

  if (
    status === emailRequestStatus.BadEmail ||
    status === emailRequestStatus.PendingVerification
  ) {
    return (
      <>
        Email found:
        <Copy value={email} style={{ marginLeft: 5, marginRight: 5 }} />
        <Tooltip
          title={
            status === emailRequestStatus.BadEmail
              ? badEmailError
              : pendingVerification
          }
          placement='right'
        >
          <div style={{ cursor: 'pointer', marginTop: -2 }}>
            <Icon icon='help' color={colours.teal} size='1x' />
          </div>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      Email found:
      <Copy value={email} style={{ marginLeft: 5 }} />
    </>
  );
};

Status.propTypes = {
  status: PropTypes.number.isRequired,
  email: PropTypes.string,
};

Status.defaultProps = {
  email: '',
};
