import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from 'reducers';

const configureStore = () => {
  const withMiddleware = applyMiddleware(thunk);
  const composedEnhancers = composeWithDevTools(withMiddleware);
  const store = createStore(rootReducer, composedEnhancers);

  return store;
};

export default configureStore();
