export const defaultError = 'Invalid UserName or Password';
const notActiveError = 'Account is not active';
const lockedError = 'User Account is still temporarily locked';
const notApprovedError = 'Account is not approved';
const noLicenseError = `Account does not have a license`;
export const errors = {
  401: 'Email Address or Password Incorrect',
  403: lockedError,
  410: 'License has expired',
  412: notActiveError,
};

export const getPreConditionError = loginError => {
  if (loginError.message.includes('Account Inactive')) {
    return notActiveError;
  }

  if (loginError.message.includes('locked')) {
    return lockedError;
  }

  if (loginError.message.includes('Your account has not yet been approved')) {
    return notApprovedError;
  }

  if (loginError.message.includes('No License')) {
    return noLicenseError;
  }

  return defaultError;
};
