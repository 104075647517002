import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatNumber } from 'utils/number';
import { Icon, Tooltip } from 'components/common';

import { getCreditsString } from './helpers';
import { Divider } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Stat = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  font-weight: 400;
  color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.3)' : 'inherit')};
  .icon {
    margin-right: 5px;
    width: 15px;
    color: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.3)' : 'inherit')};
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  .credits {
    font-size: 10px;
  }
`;

const StrikeThroughSpan = styled.span`
  text-decoration: ${props => (props.strike ? 'line-through' : 'none')};
`;

function Stats({
  isOnCreditSystem,
  showAcademicCredits,
  showIndustryCredits,
  previouslyExportedEmails,
  academicEmails,
  academicCredits,
  industryEmails,
  industryCredits,
  isAcademicTrial,
  isIndustryTrial,
  hasFullLicense,
  disabled,
  uncategorisedEmails,
  excludePreviouslyExported,
  exportTotalEmails,
  totalCredits,
  isAcademicUnlimited,
  isIndustryUnlimited,
  hasAcademicLicense,
  hasIndustryLicense,
}) {
  const hasAcademicEmails = academicEmails >= -1;
  const hasIndustryEmails = industryEmails >= -1;
  const topLevelDisabled = disabled || !hasFullLicense;
  const showCredits =
    isOnCreditSystem &&
    ((hasIndustryEmails && showIndustryCredits) ||
      (hasAcademicEmails && showAcademicCredits));
  return (
    <Container>
      {hasAcademicEmails && (
        <Stat disabled={!topLevelDisabled && isAcademicTrial}>
          <div>
            <Icon size='sm' icon='book' color='#000000' className='icon' />
            <span>Academic:</span>
          </div>
          <div>
            {formatNumber(academicEmails)} emails
            {isOnCreditSystem && showAcademicCredits && (
              <span>
                {' '}
                /{' '}
                <span className='credits'>
                  {isAcademicUnlimited
                    ? getCreditsString(-1)
                    : getCreditsString(academicCredits)}{' '}
                  credits
                </span>
              </span>
            )}
            {isOnCreditSystem &&
              showIndustryCredits &&
              hasIndustryLicense &&
              !showAcademicCredits && (
                <span>
                  {' '}
                  / <span className='credits'>fair usage</span>
                </span>
              )}
          </div>
        </Stat>
      )}
      {hasIndustryEmails && (
        <Stat disabled={!topLevelDisabled && isIndustryTrial}>
          <div>
            <Icon size='sm' icon='company' color='#000000' className='icon' />
            <span>Biopharma:</span>
          </div>
          <div>
            {formatNumber(industryEmails)} emails
            {isOnCreditSystem && showIndustryCredits && (
              <span>
                {' '}
                /{' '}
                <span className='credits'>
                  {isIndustryUnlimited
                    ? getCreditsString(-1)
                    : getCreditsString(industryCredits)}{' '}
                  credits
                </span>
              </span>
            )}
            {isOnCreditSystem &&
              !showIndustryCredits &&
              showAcademicCredits &&
              hasAcademicLicense && (
                <span>
                  {' '}
                  / <span className='credits'>fair usage</span>
                </span>
              )}
          </div>
        </Stat>
      )}
      {uncategorisedEmails >= 0 && (hasIndustryEmails || hasAcademicEmails) && (
        <Stat>
          <div>
            <Icon
              size='sm'
              icon='squarePlus'
              color='#000000'
              className='icon'
            />
            <span style={{ marginRight: '10px' }}>Unaffiliated:</span>
            <Tooltip
              title={`These emails don't have an Academic or Biopharma categorisation${
                showCredits ? ", so exporting them won't use your credits" : ''
              }. We're working on categorising them to enhance your experience.`}
              placement='right'
            >
              <>
                <Icon
                  size='xs'
                  icon='circleInfo'
                  color='#000000'
                  className='icon'
                />
              </>
            </Tooltip>
          </div>
          <div>
            {formatNumber(uncategorisedEmails)} emails
            {showCredits && (
              <span>
                {' '}
                / <span className='credits'>{getCreditsString(0)} credits</span>
              </span>
            )}
          </div>
        </Stat>
      )}
      {previouslyExportedEmails >= 0 && (
        <Stat>
          <div>
            <Icon
              size='sm'
              icon='clockRotateLeft'
              color='#000000'
              className='icon'
            />
            <span style={{ marginRight: '10px' }}>Previously Exported:</span>
            <Tooltip
              title={`Shows the number of emails exported in previous downloads. ${
                showCredits ? 'Exporting these won’t use your credits.' : ''
              }`}
              placement='right'
            >
              <>
                <Icon
                  size='xs'
                  icon='circleInfo'
                  color='#000000'
                  className='icon'
                />
              </>
            </Tooltip>
          </div>
          <div>
            <StrikeThroughSpan strike={excludePreviouslyExported}>
              {formatNumber(previouslyExportedEmails)} emails
            </StrikeThroughSpan>
            {showCredits && (
              <span>
                {' '}
                /{' '}
                <StrikeThroughSpan
                  className='credits'
                  strike={excludePreviouslyExported}
                >
                  {getCreditsString(0)} credits
                </StrikeThroughSpan>
              </span>
            )}
          </div>
        </Stat>
      )}
      <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
        <Divider style={{ borderTop: '2px solid #a9a9a9', margin: '5px' }} />
      </div>
      <Stat style={{ fontWeight: '700' }}>
        <div>
          <span>Total:</span>
        </div>
        <div>
          {formatNumber(exportTotalEmails)} emails
          {showCredits && (
            <span>
              {' '}
              /{' '}
              <span className='credits'>
                {getCreditsString(totalCredits)} credits
              </span>
            </span>
          )}
        </div>
      </Stat>
    </Container>
  );
}

Stats.propTypes = {
  isOnCreditSystem: PropTypes.bool,
  showAcademicCredits: PropTypes.bool,
  showIndustryCredits: PropTypes.bool,
  isAcademicTrial: PropTypes.bool,
  isIndustryTrial: PropTypes.bool,
  exportTotalEmails: PropTypes.number,
  previouslyExportedEmails: PropTypes.number,
  previouslyExportedCredits: PropTypes.number,
  academicEmails: PropTypes.number,
  academicCredits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  industryEmails: PropTypes.number,
  industryCredits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default Stats;
