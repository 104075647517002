import styled from 'styled-components';
import { Alert } from 'antd';

const Container = styled.div`
  font-size: 11px;
  line-height: 1.3;
  ul {
    padding-left: 25px;
    padding-top: 5px;
    margin-bottom: 0px;
  }
`;

const Message = () => (
  <Container>
    The base permission is given to every user on your account for this list
    only. You can override this on a per-user basis using the table below with
    the following caveats:
    <ul>
      <li>Account managers have admin access by default</li>
      <li>You cannot edit your own permission level</li>
      <li>You cannot edit the list owner's permission level</li>
    </ul>
  </Container>
);

const Explanation = () => {
  return (
    <Alert style={{ margin: '10px 0' }} message={<Message />} type='info' />
  );
};

export default Explanation;
