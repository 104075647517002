import Countries from './json/countries.json';
import Phases from './json/clinicalTrialPhases.json';
import Roles from './json/clinicalTrialRoles.json';
import Statuses from './json/clinicalTrialStatuses.json';
import Categories from './json/organisationCategoriesAllLicense.json';
import CategoriesAcademic from './json/organisationCategoriesAcademicLicense.json';
import CategoriesIndustry from './json/organisationCategoriesIndustryLicense.json';
import CategoriesLegacy from './json/organisationCategoriesLegacyLicense.json';
import CategoriesLegacyIndustry from './json/organisationCategoriesLegacyIndustryLicense.json';
import CategoriesAcademicList from './json/organisationCategoriesAcademicList.json';
import CategoriesIndustryList from './json/organisationCategoriesIndustryList.json';
import CategoriesCollectionHealthcare from './json/organisationCategoriesCollectionHealthcare.json';
import CategoriesCollectionTools from './json/organisationCategoriesCollectionTools.json';
import CategoriesCollectionFinancialServices from './json/organisationCategoriesCollectionFinancialServices.json';
import CategoriesCollectionOther from './json/organisationCategoriesCollectionOther.json';
import CategoriesCollectionBioinformatics from './json/organisationCategoriesCollectionBioinformatics.json';
import CategoriesList from './json/organisationCategoriesList.json';

import ActivityCodes from './json/nihActivityCodes.json';
import ApplicationTypes from './json/nihApplicationTypes.json';
import InstituteCodes from './json/nihInstituteCodes.json';
import FundingRounds from './json/fundingRounds.json';
import UpperFundingAmounts from './json/upperFundingAmounts.json';
import LowerFundingAmounts from './json/lowerFundingAmounts.json';
import CommonDateRanges from './json/commonDateRanges.json';

export const countries = Countries;
export const phases = Phases;
export const roles = Roles;
export const statuses = Statuses;
export const categories = Categories;
export const categoriesAcademic = CategoriesAcademic;
export const categoriesIndustry = CategoriesIndustry;
export const categoriesLegacy = CategoriesLegacy;
export const categoriesLegacyIndustry = CategoriesLegacyIndustry;
export const categoriesAcademicList = CategoriesAcademicList;
export const categoriesIndustryList = CategoriesIndustryList;
export const categoriesList = CategoriesList;
export const categoriesCollectionsHealthcare = CategoriesCollectionHealthcare;
export const categoriesCollectionsTools = CategoriesCollectionTools;
export const categoriesCollectionsFinancialServices = CategoriesCollectionFinancialServices;
export const categoriesCollectionsOther = CategoriesCollectionOther;
export const categoriesCollectionsBioinformatics = CategoriesCollectionBioinformatics;
export const activityCodes = ActivityCodes;
export const applicationTypes = ApplicationTypes;
export const instituteCodes = InstituteCodes;
export const fundingRounds = FundingRounds;
export const upperFundingAmounts = UpperFundingAmounts;
export const lowerFundingAmounts = LowerFundingAmounts;
export const commonDateRanges = CommonDateRanges;
export const emailStatus = ['NotYetKnown', 'SafeToSend', 'Uncertain'];
export const organisationEmployeeCount = [
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10001+',
];

export const organisationFoundationYear = [
  { label: 'Less than 1 year', value: -1 },
  { label: 'Less than 3 years', value: -3 },
  { label: 'Less than 5 years', value: -5 },
  { label: 'Less than 10 years', value: -10 },
  { label: 'More than 10 years', value: 10 },
];

export const timeAtCompany = [
  '0 - 6 months',
  '6 - 12 months',
  '1 - 3 years',
  '3 - 5 years',
  '5 - 10 years',
  '10 - 20 years',
  '> 20 years',
];

export const purchasingCategories = [
  { label: 'NIH', value: 'NIH' },
  { label: 'Universities & Hospitals', value: 'Universities' },
  { label: 'Federal', value: 'Federal Data, excluding NIH' },
];

// Search
export const states = '/api/universal/states/filter';
export const journals = '/api/universal/filter/journals/';
export const tradeshows = '/api/universal/filter/tradeshow/';
export const lists = '/api/researcherlist/search/';
export const organisationLists = '/api/organisationlists/search/';
export const sessionTypes = '/api/universal/filter/tradeshow/sessiontypes/';
export const fundingRoundInvestors = '/api/universal/filter/investors';
export const fundingRoundTypes = '/api/universal/filter/fundingtypes';
export const acquisitionTypes = '/api/universal/filter/acquisitionTypes';
export const acquisitionAcquirers = '/api/universal/filter/acquirers';
export const organisationsTypeahead =
  '/api/universal/filter/organisation/typeahead/';
export const leadSponsorTypeahead =
  '/api/universal/filter/sponsors/typeahead/true';
export const sponsorsTypeahead =
  '/api/universal/filter/sponsors/typeahead/false';
// Purchasing Search
export const purchasers = '/api/purchasing-search/purchasers/';
export const purchasingCategoriesRemote = '/api/purchasing-search/filter/types';
export const purchaserStates = '/api/purchasing-search/filter/states';
export const purchaserCountries = '/api/purchasing-search/filter/countries';
export const suppliers = '/api/purchasing-search/suppliers/';
