export const types = {
  RELOAD_USERS: 'RELOAD_USERS',
  RESTORE_STATE: 'RESTORE_STATE',
};

export const reloadUsers = reload => ({
  type: types.RELOAD_USERS,
  payload: { reload },
});

export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});
