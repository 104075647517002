import { Fragment } from 'react';

import { Pane, Table, Detail } from './styled';

const getArmLabel = (type, title) => {
  if (!type && !title) return 'No Title';
  return `${type}: ${title}`;
};

const armsConfig = {
  headers: ['Arms', 'Interventions'],
  createRow: (r, i) => (
    <Fragment key={i}>
      <div>
        <Detail
          label={getArmLabel(r.designGroup?.groupType, r.designGroup?.title)}
          style={{ paddingRight: 15 }}
        >
          {r.designGroup?.description}
        </Detail>
      </div>
      <div>
        {r.interventions.map((i, index) => (
          <Fragment key={`${i.name}-${index}`}>
            <Detail
              label={`${i.interventionType}: ${i.name}`}
              style={{ paddingRight: 15 }}
            >
              {i.description}
            </Detail>
            <Detail label='Other Names' style={{ paddingRight: 15 }}>
              {i.interventionOtherNames?.map((n, nIndex) => (
                <div key={`${n.name}-${nIndex}`}>{n.name}</div>
              ))}
            </Detail>
          </Fragment>
        ))}
      </div>
    </Fragment>
  ),
};

const Arms = ({ study }) => {
  return (
    <Pane title='Arms and Interventions' id='arms'>
      <Table config={armsConfig} data={study.studyGroupInterventions} />
    </Pane>
  );
};

export default Arms;
