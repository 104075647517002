import { Switch, Redirect, useLocation } from 'react-router-dom';

import Route from '../common/Route';
import FourOhFour from '../404';
import packageInfo from '../../../package.json';

const NotFound = () => {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Route path='/notfound' title='404 - Not Found'>
        <FourOhFour version={packageInfo.version} />
      </Route>
      <Redirect to={`/notfound?from=${pathname}`} />
    </Switch>
  );
};

export default NotFound;
