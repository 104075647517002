import packageInfo from '../../package.json';

const upgradeDb = db => {
  if (db.objectStoreNames.contains('state')) {
    db.deleteObjectStore('state');
  }
  const store = db.createObjectStore('state');
  store.transaction.oncomplete = e => {
    const transaction = db.transaction(['state'], 'readwrite');
    const store = transaction.objectStore('state');
    store.put(true, 'darkMode');
  };
};

const fallback = {
  getItem: key => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  },
  setItem: (key, value) => {
    try {
      return localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      return null;
    }
  },
  removeItem: key => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      return null;
    }
  },
};

const getItem = key =>
  new Promise((res, rej) => {
    const dbRequest = indexedDB.open('scileads', packageInfo.dbVersion);

    dbRequest.onerror = () => {
      res(fallback.getItem(key));
    };

    dbRequest.onupgradeneeded = event => {
      const db = event.target.result;
      upgradeDb(db);
    };

    dbRequest.onsuccess = event => {
      const db = event.target.result;
      const transaction = db.transaction(['state'], 'readonly');
      const store = transaction.objectStore('state');
      const request = store.get(key);

      request.onerror = event => {
        res();
      };

      request.onsuccess = event => {
        res(request.result);
      };
    };
  });

const setItem = (key, value) =>
  new Promise((res, rej) => {
    const dbRequest = indexedDB.open('scileads', packageInfo.dbVersion);

    dbRequest.onerror = () => {
      res(fallback.setItem(key, value));
    };

    dbRequest.onupgradeneeded = event => {
      const db = event.target.result;
      upgradeDb(db);
    };

    dbRequest.onsuccess = event => {
      const db = event.target.result;
      const transaction = db.transaction(['state'], 'readwrite');
      const store = transaction.objectStore('state');
      try {
        const request = store.put(value, key);
        request.onerror = event => {
          res();
        };

        request.onsuccess = event => {
          res('Yeo');
        };
      } catch (e) {}
    };
  });

const removeItem = key =>
  new Promise((res, rej) => {
    const dbRequest = indexedDB.open('scileads', packageInfo.dbVersion);

    dbRequest.onerror = event => {
      res(localStorage.removeItem(key));
    };

    dbRequest.onupgradeneeded = event => {
      const db = event.target.result;
      upgradeDb(db);
    };

    dbRequest.onsuccess = event => {
      const db = event.target.result;
      const transaction = db.transaction(['state'], 'readwrite');
      const store = transaction.objectStore('state');
      const request = store.delete(key);

      request.onerror = event => {
        res();
      };

      request.onsuccess = event => {
        res();
      };
    };
  });

const storage = {
  setItem,
  getItem,
  removeItem,
};

export default storage;
