import { Alert as AntAlert } from 'antd';

export const badWords = [' and ', ' or ', ' not ', '*'];

export const errors = {
  409: (
    <AntAlert
      style={{ marginTop: 15 }}
      type='error'
      message='This keyword already exists.'
    />
  ),
};

export const showWarning = value =>
  badWords.some(w => value.toLowerCase().includes(w));

export const Alert = () => (
  <AntAlert
    style={{ marginTop: 15 }}
    type='info'
    message='Boolean operators and wildcards will not apply so we recommend that you do not add them to the keyword list.'
  />
);
