import { useEffect } from 'react';
import { notification } from 'antd';

import { Button, BetaTag } from 'components/common';
import { useAuth, usePost } from 'hooks';
import { findEmail } from 'services/api';

const FindEmail = ({ dataToPost, size, style = {} }) => {
  const { user } = useAuth();
  const [
    { res: findEmailRes, loading: findingEmail },
    postFindEmail,
  ] = usePost({ url: findEmail });

  const handleFindEmail = e => {
    postFindEmail({
      ...dataToPost,
      location: dataToPost?.location ?? window.location.href,
    });
    e.stopPropagation();
  };

  useEffect(() => {
    if (findEmailRes) {
      notification.open({
        message: 'Find Email request',
        description: (
          <>
            <p>Thank you for your request.</p>
            <p>
              Research to find this email address is underway and you will be
              notified via email when this is completed.
            </p>
            <a href='/settings/email-requests'>Click here</a> to manage your
            requests.
          </>
        ),
        placement: 'bottomLeft',
      });
    }
  }, [findEmailRes]);

  if (user && user.hideResearcherEmail) return null;

  return (
    <Button
      disabled={findingEmail}
      type='primary'
      onClick={handleFindEmail}
      size={size}
      style={style}
    >
      Find Email <BetaTag />
    </Button>
  );
};

export default FindEmail;
