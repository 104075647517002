import { useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxGl from '!mapbox-gl';
import ToggleFilterMarker, { getWidth } from './ToggleFilterMarker';

const useMarker = map => {
  const markerNode = useRef(document.createElement('div'));
  const markerRef = useRef(
    new mapboxGl.Marker({
      element: markerNode.current,
    })
  );

  const close = useCallback(() => {
    ReactDOM.unmountComponentAtNode(markerNode.current);
    markerRef.current.remove();
  }, []);

  const show = useCallback(
    (feature, type, isSelected, onClick) => {
      if (feature) {
        let [lng, lat] = feature.geometry.coordinates;
        const markerProjection = map.project([lng, lat]);
        const { _ne } = map.getBounds();
        const northEastProjection = map.project(_ne);

        const radius = feature.layer.paint['circle-radius'];
        const markerWidth = getWidth(type, isSelected);
        const markerToTheRight =
          markerProjection.x + radius + markerWidth < northEastProjection.x;

        const markerOffset = markerToTheRight
          ? markerWidth / 2 + radius
          : -markerWidth / 2 - radius;

        markerRef.current = new mapboxGl.Marker({
          element: markerNode.current,
          offset: [markerOffset, 0],
        });
        ReactDOM.render(
          <ToggleFilterMarker
            type={type}
            isSelected={isSelected}
            onClick={onClick}
          />,
          markerNode.current
        );
        markerRef.current.setLngLat([lng, lat]).addTo(map);
      }
    },
    [map]
  );

  return [show, close, markerNode.current];
};

export default useMarker;
