import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Detail, Input, Select } from 'components/common';
import DetailsTooltip from './DetailsTooltip';

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  > div {
    flex: 1;
  }
`;

function TradeshowLocation({
  tradeshows,
  tradeshow,
  location,
  onTradeshowChange,
  onLocationChange,
  loading,
  style = {},
}) {
  const [options] = useState(
    tradeshows.map(t => ({
      label: t.name,
      value: t.id,
    }))
  );

  return (
    <Container style={style}>
      <Detail label='Tradeshow'>
        <Select
          value={tradeshow}
          size='middle'
          options={options}
          onChange={onTradeshowChange}
          style={{ width: '100%' }}
          placement='bottomRight'
          filterOption={(input, option) =>
            option.title.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          showSearch
          disabled={loading}
        />
      </Detail>
      <Detail
        label={
          <>
            Exact location to meet (e.g. Booth)
            <DetailsTooltip title='Maximum 50 characters' />
          </>
        }
      >
        <Input
          placeholder='Location'
          value={location}
          onChange={onLocationChange}
          disabled={loading}
          style={{ width: '100%' }}
          maxLength={50}
        />
      </Detail>
    </Container>
  );
}

TradeshowLocation.propTypes = {
  tradeshows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  tradeshow: PropTypes.number,
  onTradeshowChange: PropTypes.func.isRequired,
  location: PropTypes.string,
  onLocationChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default TradeshowLocation;
