import { useEffect, useRef, useState } from 'react';

function useStreamValue(value, shouldStream) {
  const [streamedValue, setStreamedValue] = useState('');
  const shouldStreamRef = useRef(false);

  useEffect(() => {
    shouldStreamRef.current = shouldStream;
  }, [shouldStream]);

  useEffect(() => {
    if (!shouldStreamRef.current || !value) {
      setStreamedValue(value);
      return;
    }

    shouldStreamRef.current = false;
    let index = 0;
    const streamValue = () => {
      index++;
      setStreamedValue(() => value.slice(0, index));
    };
    const intervalId = setInterval(() => streamValue(), 1);
    return () => {
      clearInterval(intervalId);
    };
  }, [value]);

  return [streamedValue];
}

export default useStreamValue;
