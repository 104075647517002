import React from 'react';

import Detail from '../Detail';
import Input from '../Input';
import CharacterLimitReached from './CharacterLimitReached';

const ListNameInput = ({ value, onChange }) => {
  const maxLength = 450;
  const nameLimitReached = value && value.length >= maxLength;
  return (
    <Detail label='Name'>
      <>
        <Input
          showCount
          multiline
          rows={2}
          placeholder='List Name'
          value={value}
          onChange={onChange}
          data-testid='list-name-input'
          maxLength={maxLength}
          status={nameLimitReached ? 'error' : ''}
        />
        {nameLimitReached && <CharacterLimitReached />}
      </>
    </Detail>
  );
};

export default ListNameInput;
