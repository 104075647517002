import { useState } from 'react';

import { Button } from 'components/common';

import Report from './Report';
import { ActionsContainer } from './styled';

const Actions = ({ researcher }) => {
  const { researcherProfile } = researcher || {};
  const [showReportModal, setShowReportModal] = useState(false);

  const handleToggleReport = () => setShowReportModal(s => !s);

  return (
    <div>
      <ActionsContainer>
        <Button size='small' onClick={handleToggleReport}>
          Report Profile
        </Button>
      </ActionsContainer>
      {showReportModal && (
        <Report
          onToggleModal={handleToggleReport}
          researcherId={researcherProfile.id}
        />
      )}
    </div>
  );
};

export default Actions;
