import { Icon, Link, Clamp, Highlight } from 'components/common';
import { Container } from '../styled';
import { colours } from 'utils/theme';
import { format } from 'utils/date';
import OwnersDetails from './OwnersDetail';
import {
  InnerContainerRow,
  ActivityData,
  ActivityDetail,
  MetadataLabel,
  MetadataValue,
  IconWrapper,
  ContentWrapper,
} from './styled';

const Trial = ({
  nctId,
  date,
  studyType,
  title,
  highlights,
  overallStatus,
  phase,
  researchers,
  organisations,
}) => {
  return (
    <Container>
      <InnerContainerRow>
        <IconWrapper>
          <Icon color={colours.trials} size='1x' icon='trial' />
        </IconWrapper>
        <ContentWrapper>
          <Link to={`trial/${nctId}`} showIconHint>
            <Clamp text={title} />
          </Link>
          <ActivityDetail>
            <Highlight highlights={highlights} />
            <InnerContainerRow>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='calendar'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Date published:
                </MetadataLabel>
                <MetadataValue>{format(date, 'D MMM YYYY')}</MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='trial'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Type:{' '}
                </MetadataLabel>
                <MetadataValue>{studyType}</MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='progress'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Phase:{' '}
                </MetadataLabel>
                <MetadataValue>{phase}</MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='signal'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Status:{' '}
                </MetadataLabel>
                <MetadataValue>{overallStatus}</MetadataValue>
              </ActivityData>
            </InnerContainerRow>
          </ActivityDetail>
          <OwnersDetails
            researchers={researchers}
            organisations={organisations}
          />
        </ContentWrapper>
      </InnerContainerRow>
    </Container>
  );
};

export default Trial;
