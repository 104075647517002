//@ts-check
import { Link, Table } from 'components/common';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { format } from 'utils/date';
import { siNumber } from 'utils/number';
import { Title } from '../Funding';
import OrganisationInvestmentHighlights from './InvestmentHighlights';

/**
 * @param {{
 *  organisation: import('../Funding/index').OrganisationVCFundingDetails
 * }} props
 * @returns {JSX.Element}
 **/
export default function OrganisationInvestments({ organisation }) {
  const sortedInvestments =
    organisation.superOrganisationInvestments?.sort((a, b) =>
      dayjs(b.announcedDate).diff(dayjs(a.announcedDate))
    ) || [];

  /**
   * @param {import('../Funding/index').Investment} a
   * @param {import('../Funding/index').Investment} b
   * @param {string} sort
   * @param {'asc' | 'desc' | undefined} sortDir
   * @returns
   */
  const onSort = (a, b, sort, sortDir) => {
    let aValue = a[sort]?.toLowerCase ? a[sort].toLowerCase() : a[sort];
    let bValue = b[sort]?.toLowerCase ? b[sort].toLowerCase() : b[sort];

    if (sort === 'allInvestors') {
      /**
       * @param {import('../Funding/index').Investor} v
       * @returns {string}
       */
      const lambda = v => v.name;

      aValue = aValue?.map(lambda)?.join('');
      bValue = bValue?.map(lambda)?.join('');
    }

    if (aValue == null && bValue != null) return 1;
    if (aValue != null && bValue == null) return -1;

    if (sortDir === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  };

  const investmentTableConfig = {
    headers: [
      { label: 'Investee Name', minWidth: 120, key: 'investeeName' },
      { label: 'Announced On', minWidth: 120, key: 'announcedDate' },
      { label: 'Funding Type', minWidth: 90, key: 'investmentType' },
      { label: 'Amount Raised', minWidth: 90, key: 'raisedAmountUsd' },
      {
        label: 'Investors',
        minWidth: 200,
        maxContentWidth: 500,
        key: 'allInvestors',
      },
      { label: 'Transaction Name', minWidth: 150, key: 'name' },
    ],
    /** @param {import('../Funding/index').Investment} investment */
    createRow: investment => {
      return (
        //@ts-ignore
        <Fragment key={investment.fundingRoundId}>
          <div>
            {investment.investeeSuperOrgId ? (
              //@ts-ignore
              <Link
                to={`/organisation/${investment.investeeSuperOrgId}?initialView=vcFunding`}
                showIconHint
              >
                {investment.investeeName}
              </Link>
            ) : (
              <div>{investment.investeeName}</div>
            )}
          </div>
          <div>{format(investment.announcedDate)}</div>
          <div>{investment.investmentType || 'Unknown'}</div>
          <div>
            {investment.raisedAmountUsd
              ? `$${siNumber(investment.raisedAmountUsd)}`
              : '-'}
          </div>
          <div>
            {investment.allInvestors
              ?.sort((a, b) => ('' + a.name).localeCompare(b.name))
              ?.map(i =>
                i.superOrgId ? (
                  //@ts-ignore
                  <Link
                    key={i.investorId}
                    to={`/organisation/${i.superOrgId}?initialView=investments`}
                    showIconHint
                  >
                    {i.name}
                  </Link>
                ) : (
                  <div key={i.investorId}>{i.name}</div>
                )
              ) || ''}
          </div>
          <div>{investment.name}</div>
        </Fragment>
      );
    },
  };

  return (
    <div>
      {!!sortedInvestments.length && (
        <>
          <Title>Highlights</Title>
          {/* @ts-ignore */}
          <OrganisationInvestmentHighlights organisation={organisation} />
          <Title>Investments Table</Title>
          {/* @ts-ignore */}
          <Table
            config={investmentTableConfig}
            data={sortedInvestments}
            sortable
            className={'researchers-table'}
            paginate
            onSort={onSort}
          />
        </>
      )}
    </div>
  );
}
