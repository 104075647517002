import { useDispatch, useSelector } from 'react-redux';

import {
  getSelectedMembers,
  getPageNumber,
  getPageSize,
  getTableSort,
} from 'selectors/lists';
import {
  setSelectedMembers,
  setPageSize,
  setPageNumber,
  setSort,
} from 'actions/lists';

import { ConstrainedTable } from '../styled';
import useConfig from './useConfig';
import { views } from 'components/Lists/constants';

const MembersTable = ({
  members,
  totalMembers,
  loading,
  view = views.researchers,
}) => {
  const dispatch = useDispatch();
  const selectedMembers = useSelector(getSelectedMembers);
  const pageNumber = useSelector(getPageNumber);
  const pageSize = useSelector(getPageSize);
  const sort = useSelector(getTableSort);

  const handleToggleAll = () => {
    const selected = { exclude: {}, include: {}, all: !selectedMembers.all };

    dispatch(setSelectedMembers(selected));
  };

  const handleToggleMember = id => {
    const selected = {
      ...selectedMembers,
      exclude: { ...selectedMembers.exclude },
      include: { ...selectedMembers.include },
    };

    if (selected.all) {
      if (selected.exclude[id]) {
        delete selected.exclude[id];
      } else {
        selected.exclude[id] = true;
      }
    } else {
      if (selected.include[id]) {
        delete selected.include[id];
      } else {
        delete selected.exclude[id];
        selected.include[id] = true;
      }
    }
    dispatch(setSelectedMembers(selected));
  };

  const config = useConfig(handleToggleAll, handleToggleMember, view);

  const handlePageSize = size => dispatch(setPageSize(size));

  const handlePageNumber = number => dispatch(setPageNumber(number));

  const handleSortChange = sortKey => {
    dispatch(setSort(sortKey));
  };

  return (
    <ConstrainedTable
      paginate
      sortable
      config={config}
      data={members}
      loading={loading}
      onChangePageSize={handlePageSize}
      onChangePageNumber={handlePageNumber}
      pageSize={pageSize}
      pageNumber={pageNumber}
      resultSize={totalMembers}
      onSortChange={handleSortChange}
      sort={sort.by}
      sortDir={sort.dir}
    />
  );
};

export default MembersTable;
