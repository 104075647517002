import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSortBy, performSearch, setOrgSortBy } from 'actions/search';
import {
  getSortBy,
  getSearchRequest,
  getSearchView,
  getOrgSortBy,
} from 'selectors/search';
import { Select } from 'components/common';

import { SearchContext } from '../../';
import { ResearcherSearch } from '../helpers';

const sortByOptions = [
  { label: 'Combined Matches', value: 'TotalMatches' },
  { label: 'Funding Matches', value: 'TotalGrants' },
  { label: 'Tradeshow Matches', value: 'TotalSessions' },
  { label: 'Publication Matches', value: 'TotalPublications' },
  { label: 'Clinical Trial Matches', value: 'TotalClinicalTrials' },
  { label: 'Research Impact (3 Year Avg)', value: 'ThreeYearImpact' },
  { label: 'Date', value: 'Date' },
  { label: 'No. of Collaborators', value: 'TotalCollaboratorsOverall' },
];

const orgSortByOptions = [
  { label: 'Date', value: 'Date' },
  {
    label: 'Latest Funding Round',
    value: 'SuperOrganisationLatestFundingRound.AnnouncedDate',
  },
];

const SortBy = ({ disabled }) => {
  const dispatch = useDispatch();
  const { postSearch, postGeoSearch } = useContext(SearchContext);

  const isResearcherSearch = ResearcherSearch(useSelector(getSearchView));

  const sortBy = useSelector(getSortBy);
  const orgSortBy = useSelector(getOrgSortBy);
  const { loading } = useSelector(getSearchRequest);

  const handleSortChange = value => {
    if (isResearcherSearch) {
      dispatch(setSortBy(value));
    } else {
      dispatch(setOrgSortBy(value));
    }
    dispatch(performSearch(postSearch, postGeoSearch));
  };

  return (
    <>
      <span>Sort By:</span>
      <Select
        disabled={disabled | loading}
        size='small'
        options={isResearcherSearch ? sortByOptions : orgSortByOptions}
        value={isResearcherSearch ? sortBy : orgSortBy}
        onChange={handleSortChange}
        dropdownStyle={{ minWidth: '225px' }}
        showSearch
        disableTooltip
      />
    </>
  );
};

export default SortBy;
