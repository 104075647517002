import { Tooltip } from 'antd';
import { GetSearchKeysByView } from 'components/Search/Filters/keys';
import { searchOrigin } from 'components/Search/Results/constants';
import { FiltersContainer } from 'components/Search/Results/styled';
import { Token } from 'components/common/TokenInput';
import React from 'react';
import styled from 'styled-components';
import { tokenise } from 'utils/filters';

const TokenLister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    font-size: 13px;
    line-height: 13px;
  }
`;

const TooltipTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function ListTokens({ filter, inputView, excludeTokens }) {
  const researcherSearchFields = GetSearchKeysByView(
    searchOrigin.researcherSearch
  );
  const organisationSearchFields = GetSearchKeysByView(
    searchOrigin.organisationSearch
  );

  const purchasingSearchFields = GetSearchKeysByView(
    searchOrigin.purchasingSearch
  );

  const tradeshowSearchFields = GetSearchKeysByView(searchOrigin.tradeshow);

  const orgPageSearchFields = GetSearchKeysByView(
    searchOrigin.organisationPageSearch
  );
  let searchKeys;
  if (inputView === searchOrigin.researcherSearch) {
    searchKeys = researcherSearchFields;
  } else if (inputView === searchOrigin.purchasingSearch) {
    searchKeys = purchasingSearchFields;
  } else if (inputView === searchOrigin.tradeshow) {
    searchKeys = tradeshowSearchFields;
  } else if (inputView === searchOrigin.organisationPageSearch) {
    searchKeys = orgPageSearchFields;
  } else {
    searchKeys = organisationSearchFields;
  }

  /**
   * @param {string} key
   * @param {number=} index
   * @param {string=} filterGroup
   * @returns {void}
   */

  // @ts-ignore
  const tokens = tokenise(
    filter,
    searchKeys,
    [...excludeTokens]
  );

  const groupTokens = tokens.reduce((group, token) => {
    const { filterGroup, key } = token;
    if (filterGroup) {
      group[filterGroup] = group[filterGroup] ?? [];
      group[filterGroup].push(token);
    } else if (key) {
      group[key] = group[key] ?? [];
      group[key].push(token);
    }
    return group;
  }, []);

  if (tokens.length <= 0) return null;

  return (
    <FiltersContainer>
      <TokenLister style={{ flexDirection: 'column', alignItems: 'start' }}>
        {Object.values(groupTokens).map(filters => (
          <TokenLister style={{ flexDirection: 'row' }}>
            {filters?.map((filter, index) => (
              <div key={`${filter.key}-${filter.value}-${index}`}>
                {/* @ts-ignore */}
                <Tooltip
                  title={
                    <TooltipTitle>
                      <div style={{ fontWeight: 'bold' }}>
                        {filter.metaData.sectionTitle}
                      </div>
                      <div>
                        {filter.metaData.sectionTitle !== 'Category'
                          ? filter.metaData.title
                          : ''}
                      </div>
                    </TooltipTitle>
                  }
                >
                  {/* @ts-ignore */}
                  <Token
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    hideDelete={true}
                    value={filter.value}
                    background={filter.color}
                  />
                </Tooltip>
              </div>
            ))}
          </TokenLister>
        ))}
      </TokenLister>
    </FiltersContainer>
  );
}
