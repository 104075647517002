import PropTypes from 'prop-types';
import { Icon } from 'components/common';
import { MarkerContainer } from './styled';
import { clusterLayerVar } from './config';

const addFilterWidth = 170;
const removeFilterWidth = 205;
const clusterAddFilterWidth = 175;
const clusterRemoveFilterWidth = 210;

export const getWidth = (type, isSelected) => {
  if (type === clusterLayerVar) {
    return isSelected ? clusterRemoveFilterWidth : clusterAddFilterWidth;
  }

  return isSelected ? removeFilterWidth : addFilterWidth;
};
const ToggleFilterMarker = ({ type, isSelected, onClick }) => {
  return (
    <MarkerContainer
      type={type}
      onClick={onClick}
      style={{ width: getWidth(type, isSelected) }}
    >
      {isSelected ? (
        <>
          <Icon icon='minus' size='xs' />
          Remove location{type === clusterLayerVar ? 's' : ''} from search
          filters
        </>
      ) : (
        <>
          <Icon icon='plus' size='xs' />
          Add location{type === clusterLayerVar ? 's' : ''} to search filters
        </>
      )}
    </MarkerContainer>
  );
};

ToggleFilterMarker.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleFilterMarker;
