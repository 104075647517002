//@ts-check
import { Statistic } from 'components/common';
import React from 'react';
import { colours } from 'utils/theme';
import { Stats } from './VCFundingStats';

/**
 * @param {{
 * organisation: import('../Funding/index').OrganisationVCFundingDetails,
 * loading: boolean
 * }} props
 */
export default function AcquisitionStats({ organisation, loading }) {
  if (loading) return null;

  var hasAcquisitions = !!organisation.superOrganisationAcquisitions?.length;

  return (
    <Stats>
      {hasAcquisitions && (
        <Statistic
          icon='handShake'
          title='Acquisitions'
          value={organisation.superOrganisationAcquisitions?.length || 0}
          iconColor={colours.teal}
        />
      )}
    </Stats>
  );
}
