export const formatters = {
  and: v => {
    return v && v.length > 0 && `(${v.map(x => `"${x}"`).join(' AND ')})`;
  },
  or: v => {
    return v && v.length > 0 && `(${v.map(x => `"${x}"`).join(' OR ')})`;
  },
  not: v => {
    return v && v.length > 0 && `NOT(${v.map(x => `"${x}"`).join(' OR ')})`;
  },
  proximity: v => {
    return (
      v &&
      v.proximity &&
      v.tokens?.length > 0 &&
      `("${v.tokens.join(' ')}"~${v.proximity})`
    );
  },
  advanced: v => {
    return (
      v &&
      v.reduce((acc, { esField, query, value }) => {
        if (!esField || !query || !value || value?.length === 0) return acc;
        const formatted = `${esField}:${query}`;
        return acc ? `${acc} AND ${formatted}` : formatted;
      }, '')
    );
  },
};

export const checkFieldsForErrors = fields => {
  const invalidProximity =
    fields.proximity &&
    (fields.proximity.tokens?.length < 2 || !fields.proximity.proximity);
  const invalidAdvanced = fields.advanced?.some(
    f => f?.value?.length === 0 || f?.value?.term === ''
  );

  return invalidProximity || invalidAdvanced;
};
