import * as FromPurchasing from 'reducers/purchasing/selectors';

export const SLICE = 'purchasing';

export const getFilter = (id, group = false) => state =>
  FromPurchasing.getFilter(state[SLICE], id, group);
export const getSearchRequest = state =>
  FromPurchasing.getSearchRequest(state[SLICE]);
export const getFilters = state => FromPurchasing.getFilters(state[SLICE]);
export const getPageSize = state => FromPurchasing.getPageSize(state[SLICE]);
export const getPageNumber = state =>
  FromPurchasing.getPageNumber(state[SLICE]);
export const getSort = state => FromPurchasing.getSort(state[SLICE]);
export const getSortDir = state => FromPurchasing.getSortDir(state[SLICE]);
export const getLastSuccessfulQuery = state =>
  FromPurchasing.getLastSuccessfulQuery(state[SLICE]);
export const getExternalSearchPending = state =>
  FromPurchasing.getExternalSearchPending(state[SLICE]);
export const getShowNotice = state =>
  FromPurchasing.getShowNotice(state[SLICE]);
