import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Row, Col } from 'antd';

import { Statistic, Loader, Link, Tooltip } from 'components/common';
import { getPublication } from 'services/api';
import { usePost } from 'hooks';

import JournalInfo from './JournalInfo';
import Authors from './Authors';
import FullTextData from './FullTextData';
import KeywordData from './KeywordData';
import RelatedAuthors from './RelatedAuthors';
import Grants from './Grants';
import WorldMap from './WorldMap';
import UPWLink from './UPWLink';
import {
  Container,
  Content,
  Footer,
  LeftColumn,
  RightColumn,
  StatisticRow,
  Detail,
  Keyword,
  Pane,
  PrePrintTag,
  TitleDiv,
} from './styled';

const Publication = () => {
  const { id } = useParams();
  const [{ res: publication, loading }] = usePost({
    url: getPublication,
    body: { citationId: id },
    onMount: true,
  });

  const showFamilyGroup = publication?.familyGroup?.length > 0;

  const getFamilyGroupMember = item => {
    var type = item.published ? 'PUBLISHED' : 'PREPRINT';
    return (
      <Tooltip
        key={item.citationId}
        title={item.citationId && 'Click here to navigate to the article'}
      >
        <Link external to={'/publication/' + item.citationId}>
          {type} - {item.source} - {item.doi}
        </Link>
      </Tooltip>
    );
  };

  const showPrePrintTag = publication?.journal?.prePrintRepoId > 0;

  const showUPWInfo =
    publication?.fullTextArticles[0]?.upwPdfUrl &&
    !publication?.fullTextArticles[0]?.html;

  const showAbstractNotification =
    publication?.abstractTexts.length &&
    !publication?.abstractTexts[0].abstract;

  useEffect(() => {
    if (publication) {
      const articleTitle =
        publication.article.title ||
        publication.article.untranslatedTitle ||
        '';
      const titleAsArray = articleTitle.split(' ');
      const firstThree = titleAsArray.slice(0, 3);
      const title =
        titleAsArray.length > 3
          ? `${firstThree.join(' ')}...`
          : publication.article.title;
      document.title = `${title} | SciLeads`;
    }
  }, [publication]);

  if (loading || !publication)
    return (
      <Container>
        <Pane>
          <Loader />
        </Pane>
      </Container>
    );

  const articleTitle =
    publication.article.title || publication.article.untranslatedTitle || '';

  return (
    <Container>
      <Content>
        <LeftColumn>
          <Pane>
            <Row>
              <Col flex={1}>
                <TitleDiv>Publication Details</TitleDiv>
              </Col>
              <Col flex={5}>
                <Row justify='end'>
                  {showPrePrintTag && <PrePrintTag>PrePrint</PrePrintTag>}
                </Row>
              </Col>
            </Row>

            <Detail label='Title'>{articleTitle}</Detail>
            {publication.articleELocations.map(l => (
              <Detail key={`${l.eidType}-${l.value}`} label={l.eidType}>
                {l.value}
              </Detail>
            ))}
            <JournalInfo data={publication} />
            <Authors data={publication} />
          </Pane>
          <StatisticRow>
            <Statistic
              title='Authors'
              icon='author'
              value={publication.authorTotal}
            />
            <Statistic
              title='Cited By'
              icon='citation'
              value={publication.citedByArticlesTotal}
            />
            <Statistic
              title='References'
              icon='reference'
              value={publication.referencedArticlesTotal}
            />
            <Statistic
              title='Keywords'
              icon='keywords'
              value={publication.keyTermsTotal}
            />
          </StatisticRow>
          {showAbstractNotification ? (
            <Alert
              type='info'
              description={
                <div>
                  The abstract is not shown below however we have made the text
                  searchable. This means that if you found this paper via a
                  search you may not see your keyword on this page. Instead you
                  can visit the;
                </div>
              }
            />
          ) : null}
          {showUPWInfo && (
            <Alert
              type='info'
              description={
                <UPWLink fullTextArticle={publication.fullTextArticles[0]} />
              }
            />
          )}
          <Pane title='Abstract'>
            {publication.abstractTexts.length
              ? publication.abstractTexts?.map(a => (
                  <Detail key={a.id} label={a.label}>
                    {a.abstract}
                  </Detail>
                ))
              : 'Not available'}
          </Pane>
          <Pane title='Keywords'>
            <div>
              {publication.keywords.length
                ? publication.keywords?.map((k, i) => (
                    <Keyword key={`${k.id}-${i}`}>{k.keywordValue}</Keyword>
                  ))
                : 'Not available'}
            </div>
          </Pane>
          <Pane title='Full Text'>
            <FullTextData fullTextArticle={publication.fullTextArticles[0]} />
          </Pane>
          <Pane title='Focused Research Areas'>
            <div>
              {publication.meshHeadings.length
                ? publication.meshHeadings?.map(m => (
                    <Keyword key={m.meshHeading.id}>
                      {m.meshHeading.descriptorName}
                    </Keyword>
                  ))
                : 'Not available'}
            </div>
          </Pane>
          <Pane title='Topics'>
            <div>
              {publication.topics.length
                ? publication.topics?.map(m => (
                    <Keyword key={m.id}>{m.name}</Keyword>
                  ))
                : 'Not available'}
            </div>
          </Pane>
        </LeftColumn>
        <RightColumn>
          <KeywordData data={publication.topKeyWordStats} citationId={id} />
          <Pane title='Related Information'>
            <Detail label='Other Versions'>
              {showFamilyGroup &&
                publication?.familyGroup.map((item, i) =>
                  getFamilyGroupMember(item)
                )}
            </Detail>
            <RelatedAuthors authors={publication.influentialAuthors} />
            <Grants grants={publication.grants} />
            {publication.fullTextArticleUrls.map(f => (
              <Link to={f.url} external key={f.url}>
                Full Text Article Link ({f.documentStyle}, {f.availability})
              </Link>
            ))}
            {publication.pmid && (
              <Link
                to={`https://www.ncbi.nlm.nih.gov/pubmed/${publication.pmid}`}
                external
              >
                View Directly in PubMed
              </Link>
            )}
          </Pane>
          <WorldMap data={publication.authorCountry} />
        </RightColumn>
      </Content>
      <Footer>
        <div>{publication.scrapedSourceName}</div>
      </Footer>
    </Container>
  );
};

export default Publication;
