import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: ${p => (p.horizontal ? 'column' : 'row')};
`;

export const Tabs = styled.div`
  border-right: ${p => (p.horizontal ? '0px' : '2px solid rgba(0, 0, 0, 0.1)')};
  display: flex;
  flex-direction: ${p => (p.horizontal ? 'row' : 'column')};
  margin-left: ${p => (p.leftMargin ? '5px' : '')};
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${p => p.theme.lightGrey};
  border-right: 2px solid ${p => (p.active ? 'white' : p.theme.lightGrey)};
  right: -2px;
  position: relative;
  box-shadow: ${p => (p.active ? '' : 'inset -3px 0 9px -8px rgba(0,0,0,0.4)')};
  border-radius: 8px 0px 0px 8px;
  padding: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.2s linear all;
  > svg {
    transition: 0.2s linear color;
  }
`;

export const HorizontalTab = styled(Tab)`
  border-right: 2px solid ${p => p.theme.lightGrey};
  border-bottom: 1px solid ${p => (p.active ? 'white' : p.theme.borderGrey)};
  border-radius: 8px 8px 0px 0px;
  right: 0px;
  bottom: -1px;
  box-shadow: ${p =>
    p.active ? '' : 'inset 0px -3px 9px -8px rgba(0,0,0,0.4)'};
  margin-bottom: 0px;
  margin-right: 5px;
  padding: 10px 15px;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Text = styled.div`
  margin-left: 10px;
  font-size: 11px;
  color: ${p => p.theme.darkGrey};
  font-weight: 500;
  text-transform: uppercase;
`;

export const TabExtenstionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${p => (p.horizontal ? 'center' : 'start')};
`;
