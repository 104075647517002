import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  flex: ${p => (p.fullWidth ? '0 0 100%' : '0 0 50%')};
  position: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  canvas {
    width: 100% !important;
  }
`;

export const GraphLabel = styled.div`
  font-weight: 500;
  width: 100%;
  text-align: center;
  position: relative;
`;
