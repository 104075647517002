import React, { useCallback, useState, useEffect } from 'react';
import { Button, Popover, List, Typography } from 'antd';
import { HighlightSpan } from './HighlightSpan';

const { Text } = Typography;

const HighlightPopover = ({ highlights, parentRef }) => {
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (parentRef?.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    updateWidth();
  }, [parentRef]);

  const getPopupContainer = useCallback(
    triggerNode => {
      if (parentRef.current) {
        return parentRef?.current;
      }
      return triggerNode.parentNode;
    },
    [parentRef]
  );

  if (!highlights || Object.keys(highlights).length === 0) {
    return null; // Don't render anything if highlights are empty
  }

  let allStrings = [];

  // Iterating over the keys and concatenating the values into the array
  let stringSet = new Set();
  for (let key in highlights) {
    if (highlights.hasOwnProperty(key)) {
      highlights[key].forEach(value => {
        // Add ellipsis to the start if it's not the default message
        if (value !== 'Your search matched this activity.') {
          value = '...' + value + '...';
        }
        stringSet.add(value);
      });
    }
  }
  allStrings = Array.from(stringSet);
  // Calculate the width for the List
  const listWidth = Math.max(100, parentWidth * 0.8);

  const content = (
    <div style={{ width: listWidth }}>
      <List
        size='small'
        dataSource={allStrings}
        renderItem={(highlight, index) => (
          <List.Item key={index}>
            <Text style={{ wordBreak: 'break-word' }}>
              <HighlightSpan dangerouslySetInnerHTML={{ __html: highlight }} />
            </Text>
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <Popover
      getPopupContainer={getPopupContainer}
      content={content}
      title='Search Matches'
      trigger='click'
      placement='topRight'
    >
      <Button size='small' type='secondary'>
        Matches
      </Button>
    </Popover>
  );
};

export default HighlightPopover;
