import { useEffect } from 'react';

export default function useHorizontalWheel(ref) {
  // built in react wheel event does not allow preventDefault
  useEffect(() => {
    const scrollableCurrent = ref?.current;

    if (scrollableCurrent) {
      const horitontalScroll = e => {
        if (scrollableCurrent.scrollWidth > scrollableCurrent.clientWidth) {
          e.preventDefault();
          scrollableCurrent.scrollLeft += e.deltaY;
        }
      };

      scrollableCurrent.addEventListener('wheel', horitontalScroll);

      return () => {
        scrollableCurrent.removeEventListener('wheel', horitontalScroll);
      };
    }
  });
}
