import styled from 'styled-components';

import { Tooltip, IconText, Copy, Icon } from 'components/common';
import { colours } from 'utils/theme';
import { useAuth } from 'hooks';

import FindEmail from './FindEmail';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  white-space: nowrap;
  .icon {
    display: ${p => (p.hideIcon ? 'none' : 'flex')} !important;
  }
  .not-found {
    display: flex;
    align-items: center;
  }
`;

const unavailableError = 'A valid email address cannot be found at this time';
const undeliverableError =
  'This email address is not valid, and therefore is not displayed';

const Email = ({
  email,
  emailStatusCategory,
  iconTextComponent = IconText,
  dataToPost,
  buttonSize,
  notFoundIconSize = 'lg',
  allowFind = false,
  hideIcon,
  style,
  explanationComponent,
  researcherInactiveUpdateDate,
}) => {
  const { user } = useAuth();
  const ExplanationComponent = explanationComponent;
  const IconTextComponent = iconTextComponent;

  const findEmail = (
    <IconTextComponent
      value={<FindEmail dataToPost={dataToPost} size={buttonSize} />}
      icon='email'
    />
  );

  const isTrialUser =
    emailStatusCategory === 'TrialUser' || email === 'TrialUser';

  if (isTrialUser) {
    return (
      <Container hideIcon={hideIcon}>
        <IconTextComponent
          key='researcher-email'
          value='Subscribe for Emails'
          icon='email'
        />
      </Container>
    );
  }

  if (user.hideResearcherEmail) return null;

  const isUnavailable = email === 'Email unavailable';
  const isUndeliverable = emailStatusCategory === 'Undeliverable';
  if (isUnavailable || isUndeliverable) {
    return (
      <Container style={style} hideIcon={hideIcon}>
        <IconTextComponent
          value={
            <Tooltip
              title={isUnavailable ? unavailableError : undeliverableError}
              placement='right'
              overlayStyle={{ maxWidth: 345 }}
            >
              <div className='not-found'>
                <Icon
                  size={notFoundIconSize}
                  color={colours}
                  icon='unverified'
                />
              </div>
            </Tooltip>
          }
          icon='email'
        />
      </Container>
    );
  }

  if (email) {
    const isBeingResearched = email === 'Research under way';
    const emailDisplayValue = [
      <IconTextComponent
        key='researcher-email'
        value={isBeingResearched ? email : <Copy value={email} />}
        style={{ flex: 1, minWidth: 0 }}
        textStyle={{ minWidth: 0 }}
        icon='email'
      />,
    ];
    return (
      <Container style={style} hideIcon={hideIcon}>
        {emailDisplayValue}
        {!isBeingResearched && ExplanationComponent && <ExplanationComponent />}
      </Container>
    );
  }

  // If no email present, then either return the Find Email component or nothing
  return allowFind && !researcherInactiveUpdateDate ? (
    <Container style={style} hideIcon={hideIcon}>
      {findEmail}
    </Container>
  ) : null;
};

export default Email;
