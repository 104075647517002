import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${p => (p.darkMode ? '#f7f3ed' : '#f4f4f4')};
    margin: 0;
    padding: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const colours = {
  white: '#fff',
  black: '#595959',
  darkGrey: '#7b7b7b',
  grey: '#8c8c8c',
  borderGrey: '#d9d9d9',
  lightGrey: '#e5e5e5',
  orange: '#ff612c',
  teal: '#56b3c3',
  lightTeal: '#88c0ca',
  red: '#ff5b5b',
  purple: '#8b57c3',
  linkBlue: '#2d9aff',
  grants: 'rgba(153, 102, 255, 0.5)',
  publications: 'rgba(75, 192, 192, 0.5)',
  posters: 'rgba(255, 159, 64, 0.5)',
  trials: 'rgba(255, 37, 67, 0.5)',
  researchers: 'rgba(102, 148, 255, 0.5)',
  breakpoint: {
    mobile: '@media only screen and (max-width: 667px)',
    tablet: '@media only screen and (max-width: 1042px)',
  },
  brands: {
    academia: '#1e3e53',
    linkedin: '#2867b2',
    facebook: '#4267B2',
    google: '#3b7ded',
    twitter: '#1da1f2',
    instagram: '#ee0076',
    researchgate: '#00c4b4',
  },
};

export const theme = {
  white: '#fff',
  black: '#595959',
  darkGrey: '#7b7b7b',
  grey: '#8c8c8c',
  borderGrey: '#d9d9d9',
  lightGrey: '#e5e5e5',
  orange: '#ff612c',
  teal: '#56b3c3',
  lightTeal: '#88c0ca',
  red: '#ff5b5b',
  purple: '#8b57c3',
  linkBlue: '#2d9aff',
  grants: 'rgba(153, 102, 255, 0.5)',
  publications: 'rgba(75, 192, 192, 0.5)',
  posters: 'rgba(255, 159, 64, 0.5)',
  trials: 'rgba(255, 37, 67, 0.5)',
  breakpoint: {
    mobile: '@media only screen and (max-width: 667px)',
    tablet: '@media only screen and (max-width: 1042px)',
  },
  brands: {
    linkedin: '#2867b2',
    facebook: '#4267B2',
    twitter: '#1da1f2',
    instagram: '#ee0076',
    researchgate: '#00c4b4',
  },
};

const getGutter = (width, asInt = false) => {
  if (width <= 667) return asInt ? 4 : '4px';
  if (width <= 1042) return asInt ? 8 : '8px';
  return asInt ? 15 : '15px';
};

const composedTheme = (width, darkMode) => ({
  ...theme,
  gutter: getGutter(width),
  gutterValue: getGutter(width, true),
  darkMode,
});

export default composedTheme;
