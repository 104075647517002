import { useEffect } from 'react';
import debounce from 'lodash.debounce';

import { useGet } from 'hooks';

import { OptionContainer, OptionContent, Option } from './styled';
import { Icon } from 'components/common';
import { Tooltip } from 'antd';

const BaseOptions = ({
  options,
  onOptionClick,
  limit = 10,
  optionKey,
  displayKey,
  disabledKey,
  disabledTooltipText,
  disabledChangesOnHover,
  emptyOption,
  scrollable = false,
  noLimit,
  ...rest
}) => {
  return (
    <OptionContainer {...rest}>
      <OptionContent scrollable={scrollable}>
        {options.length
          ? options.slice(0, noLimit ? undefined : limit).map((option, i) => {
              const isDisabled = disabledKey && option[disabledKey];
              const optionContent = (
                <Option
                  key={option + i}
                  onMouseDown={e =>
                    isDisabled ? e.preventDefault() : onOptionClick(option, e)
                  }
                  disabled={isDisabled}
                  disabledChangesOnHover={disabledChangesOnHover}
                  data-testid={`input-option-${i}`}
                >
                  {isDisabled && (
                    <span style={{ marginRight: '8px' }}>
                      <Icon size='1x' icon='error' color={'#d9d9d9'} />
                    </span>
                  )}
                  {displayKey ? option[displayKey] : option}
                </Option>
              );

              return isDisabled ? (
                <Tooltip
                  key={option + i}
                  title={disabledTooltipText}
                  placement='top'
                >
                  {optionContent}
                </Tooltip>
              ) : (
                optionContent
              );
            })
          : emptyOption && (
              <Option
                key={'emptyOption'}
                data-testid={`input-option-empty`}
                disabled={true}
                onMouseDown={e => e.preventDefault()}
              >
                {emptyOption}
              </Option>
            )}
      </OptionContent>
    </OptionContainer>
  );
};

const LocalOptions = ({ options, displayKey, filterTerm = '', ...rest }) => {
  const filteredOptions = options.filter(o => {
    return displayKey
      ? o[displayKey].toLowerCase().includes(filterTerm.toLowerCase())
      : o.toLowerCase().includes(filterTerm.toLowerCase());
  });

  return (
    <BaseOptions
      options={filteredOptions}
      displayKey={displayKey}
      filterTerm={filterTerm}
      {...rest}
    />
  );
};

/*
/* Extra bit of faff in here using debounce; even though our XHR hook
/* cancels on unMount, the network tab in the dev console can get spammed
/* with cancelled requests, so debouncing the call keeps it clean
*/
const RemoteOptions = ({
  options,
  filterTerm = '',
  otherRemoteParams = {},
  ...rest
}) => {
  const [{ res, error, loading }, getOptions] = useGet({
    url: options,
    query: { Name: filterTerm, ...otherRemoteParams },
  });

  useEffect(() => {
    const debounced = debounce(() => getOptions(), 300);
    debounced(options);

    return () => debounced.cancel();
  }, [filterTerm, options, getOptions]);
  if (error || loading || !res) return null;
  return <BaseOptions options={res} {...rest} />;
};

const Options = props => {
  return typeof props.options === 'string' ? (
    <RemoteOptions data-testid='remote-options' {...props} />
  ) : (
    <LocalOptions data-testid='local-options' {...props} />
  );
};

export default Options;
