import styled from 'styled-components';
import { Switch as AntSwitch } from 'antd';

const Container = styled.div`
  display: inline-flex;
  flex-direction: ${p => (p.reverseLabel ? 'row-reverse' : 'row')};
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  margin: ${p => (p.reverseLabel ? '0 5px 0 0' : '0 0 0 5px')};
`;

const Switch = ({
  label,
  onChange,
  checked,
  reverseLabel,
  className,
  size = 'small',
  disabled,
  ...rest
}) => {
  const handleClick = () => {
    !disabled && onChange && onChange(!checked);
  };

  return (
    <Container
      onClick={handleClick}
      reverseLabel={reverseLabel}
      className={className}
    >
      <AntSwitch checked={checked} size={size} disabled={disabled} {...rest} />
      {label && <Label reverseLabel={reverseLabel}>{label}</Label>}
    </Container>
  );
};

export default Switch;
