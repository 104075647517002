export const getOrganisationResearchers = (state, orgId) =>
  state.organisationResearchers[orgId];
export const getOrganisationResearchersPageNumber = (state, orgId) =>
  state.pageNumbers[orgId];
export const getOrganisationResearchersPageSize = (state, orgId) =>
  state.pageSizes[orgId];
export const getOrganisationResearchersTotals = (state, orgId) =>
  state.totals[orgId];
export const getOrganisationTradeShowTotals = (state, orgId) =>
  state.tradeshowTotals[orgId];
export const getOrganisationClinicalTrialTotals = (state, orgId) =>
  state.clinicalTrialTotals[orgId];
export const getOrganisationWorkItemTotals = (state, orgId) =>
  state.workItemTotals[orgId];
export const getOrganisationResearchersLoading = (state, orgId) =>
  state.loading[orgId];
export const getOrganisationResearcherSearchError = (state, orgId) =>
  state.researcherSearchError[orgId];
export const getOrganisationWorkItemSearchError = (state, orgId) =>
  state.workItemSearchError[orgId];
export const getFiltersByGroupId = (state, orgId) => state.filters[orgId];
export const getFilter = (state, orgId, id) =>
  state.filters[orgId] ? state.filters[orgId][id] : undefined;
export const getWorkItemCategoryFilter = (state, orgId, id) => {
  if (state.filters[orgId]) {
    if (state.filters[orgId]['workItemCategories']) {
      return state.filters[orgId]['workItemCategories'][id];
    }
  }
  return true;
};

export const getWorkItemCategoryItemSearchFilter = (state, orgId, id) => {
  if (state.workItemFilters[orgId]) {
    if (state.workItemFilters[orgId]['workItemCategories']) {
      return state.workItemFilters[orgId]['workItemCategories'][id];
    }
  }
  return true;
};

export const getSort = (state, orgId) => state.sort[orgId];
export const getOrganisationExhibitorData = (state, orgId) =>
  state.organisationExhibitors[orgId];
export const getOrganisationLoadingExhibitorData = (state, orgId) =>
  state.loadingExhibitorData[orgId];

export const getOrganisationClinicalTrialData = (state, orgId) =>
  state.organisationClinicalTrials[orgId];
export const getOrganisationLoadingClinicalTrialData = (state, orgId) =>
  state.loadingClinicalTrialData[orgId];
export const getOrganisationClinicalTrialPageNumber = (state, orgId) =>
  state.clinicalTrialPageNumbers[orgId];
export const getOrganisationClinicalTrialPageSize = (state, orgId) =>
  state.clinicalTrialPageSize[orgId];
export const getOrganisationClinicalTrialSort = (state, orgId) =>
  state.clinicalTrialSort[orgId];
export const getOrganisationClinicalTrialFilter = (state, orgId, id) =>
  state.clinicalTrialFilters[orgId]
    ? state.clinicalTrialFilters[orgId][id]
    : undefined;
export const getOrganisationClinicalTrialFilters = (state, orgId) =>
  state.clinicalTrialFilters[orgId];
export const getOrganisationClinicalTrialsIsLoading = (state, orgId) =>
  state.clinicalTrialsLoading[orgId];

export const getOrganisationView = state => state.view;
export const getHasFilters = (state, orgId, keys) => {
  const currentKeys = state.filters[orgId]
    ? Object.keys(state.filters[orgId])
    : null;
  return keys.find(key => currentKeys?.includes(key));
};
export const getPurchasingSearchRequest = (state, orgId) =>
  state.purchasingSearchRequest[orgId];

export const getPurchasingFilter = ({ purchasingFilters }, id, group) => {
  if (group && purchasingFilters[group]) {
    return purchasingFilters[group][id];
  }
  return purchasingFilters[id];
};

export const getPurchasingFilters = state => state.purchasingFilters;
export const getPurchasingPageSize = state => state.purchasingPageSize;
export const getPurchasingPageNumber = state => state.purchasingPageNumber;
export const getPurchasingSort = state => state.purchasingSort;
export const getPurchasingSortDir = state => state.purchasingSortDir;
export const getLastSuccessfulPurchasingQuery = state =>
  state.purchasingLastSuccessfulSearch.query;
export const getPurchasingLoading = state => state.purchasingLoading;
export const getShowNotice = state => state.showNotice;
export const getShowClinicalTrialNotice = state =>
  state.showClinicalTrialNotice;

export const getOrganisationWorkItemPageNumber = (state, orgId) =>
  state.workItemPageNumbers[orgId];
export const getOrganisationWorkItemPageSize = (state, orgId) =>
  state.workItemPageSize[orgId];
export const getOrganisationWorkItemSort = (state, orgId) =>
  state.workItemSort[orgId];
export const getOrganisationWorkItemIsLoading = (state, orgId) =>
  state.workItemLoading[orgId];
export const getOrganisationWorkItems = (state, orgId) =>
  state.workItems[orgId];
export const getOrganisationWorkItemFilter = (state, orgId, id) =>
  state.workItemFilters[orgId] ? state.workItemFilters[orgId][id] : undefined;
export const getOrganisationWorkItemFilters = (state, orgId) =>
  state.workItemFilters[orgId];
export const getBlockedForWorkItemExport = (state, orgId) =>
  state.blockedForWorkItemExport[orgId];
export const getBlockedForResearchersExport = (state, orgId) =>
  state.blockedForResearchersExport[orgId];
export const getBlockedForClinicalTrialExport = (state, orgId) =>
  state.blockedForClinicalTrialExport[orgId];
export const getOrganisationClinicalTrialSearchError = (state, orgId) =>
  state.clinicalTrialSearchError[orgId];

export const getHasValidQuery = (state, orgId) => {
  if (state.filters[orgId]) {
    if (state.filters[orgId]['workItemCategories']) {
      const workItemCategories = state.filters[orgId]['workItemCategories'];
      const keys = Object.keys(workItemCategories);
      for (let i = 0; i < keys.length; i++) {
        if (workItemCategories[keys[i]]) {
          return true;
        }
      }
      return false;
    }
  }
  return true;
};

export const getHasValidItemSearchQuery = (state, orgId) => {
  if (state.workItemFilters[orgId]) {
    if (state.workItemFilters[orgId]['workItemCategories']) {
      const workItemCategories =
        state.workItemFilters[orgId]['workItemCategories'];
      const keys = Object.keys(workItemCategories);
      for (let i = 0; i < keys.length; i++) {
        if (workItemCategories[keys[i]]) {
          return true;
        }
      }
      return false;
    }
  }
  return true;
};

export const getHasValidClinicalTrialSearchQuery = (state, orgId) => {
  if (state.workItemFilters[orgId]) {
    return true;
  }
  return true;
};
