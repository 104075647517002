import { types } from 'actions/search';
import {
  activitySearch,
  organisationGeoSearch,
  organisationInsightsSearch,
  organisationSearch,
  researcherGeoSearch,
  researcherSearch,
} from 'services/api';
import { buildQuery, noValue } from 'utils/search';
import { searchOrigin, view } from 'components/Search/Results/constants';
import { getHasValidQuery } from './selectors';
import { handleSearchError, isExceededClausesLimit } from '../notifications';
import {
  ResearcherSearch,
  OrganisationSearch,
  ActivitySearch,
  getSearchOrigin,
} from 'components/Search/Results/helpers';
import { views } from 'components/Lists/constants';

const resetSkipKeys = ['term', 'category', 'date'];

const defaultState = {
  view: view.researcher,
  sortBy: 'TotalMatches',
  orgSortBy: 'Date',
  page: 0,
  lastSuccessfulSearch: {},
  externalSearch: {},
  searchRequest: {
    loading: false,
    error: false,
    termCountError: false,
    res: {},
    orgRes: {},
    orgVisualisations: {},
    activityRes: {},
  },
  geoSearchData: {
    res: {},
    orgRes: {},
  },
  listsRequest: {
    loading: false,
    error: false,
    res: [],
  },
  blockedForExport: false,
  exportRequest: {
    loading: false,
    error: false,
    res: {},
  },
  filters: {
    term: '',
    refreshSearch: false,
    category: {
      funding: true,
      tradeshows: true,
      publications: true,
    },
    date: 'all',
  },
  restoringState: true,
  showBuilder: false,
  searchBuilderFields: {},
  researcherIds: [],
  listAssignedToResearchers: {
    loading: false,
    error: false,
    res: {},
  },
  organisationIds: [],
  listAssignedToOrganisations: {
    loading: false,
    error: false,
    res: {},
  },
  listAssignedToResearcherAvailable: false,
  mapRegion: 1,
  isPdfExportRunning: false,
  openFilterSections: {},
  dismissedVCFundingSortModal: false,
  addMembersToListFromSearchError: '',
};

const REDUCER = {
  [types.SET_FILTER]: (state, action) => {
    const newState = { ...state.filters };
    const { id, value } = action.payload;
    if (noValue(value)) {
      delete newState[id];
    } else {
      newState[id] = value;
    }
    if (id === 'tradeshow' && value.length === 0) {
      delete newState.thisShowOnly;
      delete newState.exhibitingShow;
      delete newState.presentingAtTradeshow;
    }
    if (id === 'tradeshow' && value.length && !newState.thisShowOnly) {
      newState.presentingAtTradeshow = true;
    }
    if (id === 'sessionType' && value.length === 0) {
      delete newState.thisSessionTypeOnly;
    }
    if (id === 'thisShowOnly' && value) {
      delete newState.exhibitingShow;
      delete newState.presentingAtTradeshow;
    }
    if (id === 'announcedDateSelection' && value !== 'custom') {
      delete newState.announcedDatePicked;
    }
    if (id === 'acquisitionDateSelection' && value !== 'custom') {
      delete newState.acquisitionDatePicked;
    }
    if (id === 'showAcquiredCompanies' && !value) {
      delete newState.acquirers;
    }
    if (
      (id === 'showAcquiredCompanies' || id === 'showAcquiringCompanies') &&
      !newState.showAcquiredCompanies &&
      !newState.showAcquiringCompanies
    ) {
      delete newState.acquisitionDatePicked;
      delete newState.acquisitionDateSelection;
      delete newState.acquirers;
      delete newState.acquisitionType;
      delete newState.minAcquisitionPrice;
      delete newState.maxAcquisitionPrice;
    }
    if (id === 'state') {
      const prevStates = state.filters.state || [];
      if (value && value.length > prevStates.length) {
        const currentGeography = newState.organisationGeography || [];
        const lastEntry = value[value.length - 1];
        if (!currentGeography.includes(lastEntry.countryName)) {
          newState.organisationGeography = [
            ...currentGeography,
            lastEntry.countryName,
          ];
        }
      }
    }
    return {
      ...state,
      filters: newState,
      blockedForExport: id === 'refreshSearch' ? false : true,
    };
  },
  [types.SET_FILTERS]: (state, action) => {
    const { filters } = action.payload;
    const newState = { ...state.filters, ...filters };

    Object.entries(newState).forEach(([key, value]) => {
      if (noValue(value)) {
        delete newState[key];
      }
    });

    return {
      ...state,
      filters: newState,
      blockedForExport: true,
    };
  },
  [types.SET_FILTERS_FROM_ALERT]: (state, action) => {
    let { view, filters, date } = action.payload;
    let parsedSearch = filters;
    if (date) {
      parsedSearch['date'] = date;
    }
    return {
      ...state,
      view:
        view === searchOrigin.organisationSearch
          ? views.organisations
          : views.researchers,
      filters: {
        ...defaultState.filters,
        ...parsedSearch,
        refreshSearch: true,
      },
      dismissedVCFundingSortModal: true,
    };
  },

  [types.SET_EXTERNAL_SEARCH_FROM_SAVED_SEARCH]: (state, action) => {
    let { view, filters, date } = action.payload;
    let newFilters = { term: filters, date: date };
    return {
      ...state,
      view:
        view === searchOrigin.organisationSearch
          ? views.organisations
          : views.researchers,
      externalSearch: { ...newFilters },
      filters: {},
    };
  },

  [types.SET_FILTER_GROUP]: (state, action) => {
    let newState = { ...state.filters };
    const { group, id, value } = action.payload;
    if (noValue(value)) {
      delete newState[group][id];
    } else {
      newState = {
        ...newState,
        [group]: {
          ...newState[group],
          [id]: value,
        },
      };
    }
    if (Object.keys(newState[group]).length === 0) {
      delete newState[group];
    }
    return {
      ...state,
      filters: newState,
      blockedForExport: true,
    };
  },
  [types.LOAD_MORE]: (state, action) => {
    const {
      lastSuccessfulSearch: {
        query,
        highlightQuery,
        lists,
        excludeLists,
        nestedQueries,
        organisationLists,
        organisationExcludeLists,
      },
    } = state;

    let url, order, res, orgRes, activityRes, nextPage;
    const searchOrigin = getSearchOrigin(state.view);
    switch (searchOrigin) {
      case 'ResearcherSearch':
        url = researcherSearch;
        order = state.sortBy;
        res = state.searchRequest.res;
        orgRes = {};
        activityRes = {};
        nextPage = null;
        break;
      case 'OrganisationSearch':
        url = organisationSearch;
        order = state.orgSortBy;
        res = {};
        orgRes = state.searchRequest.orgRes;
        activityRes = {};
        nextPage = null;
        break;
      case 'ActivitySearch':
        url = activitySearch;
        order = 'Date';
        nextPage = state.searchRequest.activityRes.nextPage;
        res = {};
        orgRes = {};
        activityRes = state.searchRequest.activityRes;
        break;
      default:
        // Handle default case if necessary
        break;
    }
    action.payload.request({
      url: url,
      body: {
        query: query,
        highlightQuery,
        count: 15,
        from: state.page * 15,
        order: order,
        orderBy: 'desc',
        filters: {
          lists,
          excludeLists,
          organisationLists,
          organisationExcludeLists,
        },
        nestedQueries,
        nextPage,
      },
    });

    return {
      ...state,
      searchRequest: {
        res: res,
        orgRes: orgRes,
        activityRes: activityRes,
        loading: true,
        error: false,
        termCountError: false,
      },
    };
  },

  [types.PERFORM_ORG_INSIGHTS_SEARCH]: (state, action) => {
    if (!getHasValidQuery(state)) {
      return {
        ...state,
        searchRequest: {
          res: state.searchRequest.res,
          orgRes: state.searchRequest.orgRes,
          orgVisualisations: {},
          loading: false,
          error: false,
          termCountError: false,
        },
      };
    }

    const [query, highlightQuery, terms, nestedQueries] = buildQuery(
      state.filters,
      state.view
    );

    action.payload.request({
      url: organisationInsightsSearch,
      body: {
        query,
        highlightQuery,
        count: 15,
        from: 0,
        order: 'Date',
        orderBy: 'desc',
        filters: { ...terms },
        nestedQueries,
      },
    });

    return {
      ...state,
      lastSuccessfulSearch: {
        query,
        highlightQuery,
        ...state.filters,
        nestedQueries,
      },
      page: 0,
      searchRequest: {
        res: state.searchRequest.res,
        orgRes: state.searchRequest.orgRes,
        orgVisualisations: {},
        activityRes: state.searchRequest.activityRes,
        loading: true,
        error: false,
        termCountError: false,
      },
      blockedForExport: false,
    };
  },

  [types.PERFORM_SEARCH]: (state, action) => {
    if (!getHasValidQuery(state)) {
      return {
        ...state,
        searchRequest: {
          res: ResearcherSearch(state.view) ? [] : state.searchRequest.res,
          orgRes: ResearcherSearch(state.view)
            ? state.searchRequest.orgRes
            : [],
          loading: false,
          error: false,
          termCountError: false,
        },
      };
    }
    const [query, highlightQuery, terms, nestedQueries] = buildQuery(
      state.filters,
      state.view
    );

    let url;
    let order;
    let res;
    let orgRes;
    let activityRes;

    const searchOrigin = getSearchOrigin(state.view);
    switch (searchOrigin) {
      case 'ResearcherSearch':
        url = researcherSearch;
        order = state.sortBy;
        res = {};
        orgRes = state.searchRequest.orgRes;
        activityRes = state.searchRequest.activityRes;
        break;
      case 'OrganisationSearch':
        url = organisationSearch;
        order = state.orgSortBy;
        res = state.searchRequest.res;
        orgRes = {};
        activityRes = state.searchRequest.activityRes;
        break;
      case 'ActivitySearch':
        url = activitySearch;
        order = 'Date';
        res = state.searchRequest.res;
        orgRes = state.searchRequest.orgRes;
        activityRes = {};
        break;
      default:
        // Handle default case if necessary
        break;
    }

    action.payload.request({
      url,
      body: {
        query,
        highlightQuery,
        count: 15,
        from: 0,
        order: order,
        orderBy: 'desc',
        filters: { ...terms },
        nestedQueries,
      },
    });

    if (searchOrigin !== 'ActivitySearch') {
      action.payload.geoRequest({
        url: ResearcherSearch(state.view)
          ? researcherGeoSearch
          : organisationGeoSearch,
        body: {
          query,
          order: state.sortBy,
          orderBy: 'desc',
          filters: { ...terms },
          nestedQueries,
        },
      });
    }

    return {
      ...state,
      lastSuccessfulSearch: {
        query,
        highlightQuery,
        ...state.filters,
        nestedQueries,
      },
      page: 0,
      searchRequest: {
        res,
        orgRes,
        activityRes,
        loading: true,
        error: false,
        termCountError: false,
      },
      blockedForExport: false,
    };
  },

  [types.PERFORM_EXTERNAL_SEARCH]: (state, action) => {
    const [query, , terms, nestedQueries] = buildQuery(action.payload.filters);
    action.payload.request({
      url:
        state.view === view.organisation
          ? organisationSearch
          : researcherSearch,
      body: {
        query,
        count: 15,
        from: 0,
        order: ResearcherSearch(state.view) ? state.sortBy : state.orgSortBy,
        orderBy: 'desc',
        filters: { ...terms },
        nestedQueries,
      },
    });

    if (ResearcherSearch(state.view)) {
      action.payload.geoRequest({
        url: researcherGeoSearch,
        body: {
          query,
          order: state.sortBy,
          orderBy: 'desc',
          filters: { ...terms },
          nestedQueries,
        },
      });
    }

    return {
      ...state,
      lastSuccessfulSearch: { query, ...action.payload.filters, nestedQueries },
      page: 0,
      searchRequest: {
        res: ResearcherSearch(state.view) ? {} : state.searchRequest.res,
        orgRes: ResearcherSearch(state.view) ? state.searchRequest.orgRes : {},
        loading: true,
        error: false,
        termCountError: false,
      },
      filters: { ...action.payload.filters },
      externalSearch: {},
      blockedForExport: false,
    };
  },
  [types.SET_GEO_SEARCH_DATA]: (state, action) => {
    return {
      ...state,
      geoSearchData: {
        res: ResearcherSearch(state.view)
          ? action.payload
          : state.geoSearchData.res,
        orgRes: !ResearcherSearch(state.view)
          ? action.payload
          : state.geoSearchData.orgRes,
      },
    };
  },
  [types.SET_ORG_INSIGHTS_SEARCH_REQUEST]: (state, action) => {
    let nextState = state;

    if (action.payload.error) {
      handleSearchError(action.payload.error);
      nextState = {
        ...state,
        searchRequest: {
          ...action.payload,
          res: state.searchRequest.res,
          orgRes: state.searchRequest.orgRes,
          activityRes: state.searchRequest.activityRes,
          orgVisualisations: {},
        },
      };
    }

    if (action.payload.res) {
      nextState = {
        ...state,
        searchRequest: {
          ...state.searchRequest,
          orgVisualisations: action.payload.res,
          loading: action.payload.loading,
          error: action.payload.error,
        },
      };
    }
    return nextState;
  },
  [types.SET_SEARCH_REQUEST]: (state, action) => {
    let nextState = state;

    if (action.payload.error) {
      handleSearchError(action.payload.error);
      nextState = {
        ...state,
        searchRequest: {
          ...action.payload,
          termCountError: isExceededClausesLimit(action.payload.error),
          res: ResearcherSearch(state.view) ? {} : state.searchRequest.res,
          orgRes: OrganisationSearch(state.view)
            ? {}
            : state.searchRequest.orgRes,
          activityRes: ActivitySearch(state.view)
            ? {}
            : state.searchRequest.activityRes,
        },
      };
    }

    if (action.payload.res) {
      if (state.page > 0) {
        nextState = {
          ...state,
          researcherIds: ResearcherSearch(state.view)
            ? action.payload.res.groupedResults
                ?.map(r => r.researcherId)
                .concat(...state.researcherIds)
            : state.researcherIds,
          organisationIds: OrganisationSearch(state.view)
            ? action.payload.res.groupedResults
                ?.map(r => r.superOrganisationId)
                .concat(...state.organisationIds)
            : state.organisationIds,
          searchRequest: {
            ...action.payload,
            res: ResearcherSearch(state.view)
              ? {
                  ...action.payload.res,
                  groupedResults: [
                    ...state.searchRequest.res.groupedResults,
                    ...action.payload.res.groupedResults,
                  ],
                }
              : state.searchRequest.res,
            orgRes: OrganisationSearch(state.view)
              ? {
                  ...action.payload.res,
                  groupedResults: [
                    ...state.searchRequest.orgRes.groupedResults,
                    ...action.payload.res.groupedResults,
                  ],
                }
              : state.searchRequest.orgRes,
            activityRes: ActivitySearch(state.view)
              ? {
                  ...action.payload.res,
                  groupedResults: [
                    ...state.searchRequest.activityRes.groupedResults,
                    ...action.payload.res.groupedResults,
                  ],
                }
              : state.searchRequest.activityRes,
          },
        };
      } else {
        nextState = {
          ...state,
          researcherIds: ResearcherSearch(state.view)
            ? action.payload.res.groupedResults?.map(r => r.researcherId)
            : state.researcherIds,
          organisationIds: OrganisationSearch(state.view)
            ? action.payload.res.groupedResults?.map(r => r.superOrganisationId)
            : state.organisationIds,
          searchRequest: {
            error: action.payload.error,
            loading: action.payload.loading,
            res: ResearcherSearch(state.view)
              ? action.payload.res
              : state.searchRequest.res,
            orgRes: OrganisationSearch(state.view)
              ? action.payload.res
              : state.searchRequest.orgRes,
            activityRes: ActivitySearch(state.view)
              ? action.payload.res
              : state.searchRequest.activityRes,
          },
        };
      }

      if (action.payload.res.newSearchPerformed && state.page > 0) {
        nextState = {
          ...state,
          researcherIds: ResearcherSearch
            ? action.payload.res.groupedResults?.map(r => r.researcherId)
            : [],
          organisationIds: OrganisationSearch(state.view)
            ? []
            : action.payload.res.groupedResults?.map(
                r => r.superOrganisationId
              ),
          showRefreshDialog: ActivitySearch(view) ? false : true,
          page: 0,
          nextResults: action.payload.res,
          searchRequest: {
            ...action.payload.searchRequest,
            res: {
              ...state.searchRequest.res,
            },
            orgRes: {
              ...state.searchRequest.orgRes,
            },
            activityRes: {
              ...state.searchRequest.activityRes,
            },
          },
        };
      }
    }
    return nextState;
  },
  [types.RESET_FILTERS]: state => ({
    ...state,
    filters: Object.entries(state.filters).reduce((acc, [key, value]) => {
      if (resetSkipKeys.includes(key)) {
        return { ...acc, [key]: value };
      } else {
        return acc;
      }
    }, {}),

    blockedForExport: true,
    dismissedVCFundingSortModal: false,
  }),
  [types.CLEAR_FILTERS]: state => ({
    ...state,
    searchRequest: {
      res: [],
      orgRes: [],
      loading: false,
      error: false,
      termCountError: false,
    },
    filters: {
      ...defaultState.filters,
      category: {
        ...defaultState.filters.category,
      },
    },
    openFilterSections: {},
    searchBuilderFields: {},
    blockedForExport: true,
    dismissedVCFundingSortModal: false,
  }),
  [types.SET_LISTS_REQUEST]: (state, action) => ({
    ...state,
    listsRequest: {
      res: action.payload.res,
      loading: action.payload.loading,
      error: action.payload.error,
    },
  }),
  [types.SET_ORG_SORT_BY]: (state, action) => ({
    ...state,
    orgSortBy: action.payload.sortBy,
  }),
  [types.SET_SORT_BY]: (state, action) => ({
    ...state,
    sortBy: action.payload.sortBy,
  }),
  [types.SET_VIEW]: (state, action) => ({
    ...state,
    view: action.payload.view,
  }),
  [types.SET_PAGE]: (state, action) => ({
    ...state,
    page: action.payload.page,
  }),
  [types.INCREMENT_PAGE]: state => ({
    ...state,
    page: state.page + 1,
  }),
  [types.SET_EXTERNAL_SEARCH]: (state, action) => ({
    ...state,
    externalSearch: {
      ...action.payload,
    },
  }),
  [types.SET_SHOW_REFRESH_DIALOG]: (state, action) => {
    let nextState = {
      ...state,
      showRefreshDialog: action.payload,
    };
    return nextState;
  },
  [types.CLEAR_NEXT_RESULTS]: state => ({
    ...state,
    nextResults: null,
  }),
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...defaultState,
        ...state,
        restoringState: false,
      };
    }

    let restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState = {
      ...defaultState,
      ...restoredState,
    };
    restoredState.restoringState = false;
    if (restoredState.searchRequest.loading) {
      restoredState.searchRequest.loading = false;
    }
    return restoredState;
  },
  [types.APPEND_FILTER]: (state, action) => {
    const { id, value } = action.payload;
    const valueAsString = JSON.stringify(value);
    let nextValue = state.filters[id] || [];
    nextValue = nextValue.map(v => JSON.stringify(v));

    if (nextValue.includes(valueAsString)) {
      nextValue = nextValue.filter(x => x !== valueAsString);
    } else {
      nextValue = [...nextValue, valueAsString];
    }

    nextValue = nextValue.map(v => JSON.parse(v));
    const nextState = {
      ...state,
      filters: {
        ...state.filters,
        [id]: nextValue,
      },
      blockedForExport: true,
    };

    if (noValue(nextValue)) {
      delete nextState.filters[id];
    }

    if (id === 'state' && Object.keys(nextValue).length > 0) {
      const currentGeography = nextState.filters.organisationGeography || [];
      const lastEntry = nextValue[nextValue.length - 1];
      if (!currentGeography.includes(lastEntry.countryName)) {
        nextState.filters.organisationGeography = [
          ...currentGeography,
          lastEntry.countryName,
        ];
      }
    }

    return nextState;
  },
  [types.SET_SHOW_BUILDER]: (state, action) => {
    return {
      ...state,
      showBuilder: action.payload,
    };
  },
  [types.SET_QUERY_BUILDER_FIELDS]: (state, action) => {
    const { type, terms, id } = action.payload;
    const builderFields = { ...state.searchBuilderFields };
    builderFields[id] = { ...builderFields[id], [type]: terms };
    return {
      ...state,
      searchBuilderFields: builderFields,
    };
  },
  [types.CLEAR_SEARCH_BUILDER_FIELDS]: state => ({
    ...state,
    searchBuilderFields: {},
  }),
  [types.PERFORM_SEARCH_LISTS_ASSIGNED_TO_RESEARCHERS]: (state, action) => {
    if (
      state.researcherIds === undefined ||
      state.researcherIds.length === 0 ||
      OrganisationSearch(state.view)
    ) {
      return {
        ...state,
        listAssignedToResearcherAvailable: false,
        listAssignedToResearchers: {
          res: [],
          loading: false,
          error: false,
        },
      };
    }
    action.payload.request({
      body: {
        researcherIds: [...state.researcherIds],
      },
    });

    return {
      ...state,
      listAssignedToResearcherAvailable: false,
      listAssignedToResearchers: {
        res: [],
        loading: true,
        error: false,
      },
    };
  },

  [types.SET_LISTS_ASSIGNED_TO_RESEARCHERS]: (state, action) => {
    return {
      ...state,
      listAssignedToResearcherAvailable: true,
      listAssignedToResearchers: {
        res: action.payload.res,
        loading: action.payload.loading,
        error: action.payload.error,
      },
    };
  },
  [types.PERFORM_SEARCH_LISTS_ASSIGNED_TO_ORGANISATIONS]: (state, action) => {
    if (!state.organisationIds?.length || state.view !== view.organisation) {
      return {
        ...state,
        listAssignedToOrganisations: {
          res: [],
          loading: false,
          error: false,
        },
      };
    }
    action.payload.request({
      body: {
        memberIds: [...state.organisationIds],
      },
    });

    return {
      ...state,
      listAssignedToOrganisations: {
        res: [],
        loading: true,
        error: false,
      },
    };
  },

  [types.SET_LISTS_ASSIGNED_TO_ORGANISATIONS]: (state, action) => {
    return {
      ...state,
      listAssignedToOrganisations: {
        res: action.payload.res,
        loading: action.payload.loading,
        error: action.payload.error,
      },
    };
  },
  [types.CLEAR_SEARCH_BUILDER_FIELDS]: (state, action) => {
    const { id } = action.payload;
    const builderFields = { ...state.searchBuilderFields };
    builderFields[id] = {};
    return {
      ...state,
      searchBuilderFields: builderFields,
    };
  },

  [types.SET_MAP_REGION]: (state, action) => ({
    ...state,
    mapRegion: action.payload.region,
  }),

  [types.SET_PDF_EXPORT_STATUS]: (state, action) => {
    return {
      ...state,
      isPdfExportRunning: action.payload.exporting,
    };
  },
  [types.SET_FILTER_SECTION_COLLAPSED]: (state, action) => {
    return {
      ...state,
      openFilterSections: {
        ...state.openFilterSections,
        [action.payload.sectionKey]: !action.payload.isCollapsed,
      },
    };
  },
  [types.SET_HAS_DISMISSED_VC_FUNDING_SORT_MODAL]: (state, action) => {
    return {
      ...state,
      dismissedVCFundingSortModal:
        action.payload.hasDismissedVCFundingSortModal,
    };
  },
  [types.SHOW_VC_FUNDING_SORT_MODAL]: (state, action) => {
    return {
      ...state,
      showVCFundingSortModal: action.payload.showVCFundingSortModal,
    };
  },
  [types.ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR]: (state, action) => {
    return {
      ...state,
      addMembersToListFromSearchError: action.payload.error,
    };
  },
  [types.RESET_ADD_MEMBERS_TO_LIST_FROM_SEARCH_ERROR]: (state, action) => {
    return {
      ...state,
      addMembersToListFromSearchError: '',
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
