import storage from 'services/storage';

export const types = {
  SET_NEED_TO_PASS_CAPTCHA: 'SET_NEED_TO_PASS_CAPTCHA',
  RESTORE_STATE: 'RATE_LIMITS_RESTORE_STATE',
};

const persistState = async getState => {
  const state = getState();
  const toSave = JSON.parse(JSON.stringify(state)).rateLimits;
  await storage.setItem('rateLimits', toSave);
};

export const setNeedPassCaptcha = value => (dispatch, getState) => {
  dispatch({
    type: types.SET_NEED_TO_PASS_CAPTCHA,
    payload: value,
  });
  persistState(getState);
};

export const restoreState = state => ({
  type: types.RESTORE_STATE,
  payload: state,
});
