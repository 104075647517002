import { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getBreadcrumb } from 'selectors/other';
import { setBreadcrumb } from 'actions/other';

const topLevelRoutes = {
  '/': true,
  '/searches': true,
  '/lists': true,
  '/calendar': true,
  '/purchasing': true,
  '/settings': true,
};

const Container = styled.div`
  margin-top: ${p => p.theme.gutter};
`;

const Breadcrumb = () => {
  const dispatch = useDispatch();
  const breadcrumb = useSelector(getBreadcrumb) || [];
  const { pathname } = useLocation();

  useEffect(() => {
    if (topLevelRoutes[pathname]) {
      dispatch(setBreadcrumb([]));
    }
  }, [pathname, dispatch]);

  const handleClick = id => {
    const newCrumbs = [];

    for (let c of breadcrumb) {
      if (c.id === id) break;
      newCrumbs.push(c);
    }

    dispatch(setBreadcrumb(newCrumbs));
  };

  return (
    <Container>
      {breadcrumb.map((crumb, i) => (
        <Fragment key={`${crumb.name}-${i}`}>
          <Link to={crumb.path} onClick={() => handleClick(crumb.id)}>
            {crumb.name}
          </Link>
          <span>{' > '}</span>
          {i === breadcrumb.length - 1 && <span>Here</span>}
        </Fragment>
      ))}
    </Container>
  );
};

export default Breadcrumb;
