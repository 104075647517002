export const view = {
  researcher: 'researcher',
  organisation: 'organisation',
  activity: 'activity',
  visualisation: 'visualisation',
  map: 'map',
  organisationMap: 'organisationMap',
  organisationInsights: 'organisationInsights',
};

export const regions = {
  world: 0,
  europe: 1,
  asia: 2,
  africa: 3,
  usa: 4,
  southAmerica: 5,
  canada: 6,
};

export const geoArea = {
  region: 0,
  country: 1,
  state: 2,
};

export const searchOrigin = {
  organisationSearch: 'OrganisationSearch',
  researcherSearch: 'ResearcherSearch',
  activitySearch: 'ActivitySearch',
  organisationPageSearch: 'OrganisationPageSearch',
  purchasingSearch: 'PurchasingSearch',
  tradeshow: 'Tradeshow',
  researcherList: 'ResearcherList',
  organisationList: 'OrganisationList',
};
