import { EXPORT_STATUS } from '../ExportHistoryHelper';
import Download from './Download';
import ViewSummary from './ViewSummary';
import SearchAgain from './SearchAgain';
import React from 'react';
import { downloadType } from 'components/Export/Summary/constants';

const ActionButtons = ({
  type,
  status,
  structuredSearch,
  downloadGuid,
  organisationId,
  researcherListId,
  researcherId,
  setSelectedCRM,
  selectedCRM,
  organisationListId
}) => {
  const fetchActionButton = (
    exportType,
    status,
    structuredSearch,
    downloadGuid
  ) => {
    if (exportType === downloadType.crmPush) {
      return (
        <div style={{ display: 'flex', flexWrap: 'no-wrap' }}>
          <SearchAgain
            type={type}
            researcherListId={researcherListId}
            showText={false}
          />
          <span style={{ marginRight: '5px' }} />
          <ViewSummary
            disabled={status !== EXPORT_STATUS.SUCCESS}
            selectedCRM={selectedCRM}
            setSelectedCRM={setSelectedCRM}
          />
        </div>
      );
    } else {
      if (status === EXPORT_STATUS.EXPIRED || status === EXPORT_STATUS.FAILED) {
        return (
          <SearchAgain
            organisationId={organisationId}
            type={type}
            structuredSearch={structuredSearch}
            researcherListId={researcherListId}
            researcherId={researcherId}
            organisationListId={organisationListId}
          />
        );
      } else {
        return (
          <div style={{ display: 'flex', flexWrap: 'no-wrap' }}>
            <SearchAgain
              organisationId={organisationId}
              type={type}
              structuredSearch={structuredSearch}
              researcherListId={researcherListId}
              researcherId={researcherId}
              showText={false}
              organisationListId={organisationListId}
            />
            <span style={{ marginRight: '5px' }} />
            <Download
              disabled={status === EXPORT_STATUS.INPROGRESS}
              downloadGuid={downloadGuid}
            />
          </div>
        );
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        maxWidth: '160px',
        marginLeft: 'auto',
      }}
    >
      <div style={{ marginLeft: 'auto' }}>
        {fetchActionButton(type, status, structuredSearch, downloadGuid)}
      </div>
    </div>
  );
};

export default ActionButtons;
