import { useState } from 'react';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { Button, Input, Logo, Form } from 'components/common';
import { usePost } from 'hooks';
import { resetUserPassword } from 'services/api';

import EmailSent from './EmailSent';
import SetNewPass from './SetNewPass';
import { Container, Pane, InnerContent } from '../styled';

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const { push } = useHistory();
  const { search } = useLocation();
  const { code } = qs.parse(search);
  const [{ res: resetRes, loading }, postReset] = usePost({
    url: resetUserPassword,
  });

  const handleReset = () => {
    postReset({ username });
  };

  const toLogin = () => {
    push('/login');
  };

  if (resetRes) return <EmailSent />;
  if (code)
    return (
      <Container>
        <Logo />
        <Pane>
          <InnerContent>
            <SetNewPass code={code} />
          </InnerContent>
        </Pane>
      </Container>
    );

  return (
    <>
      <div className='form-header'>Forgot Password</div>
      <InnerContent>
        <p>
          Forgot your password? Enter your email address associated with your
          account, and we'll send you instructions to reset it.
        </p>
        <Form onSubmit={handleReset}>
          <div>
            <div className='label'>Email</div>
            <Input
              disabled={loading}
              onChange={setUsername}
              value={username}
              placeholder='Enter Email Address'
            />
          </div>
        </Form>
        <Button disabled={loading} onClick={handleReset} type='primary'>
          Reset Password
        </Button>
      </InnerContent>
      <Button
        type='link'
        disabled={loading}
        onClick={toLogin}
        className='back-to-login'
      >
        Back to Login
      </Button>
    </>
  );
};

export default ResetPassword;
