import React, { useEffect } from 'react';
import { Layout } from 'antd';

import { FullPageLoader } from 'components/common';
import { useGet } from 'hooks';

import Logo from './Logo';
import Carousel from './Carousel';
import CTA from './CTA';
import {
  LayoutContainer,
  HeaderContainer,
  ContentContainer,
  FooterContainer,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginCarouselContent } from 'selectors/other';
import { setLoginCarouselContent } from 'actions/other';

const { Sider } = Layout;

const contentUrl =
  process.env.REACT_APP_SIDEBAR_CAROUSEL_CONTENT ||
  '/content.json';
function BaseLayout({ children }) {
  const dispatch = useDispatch();
  const content = useSelector(getLoginCarouselContent);
  const [{ res, loading: isContentloading }] = useGet({
    url: contentUrl,
    onMount: !content,
  });

  useEffect(() => {
    if (res) {
      dispatch(setLoginCarouselContent(res));
    }
  }, [res, dispatch]);

  if (isContentloading) return <FullPageLoader />;

  return (
    <LayoutContainer className='top'>
      <LayoutContainer>
        <HeaderContainer className='login-header'>
          <Logo />
        </HeaderContainer>
        <ContentContainer>{children}</ContentContainer>
        <FooterContainer>
          <CTA />
        </FooterContainer>
      </LayoutContainer>
      {(content?.featureDetails?.length || content?.customerQuotes?.length) && (
        <Sider width='50%' collapsed={false}>
          <Carousel content={content} />
        </Sider>
      )}
    </LayoutContainer>
  );
}

export default BaseLayout;
