export const boolValues = ['OR', 'AND', 'NOT', 'TO'];
export const avoidChars = [
  ')',
  '(',
  ':',
  '"',
  '[',
  ']',
  '^',
  '~',
  '+',
  '>',
  '=',
];
