import { formatNumber } from 'utils/number';

const generateAuthorCounts = (
  firstNamedCount,
  lastNamedCount,
  totalCollabs
) => {
  return (
    <>
      <span style={{ marginRight: 8 }}>
        First author:{' '}
        <span style={{ fontWeight: 'normal' }}>{firstNamedCount}</span>
      </span>
      <span style={{ marginRight: 8 }}>
        Last author:{' '}
        <span style={{ fontWeight: 'normal' }}>{lastNamedCount}</span>
      </span>
      <span>
        Collaborators:{' '}
        <span style={{ fontWeight: 'normal' }}>{totalCollabs}</span>
      </span>
    </>
  );
};

const PublicationsFooter = ({
  firstNamedCount,
  lastNamedCount,
  totalCollabs,
  impactAll,
  impactThree,
}) => {
  const showImpact = impactAll || impactThree;

  return (
    <div>
      <div>
        {generateAuthorCounts(firstNamedCount, lastNamedCount, totalCollabs)}
      </div>
      {!!showImpact && (
        <div>
          <span>Research Impact: </span>
          {impactAll > 0 && (
            <span style={{ fontWeight: 'normal' }}>
              {formatNumber(impactAll)} (All Time Avg)
            </span>
          )}
          {impactThree > 0 && (
            <span style={{ fontWeight: 'normal', marginLeft: 6 }}>
              {formatNumber(impactThree)} (3 Year Avg)
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default PublicationsFooter;
