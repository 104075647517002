import { useEffect } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import { getActiveList, getView } from 'selectors/lists';
import { useDel } from 'hooks';
import { deleteList } from 'services/api';

const Delete = ({ onClose }) => {
  const view = useSelector(getView);
  const activeList = useSelector(getActiveList);
  const [{ res: deleteRes, loading }, delList] = useDel({
    url: deleteList(activeList.id, view),
  });

  useEffect(() => {
    if (deleteRes) {
      onClose({ deleteListSuccessfully: true });
    }
  }, [onClose, deleteRes]);

  const handleDelete = () => {
    delList();
  };

  return (
    <Modal
      open
      title={`Delete ${activeList.name}`}
      onCancel={onClose}
      onOk={handleDelete}
      okText='Yes'
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      Are you sure you want to delete {activeList.name}?
    </Modal>
  );
};

export default Delete;
