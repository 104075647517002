import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/common';
import { useAuth, useGet } from 'hooks';

import { reloadCrmConnections } from 'actions/other';

import { AuthPanel } from './styled';
import { getCrmDataUrl, getCrmImage } from './helpers';

const Unauthed = ({ url, crmType }) => {
  const { checkUser } = useAuth();
  const dispatch = useDispatch();
  const [connecting, setConnecting] = useState(false);

  const [{ res }, checkCrmConnection] = useGet({
    url: getCrmDataUrl(crmType),
    onMount: true,
  });

  useEffect(() => {
    if (!connecting) return;
    const checkCrmConnectionInterval = setInterval(() => {
      checkCrmConnection();
      if (res && res.isAuthenticated) {
        clearInterval(checkCrmConnectionInterval);
        checkUser();
        dispatch(reloadCrmConnections(true));
      }
    }, 2000);
    return () => clearInterval(checkCrmConnectionInterval);
  }, [connecting, checkCrmConnection, res, checkUser, dispatch]);

  const openAuth = () => {
    setConnecting(true);
    window.open(url, '_blank');
  };

  return (
    <AuthPanel>
      <img alt={crmType + ' logo'} src={getCrmImage(crmType)} />
      <Button onClick={openAuth} type='primary'>
        Connect {crmType}
      </Button>
    </AuthPanel>
  );
};

export default Unauthed;
