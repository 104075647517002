import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Icon, Loader, BetaTag } from 'components/common';
import { colours } from 'utils/theme';
import { useAuth, useGet } from 'hooks';
import { autoRenew } from 'services/api';

import Account from './Account';
import { LeftPane, OptionContainer, NavOptions } from './styled';
import { isEmailRequestsRouteAvailable } from '.';

const NavOption = ({
  icon,
  label,
  route,
  validate,
  'data-testid': dataTestId,
}) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { user } = useAuth();

  const active = pathname === route;
  const color = active ? colours.orange : colours.grey;
  const iconColor = active ? colours.orange : colours.darkGrey;

  if (validate && !validate(user)) {
    return null;
  }

  return (
    <OptionContainer
      color={color}
      onClick={() => push(route)}
      data-testid={dataTestId}
    >
      <div>
        <Icon icon={icon} color={iconColor} size='sm' />
      </div>
      <span>{label}</span>
    </OptionContainer>
  );
};

const Nav = () => {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    const allowedRoutes = {
      '/settings/userSettings': true,
      '/settings/crm': user.canPushToSalesforce || user.canPushToHubspot,
      '/settings/users': user.isUserManager,
      '/settings/report': user.isUserManager,
      '/settings/keywords': user.isAccountManager,
      '/settings/email-requests': isEmailRequestsRouteAvailable(user),
    };

    // If user is allowed to access path, do nothing
    // otherwise send them to the first path they're
    // allowed to access
    if (allowedRoutes[pathname]) return;

    if (user.canPushToSalesforce || user.canPushToHubspot) {
      return replace('/settings/crm');
    }
    if (user.isUserManager) return replace('/settings/users');

    // Default view is user settings
    return replace('/settings/userSettings');
  }, [replace, user, pathname]);

  const [{ res: autoRenewStatus, loading }] = useGet({
    url: autoRenew,
    onMount: true,
  });

  if (loading) {
    return (
      <LeftPane>
        <Loader />
      </LeftPane>
    );
  }

  const emailRequestLabel = (
    <span>
      Email Requests <BetaTag />
    </span>
  );
  return (
    <LeftPane title='My Account'>
      <Account autoRenew={autoRenewStatus} />
      <NavOptions>
        <NavOption
          route='/settings/userSettings'
          icon='users'
          label='User Settings'
          data-testid='nav-user-settings'
        />
        <NavOption
          route='/settings/crm'
          icon='crm'
          label='CRM Integrations'
          validate={u => u.canPushToSalesforce || u.canPushToHubspot}
          data-testid='nav-crm-integrations'
        />
        <NavOption
          route='/settings/users'
          icon='users'
          label='User Management'
          data-testid='nav-user-management'
          validate={u => u.isUserManager}
        />
        <NavOption
          route='/settings/report'
          icon='report'
          label='User Reports'
          data-testid='nav-user-report'
          validate={u => u.isUserManager}
        />
        <NavOption
          route='/settings/keywords'
          icon='keywords'
          label='Keyword Management'
          validate={u => u.isAccountManager}
        />
        <NavOption
          route='/settings/email-requests'
          icon='email'
          label={emailRequestLabel}
          validate={isEmailRequestsRouteAvailable}
        />
      </NavOptions>
    </LeftPane>
  );
};

export default Nav;
