import styled, { createGlobalStyle } from 'styled-components';

import { Loader } from 'components/common';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Container = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => (p.theme.darkMode ? '#f7f3ed' : '#f4f4f4')};
  z-index: 999;
`;

const FullPageLoader = () => {
  return (
    <Container data-testid='full-page-loader'>
      <Loader />
      <GlobalStyle />
    </Container>
  );
};

export default FullPageLoader;
