import { Detail, Link } from 'components/common';

const RelatedAuthors = ({ authors }) => {
  if (!authors || !authors.length)
    return <Detail label='Influential Related Authors'></Detail>;
  const content = authors.reduce((acc, author) => {
    if (!author.superResearcherId) return acc;
    return [
      ...acc,
      <div>
        <Link to={`/researcher/${author.superResearcherId}`} inline>
          {author.surname} {author.initials}
        </Link>{' '}
        <Link to={`/publication/${author.citationId}`} inline>
          {author.citationId}
        </Link>
      </div>,
    ];
  }, []);

  return <Detail label='Influential Related Authors'>{content}</Detail>;
};

export default RelatedAuthors;
