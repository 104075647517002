import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SearchContext } from '../../';

import {
  getSearchRequest,
  getSearchView,
  getOrgInsightsSearchRequest,
} from 'selectors/search';

import DataView from './DataView';
import useLog from 'hooks/useLog';
import { ResearcherSearch } from '../helpers';
import { performOrgInsightsSearch } from 'actions/search';

const Container = styled.div`
  display: inline-block;
`;

const ResearcherVisualisations = () => {
  const { res: data, loading } = useSelector(getSearchRequest);
  useLog(
    JSON.stringify({ description: 'User clicked to Insights tab.' }),
    'ViewInsightsTab',
    'Success'
  );

  if (
    !data ||
    !data.visualisations ||
    !data.groupedResults ||
    data?.totalResults === 0 ||
    loading
  ) {
    return null;
  }

  const {
    topTradeshows,
    topCountries,
    fundingByYear,
    publicationsByYear,
    researchersByYear,
    topUsStates,
    topMeshTopics,
    regions,
    tables,
  } = data.visualisations;

  return (
    <Container>
      <DataView
        topTradeshows={topTradeshows}
        topCountries={topCountries}
        regions={regions}
        fundingByYear={fundingByYear}
        publicationsByYear={publicationsByYear}
        topUsStates={topUsStates}
        researchersByYear={researchersByYear}
        topMeshTopics={topMeshTopics}
        tables={tables}
      />
    </Container>
  );
};

const OrgVisualisations = () => {
  const { postOrgInsightsSearch } = useContext(SearchContext);
  const dispatch = useDispatch();
  const { res: data, loading } = useSelector(getOrgInsightsSearchRequest);

  useEffect(() => {
    if (!data && !loading) {
      dispatch(performOrgInsightsSearch(postOrgInsightsSearch));
    }
  }, [postOrgInsightsSearch, dispatch, data, loading]);

  useLog(
    JSON.stringify({
      description: 'User clicked to Organisation Insights tab.',
    }),
    'ViewOrganisationInsightsTab',
    'Success'
  );

  if (!data || loading) {
    return null;
  }

  const {
    topTradeshows,
    topExhibited,
    topVCFunding,
    topCountries,
    regions,
    topUsStates,
    tables,
  } = data;

  return (
    <Container>
      <DataView
        topTradeshows={topTradeshows}
        topCountries={topCountries}
        topExhibited={topExhibited}
        topVCFunding={topVCFunding}
        topUsStates={topUsStates}
        regions={regions}
        tables={tables}
      />
    </Container>
  );
};

export default function Visualisations() {
  const isResearcherView = ResearcherSearch(useSelector(getSearchView));

  return isResearcherView ? (
    <ResearcherVisualisations />
  ) : (
    <OrgVisualisations />
  );
}
