import React from 'react';
import AlertSuccessContent from './AlertSuccessContent';
import { notification } from 'antd';
import { views } from '../constants';

const NotificationSuccess = (callback, type = views.researchers) => {
  notification.open({
    message: 'List Updated',
    description: <AlertSuccessContent type={type} />,
    placement: 'bottomLeft',
  });
  if (callback) callback();
};

export default NotificationSuccess;
