// @ts-check
import React from 'react';
import styled from 'styled-components';

import { CollapsiblePanel, Icon } from 'components/common';
import { colours } from 'utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterSectionIsCollapsed } from 'selectors/search';
import { setFilterSectionIsCollapsed } from 'actions/search';

const Container = styled.div`
  font-size: 14px;
  border-bottom: 1px solid ${p => p.theme.lightGrey};
`;

const Title = styled.div`
  user-select: none;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  color: ${p => p.theme.grey};
  > div {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Content = styled.div`
  padding: 0px 10px 10px;
  > * {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 5px;
    }
  }
`;

/**
 * @param {{up: boolean, children: JSX.Element, rest?: any}} props
 */
const RotatingIconWithProps = ({ up, children, ...rest }) => (
  <div {...rest}>{children}</div>
);

export const RotatingIcon = styled(RotatingIconWithProps)`
  margin-left: auto;
  transform: ${p => (p.up ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.2s linear;
`;

/**
 * @param {{title: string, icon: string, activeKeys: string[], children: JSX.Element, sectionKey: string }} props
 */
const FilterGroup = ({ title, icon, activeKeys, children, sectionKey }) => {
  const isCollapsed = useSelector(getFilterSectionIsCollapsed(sectionKey));
  const dispatch = useDispatch();

  /**
   * @param {boolean} toggle
   */
  function setIsCollapsed(toggle) {
    dispatch(setFilterSectionIsCollapsed(sectionKey, toggle));
  }

  return (
    <Container data-testid={`filter-group-${sectionKey}`}>
      <Title
        data-testid='filter-group-title'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div>
          {/* @ts-ignore */}
          <Icon
            icon={icon}
            color={activeKeys?.length ? colours.orange : colours.darkGrey}
            size='sm'
          />
        </div>
        {title}
        <RotatingIcon up={!isCollapsed}>
          {/* @ts-ignore */}
          <Icon icon={'chevronDown'} size='sm' color={colours.darkGrey} />
        </RotatingIcon>
      </Title>
      <CollapsiblePanel isCollapsed={isCollapsed}>
        {<Content>{children}</Content>}
      </CollapsiblePanel>
    </Container>
  );
};

export default FilterGroup;
