import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  getShowRefreshDialog,
  getNextResultsForRefresh,
} from 'selectors/search';
import {
  setShowRefreshDialog,
  setSearchRequest,
  clearNextResults,
} from 'actions/search';

const RefreshModal = () => {
  const dispatch = useDispatch();
  const showRefreshDialog = useSelector(getShowRefreshDialog);
  const nextResults = useSelector(getNextResultsForRefresh);

  const handleRefresh = () => {
    dispatch(setShowRefreshDialog(false));
    dispatch(clearNextResults);
    dispatch(
      setSearchRequest({
        loading: false,
        error: false,
        res: nextResults,
      })
    );
    window.scrollTo(0, 0);
  };

  const handleCancel = () => {
    dispatch(setShowRefreshDialog(false));
  };

  if (!showRefreshDialog) return null;

  return (
    <Modal
      open
      title='Stale Data Detected'
      okText='Refresh'
      onOk={handleRefresh}
      onCancel={handleCancel}
    >
      While processing your request we noticed that your current result set is
      stale.
      <br />
      <br />
      To load the first page of the latest data, please click{' '}
      <strong>Refresh</strong> below.
      <br />
      <br />
      If you want to continue working with your current result set, please click{' '}
      <strong>Cancel</strong>.
    </Modal>
  );
};

export default RefreshModal;
