import { useEffect, useState, useRef } from 'react';
import { Popover } from 'antd';
import debounce from 'lodash.debounce';

const TablePopover = ({
  children,
  content,
  parentRef,
  widthRatio,
  color,
  style = {},
}) => {
  const childRef = useRef();
  const [parentWidth, setParentWidth] = useState(0);
  const [popoverWidth, setPopoverWidth] = useState(50);
  const [open, setOpen] = useState(false);

  const ratio = (widthRatio ? widthRatio : 0.55) * parentWidth;
  useEffect(() => {
    if (parentRef?.current) {
      setParentWidth(parentRef.current.offsetWidth);
      const handleResize = debounce(() => {
        setParentWidth(parentRef.current.offsetWidth);
      }, 100);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [parentRef]);

  useEffect(() => {
    if (childRef?.current) {
      setPopoverWidth(childRef.current.offsetWidth + 2);
    }
  }, [childRef]);

  return (
    <Popover
      showArrow={false}
      content={
        <div style={{ marginTop: '-10px', marginBottom: '-10px' }}>
          {content}
        </div>
      }
      placement='bottomLeft'
      overlayInnerStyle={{
        border: '2px solid',
        borderColor: color,
        minWidth: popoverWidth,
        width: 'auto',
        boxShadow: 'none',
        maxWidth: ratio,
        display: 'flex',
        overflowY: 'auto',
        overflowX: 'auto',
        maxHeight: '400px',
        ...style,
      }}
      align={{ offset: [0, -42] }}
      onOpenChange={x =>
        setTimeout(function() {
          setOpen(x);
        }, 100)
      }
    >
      <div ref={childRef} style={{ visibility: open ? 'hidden' : 'visible' }}>
        {children}
      </div>
    </Popover>
  );
};
export default TablePopover;
