import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import { useAuth } from 'hooks';

const useHotjar = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (
      user &&
      user.accountId !== 5 &&
      user.accountId !== 41 &&
      user.accountId !== 100
    ) {
      const { enzuzoGtmConsentObj } = window;

      if (!enzuzoGtmConsentObj) return;

      if (enzuzoGtmConsentObj.analytics_storage !== 'denied') {
        hotjar.initialize('3082741', '6');
      }
    }
  }, [user]);
};

export default useHotjar;
