import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { Input, Button, DatePicker } from 'components/common';
import { useGet, usePost } from 'hooks';
import {
  tradeshowCalendar,
  tradeshowCalendarYears,
  requestTradeshow,
  userKeywords,
} from 'services/api';
import { getFilters } from 'selectors/calendar';
import { clearFilters, setFilter } from 'actions/calendar';
import { notification } from 'antd';

import RequestTradeShow from './Modals/RequestTradeshow';
import Table from './Table';
import { Container, FilterContainer } from './styled';
import YearsMenu from './YearsMenu';

const filterData = (data, name, location, year, hasKeywords) => {
  if (!data) return;
  let filteredData = data;
  if (name) {
    filteredData = filteredData.filter(r =>
      r.name.toLowerCase().includes(name.toLowerCase())
    );
  }
  if (location) {
    filteredData = filteredData.filter(r =>
      r.location.toLowerCase().includes(location.toLowerCase())
    );
  }
  if (year) {
    filteredData = filteredData.filter(
      r => r.year.toString() === year.toString()
    );
  }
  filteredData.forEach(d => (d['hasKeywords'] = hasKeywords));

  return filteredData;
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const formatMonthDate = (year, month) => {
  if (!month) return '';
  const monthIndex = months.indexOf(month) + 1;
  const monthString = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;
  return `${year}-${monthString}-01`;
};

const Calendar = () => {
  const {
    name: filterName,
    location: filterLocation,
    year: filterYear,
    month: filterMonth,
    startDate: filterStartDate,
  } = useSelector(getFilters);
  const dispatch = useDispatch();
  const [{ res: years, loadingYears }] = useGet({
    url: tradeshowCalendarYears,
    onMount: true,
  });
  const queryParams = {
    Year: filterYear,
    Month: formatMonthDate(filterYear, filterMonth),
  };

  if (filterStartDate && filterStartDate.length === 2) {
    queryParams.StartDate = new Date(filterStartDate[0])
      .toISOString()
      .split('T')[0];
    queryParams.EndDate = new Date(filterStartDate[1])
      .toISOString()
      .split('T')[0];
  }

  const [{ res, loading }] = useGet({
    url: tradeshowCalendar,
    query: queryParams,
    onMount: true,
  });

  const [
    { res: requestFinished, loading: reportLoading },
    postExport,
  ] = usePost({
    url: requestTradeshow,
  });

  useEffect(() => {
    if (requestFinished) {
      notification.open({
        message: 'Trade Show Request Sent',
        description: (
          <div>
            An email regarding your Trade Show Request has been sent to support
            to be processed.
          </div>
        ),
        placement: 'bottomLeft',
      });
    }
  }, [requestFinished]);

  const [displayTradeShowModal, setDisplayTradeShowModal] = useState(false);
  const [displayCustomDate, setDisplayCustomDate] = useState(false);
  const [localDateRange, setLocalDateRange] = useState(filterStartDate);
  const [selectedYear, setSelectedYear] = useState(filterYear);
  const [selectedMonth, setSelectedMonth] = useState(filterMonth);
  const [{ res: keywords }] = useGet({
    url: userKeywords,
    onMount: true,
  });

  const filteredData = filterData(
    res,
    filterName,
    filterLocation,
    filterYear,
    keywords && keywords[0]
  );

  function onRequestNewTradeShowClick() {
    setDisplayTradeShowModal(true);
  }

  const handleYearSelect = year => {
    dispatch(setFilter('year', year));
    dispatch(setFilter('month', null));
    dispatch(setFilter('startDate', null));
    setDisplayCustomDate(false);
    setLocalDateRange(null);
  };

  const handleMonthSelect = (year, month) => {
    dispatch(setFilter('month', month));
    dispatch(setFilter('year', year));
    dispatch(setFilter('startDate', null));
    setDisplayCustomDate(false);
    setLocalDateRange(null);
  };

  const handleCustomSelect = () => {
    setDisplayCustomDate(true);
  };

  const handleClear = () => {
    setDisplayCustomDate(false);
    setSelectedYear(+dayjs().format('YYYY'));
    setSelectedMonth(null);
    dispatch(clearFilters);
  };

  const handleDateRangeChange = value => {
    setLocalDateRange(value);
    if (!value) {
      return;
    }
    const [start, end] = value;
    if (start && end) {
      dispatch(setFilter('month', null));
      dispatch(setFilter('year', null));
      dispatch(setFilter('startDate', value));
    }
  };

  return (
    <div>
      <Container
        title='Tradeshows Calendar'
        headerRight={
          <Button
            data-testid='request-tradeshow-link'
            type='seconday'
            onClick={onRequestNewTradeShowClick}
          >
            Request New Trade Show
          </Button>
        }
      >
        <FilterContainer style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: 13, flex: '1 0 auto' }}>
            Show tradeshows for
          </div>
          <YearsMenu
            years={years}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            dateRange={filterStartDate}
            onYearSelect={handleYearSelect}
            onMonthSelect={handleMonthSelect}
            onCustomSelect={handleCustomSelect}
            loading={loadingYears}
          />
          {(displayCustomDate || filterStartDate) && (
            <DatePicker
              containerStyle={{ flex: 'none' }}
              type='range'
              placeholder={['Start Date', 'End Date']}
              onChange={handleDateRangeChange}
              value={localDateRange}
            />
          )}
          <Input
            disabled={loading}
            placeholder='Filter Name'
            value={filterName}
            data-testid='filter-name'
            onChange={value => dispatch(setFilter('name', value))}
            allowClear
          />
          <Input
            disabled={loading}
            placeholder='Filter Location'
            value={filterLocation}
            data-testid='filter-location'
            onChange={value => dispatch(setFilter('location', value))}
            allowClear
          />
          <Button
            type='secondary'
            data-testid='clear-search-button'
            onClick={() => handleClear()}
          >
            Clear
          </Button>
        </FilterContainer>
        <Table data={filteredData} loading={loading || loadingYears} />
      </Container>
      {displayTradeShowModal && !reportLoading ? (
        <RequestTradeShow
          canDisplay={displayTradeShowModal}
          setDisplay={setDisplayTradeShowModal}
          performExport={postExport}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Calendar;
