import styled from 'styled-components';
import { Progress, Tooltip } from 'antd';

import { useWindowWidth } from 'hooks';

const StatTitle = styled.div`
  margin-top: 5px;
  font-size: 12px;
  white-space: pre-line;
  text-align: center;
  line-height: 12px;
  color: ${p => p.theme.black};
`;

const Stat = ({ label, value, percent, color, total, onClick }) => {
  const { isMobile } = useWindowWidth();
  const fadedColour = color.replace('0.5', '0.1');

  let title = total
    ? `${value} matched out of ${total} total`
    : `${value} matched`;

  return (
    <Tooltip title={title}>
      <div
        onClick={onClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        <Progress
          format={() => (
            <div style={{ fontSize: 18, fontWeight: 500 }}>{value}</div>
          )}
          trailColor={total > 0 ? fadedColour : null}
          type='circle'
          width={isMobile ? 45 : 60}
          percent={percent}
          strokeColor={color}
          style={{ padding: isMobile ? '0 5px' : '0 15px' }}
          status='normal'
        />
        <StatTitle>{label}</StatTitle>
      </div>
    </Tooltip>
  );
};

export default Stat;
