import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  .beta {
    margin-left: 8px;
    padding: 8px 12px 8px 12px;
    background-color: #f2f8fb;
    color: #1890ff;
    border-radis: 4px;
  }
`;

function Title(props) {
  return (
    <Container>
      Draft Message with AI
      <div className='beta'>Beta</div>
    </Container>
  );
}

export default Title;
