import React from 'react';
import PropTypes from 'prop-types';

function LoadingBar({ width, height, className = '', style = {} }) {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width={width}
        height={height}
        rx='4'
        fill={`url(#paint0_linear_11_${width}_${height})`}
        fillOpacity='0.2'
      />
      <defs>
        <linearGradient
          id={`paint0_linear_11_${width}_${height}`}
          x1='0'
          y1='10'
          x2={width}
          y2='10'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8C8C8C' />
          <stop offset='0.1' stopColor='#EBEBEB'>
            <animate
              attributeName='offset'
              values='0.1;1'
              dur='2s'
              repeatDur='indefinite'
              fill='freeze'
            />
          </stop>
          <stop offset='1' stopColor='#8C8C8C' />
        </linearGradient>
      </defs>
    </svg>
  );
}

LoadingBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
};

export default LoadingBar;
