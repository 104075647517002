import React from 'react';
import PropTypes from 'prop-types';
import { Loader, LoadingBar } from 'components/common';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  .bar {
    margin-bottom: 20px;
  }
  .loader {
    margin: auto;
  }
`;

function FullLoader({ style }) {
  return (
    <Container style={style}>
      <LoadingBar className='bar' width={480} height={36} />
      <LoadingBar className='bar' width={189} height={36} />
      <LoadingBar className='bar' width={480} height={36} />
      <Loader className='loader' />
    </Container>
  );
}

FullLoader.propTypes = {
  style: PropTypes.shape({}),
};

export default FullLoader;
