import { useEffect } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import { usePatch } from 'hooks';
import { manageMembers } from 'services/api';
import {
  getAddToTarget,
  getActiveList,
  getSelectedMemberIds,
  getAreAllMembersSelected,
  getSelectedMembers,
  getView,
} from 'selectors/lists';

import { NotificationSuccess, NotificationFailure } from '../Notifications';

const AddTo = ({ onClose }) => {
  const view = useSelector(getView);
  const targetList = useSelector(getAddToTarget);
  const activeList = useSelector(getActiveList);
  const selectedMembers = useSelector(getSelectedMembers);
  const selectedMemberIds = useSelector(getSelectedMemberIds);
  const allSelected = useSelector(getAreAllMembersSelected);

  const [{ res: addRes, loading, error }, patchMembers] = usePatch({
    url: manageMembers(targetList.id, view),
  });

  useEffect(() => {
    if (addRes) {
      onClose();
      NotificationSuccess(null, view);
    }

    if (error && error.status === 400) {
      onClose();
      NotificationFailure(error.message, view);
    }
  }, [addRes, error, onClose, view]);

  const handleAdd = () => {
    if (allSelected) {
      return patchMembers({ addFromListId: activeList.id });
    }

    if (selectedMembers.all) {
      patchMembers({
        addFromListId: activeList.id,
        excludedFromAllResearcherIds: Object.keys(selectedMembers.exclude),
        excludedFromAllMemberIds: Object.keys(selectedMembers.exclude).map(
          x => +x
        ),
      });
    } else {
      patchMembers({
        researcherIds: selectedMemberIds,
        memberIds: selectedMemberIds,
      });
    }
  };

  const allOrSelectedText = allSelected ? 'all' : 'the selected';

  return (
    <Modal
      open
      title={`Add Members to ${targetList.name}`}
      onCancel={onClose}
      okText='Yes'
      onOk={handleAdd}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      Would you like to add {allOrSelectedText} members of {activeList.name} to{' '}
      {targetList.name}?
    </Modal>
  );
};

export default AddTo;
