import { useEffect, useContext, createContext, useState } from 'react';
import storage from 'services/storage';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const value = useGenerateDarkMode();
  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => useContext(DarkModeContext);

const useGenerateDarkMode = () => {
  const [darkMode, setDarkMode] = useState();

  useEffect(() => {
    const restore = async () => {
      const stored = await storage.getItem('darkMode');
      setDarkMode(stored);
    };

    restore();
  }, []);

  const toggleDarkMode = async () => {
    await storage.setItem('darkMode', !darkMode);
    setDarkMode(s => !s);
  };

  return [darkMode, toggleDarkMode];
};
