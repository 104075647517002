import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePost } from 'hooks';
import {
  performSearch,
  setSearchRequest,
  performExternalSearch,
  performSearchListAssignedToResearchers,
  setListAssignedToResearchers,
  setGeoSearchData,
  setOrgInsightsSearchData,
  performSearchListAssignedToOrganisations,
  setListAssignedToOrganisatinos,
  setFilter,
} from 'actions/search';
import {
  getExternalSearch,
  getFilter,
  getOrganisationIds,
  getResearcherIds,
} from 'selectors/search';

import SearchArea from './SearchArea';
import Statistics from './Statistics';
import Filters from './Filters';
import Results from './Results';
import RefreshModal from './RefreshModal';
import { ResultsArea } from './styled';
import { organisationsLists, researchersLists } from 'services/api';
import FundingSortModal from './FundingSortModal/fundingSortModal';

export const SearchContext = createContext();

const Search = () => {
  const dispatch = useDispatch();
  const [searchState, postSearch] = usePost({});
  const [geoSearchState, postGeoSearch] = usePost({});
  const [orgInsightsSearchState, postOrgInsightsSearch] = usePost({});

  const [
    searchListAssignedToResearcher,
    postSearchListAssignedToResearcher,
  ] = usePost({ url: researchersLists });

  const [
    searchListAssignedToOrganisation,
    postSearchListAssignedOrganisation,
  ] = usePost({ url: organisationsLists });

  const [
    loadingListResearcherInformation,
    setLoadingListResearcherInformation,
  ] = useState(false);

  const externalSearch = useSelector(getExternalSearch);
  const lstResearchersIds = useSelector(getResearcherIds);
  const getRefreshSearch = useSelector(getFilter('refreshSearch'));
  const lstOrganisationsIds = useSelector(getOrganisationIds);

  const reloadListGroupByResearcher = () => {
    if (!loadingListResearcherInformation) {
      setLoadingListResearcherInformation(true);
      dispatch(
        performSearchListAssignedToResearchers(
          postSearchListAssignedToResearcher
        )
      );
    }
  };

  if (Object.keys(externalSearch).length > 0) {
    dispatch(performExternalSearch(postSearch, postGeoSearch, externalSearch));
  }

  useEffect(() => {
    if (searchState.res || searchState.error || searchState.loading) {
      dispatch(setSearchRequest(searchState));
    }
  }, [searchState, dispatch]);

  useEffect(() => {
    if (getRefreshSearch) {
      dispatch(performSearch(postSearch, postGeoSearch));
      dispatch(setFilter('refreshSearch', false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRefreshSearch]);

  useEffect(() => {
    if (geoSearchState.res || geoSearchState.error || geoSearchState.loading) {
      dispatch(setGeoSearchData(geoSearchState));
    }
  }, [geoSearchState, dispatch]);

  useEffect(() => {
    if (
      orgInsightsSearchState.res ||
      orgInsightsSearchState.error ||
      orgInsightsSearchState.loading
    ) {
      dispatch(setOrgInsightsSearchData(orgInsightsSearchState));
    }
  }, [orgInsightsSearchState, dispatch]);

  useEffect(() => {
    if (lstResearchersIds) {
      dispatch(
        performSearchListAssignedToResearchers(
          postSearchListAssignedToResearcher
        )
      );
    }
  }, [lstResearchersIds, dispatch, postSearchListAssignedToResearcher]);

  useEffect(() => {
    if (lstOrganisationsIds) {
      dispatch(
        performSearchListAssignedToOrganisations(
          postSearchListAssignedOrganisation
        )
      );
    }
  }, [lstOrganisationsIds, dispatch, postSearchListAssignedOrganisation]);

  useEffect(() => {
    if (
      searchListAssignedToResearcher.res != null ||
      searchListAssignedToResearcher.error ||
      searchListAssignedToResearcher.loading
    ) {
      setLoadingListResearcherInformation(false);
      dispatch(setListAssignedToResearchers(searchListAssignedToResearcher));
    }
  }, [searchListAssignedToResearcher, dispatch]);

  useEffect(() => {
    if (
      searchListAssignedToOrganisation.res != null ||
      searchListAssignedToOrganisation.error ||
      searchListAssignedToOrganisation.loading
    ) {
      dispatch(
        setListAssignedToOrganisatinos(searchListAssignedToOrganisation)
      );
    }
  }, [searchListAssignedToOrganisation, dispatch]);

  return (
    <>
      <SearchContext.Provider
        value={{
          postSearch,
          postGeoSearch,
          postOrgInsightsSearch,
          reloadListGroupByResearcher,
        }}
      >
        <SearchArea />
        <Statistics />
        <ResultsArea>
          <Filters />
          <Results />
        </ResultsArea>
        <RefreshModal />
        <FundingSortModal />
      </SearchContext.Provider>
    </>
  );
};

export default Search;
