import styled from 'styled-components';

export const Container = styled.div`
  ${p =>
    p.theme.darkMode
      ? `
    background: #31302f;
    color: white;
    box-shadow: none;
  `
      : `
    color: #31302f;
    background: white;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.18);
  `}

  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100vh;
  width: 5rem;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  transition: background 0.2s linear;
  transition: color 0.2s linear;
`;

export const ThemedText = styled.div`
  font-size: 12px;
  text-align: center;
  ${p => (p.isActive ? `color: ${p.theme.orange}` : ``)}
`;

export const IconContainer = styled.div`
  ${p =>
    p.theme.darkMode
      ? `
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
  > svg {
    color: ${p.isActive ? p.theme.orange : 'white'};
  }
  `
      : `
  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
  > svg {
    color: ${p.isActive ? p.theme.orange : p.theme.darkGrey};
  }
  `}

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: normal;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 2px;
  ${p => p.theme.breakpoint.mobile} {
    padding: 15px 5px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  ${p => p.theme.breakpoint.mobile} {
    padding: 15px 5px;
  }
`;

export const StyledAvatar = styled.div`
  border: solid 3px #ff612c;
  width: 48px;
  border-radius: 50%;
  padding: 10px 12px;
  font-weight: bold;
`;
