import styled from 'styled-components';

import { Table as CommonTable } from 'components/common';

export const Container = styled.div`
  margin-top: ${p => p.theme.gutter};
  > div {
    margin-top: ${p => p.theme.gutter};
  }
`;

export const StatisticsContainer = styled.div`
  display: flex;
  > div {
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  > div {
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
    > div {
      margin-bottom: ${p => p.theme.gutter};
      :last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export const FiltersContainer = styled.div`
  > * {
    margin-top: 10px;
  }
`;

export const Table = styled(CommonTable)`
  margin-top: 10px;
  max-height: 75vh;
  overflow-y: auto;
`;

export const FilterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: 10px;
  }
`;

export const FilterWrapper = styled.div`
  flex: 0 0 25%;
  width: 100%;
  position: sticky;
  top: 0;
  align-self: flex-start;
  ${p => p.theme.breakpoint.tablet} {
    margin-right: 0px;
    position: static;
    align-self: auto;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  margin-left: 10px;
  > * {
    margin-right: 10px;
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
    > * {
      margin-left: 0px;
      margin-bottom: 10px;
      :last-child {
        margin-bottom: 0px;
      }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
`;
