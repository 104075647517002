import { Pane } from 'components/common';
import { useEffect, useState } from 'react';
import AllExportsTable from './Tables/AllExportsTable';
import { resetSignalRExportRefresh, setFilters } from 'actions/exportHistory';
import { getExportToRefresh, getFilters } from 'selectors/exportHistory';
import { useAuth, useGet } from 'hooks';
import {
  allExportHistory,
  getUserCredits,
  refreshExportHistory,
} from 'services/api';
import StatsProgress from 'components/Export/Summary/StatsProgress';
import { ActionsHeader, StatsContainer } from './styled';
import { isAcademicFull, isIndustryFull } from 'utils/helpers/license';
import ExportHistoryFilters from './Filters';
import { useDispatch, useSelector } from 'react-redux';

const fullRefreshOrderFields = [
  'academicCredits',
  'biopharmaCredits',
  'status',
  'numberOfEmails',
];

const ExportHistory = () => {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const showUserCreditInformation =
    user.isOnCreditSystem &&
    ((isAcademicFull(user) && user.showAcademicCredits) ||
      (isIndustryFull(user) && user.showIndustryCredits));

  const filters = useSelector(getFilters);

  const [creditData, setCreditData] = useState({
    academicCreditsTotal: 0,
    academicCreditsUsed: 0,
    industryCreditsTotal: 0,
    industryCreditsUsed: 0,
  });

  const [localData, setLocalData] = useState([]);

  const [historyToUpdate, setHistoryToUpdate] = useState();

  const [{ res: allData, loading: isAllLoading }, getAllExportData] = useGet({
    url: allExportHistory,
    onMount: false,
  });

  useEffect(() => {
    if (!isAllLoading) {
      setLocalData(allData);
    }
  }, [allData, isAllLoading]);

  const [
    { res: creditsRes, loading: creditsLoading },
    getUserCreditData,
  ] = useGet({
    url: getUserCredits,
    onMount: false,
  });

  const { id: updatedExportHistoryId } = useSelector(getExportToRefresh) || {};

  const [
    { res: refreshedHistory, loading: isLoadingRefresh },
    refreshExportHistoryItem,
  ] = useGet({
    url: refreshExportHistory(updatedExportHistoryId),
    onMount: false,
  });

  useEffect(() => {
    if (updatedExportHistoryId) {
      const history = localData?.data?.find(
        x => x.id === updatedExportHistoryId
      );
      if (history) {
        if (fullRefreshOrderFields.includes(filters.orderBy)) {
          dispatch(setFilters(f => ({ ...f })));
        }

        refreshExportHistoryItem();
        setHistoryToUpdate(history);
        if (showUserCreditInformation) {
          getUserCreditData();
        }
      }
    }
  }, [
    updatedExportHistoryId,
    localData,
    refreshExportHistoryItem,
    filters.orderBy,
    getUserCreditData,
    showUserCreditInformation,
    dispatch,
  ]);

  useEffect(() => {
    if (refreshedHistory && historyToUpdate && !isLoadingRefresh) {
      const replaceIndex = localData?.data?.findIndex(
        x => x === historyToUpdate
      );

      if (replaceIndex > -1) {
        localData.data[replaceIndex] = refreshedHistory;
        setLocalData({ ...localData, data: [...localData.data] });
      }
      dispatch(resetSignalRExportRefresh());
      setHistoryToUpdate(null);
    }
  }, [
    refreshedHistory,
    historyToUpdate,
    localData,
    isLoadingRefresh,
    dispatch,
  ]);

  function updateFilters(filters) {
    dispatch(setFilters(filters));
  }

  useEffect(() => {
    if (showUserCreditInformation) {
      getUserCreditData();
    }
    getAllExportData({ query: filters });
  }, [showUserCreditInformation, getUserCreditData, getAllExportData, filters]);

  useEffect(() => {
    if (!creditsLoading) {
      setCreditData(creditsRes);
    }
  }, [creditsRes, creditsLoading]);

  const CreditsBar = ({ creditData }) => {
    return (
      <>
        <StatsContainer>
          <StatsProgress
            blockTimeout={true}
            showAcademicCredits={
              user.showAcademicCredits && isAcademicFull(user)
            }
            academicCreditsUsed={creditData?.academicCreditsUsed ?? 0}
            academicCreditsRequired={0}
            academicCreditsTotal={creditData?.academicCreditsTotal ?? 0}
            showIndustryCredits={
              user.showIndustryCredits && isIndustryFull(user)
            }
            industryCreditsUsed={creditData?.industryCreditsUsed ?? 0}
            industryCreditsRequired={0}
            industryCreditsTotal={creditData?.industryCreditsTotal ?? 0}
            renewDays={creditData?.daysUntilReset}
          />
        </StatsContainer>
      </>
    );
  };

  return (
    <Pane title={'Export History'}>
      <ActionsHeader>
        <ExportHistoryFilters
          fullWidth={!showUserCreditInformation}
          filters={filters}
          user={user}
          updateFilters={updateFilters}
        />
        {showUserCreditInformation && <CreditsBar creditData={creditData} />}
      </ActionsHeader>
      <AllExportsTable
        user={user}
        isLoading={isAllLoading}
        data={localData}
        updateFilters={updateFilters}
        filters={filters}
      />
    </Pane>
  );
};

export default ExportHistory;
