import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmailExplanation from 'components/common/Researcher/EmailExplanation';
import {
  getOrganisationResearchers,
  getOrganisationResearchersLoading,
  getOrganisationResearchersPageNumber,
  getOrganisationResearchersPageSize,
  getSort,
} from 'selectors/organisation';
import { setPageNumber, setPageSize, setSort } from 'actions/organisation';
import { makeHttpsUrl } from 'utils/helpers/externalLink';
import { format } from 'utils/date';
import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';

import { SparklesIcon, Button, Link, ResearcherEmail } from '../../common';
import SocialMediaLink from '../../Researcher/Profile/SocialMediaLink';
import { Table } from '../styled';
import { getLinkedInNetwork } from '..';
import AiMessage from 'components/AiMessage';

const OrganisationResearchers = ({ organisation, handleSearch, loading }) => {
  const { hasLicense } = useAuth();
  const hasPermissionToAI = hasLicense(
    [licenseType.academic, licenseType.industry],
    true
  );
  const sort = useSelector(getSort(organisation.id));
  const pageSize = useSelector(
    getOrganisationResearchersPageSize(organisation.id)
  );
  const researchers = useSelector(getOrganisationResearchers(organisation.id));
  const isLoading =
    useSelector(getOrganisationResearchersLoading(organisation.id)) || loading;
  const pageNumber = useSelector(
    getOrganisationResearchersPageNumber(organisation.id)
  );
  const dispatch = useDispatch();

  const [aiMessage, setAiMessage] = useState(null);

  const perPage = [
    { value: 25, label: '25 per page' },
    { value: 50, label: '50 per page' },
    { value: 100, label: '100 per page' },
  ];

  const makeCustomLinkedInSearchUrl = researcher =>
    `http://www.google.com/search?q=${researcher.researcherFirstName} ${researcher.researcherLastName} ${organisation.organisationName} site:linkedin.com`;

  let headers = [
    { label: 'Name', key: 'FullName.keyword', minWidth: 100 },
    {
      label: 'Job Title',
      key: 'JobTitle.keyword',
      minWidth: 100,
      maxContentWidth: 200,
    },
    {
      label: 'Location',
      key: 'City.keyword',
      minWidth: 100,
    },
    {
      label: 'Contact',
      key: 'Email.keyword',
      minWidth: 150,
      maxContentWidth: 250,
    },
    {
      key: 'ai',
      minWidth: 150,
    },
    {
      label: 'Last activity',
      key: 'LastActivityDate',
      minWidth: 100,
    },
  ];
  if (!hasPermissionToAI) {
    headers = headers.filter(x => x.key !== 'ai');
  }

  const config = {
    headers,
    createRow: r => {
      let location = '';
      if (r.organisationCity) {
        location += r.organisationCity;
      }
      if (r.organisationState) {
        location += `${location ? ',' : ''} ` + r.organisationState;
      }
      if (r.organisationCountry) {
        location += `${location ? ',' : ''} ` + r.organisationCountry;
      }
      return (
        <Fragment key={r.researcherId}>
          <div>
            {r.totalMatches > 0 && (
              <Link
                size='medium'
                to={`/researcher/${r.researcherId}`}
                showIconHint
              >
                {r.researcherFirstName} {r.researcherLastName}
              </Link>
            )}
            {r.totalMatches === 0 && (
              <span>
                <span style={{ margin: '5px' }}>
                  {' '}
                  {r.researcherFirstName} {r.researcherLastName}
                </span>
              </span>
            )}
          </div>
          <div>{r.jobTitle}</div>
          <div>{location}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SocialMediaLink
              network={getLinkedInNetwork()}
              title={
                r.researcherLinkedIn
                  ? 'View on LinkedIn'
                  : 'Search for LinkedIn'
              }
              url={
                r.researcherLinkedIn
                  ? makeHttpsUrl(r.researcherLinkedIn)
                  : makeCustomLinkedInSearchUrl(r)
              }
              isMuted={!r.researcherLinkedIn}
              logDescription={{
                entityType: 'Researcher',
                location: 'OrganisationPage:People',
              }}
            />
            <div style={{ marginLeft: '10px' }}></div>
            <ResearcherEmail
              email={r.researcherEmail}
              dataToPost={{
                sciLeadsResearcherId: r.researcherId,
                location: 'OrganisationPage:People',
              }}
              emailStatusCategory={r.emailStatusCategory}
              allowFind
              buttonSize='small'
              hideIcon
              style={{
                display: 'inline-flex',
                marginLeft: 10,
              }}
              explanationComponent={() => (
                <EmailExplanation
                  email={r.researcherEmail}
                  organisationCategoryGroup={
                    organisation.organisationCategoryGroup
                  }
                />
              )}
            />
          </div>
          {hasPermissionToAI && (
            <Button
              type='primary'
              size='small'
              onClick={e => {
                e?.stopPropagation();
                setAiMessage({
                  id: r.researcherId,
                  organisationCity: r.organisationCity,
                });
              }}
              icon={<SparklesIcon style={{ marginRight: '6px' }} />}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              AI Message
            </Button>
          )}
          <div>{format(r.mostRecentDateTime)}</div>
        </Fragment>
      );
    },
  };

  const handleSortChange = sortKey => {
    dispatch(
      setSort(organisation.id, {
        field: sortKey,
        direction: !sort
          ? 'asc'
          : sortKey === !sort.field
          ? 'asc'
          : sort.direction === 'asc'
          ? 'desc'
          : 'asc',
      })
    );
    handleSearch();
  };

  const handlePageSize = size => {
    dispatch(setPageSize(organisation.id, size));
    handleSearch();
  };

  const handlePageNumber = number => {
    dispatch(setPageNumber(organisation.id, number));
    handleSearch();
  };

  return (
    organisation && (
      <>
        <Table
          config={config}
          data={researchers ? researchers.groupedResults : []}
          loading={isLoading}
          pageSizeOptions={perPage}
          onChangePageSize={handlePageSize}
          onChangePageNumber={handlePageNumber}
          pageSize={pageSize ? pageSize : 25}
          pageNumber={pageNumber ? pageNumber : 0}
          resultSize={
            researchers ? researchers.distinctSuperResearchersCount : 0
          }
          onSortChange={handleSortChange}
          paginate
          sortable
          className={'researchers-table'}
          sort={sort?.field}
          sortDir={sort?.direction}
        />
        {aiMessage && (
          <AiMessage
            requestLocation='search'
            researcherId={aiMessage.id}
            city={aiMessage.organisationCity}
            onClose={() => setAiMessage(null)}
            requestTradeshows
          />
        )}
      </>
    )
  );
};

export default OrganisationResearchers;
