import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export const format = (date, format = 'Do MMM YYYY') =>
  date ? dayjs(date).format(format) : null;

export const isAfter = (dateA, dateB) => dayjs(dateA).isAfter(dayjs(dateB));

// return 0 - dates are equal, 1 - dateA after dateB, -1 - dateA before dateB
export const compare = (dateA, dateB, unit = null) => {
  const dayjsA = dayjs(dateA);
  const dayjsB = dayjs(dateB);
  if (dayjsA.isSame(dayjsB, unit)) {
    return 0;
  }

  return dayjsA.isAfter(dayjsB, unit) ? 1 : -1;
};

export const sub = (date, amount, unit = 'days') =>
  dayjs(date).subtract(amount, unit);

export const diff = (date1, date2, unit = 'month') =>
  dayjs(date2).diff(dayjs(date1), unit, true);

export const timeDiff = (date1, date2) => {
  const daysDiff = dayjs(date2).diff(dayjs(date1), 'day', true);
  if (daysDiff >= 365) return `${Math.floor(daysDiff / 365)} years`;
  if (daysDiff >= 30) return `${Math.floor(daysDiff / 30)} month`;
  return daysDiff > 0 ? `${Math.floor(daysDiff)} days` : '';
};
