import {
  getHubSpotData,
  getSalesforceData,
  saveHubSpotData,
  saveSalesforceData,
  disconnectSalesforce,
  disconnectHubSpot,
} from 'services/api';
import Salesforce from './salesforce.png';
import HubSpot from './hubSpot.png';
import { crmTypes } from 'utils/helpers/crm';

export const getCrmDataUrl = crmType => {
  switch (crmType) {
    case crmTypes.SALESFORCE:
      return getSalesforceData;
    case crmTypes.HUBSPOT:
      return getHubSpotData;
    default:
      return '';
  }
};

export const getCrmSaveUrl = crmType => {
  switch (crmType) {
    case crmTypes.SALESFORCE:
      return saveSalesforceData;
    case crmTypes.HUBSPOT:
      return saveHubSpotData;
    default:
      return '';
  }
};

export const getCrmDisconnectUrl = crmType => {
  switch (crmType) {
    case crmTypes.SALESFORCE:
      return disconnectSalesforce;
    case crmTypes.HUBSPOT:
      return disconnectHubSpot;
    default:
      return '';
  }
};

export const getCrmImage = crmType => {
  switch (crmType) {
    case crmTypes.SALESFORCE:
      return Salesforce;
    case crmTypes.HUBSPOT:
      return HubSpot;
    default:
      return '';
  }
};
