import { useState } from 'react';

import { Button, Detail, Switch } from 'components/common';
import { useAuth, useDarkMode } from 'hooks';

import Name from './Name';
import LicenseRenew from './LicenseRenew';
import ResetPassword from './ResetPassword';
import { Container } from './styled';
import { licenseTypeToStringMap } from 'models/licenseType';

const Account = ({ autoRenew }) => {
  const [showModal, setShowModal] = useState(false);
  const [darkMode, toggleDarkMode] = useDarkMode();
  const { user } = useAuth();

  return (
    <Container>
      <div>
        <Detail label='Alternate Theme'>
          <Switch
            size='small'
            onChange={toggleDarkMode}
            checked={darkMode}
            label={darkMode ? 'Enabled' : 'Disabled'}
            reverseLabel
          />
        </Detail>
        <Detail label='Organisation'>{user.userCompanyName}</Detail>
        <LicenseRenew enabled={user.isAccountManager} autoRenew={autoRenew} />
      </div>
      <div>
        <Name user={user} />
        <Detail label='Email'>{user.userEmail}</Detail>
        <Detail label='License Type'>
          {user.licenses?.filter(x => licenseTypeToStringMap[x.type]).length > 0
            ? user.licenses.map(x => licenseTypeToStringMap[x.type]).join(' & ')
            : 'No Licenses'}
        </Detail>
        <Button onClick={() => setShowModal(true)} size='small'>
          Change Password
        </Button>
      </div>
      <ResetPassword showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default Account;
