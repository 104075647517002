import React from 'react';
import { views } from '../constants';

const AlertFailureContent = ({
  message,
  isBulkManage = false,
  type = views.researchers,
}) => {
  let body;
  try {
    body = JSON.parse(message);
  } catch {
    body = message || '';
  }
  if (body.message) {
    return <div>{body.message}</div>;
  }
  if (body.CurrentCount >= 0) {
    if (!isBulkManage) {
      return (
        <div>
          Your list would contain {body.CurrentCount + body.NewItemCount}{' '}
          {type === views.researchers ? 'researchers' : 'organisations'}. The
          maximum list size is {body.Max}
        </div>
      );
    } else {
      return (
        <>
          <div>
            Your list would contain {body.CurrentCount + body.NewItemCount}{' '}
            {type === views.researchers ? 'researchers' : 'organisations'}. The
            maximum list size is {body.Max}
          </div>
          <br />
          <div>
            Therefore your new list has <strong>not</strong> been created.
          </div>
        </>
      );
    }
  }

  return <div></div>;
};

export default AlertFailureContent;
