import { Radio as AntRadio } from 'antd';

const Radio = ({
  value,
  onChange,
  options,
  optionType,
  containerStyle = {},
  style = {},
  size = 'large',
  'data-testid': dataTestId,
  radioStyle = {},
  ...rest
}) => {
  const handleChange = e => {
    return onChange(e.target.value);
  };

  if (optionType === 'button')
    return (
      <div
        style={{ display: 'inline-block', ...containerStyle }}
        data-testid={dataTestId}
      >
        <AntRadio.Group
          size={size}
          options={options}
          onChange={handleChange}
          value={value}
          optionType='button'
          buttonStyle='solid'
          style={style}
          {...rest}
        />
      </div>
    );

  return (
    <AntRadio.Group value={value} onChange={handleChange} {...rest}>
      {options.map(o => (
        <AntRadio
          key={`radio-option-${o.value}`}
          disabled={o.disabled}
          value={o.value}
          style={
            radioStyle ?? {
              display: 'block',
              height: '30px',
              lineHeight: '30px',
            }
          }
        >
          {o.label}
        </AntRadio>
      ))}
    </AntRadio.Group>
  );
};

export default Radio;
