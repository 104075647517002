export const makeHttpsUrl = url => {
  const prefix = 'https://';
  let result;

  if (url.substr(0, prefix.length) !== prefix) {
    result = prefix + url.replace(/^http:\/\//, '');
  } else {
    result = url;
  }

  return result;
};
