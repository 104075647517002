import { Modal as AntModal } from 'antd';
import { Button } from 'components/common';
import { ModalHeader, ModalRow, ModalValue } from '../styled';

const ViewCRMSummary = ({ open, selectedCRM, onClose }) => {

  if(!selectedCRM) 
    return null;

  return (
    <AntModal
      open={open}
      title='Test Push'
      onCancel={onClose}
      footer={[
        <Button key='cancel' onClick={onClose}>
          Cancel
        </Button>,
      ]}
    >
      <div>
        We have completed pushing SciLeads list <b>{selectedCRM.term}</b> to campaign{' '}
        <b>Test</b>
      </div>

      <ModalHeader>
        <ModalRow>
          <b>Researchers selected to push:</b>
          <ModalValue>{selectedCRM.researchersPushed}</ModalValue>
        </ModalRow>
        <ModalRow>
          <b>Researchers not pushed:</b>
          <ModalValue>{selectedCRM.researchersNotPushed}</ModalValue>
        </ModalRow>
        <ModalRow>
          <b>Existing contacts found:</b>
          <ModalValue>{selectedCRM.existingContactsFound}</ModalValue>
        </ModalRow>
        <ModalRow>
          <b>New contacts created:</b>
          <ModalValue>{selectedCRM.newContactsCreated}</ModalValue>
        </ModalRow>
        <ModalRow>
          <b>Contacts linked to campaign/list:</b>
          <ModalValue>{selectedCRM.contactsLinkedToList}</ModalValue>
        </ModalRow>
        <ModalRow>
          <b>Researchers skipped (no email):</b>
          <ModalValue>{selectedCRM.researchersSkippedNoEmail}</ModalValue>
        </ModalRow>
        <ModalRow>
          <b>Researchers skipped (inactive):</b>
          <ModalValue>{selectedCRM.researchersSkippedInactive}</ModalValue>
        </ModalRow>
      </ModalHeader>
    </AntModal>
  );
};

export default ViewCRMSummary;
