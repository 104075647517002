import styled from 'styled-components';

import {
  Switch as CommonSwitch,
  Select as CommonSelect,
} from 'components/common';

import { colours } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-top: 10px;
  }
  ${p => p.theme.breakpoint.tablet} {
    flex-wrap: wrap;
  }
  ${p => p.theme.breakpoint.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Switches = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 10px;
    ${p => p.theme.breakpoint.tablet} {
      :first-child {
        margin-left: 0px;
      }
    }
  }
`;

export const Switch = styled(CommonSwitch)`
  font-size: 13px;
`;

export const Buttons = styled.div`
  > * {
    margin-left: 10px;
    ${p => p.theme.breakpoint.mobile} {
      :first-child {
        margin-left: 0px;
      }
    }
  }
`;

export const Select = styled(CommonSelect)`
  flex: 1;
  ${p => p.theme.breakpoint.tablet} {
    flex: 1 0 100%;
  }
  ${p => p.theme.breakpoint.mobile} {
    width: 100%;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ListOption = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CRMStatus = styled.div`
  text-transform: uppercase;
  font-size: 9px;
  margin-right: 10px;
  color: ${p => (p.isMappedToCrm ? colours.teal : colours.orange)};
`;
