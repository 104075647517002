import React, { useState } from 'react';

import { colours } from 'utils/theme';

import Detail from '../../Detail';
import {
  Container,
  ActiveOption,
  OptionContainer,
  Options,
  StyledIcon,
} from './styled';
import { permissions, descriptions } from './helpers';

const Option = ({ title, description, onClick }) => {
  return (
    <OptionContainer onClick={onClick}>
      <div className='title'>{title}</div>
      <div className='description'>{description}</div>
    </OptionContainer>
  );
};

const PermissionSelect = ({ value, onChange }) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionSelect = option => {
    onChange(option);
    setShowOptions(false);
  };

  return (
    <Detail label='Base Permission' style={{ marginTop: 15 }}>
      <Container>
        <ActiveOption onClick={() => setShowOptions(!showOptions)}>
          <Option
            title={permissions[value]}
            description={descriptions[value]}
          />
          <StyledIcon
            icon='caret'
            color={colours.lightGrey}
            active={showOptions}
          />
        </ActiveOption>
        <Options showOptions={showOptions}>
          {Object.keys(permissions).map(key => (
            <Option
              key={key}
              onClick={() => handleOptionSelect(key)}
              title={permissions[key]}
              description={descriptions[key]}
            />
          ))}
        </Options>
      </Container>
    </Detail>
  );
};

export default PermissionSelect;
