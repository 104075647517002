import { forwardRef } from 'react';
import { Input as AntInput } from 'antd';

const { Password, TextArea } = AntInput;

const Input = forwardRef(({ onChange, multiline, password, ...rest }, ref) => {
  const handleOnChange = e => {
    onChange(e.target.value);
  };

  let Component = AntInput;

  if (multiline) {
    Component = TextArea;
  }
  if (password) {
    Component = Password;
  }

  return <Component {...rest} onChange={handleOnChange} ref={ref} />;
});

Input.defaultProps = {
  'data-testid': 'input',
};

export default Input;
