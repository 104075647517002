import { format } from 'utils/date';

import { Pane, Detail, DetailColumn, Row, DetailContainer } from './styled';

const Description = ({ study }) => {
  return (
    <Row>
      <Pane title='Study Description' id='description'>
        <Detail label='Brief Summary'>{study.briefSummary}</Detail>
        <Detail label='Conditions/Diseases'>
          {study.conditions.join(', ')}
        </Detail>
        <Detail label='Intervention/Treatment'>
          {study.interventions.map(i => (
            <div key={i}>{i}</div>
          ))}
        </Detail>
        <Detail label='Detailed Description'>
          {study.detailedDescription}
        </Detail>
      </Pane>
      <Pane title='Study Design' id='design'>
        <DetailContainer>
          <DetailColumn>
            <Detail label='Study Type'>{study.studyType}</Detail>
            <Detail label='Estimated Enrollment'>{study.enrollment}</Detail>
            <Detail label='Allocation'>{study.design?.allocation}</Detail>
            <Detail label='Intervention Model'>
              {study.design?.interventionModel}
            </Detail>
            <Detail label='Masking'>{study.design?.masking}</Detail>
          </DetailColumn>
          <DetailColumn>
            <Detail label='Primary Purpose'>
              {study.design?.primaryPurpose}
            </Detail>
            <Detail label='Actual Study Start Date'>
              {format(study.startDate)}
            </Detail>
            <Detail label='Estimated Primary Completion Date'>
              {format(study.primaryCompletionDate)}
            </Detail>
            <Detail label='Estimated Study Completion Date'>
              {format(study.completionDate)}
            </Detail>
            <Detail label='Official Title'>{study.officialTitle}</Detail>
            <Detail label='Other Study Ids'>
              {study.otherTrialIds.map(i => (
                <div key={i}>{i}</div>
              ))}
            </Detail>
          </DetailColumn>
        </DetailContainer>
      </Pane>
    </Row>
  );
};

export default Description;
