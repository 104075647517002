import { useState } from 'react';

import Pagination from '../Pagination';

export const ControlledSortable = props => {
  return <Pagination {...props} sortable />;
};

export const UncontrolledSortable = ({ data, onSort, ...rest }) => {
  const [sort, setSort] = useState('');
  const [sortDir, setSortDir] = useState('');

  let sortedData = data;

  const handleSort = key => {
    if (key === sort) {
      if (sortDir === 'asc') {
        setSort(key);
        setSortDir('desc');
      } else {
        setSort('');
        setSortDir('');
      }
    } else {
      setSort(key);
      setSortDir('asc');
    }
  };

  if (sort) {
    const defaultSort = (a, b) => {
      const aValue = a[sort]?.toLowerCase ? a[sort].toLowerCase() : a[sort];
      const bValue = b[sort]?.toLowerCase ? b[sort].toLowerCase() : b[sort];

      if (!aValue && bValue) return 1;
      if (aValue && !bValue) return -1;

      if (sortDir === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue > bValue ? -1 : 1;
      }
    };
    const sortFunction = onSort || defaultSort;
    sortedData = [...data].sort((a, b) => sortFunction(a, b, sort, sortDir));
  }

  return (
    <ControlledSortable
      sort={sort}
      sortDir={sortDir}
      onSortChange={handleSort}
      data={sortedData}
      {...rest}
    />
  );
};

const Sortable = ({ sortable, onSortChange, ...rest }) => {
  if (sortable && onSortChange)
    return <ControlledSortable {...rest} onSortChange={onSortChange} />;
  if (sortable && !onSortChange) return <UncontrolledSortable {...rest} />;
  return <Pagination {...rest} />;
};

export default Sortable;
