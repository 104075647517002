import { Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Button, Link, ResearcherEmail } from 'components/common';
import { useHistory } from 'react-router-dom';
import { getResearcherFilter, getResearcherToUnfollow } from 'selectors/alerts';
import { setResearcherFilter, setResearcherToUnfollow } from 'actions/alerts';
import { useAuth } from 'hooks';

import { StyledTable, TableActions } from './styled';
import { UnfollowModal } from './Modal';
import { tablePerPage } from './helpers';
import { setFiltersFromAlert } from 'actions/search';
import { RecentResults } from './TablePopover/RecentResults';

export const ResearcherAlerts = ({ data, getResearchersToFollow, loading }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const researcherFilter = useSelector(getResearcherFilter);
  const { push } = useHistory();
  const ref = useRef();
  const researcherToUnfollow = useSelector(getResearcherToUnfollow);

  const getFilteredData = () => {
    if (!data) return [];
    if (!researcherFilter) return data;

    return data.filter(k =>
      `${k.name.toLowerCase()}`.includes(researcherFilter.toLowerCase())
    );
  };

  const handleUnfollowSuccess = () => {
    dispatch(setResearcherToUnfollow());
    getResearchersToFollow({
      query: { UserId: user.userId, shouldLog: false },
    });
  };

  const handleUnfollowCancel = () => {
    dispatch(setResearcherToUnfollow());
  };

  const researcherConfig = {
    headers: [
      { label: 'New', key: 'recentItemsResults', minWidth: 150, maxWidth: 150 },
      { label: 'Researcher', key: 'name', minWidth: 150, maxWidth: 300 },
      {
        label: 'Organisation',
        key: 'organisation',
        minWidth: 150,
        maxWidth: 300,
      },
      { label: 'Email', key: 'email', minWidth: 150, maxWidth: 300 },
      { testId: 'actions', minWidth: 100 },
    ],
    createRow: r => (
      <Fragment key={r.id}>
        <RecentResults onClick={last30DaysClicked} alert={r} ref={ref} />

        <div>
          <Link to={`/researcher/${r.superResearcherId}`}>{r.name}</Link>
        </div>
        <div>{r.organisation}</div>
        <div>
          <ResearcherEmail
            email={r.email}
            emailStatusCategory={r.emailVerificationCategory}
            hideIcon
          />
        </div>

        <TableActions className='rightAlign'>
          <Button
            type='primary'
            size='small'
            onClick={() => dispatch(setResearcherToUnfollow(r))}
          >
            Unfollow
          </Button>
        </TableActions>
      </Fragment>
    ),
  };

  const last30DaysClicked = alert => {
    dispatch(
      setFiltersFromAlert(
        alert.searchOrigin,
        { term: `SciLeadsSuperResearcherId:${alert.superResearcherId}` },
        'one'
      )
    );
    push('/');
  };

  const handleResearcherChange = value => {
    dispatch(setResearcherFilter(value));
  };

  return (
    <div ref={ref}>
      <Input
        placeholder='Filter Researchers'
        style={{ marginRight: 15, marginTop: 15 }}
        onChange={handleResearcherChange}
        value={researcherFilter}
        allowClear
      />
      <StyledTable
        config={researcherConfig}
        pageSizeOptions={tablePerPage}
        sortable
        paginate
        data={getFilteredData()}
        loading={loading}
        testId='researcher-table'
      />
      <UnfollowModal onUnfollowSuccess={handleUnfollowSuccess} />
      {researcherToUnfollow && (
        <UnfollowModal
          onUnfollowSuccess={handleUnfollowSuccess}
          onUnfollowCancel={handleUnfollowCancel}
        />
      )}
    </div>
  );
};

export default ResearcherAlerts;
