import styled from 'styled-components';

export const Container = styled.div`
  flex: 0 0 25%;
  top: 0;
  align-self: flex-start;
  margin-right: 15px;
  margin-top: ${p => p.theme.gutter};
  ${p => p.theme.breakpoint.tablet} {
    margin-right: 0px;
    position: static;
    align-self: auto;
  }
`;

export const Content = styled.div`
  scrollbar-width: thin;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  ${p => p.theme.breakpoint.tablet} {
    max-height: none;
    overflow: visible;
  }
`;

export const Actions = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const FilterSection = styled.div`
  font-weight: 500;
  color: ${p => p.theme.grey};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
`;
