import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { useEffect, useState } from 'react';
import { ExpandCell, HiddenArea } from '../styled';

export const ResizeableRowItem = ({
  index,
  dataTestId,
  children,
  hiddenChildren,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!hiddenChildren.length) {
      setIsOpen(false);
    }
  }, [hiddenChildren.length]);

  return (
    <>
      <div
        data-testid={`${dataTestId}-row-${index}`}
        style={{ display: 'contents' }}
      >
        {!!hiddenChildren.length && (
          <ExpandCell className='cell' index={index}>
            <Button
              size='small'
              onClick={() => setIsOpen(iO => !iO)}
              disabled={!hiddenChildren.length}
            >
              {isOpen ? (
                <Icon size='xs' icon='minus' color='black' />
              ) : (
                <Icon size='xs' icon='plus' color='black' />
              )}
            </Button>
          </ExpandCell>
        )}

        {children}
      </div>

      <div
        style={{
          height: `${isOpen ? 'auto' : '0'}`,
          gridArea: `${index * 2 + 3} / ${
            hiddenChildren.length ? 1 : 0
          } / auto / -1`,
        }}
      >
        {isOpen && <HiddenArea>{hiddenChildren}</HiddenArea>}
      </div>
    </>
  );
};

export const RowItem = ({ index, dataTestId, children }) => {
  return (
    <div
      data-testid={`${dataTestId}-row-${index}`}
      style={{ display: 'contents' }}
    >
      {children}
    </div>
  );
};
