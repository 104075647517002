import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxGl from '!mapbox-gl';

const useMap = () => {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    mapboxGl.accessToken =
      process.env.REACT_APP_MAP_BOX_API_KEY ||
      'pk.eyJ1Ijoic2NpbGVhZHMiLCJhIjoiY2w5NnZ0d2dsMDlzYjNvcGE2ZDd3ZTlyZCJ9.JxwFpsSC6kocm2rN1PXDng';
  }, []);

  const init = useCallback((container, rest) => {
    const map = new mapboxGl.Map({
      style: rest.style || 'mapbox://styles/mapbox/light-v10',
      container,
      ...rest,
    });
    map.on('load', () => {
      setIsLoaded(true);
    });
    map.on('error', () => {
      setIsError(true);
    });
    return map;
  }, []);

  return [init, isLoaded, isError];
};

export default useMap;
