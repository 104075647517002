import { Layout } from 'antd';
import styled from 'styled-components';
import { Pane as BasePane } from 'components/common';

const { Content, Header, Footer } = Layout;

export const LayoutContainer = styled(Layout)`
  background-color: #fff;
  .ant-layout-sider {
    background: none;
  }
  &.top {
    min-height: 100vh;
  }
`;

export const HeaderContainer = styled(Header)`
  background: none;
  height: unset;
  margin: 50px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .form-header {
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
  }
  .back-to-login {
    color: #1890ff;
    margin-top: 15px;
  }
`;

export const FooterContainer = styled(Footer)`
  background: none;
  margin: 50px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContentBody = styled.div`
  display: flex;
`;

export const InnerContent = styled.div`
  width: 395px;
  display: flex;
  flex-direction: column;
  > *,
  form > * {
    margin-bottom: 15px;
    :last-child {
      margin-bottom: 0px;
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 20px;
  }
  .enrollment-required {
    text-align: center;
    font-weight: 500;
  }
  .qr-code {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .label {
    margin-bottom: 5px;
    color: rgb(0, 0, 0);
  }
  .forgot-password {
    color: #1890ff;
    text-align: end;
    padding: 0;
  }
  .or {
    text-align: center;
  }
`;

export const Pane = styled(BasePane)`
  width: auto;
  flex: 0;
  display: inline-flex;
  .content {
    flex-direction: row;
    ${p => p.theme.breakpoint.mobile} {
      flex-direction: column;
    }
  }
`;

export const FormContent = styled.div`
  width: 395px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  padding-left: 15px;
  > span {
    font-weight: 500;
    text-align: center;
    padding-bottom: 7px;
  }
  > input {
    margin-bottom: 10px;
  }
  ${p => p.theme.breakpoint.mobile} {
    flex-direction: column;
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 15px;
    padding-top: 15px;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const TfaMessageContainer = styled.div`
  p {
    text-align: left;
  }
  .more-container {
    text-align: right;
  }
`;
