import { useHistory } from 'react-router-dom';

import { Button, Logo } from 'components/common';

import { Container, Pane, InnerContent } from '../styled';

const EmailSent = () => {
  const { push } = useHistory();

  const handleLogin = () => {
    push('/login');
  };

  return (
    <Container>
      <div>
        <Logo />
      </div>
      <Pane>
        <InnerContent>
          <p>
            You will receive an email shortly with details on how to complete
            the password reset process.
          </p>
          <Button onClick={handleLogin}>Go to Login</Button>
        </InnerContent>
      </Pane>
    </Container>
  );
};

export default EmailSent;
