import { Fragment } from 'react';

import { Pane, Detail, DetailColumn, DetailContainer, Table } from './styled';

const locationConfig = {
  headers: ['Name', 'Investigators', 'Contacts'],
  createRow: (r, i) => (
    <Fragment key={i}>
      <div>{r.fullName}</div>
      <div>
        {r.facilityInvestigators.length > 0
          ? r.facilityInvestigators.map((fi, i) => (
              <div key={i}>
                {fi.name} | {fi.role}
              </div>
            ))
          : '-'}
      </div>
      <div>
        {r.facilityContacts.length > 0
          ? r.facilityContacts.map((c, i) => (
              <div key={i}>
                {c.name} | {c.contactType}
              </div>
            ))
          : '-'}
      </div>
    </Fragment>
  ),
};

const Locations = ({ study }) => {
  return (
    <Pane title='Contacts and Locations' id='locations'>
      <DetailContainer>
        <DetailColumn style={{ flex: '0 0 auto' }}>
          <Detail label='Central Contacts'>
            {study.centralContacts.length > 0
              ? study.centralContacts.map((c, i) => (
                  <div key={i}>{c.fullName}</div>
                ))
              : null}
          </Detail>
          <Detail label='Overall Officials'>
            {study.overallOfficials.length > 0
              ? study.overallOfficials.map((o, i) => (
                  <div key={i}>{o.fullName}</div>
                ))
              : null}
          </Detail>
          <Detail label='Result Contacts'>
            {study.resultContacts.length > 0
              ? study.resultContacts.map((c, i) => (
                  <div key={i}>{c.fullName}</div>
                ))
              : null}
          </Detail>
        </DetailColumn>
        <DetailColumn>
          <Detail label='Locations'>
            <Table config={locationConfig} data={study.facilities} />
          </Detail>
        </DetailColumn>
      </DetailContainer>
    </Pane>
  );
};

export default Locations;
