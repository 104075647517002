import { Token } from './TokenInput';

export const tagRender = props => {
  const { label, onClose, value } = props;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Token
      key={value != null ? value : label}
      value={label}
      removeToken={onClose}
      style={{ marginTop: '2px', marginBottom: '2px' }}
      onMouseDown={onPreventMouseDown}
    />
  );
};
