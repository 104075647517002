import { types } from 'actions/purchasing';
import { purchasingSearch } from 'services/api';

import { buildQuery, noValue } from 'utils/purchasing';
import { handleSearchError } from '../notifications';

const resetSkipKeys = ['term', 'category'];

const defaultState = {
  lastSuccessfulSearch: {},
  externalSearch: {},
  searchRequest: {
    loading: false,
    error: false,
    res: {},
  },
  exportRequest: {
    loading: false,
    error: false,
    res: {},
  },
  filters: {
    term: '',
  },
  pageSize: 100,
  pageNumber: 0,
  restoringState: true,
  showNotice: true,
};

const REDUCER = {
  [types.SET_SEARCH_REQUEST]: (state, action) => {
    if (action.payload.error) {
      handleSearchError(action.payload.error);
    }
    return {
      ...state,
      searchRequest: action.payload,
    };
  },
  [types.PERFORM_SEARCH]: (state, action) => {
    const { lastSuccessfulSearch } = state;
    const { useLastSuccessfulSearch, newSearch } = action.payload;

    const query = useLastSuccessfulSearch
      ? lastSuccessfulSearch.query
      : buildQuery(state.filters);

    action.payload.request({
      url: purchasingSearch,
      body: {
        query,
        count: state.pageSize,
        from: newSearch ? 0 : state.pageNumber * state.pageSize,
        order: state.sort,
        orderBy: state.sortDir,
        location: 'PurchasingDestkop',
      },
    });

    return {
      ...state,
      lastSuccessfulSearch: { query, ...state.filters },
      pageNumber: newSearch ? 0 : state.pageNumber,
      externalSearchPending: false,
      searchRequest: {
        res: {},
        loading: true,
        error: false,
      },
    };
  },
  [types.SETUP_EXTERNAL_SEARCH]: (state, action) => ({
    ...state,
    filters: {
      ...action.payload.filters,
    },
    externalSearchPending: true,
  }),
  [types.CLEAR_FILTERS]: state => ({
    ...state,
    filters: {
      ...defaultState.filters,
      category: {
        ...defaultState.filters.category,
      },
    },
  }),
  [types.SET_FILTER]: (state, action) => {
    const filters = { ...state.filters };
    const { id, value } = action.payload;
    if (noValue(value)) {
      delete filters[id];
    } else {
      filters[id] = value;
    }
    return {
      ...state,
      filters,
    };
  },
  [types.SET_FILTERS]: (state, action) => {
    const { value } = action.payload;
    return {
      ...state,
      filters : value,
      externalSearchPending: true
    };
  },
  [types.SET_FILTER_GROUP]: (state, action) => {
    let filters = { ...state.filters };
    const { group, id, value } = action.payload;
    if (noValue(value)) {
      delete filters[group][id];
    } else {
      filters = {
        ...filters,
        [group]: {
          ...filters[group],
          [id]: value,
        },
      };
    }
    if (Object.keys(filters[group]).length === 0) {
      delete filters[group];
    }
    return {
      ...state,
      filters,
    };
  },
  [types.RESET_FILTERS]: state => ({
    ...state,
    filters: Object.entries(state.filters).reduce((acc, [key, value]) => {
      if (resetSkipKeys.includes(key)) {
        return { ...acc, [key]: value };
      } else {
        return acc;
      }
    }, {}),
  }),
  [types.SET_SORT]: (state, action) => {
    let sort;
    let sortDir;

    if (action.payload.sort === state.sort) {
      if (state.sortDir === 'asc') {
        sort = action.payload.sort;
        sortDir = 'desc';
      } else {
        sort = '';
        sortDir = '';
      }
    } else {
      sort = action.payload.sort;
      sortDir = 'asc';
    }

    return {
      ...state,
      sort,
      sortDir,
    };
  },
  [types.SET_PAGE_SIZE]: (state, action) => ({
    ...state,
    pageSize: action.payload.size,
  }),
  [types.SET_PAGE_NUMBER]: (state, action) => ({
    ...state,
    pageNumber: action.payload.number,
  }),
  [types.RESTORE_STATE]: (state, action) => {
    if (!action.payload) {
      return {
        ...state,
        restoringState: false,
      };
    }
    let restoredState = JSON.parse(JSON.stringify(action.payload));
    restoredState = {
      ...defaultState,
      ...restoredState,
    };

    restoredState.restoringState = false;
    if (restoredState.searchRequest.loading) {
      restoredState.searchRequest.loading = false;
    }
    return restoredState;
  },
  [types.DISMISS_NOTICE]: state => {
    return {
      ...state,
      showNotice: false,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
