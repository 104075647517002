import { useState, useRef } from 'react';
import { Tooltip, Button } from 'antd';
import styled from 'styled-components';
import { Icon } from 'components/common';

const ProfileTermsContainer = styled.div`
  overflow-y: auto;
  max-height: 300px;
  color: 'black';
`;

const TermsButton = styled(Button).attrs(p => ({
  style: {
    backgroundColor: `${p.backgroundcolor}`,
  },
}))`
  width: 290px;
  text-align: left;
`;

const ButtonText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SummaryTerms = ({ researcherTerms, researcherSummary }) => {
  const [showHideText, setShowHideText] = useState('Show');

  const termsButton = useRef(null);

  const handleShowTermsChange = value => {
    if (value) {
      setShowHideText('Hide');
      termsButton.current.blur();
    } else {
      setShowHideText('Show');
      termsButton.current.blur();
    }
  };

  const tooltipContent = (researcherTerms, researcherSummary) => {
    return (
      <ProfileTermsContainer>
        {researcherSummary && (
          <div>
            RESEARCHER SUMMARY:
            <br />
            {researcherSummary}
          </div>
        )}
        {researcherTerms?.length > 0 && researcherSummary && (
          <div>
            <br />
          </div>
        )}
        {researcherTerms?.length > 0 && <div>RESEARCHER TERMS</div>}
        {researcherTerms?.length > 0 && (
          <div style={{ textTransform: 'capitalize' }}>
            {researcherTerms?.map(term => term).join(', ')}
          </div>
        )}
      </ProfileTermsContainer>
    );
  };

  return (
    <Tooltip
      onOpenChange={handleShowTermsChange}
      placement='right'
      title={tooltipContent(researcherTerms, researcherSummary)}
      trigger={'click'}
      color='white'
      overlayInnerStyle={{ color: 'black', width: '500px' }}
    >
      <TermsButton
        size='small'
        ref={termsButton}
        backgroundcolor={
          showHideText === 'Show' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.1)'
        }
      >
        <ButtonText>
          {showHideText} Researcher Summary and Terms{' '}
          <Icon icon='circleInfo' color='rgba(0, 0, 0, 0.1)' size='sm' />
        </ButtonText>
      </TermsButton>
    </Tooltip>
  );
};

export default SummaryTerms;
