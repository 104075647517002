import PropTypes from 'prop-types';
import { Tooltip } from 'components/common';
import { Filter, helpText } from '../helpers';
import { useSelector } from 'react-redux';
import { getFilter } from 'selectors/search';
import { tradeshowOrganisationKeys } from './keys';

const OrgFilters = ({ loading }) => {
  const tradeshowValue = useSelector(
    getFilter(tradeshowOrganisationKeys.tradeshow.key)
  );
  const thisShowSelected = useSelector(
    getFilter(tradeshowOrganisationKeys.thisShowOnly.key)
  );

  return (
    <>
      <Tooltip
        title={helpText.exhibitingShow}
        placement='right'
        overlayStyle={{ maxWidth: 450 }}
      >
        <Filter
          filterType='checkbox'
          filterId={tradeshowOrganisationKeys.exhibitingShow.key}
          disabled={
            !tradeshowValue ||
            tradeshowValue.length === 0 ||
            thisShowSelected ||
            loading
          }
          labelText='Exhibiting at a tradeshow'
        />
      </Tooltip>
      <Tooltip
        title={helpText.presentingShow}
        placement='right'
        overlayStyle={{ maxWidth: 450 }}
      >
        <Filter
          filterType='checkbox'
          filterId={tradeshowOrganisationKeys.presentingAtTradeshow.key}
          disabled={
            !tradeshowValue ||
            tradeshowValue.length === 0 ||
            thisShowSelected ||
            loading
          }
          labelText='Presenting at a tradeshow'
        />
      </Tooltip>
    </>
  );
};

OrgFilters.propTypes = {
  loading: PropTypes.bool,
};

export default OrgFilters;
