import React from 'react';

import { siNumber } from 'utils/number';
import { format } from 'utils/date';

import GraphBase from './GraphBase';

export default function ResearchersByYear({
  data,
  hideLabel,
  hideHelpText,
  animate = true,
}) {
  if (!data) return null;

  let options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value, index, values) {
              return siNumber(value, 1);
            },
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback: function(value, index, values) {
              return format(value, 'YYYY');
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return siNumber(tooltipItem.yLabel);
        },
        title: function(tooltipItem, data) {
          return format(tooltipItem[0].xLabel, 'YYYY');
        },
      },
    },
  };

  if (!animate) {
    options = { ...options, animation: false };
  }

  return (
    <GraphBase
      type='line'
      label={hideLabel ? '' : 'Activity Trend'}
      data={data}
      options={options}
      helpText={
        hideHelpText
          ? ''
          : 'This graph indicates researchers who had an activity mentioning your keyword in that year'
      }
    />
  );
}
