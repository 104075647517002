import styled from 'styled-components';

// import { Icon } from 'components/common';

const Container = styled.div`
  flex: 1;
  width: 100%;
  background: ${p => (p.theme.darkMode ? 'white' : 'white')};
  padding: 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  font-size: 16px;
  color: ${p => p.theme.black};
  margin-top: 0px;
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const StickyHeader = styled.div`
  z-index: 100;
  flex: 1;
  width: 100%;
  background: ${p => (p.theme.darkMode ? 'white' : 'white')};
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 16px;
  color: ${p => p.theme.black};
  position: sticky;
  top: 0;
  align-self: flex-start;
  background: ${p => (p.theme.darkMode ? 'white' : 'white')};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* > div {
    margin-left: 5px;
  } */
`;

const StickyPane = ({
  className,
  children,
  headerRight,
  title,
  secondRow,
  thirdRow,
  fourthRow,
  ...rest
}) => {
  return (
    <>
      <StickyHeader>
        {title && (
          <Header data-testid='panel-title'>
            <Title>
              {/* <Icon icon='caret' color='#31302f' size='1x' /> */}
              <div>{title}</div>
            </Title>
            {headerRight}
          </Header>
        )}
        {secondRow}
        {thirdRow}
        {fourthRow}
      </StickyHeader>
      <Container className={className} {...rest}>
        <div className='content'>{children}</div>
      </Container>
    </>
  );
};

export default StickyPane;
