import styled from 'styled-components';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Link, IconText, Button, Tooltip, Avatar } from 'components/common';
import { formatRange } from 'utils/number';
import SocialMediaLink from 'components/Researcher/Profile/SocialMediaLink';
import React from 'react';
import { useWindowWidth } from 'hooks';
import { useDispatch } from 'react-redux';
import { setViewAndResetState } from 'actions/organisation';
import { useRef } from 'react';
import useHorizontalWheel from 'hooks/useHorizontalWheel';
import { addBreadcrumb } from 'actions/other';
import AcquiredIcon from './AcquiredIcon';
import { ORGANISATIONPEOPLEVIEW } from 'components/Organisation/helpers';
import Actions from 'components/Organisation/Actions';
import RebrandIcon from './RebrandIcon';

const ProfileContainer = styled.div`
  font-size: 15px;
  line-height: 1.75em;
  color: ${p => p.theme.black};
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${p => p.theme.breakpoint.mobile} {
    align-items: normal;
    flex-direction: column;
  }
`;

const ProfileContainerInner = styled.div`
  font-size: 15px;
  line-height: 1.75em;
  color: ${p => p.theme.black};
  display: flex;
  flex: 1;
  width: 0;

  .horizontalScrollable {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
  }

  .horizontalScrollable::-webkit-scrollbar {
    display: none;
  }

  ${p => p.theme.breakpoint.tablet} {
    flex-wrap: wrap;
  }

  ${p => p.theme.breakpoint.mobile} {
    flex-direction: column;
    width: auto;
  }
`;

const LogoImage = styled.img`
  margin-right: 25px;
`;

const ProfileSection = styled.div`
  display: flex;
  white-space: nowrap;
  column-gap: 5px;

  ${p => p.theme.breakpoint.tablet} {
    white-space: normal;
    flex-wrap: wrap;
  }
`;

const makeCustomSearchUrl = organisation =>
  `http://www.google.com/search?q=${organisation} site:linkedin.com`;

const Profile = ({
  organisationName,
  organisationCity,
  organisationCountry,
  organisationCategory,
  superOrganisationSizeRangeLowerBound,
  superOrganisationSizeRangeUpperBound,
  superOrganisationId,
  // superOrganisationRevenue,
  superOrganisationFoundedYear,
  superOrganisationLinkedIn,
  superOrganisationLogoUrl,
  superOrganisationAcquisitions,
  superOrganisationPreviousName,
}) => {
  const { isMobile } = useWindowWidth();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const parentRef = useRef(null);
  const scrollableProfile = useRef(null);

  const setOrgProfileView = () => {
    dispatch(setViewAndResetState(ORGANISATIONPEOPLEVIEW));
  };

  const onLogoClick = () => {
    setOrgProfileView();
    dispatch(
      addBreadcrumb({
        name: document.title.split(' | ')[0],
        path: pathname,
        id: Math.floor(Math.random() * Math.floor(10000)),
      })
    );
  };

  const makeImageUrl = () => {
    const s3Bucket = process.env.REACT_APP_PORTAL_CDN || 'dev-scil-portal-cdn';
    return `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/${superOrganisationLogoUrl}_40x40`;
  };

  const addDefaultSrc = ev => {
    const s3Bucket = process.env.REACT_APP_PORTAL_CDN || 'dev-scil-portal-cdn';
    var defaultSrc = `https://${s3Bucket}.s3.eu-west-1.amazonaws.com/images/logos/superorganisation/Avatar_40_40.png`;
    if (ev.target.src !== defaultSrc) {
      ev.target.src = defaultSrc;
    }
  };

  useHorizontalWheel(scrollableProfile);

  return (
    <>
      <ProfileContainer
        style={{ justifyContent: 'space-between' }}
        ref={parentRef}
      >
        <ProfileContainerInner>
          <ProfileSection style={{ alignItems: 'center' }}>
            <RouterLink
              to={`/organisation/${superOrganisationId}`}
              onClick={onLogoClick}
            >
              {!isMobile &&
                (superOrganisationLogoUrl ? (
                  <LogoImage
                    src={makeImageUrl()}
                    alt={organisationName}
                    onError={addDefaultSrc}
                  />
                ) : (
                  <Avatar icon='company' size={'small'} />
                ))}
            </RouterLink>
            <Link
              size='large'
              to={`/organisation/${superOrganisationId}`}
              showIconHint
              style={{ marginBottom: '0px' }}
              onClick={() => setOrgProfileView()}
            >
              {organisationName}
            </Link>
            <AcquiredIcon
              size='lg'
              style={{ marginRight: '5px' }}
              acquisitions={superOrganisationAcquisitions}
            />
            <RebrandIcon
              previousName={superOrganisationPreviousName}
              parentRef={parentRef}
              style={{ marginRight: '5px' }}
            />
          </ProfileSection>
          <ProfileSection
            ref={scrollableProfile}
            className='horizontalScrollable'
            style={{
              marginRight: '15px',
              alignItems: 'center',
            }}
          >
            <IconText value={organisationCity} icon='map' />
            <IconText value={organisationCountry} icon='globe' />
            <IconText value={organisationCategory} icon='category' />
            <Actions
              organisation={superOrganisationId}
              modalTitle={'Report Organisation Category'}
              isAiIcon={true}
              placeholder={'Organisation Category Suggestion'}
            />
            {(superOrganisationSizeRangeLowerBound ||
              superOrganisationSizeRangeUpperBound) && (
              <Tooltip
                title={
                  'This number represents the count of employees at the company'
                }
              >
                <span>
                  <IconText
                    value={formatRange(
                      superOrganisationSizeRangeLowerBound,
                      superOrganisationSizeRangeUpperBound
                    )}
                    icon='user'
                  />
                </span>
              </Tooltip>
            )}
            {superOrganisationFoundedYear && (
              <Tooltip title={'This represents when the company was founded'}>
                <span>
                  <IconText
                    value={superOrganisationFoundedYear}
                    icon='rocket'
                  />
                </span>
              </Tooltip>
            )}
          </ProfileSection>
        </ProfileContainerInner>

        <ProfileSection>
          <SocialMediaLink
            network={{
              type: 'linkedin',
              displayName: 'LinkedIn',
            }}
            title={
              superOrganisationLinkedIn
                ? 'View on LinkedIn'
                : 'Search for LinkedIn'
            }
            url={
              superOrganisationLinkedIn
                ? `https://${superOrganisationLinkedIn}`
                : makeCustomSearchUrl(organisationName)
            }
            isMuted={superOrganisationLinkedIn ? false : true}
            logDescription={{
              entityType: 'Organisation',
              location: 'OrganisationSearch',
            }}
          />
          <Button
            type='primary'
            style={{ marginLeft: '10px' }}
            href={`/organisation/${superOrganisationId}`}
            target={'_blank'}
            data-testid='filter-update'
          >
            VIEW DETAILS
          </Button>
        </ProfileSection>
      </ProfileContainer>
    </>
  );
};

export default Profile;
