import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth, usePost } from 'hooks';
import { setSearchRequest, performSearch } from 'actions/purchasing';
import { getExternalSearchPending } from 'selectors/purchasing';
import NotFound from 'components/Redirects/NotFound';
import licenseType from 'models/licenseType';

import SearchArea from './SearchArea';
import Statistics from './Statistics';
import Filters from './Filters';
import Results from './Results';
import PurchasingNotice from './PurchasingNotice';
import { Container, Row, Column } from './styled';

export const PurchasingContext = createContext();

const Purchasing = () => {
  const dispatch = useDispatch();
  const { hasLicense } = useAuth();
  const [searchState, postSearch] = usePost({});
  const externalSearchPending = useSelector(getExternalSearchPending);

  useEffect(() => {
    if (searchState.res || searchState.error || searchState.loading) {
      dispatch(setSearchRequest(searchState));
    }
  }, [searchState, dispatch]);

  const haslicenseToPurchasing = hasLicense(
    [licenseType.legacy, licenseType.academic],
    true
  );
  if (!haslicenseToPurchasing) {
    return <NotFound />;
  }

  if (externalSearchPending) {
    dispatch(performSearch(postSearch, { newSearch: true }));
  }

  return (
    <PurchasingContext.Provider value={postSearch}>
      <Container>
        <SearchArea />
        <PurchasingNotice />
        <Statistics />
        <Row>
          <Filters loading={searchState.loading} />
          <Column>
            <Results />
          </Column>
        </Row>
      </Container>
    </PurchasingContext.Provider>
  );
};

export default Purchasing;
