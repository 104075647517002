import styled, { css } from 'styled-components';

import { Pane } from 'components/common';

const Container = css`
  flex: 0 1 300px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.loading ? 'center' : 'flex-start')};
  align-items: ${p => (p.loading ? 'center' : 'stretch')};
  margin-right: ${p => p.theme.gutter};
`;

export const LeftPane = styled(Pane)`
  ${Container}
  > :nth-child(3) {
    border-top: 1px solid ${p => p.theme.lightGrey};
    margin-top: 10px;
  }

  ${p => p.theme.breakpoint.tablet} {
    flex: 0 1 auto;
    width: 100%;
    margin-bottom: ${p => p.theme.gutter};
    .content {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
`;

export const OptionContainer = styled.div`
  padding: 10px 0;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid ${p => p.theme.lightGrey};
  color: ${p => p.color};
  display: flex;
  cursor: pointer;
  > div {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :hover {
    background: rgba(0, 0, 0, 0.025);
  }
  ${p => p.theme.breakpoint.tablet} {
    padding-right: 10px;
  }
`;

export const NavOptions = styled.div`
  ${p => p.theme.breakpoint.tablet} {
    margin-right: 15px;
  }
`;

export const LeftContainer = styled.div`
  ${Container}
  > :nth-child(1) {
    border-top: 1px solid ${p => p.theme.lightGrey};
    margin-top: 0px;
  }
  > :nth-child(2) {
    border-top: 1px solid ${p => p.theme.lightGrey};
    margin-top: 10px;
    flex: 0 1 auto;
  }
  }
`;
