import { notification } from 'antd';

export const ewrcaDescription = 'This export is too large to process. Please reduce the activity count (this comprises the total number of Funding Awards, Tradeshow Sessions, Publication Articles and Clinical Trials) to below a combined 200k, people to below 50k and emails to below 8k.';
export const ewrcaOrganisationDescription = 'This export is too large to process. Please reduce the Organisation count to below 10K.';

export const exportNotification = () => ({
  message: 'Generating Report',
  description: `We are generating your report and will notify you when it is ready.`,
  placement: 'bottomLeft',
});

const displayableSearchErrors = ['Search.ExceededSearchTermsLimit'];

function getWhoopsDescription(error) {
  if (error?.errors) {
    const errorsToDisplay = displayableSearchErrors
      .filter(err => error.errors[err])
      .map(err => error.errors[err]);
    if (errorsToDisplay.length > 0) {
      return errorsToDisplay[0];
    }
  }

  if (error) {
    if (displayableSearchErrors.includes(error.statusText)) {
      return error.message;
    }
  }

  return (
    <>
      There was an issue with your search. This is commonly caused by a typo in
      your search term. Please review your query and try again.
      <br />
      <br />
      If your problem persists, you can contact us via live chat in the bottom
      right.
    </>
  );
}

export const handleSearchError = error => {
  if (error.status === 429) {
    notification.open({
      message: 'Trial Limit Reached',
      description: (
        <div>
          You have reached the daily search limit on your free trial.
          <br />
          <br />
          To enable unlimited searches, speak to our sales team about upgrading
          your account.
        </div>
      ),
      placement: 'bottomLeft',
    });
  } else {
    notification.open({
      message: 'Whoops!',
      description: (
        <div style={{ whiteSpace: 'pre-line' }}>
          {getWhoopsDescription(error)}
        </div>
      ),
      placement: 'bottomLeft',
    });
  }
};

export const handleExportNotification = () => {
  notification.open(
    exportNotification()
  );
};

export const isExceededClausesLimit = error => {
  if (error?.errors) {
    return !!error.errors['Search.ExceededClausesLimit'];
  }
  return error?.statusText === 'Search.ExceededClausesLimit';
};
