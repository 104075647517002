import React from 'react';

import Detail from '../../Detail';
import AddUser from './Add';
import Table from './Table';

const Users = ({
  value,
  onChange,
  activeList,
  options,
  currentUser,
  loading,
}) => {
  return (
    <Detail label='Per-user permissions' style={{ marginTop: 15 }}>
      <AddUser
        tableData={value}
        setTableData={onChange}
        list={activeList}
        allUsers={options}
        loading={loading}
      />
      <Table
        tableData={value}
        setTableData={onChange}
        listOwner={activeList.createdByUserId}
        currentUser={currentUser}
      />
    </Detail>
  );
};

Users.defaultProps = {
  activeList: {},
};

export default Users;
