import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';

const Container = styled.div`
  position: fixed;
  right: 50px;
  top: ${p => (p.expanded ? '0px' : p.heightToExpand)};
  opacity: ${p => (p.heightToExpand ? '1' : '0')};
  transition: top 0.2s linear;
  z-index: 2;
`;

const Content = styled.div`
  background: white;
  position: relative;
  padding: 15px;
  border-radius: 0px 0px 0px 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  > a {
    display: block;
  }
`;

const Label = styled.div`
  position: absolute;
  right: 0px;
  background: white;
  padding: 10px 10px 5px;
  border-radius: 0px 0px 5px 5px;
  border-bottom: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
  > svg {
    color: ${p => p.theme.black};
    font-size: 11px;
    transform: ${p => (p.expanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
    transition: transform 0.2s linear;
  }
`;

const Jumper = ({ items }) => {
  const [expanded, setExpanded] = useState(false);
  const [heightToExpand, setHeightToExpand] = useState();
  const ref = useRef(null);

  useEffect(() => {
    setHeightToExpand(`-${ref.current.clientHeight + 5}px`);
  }, []);

  return (
    <Container expanded={expanded} heightToExpand={heightToExpand}>
      <Content ref={ref}>
        {items.map((i, index) => (
          <a key={index} href={`#${i.to}`}>
            {i.label}
          </a>
        ))}
      </Content>
      {ref.current && (
        <Label expanded={expanded} onClick={() => setExpanded(s => !s)}>
          Jump To <Icon icon='caret' />
        </Label>
      )}
    </Container>
  );
};

Jumper.defaultProps = {
  items: [],
};

export default Jumper;
