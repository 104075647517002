import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import storage from 'services/storage';
import { restoreState as restoreSearchState } from 'actions/search';
import { restoreState as restorePurchasingState } from 'actions/purchasing';
import { restoreState as restoreOtherState } from 'actions/other';
import { restoreState as restoreListsState } from 'actions/lists';
import { restoreState as restoreUserSettingsState } from 'actions/userSettings';
import { restoreState as restoreUsersState } from 'actions/users';

import { restoreState as restoreCalendarState } from 'actions/calendar';
import { restoreOrgResearchersSearchState } from 'actions/organisation';
import { restoreState as restoreRateLimitsState } from 'actions/rateLimits';
import { restoreState as restoreAIState } from 'actions/ai';
import { restoreState as restoreExportHistoryState } from 'actions/exportHistory';

const RestoreState = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const restoreState = async () => {
      const searchState = await storage.getItem('search');
      const userSettings = await storage.getItem('userSettings');
      const users = await storage.getItem('users');

      const organisationResearchersSearchState = await storage.getItem(
        'organisation'
      );
      const purchasingState = await storage.getItem('purchasing');
      const otherState = await storage.getItem('other');
      const listsState = await storage.getItem('listsV2');
      const calendarState = await storage.getItem('calendar');
      const rateLimitsState = await storage.getItem('rateLimits');
      const aIState = await storage.getItem('ai');
      const exportHistoryState = await storage.getItem('exportHistory');

      dispatch(restoreSearchState(searchState));
      dispatch(restorePurchasingState(purchasingState));
      dispatch(restoreOtherState(otherState));
      dispatch(restoreListsState(listsState));
      dispatch(restoreCalendarState(calendarState));
      dispatch(restoreUserSettingsState(userSettings));
      dispatch(restoreUsersState(users));
      dispatch(
        restoreOrgResearchersSearchState(organisationResearchersSearchState)
      );
      dispatch(restoreRateLimitsState(rateLimitsState));
      dispatch(restoreAIState(aIState));
      dispatch(restoreExportHistoryState(exportHistoryState));
    };

    restoreState();
  }, [dispatch]);

  return null;
};

export default RestoreState;
