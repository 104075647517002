import { Form, Input, Button } from 'components/common';
import { useState, useEffect } from 'react';

import PasswordError from '../../Settings/Account/PasswordError';

import { passwordCheck } from 'services/api';
import { usePost } from 'hooks';

const PasswordChange = ({
  serverError,
  customError,
  onChange,
  newPass,
  setNewPass,
  handleSubmit,
  loading,
  setCustomError,
  hideChangePasswordButton,
  confirmPass,
  setConfirmPass,
}) => {
  const handleClientSidePasswordErrorChange = (
    hasErrors,
    hasPasswordNotMatchingError,
    passwordTooShortError,
    passwordTooWeakError
  ) => {
    onChange(hasErrors || !passwordCheckRes);
    setHasErrors(hasErrors);
    setHasPasswordNotMatchingError(hasPasswordNotMatchingError);
    setPasswordTooWeakError(passwordTooWeakError);
    setPasswordTooShortError(passwordTooShortError);
    setHasValidatedErrors(true);
  };

  const [previousNewPass, setPreviousNewPass] = useState('');

  const [hasValidatedErrors, setHasValidatedErrors] = useState(false);

  const [
    hasUserUnfocusedNewPasswordField,
    setHasUserUnfocusedNewPasswordField,
  ] = useState(false);
  const [
    hasUserFocusedCurrentPasswordField,
    setHasUserFocusedConfirmPasswordField,
  ] = useState(false);

  const [, setHasPasswordNotMatchingError] = useState(false);

  const [passwordTooWeakError, setPasswordTooWeakError] = useState(false);

  const [passwordTooShortError, setPasswordTooShortError] = useState(false);

  const [hasErrors, setHasErrors] = useState(false);

  const [
    { error: passCheckError, res: passwordCheckRes },
    postPasswordCheck,
  ] = usePost({
    url: passwordCheck,
  });

  const handleNewPasswordUnfocus = () => {
    setHasValidatedErrors(false);
    setHasUserUnfocusedNewPasswordField(true);
  };

  useEffect(() => {
    if (
      hasValidatedErrors &&
      hasUserUnfocusedNewPasswordField &&
      !passwordTooShortError &&
      !passwordTooWeakError &&
      newPass !== previousNewPass &&
      newPass !== ''
    ) {
      setPreviousNewPass(newPass);
      postPasswordCheck({ password: newPass });
    }
  }, [
    hasValidatedErrors,
    hasUserUnfocusedNewPasswordField,
    passwordTooShortError,
    passwordTooWeakError,
    newPass,
    previousNewPass,
    postPasswordCheck,
  ]);

  const disableButton =
    !newPass || !confirmPass || loading || hasErrors || passCheckError;

  const handleFormSubmit = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (passwordCheckRes) {
      setCustomError(null);
    }
    if (passCheckError) {
      setCustomError(
        'This password is not secure. Please choose a different password.'
      );
    }
  }, [setCustomError, passwordCheckRes, passCheckError]);

  return (
    <Form canSubmit={!disableButton} onSubmit={handleSubmit}>
      <p>
        Create a strong password to secure your account. Use a mix of letters,
        numbers, and symbols for added protection.
      </p>
      <div>
        <div className='label'>New Password</div>
        <Input
          password
          placeholder='New Password'
          value={newPass}
          onChange={setNewPass}
          onFocus={() => setHasUserUnfocusedNewPasswordField(false)}
          onBlur={handleNewPasswordUnfocus}
        />
      </div>
      <div>
        <div className='label'>Confirm New Password</div>
        <Input
          password
          placeholder='Confirm New Password'
          value={confirmPass}
          onChange={setConfirmPass}
          onFocus={() => setHasUserFocusedConfirmPasswordField(true)}
          onBlur={() => setHasUserFocusedConfirmPasswordField(false)}
        />
      </div>
      <PasswordError
        confirmPass={confirmPass}
        newPass={newPass}
        shouldRender={
          hasUserUnfocusedNewPasswordField || hasUserFocusedCurrentPasswordField
        }
        serverError={serverError}
        onChange={handleClientSidePasswordErrorChange}
        passwordCheckFunction={postPasswordCheck}
      />
      {!hideChangePasswordButton && (
        <Button
          visible={hideChangePasswordButton}
          type='primary'
          disabled={disableButton}
          onClick={handleFormSubmit}
          style={{ width: '100%' }}
        >
          Set a Password
        </Button>
      )}
    </Form>
  );
};

export default PasswordChange;
