import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Statistic } from 'components/common';
import licenseType, { licenseTypeToStringMap } from 'models/licenseType';
import { crmTypes, crmTypesToStringMap } from 'utils/helpers/crm';

const licenseImageMap = {
  [licenseType.legacy]: 'image',
  [licenseType.academic]: 'book',
  [licenseType.industry]: 'company',
};

export const integrationsImageMap = {
  [crmTypes.SALESFORCE]: 'salesforce',
  [crmTypes.HUBSPOT]: 'hubspot',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  > div:not(:last-child) {
    margin-right: 10px;
  }
`;

const LicenseAllocation = ({ licenses, integrations }) => {
  return (
    <Container>
      <div>Current License Allocation</div>
      <Content>
        {licenses.map(x => (
          <Statistic
            key={x.type}
            icon={licenseImageMap[x.type]}
            title={licenseTypeToStringMap[x.type]}
            value={`${x.total - x.available}/${x.total}`}
          />
        ))}
        {integrations.map(x => (
          <Statistic
            key={x.type}
            icon={integrationsImageMap[x.type]}
            title={crmTypesToStringMap[x.type]}
            value={`${x.total - x.available}/${x.total}`}
          />
        ))}
      </Content>
    </Container>
  );
};

LicenseAllocation.propTypes = {
  licenses: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number.isRequired,
      available: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    })
  ).isRequired,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      available: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default LicenseAllocation;
