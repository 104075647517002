import { Icon, Tooltip } from 'components/common';
import useLog, { outcomes } from 'hooks/useLog';
import { colours } from 'utils/theme';

const socialTypes = {
  linkedin: 'linkedin',
};

const actions = {
  [socialTypes.linkedin]: 'VisitLinkedInProfile',
};

const SocialMediaLink = ({ network, url, title, isMuted, logDescription }) => {
  const postLog = useLog(
    JSON.stringify({ url, ...logDescription }),
    actions[network.type],
    outcomes.success,
    false
  );
  const handleClick = () => {
    window.open(url, '_blank');
    if (network.type === socialTypes.linkedin) {
      postLog();
    }
  };

  return (
    <Tooltip title={title}>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Icon
          onClick={handleClick}
          style={{
            filter: `grayscale(${isMuted ? '100%' : '0%'})`,
            cursor: 'pointer',
          }}
          key={network.type}
          icon={network.type}
          color={colours.brands[network.type]}
        />
      </div>
    </Tooltip>
  );
};

export default SocialMediaLink;
