import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;
const Content = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 25px;

  .hlt1 {
    font-weight: 700;
    font-style: italic;
  }
`;

const Highlight = ({ highlights }) => {
  let highlightText = null;
  if (!(Object.keys(highlights).length === 0)) {
    for (let key in highlights) {
      if (highlights.hasOwnProperty(key) && highlights[key].length > 0) {
        highlightText = highlights[key][0];
        break;
      }
    }
  }

  if (highlightText !== 'Your search matched this activity.') {
    highlightText = '...' + highlightText;
  }

  if (highlightText && !highlightText.endsWith('.')) {
    highlightText += ' ...';
  }

  return (
    <Container>
      <Content
        lines={2}
        dangerouslySetInnerHTML={{ __html: highlightText ?? '-' }}
      ></Content>
    </Container>
  );
};

export default Highlight;
