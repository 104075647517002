import Profile from './Profile';

import { ActionBar, OrgContainer, OrgTopContent } from '../styled';
import Actions from '../Researcher/Actions';
import { views } from 'components/Lists/constants';

const Result = props => {
  return (
    <OrgContainer>
      <OrgTopContent>
        <Profile {...props} />
      </OrgTopContent>
      <ActionBar organisation>
        <Actions {...props} type={views.organisations} />
      </ActionBar>
    </OrgContainer>
  );
};

export default Result;
