export const getColour = (data, type) => {
  const OPACITY = 0.4;

  if (type === 'line') {
    return `hsl(189, 48%, 55%, ${OPACITY})`;
  }
  return data.map((x, i) =>
    i % 2 === 0
      ? `hsla(15, 100%, 59%, ${OPACITY})`
      : `hsl(189, 48%, 55%, ${OPACITY})`
  );
};
