//@ts-check
import { Icon, Link, Tooltip } from 'components/common';
import dayjs from 'dayjs';
import React from 'react';
import { format } from 'utils/date';
import { colours } from 'utils/theme';

/**
 * @param {{
 *  acquisitions?: import('components/Organisation/Funding/index').Acquisition[],
 *  size: string,
 *  rest: React.HTMLAttributes<HTMLDivElement>
 * }} props
 */
export default function AcquiredIcon({ acquisitions, size = 'sm', ...rest }) {
  // This is disabled for now
  return null;

  /* eslint-disable no-unreachable */
  const acquiredBy =
    acquisitions
      ?.filter(x => !x.acquisitionMade)
      .sort((a, b) => dayjs(b.acquiredOn).diff(dayjs(a.acquiredOn))) || [];
  if (!acquiredBy.length) return null;

  return (
    <Tooltip title={<ToolTipLink acquisitions={acquiredBy} />}>
      <div {...rest}>
        {/* @ts-ignore */}
        <Icon size={size} icon='handShake' color={colours.teal} />
      </div>
    </Tooltip>
  );
  /* eslint-enable no-unreachable */
}

/**
 * @param {{acquisitions: import('components/Organisation/Funding/index').Acquisition[]}} props
 */
const ToolTipLink = ({ acquisitions }) => {
  /**
   * @param {import('components/Organisation/Funding/index').Acquisition} acquisition
   * @returns {string}
   */
  function getAcquisitionStatement(acquisition) {
    let statement = 'Acquired by';
    if (acquisition.acquisitionType) {
      if (acquisition.acquisitionType === 'Merger') {
        statement = 'Merged with';
      } else if (
        acquisition.acquisitionType &&
        acquisition.acquisitionType !== 'Acquisition'
      ) {
        statement = `${acquisition.acquisitionType} by`;
      }
    }
    return statement;
  }

  return (
    <>
      {acquisitions.map(acquiredBy => (
        <div key={acquiredBy.acquisitionId}>
          {getAcquisitionStatement(acquiredBy)}
          {acquiredBy.superOrgId ? (
            //@ts-ignore
            <Link to={`/organisation/${acquiredBy.superOrgId}`} showIconHint>
              {acquiredBy.organisationName}
            </Link>
          ) : (
            ` ${acquiredBy.organisationName}`
          )}
          {` ${format(acquiredBy.acquiredOn)}`}
        </div>
      ))}
    </>
  );
};
