import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';

import { useAuth, usePost, useGet } from 'hooks';
import { createList, allUsers } from 'services/api';
import { setActiveList, setView } from 'actions/lists';
import {
  ListNameInput,
  ListDescriptionInput,
  ListPermissionSelect,
  ListExplanation,
  ListUsers,
} from 'components/common/Lists';
import { views } from 'components/Lists/constants';

const Create = ({
  onClose,
  title,
  buttonOkText,
  shouldSetActiveList = true,
  type = views.researchers,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [{ res: createListRes, loading: creating }, postCreateList] = usePost({
    url: createList(type),
  });
  const [{ res: allUsersRes, loading: loadingUsers }] = useGet({
    url: allUsers,
    onMount: true,
  });
  const [list, setList] = useState({
    basePermission: 'Read',
    users: [
      {
        userId: user.userId,
        userName: user.userName,
        accessLevel: 'Admin',
      },
    ],
  });

  useEffect(() => {
    if (createListRes) {
      if (shouldSetActiveList) {
        dispatch(setView(type));
        dispatch(setActiveList(createListRes));
      }

      onClose({ fetch: true, ...createListRes });
    }
  }, [createListRes, dispatch, onClose, shouldSetActiveList, type]);

  const userOptions =
    allUsersRes?.userAccounts.filter(u => u.enabled && !u.isLicenseExpired) ||
    [];

  const handleChange = key => value => {
    setList(s => ({ ...s, [key]: value }));
  };

  const handleCreate = () => {
    const description = list.description ? list.description.trim() : null;
    postCreateList({
      defaultAccess: list.basePermission,
      accessLevels: list.users,
      name: list.name,
      description: description,
    });
  };

  const isValid = list.name && list.name.trim().length > 0;

  return (
    <Modal
      open
      title={title ? title : 'Create List'}
      onCancel={onClose}
      okText={buttonOkText ? buttonOkText : 'Create'}
      onOk={handleCreate}
      okButtonProps={{ disabled: creating || !isValid }}
      cancelButtonProps={{ disabled: creating }}
    >
      <ListNameInput value={list.name} onChange={handleChange('name')} />
      <ListDescriptionInput
        value={list.description}
        onChange={handleChange('description')}
      />
      <ListPermissionSelect
        value={list.basePermission}
        onChange={handleChange('basePermission')}
      />
      <ListExplanation />
      <ListUsers
        loading={loadingUsers}
        value={list.users}
        onChange={handleChange('users')}
        currentUser={user}
        options={userOptions}
      />
    </Modal>
  );
};

export default Create;
