import { useState } from 'react';

import { Button } from 'components/common';

import Report from './Report';
import { ActionsContainer } from './Profile/styled';

import styled from 'styled-components';

import { colours } from 'utils/theme';

import Tooltip from 'components/common/Tooltip';
import Icon from 'components/common/Icon';

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const Actions = ({ organisation, modalTitle, placeholder, isAiIcon }) => {
  const [showReportModal, setShowReportModal] = useState(false);

  const handleToggleReport = () => setShowReportModal(s => !s);

  return (
    <span>
      <ActionsContainer>
        {!isAiIcon ? (
          <Button size='small' onClick={handleToggleReport}>
            Report Profile
          </Button>
        ) : (
          <Tooltip title='Help train the algorithm. Click here to suggest a better category.'>
            <IconContainer>
              <Icon
                icon='sparkles'
                color={colours.linkBlue}
                onClick={handleToggleReport}
                size='1x'
              />
            </IconContainer>
          </Tooltip>
        )}
      </ActionsContainer>
      {showReportModal && (
        <Report
          onToggleModal={handleToggleReport}
          superOrganisationId={organisation}
          modalTitle={modalTitle}
          placeholder={placeholder}
          isAiIcon={isAiIcon}
        />
      )}
    </span>
  );
};

export default Actions;
