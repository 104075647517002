import { useEffect, useState, useMemo } from 'react';
import { Alert, Modal as AntModal, Select as AntSelect } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { usePost, useGet, useAuth } from 'hooks';
import { Input, Select, Detail, Loader, Radio, Icon } from 'components/common';
import {
  userAlerts,
  sanityCheckAlert,
  allUsersIncludeInactive,
  researcherListPermissions,
  organisationListPermissions,
} from 'services/api';
import { getAlertToCreate } from 'selectors/alerts';
import { setAlertToCreate } from 'actions/alerts';
import {
  frequencyEnums,
  frequencyOptions,
  frequencyRadioOptions,
} from 'models/frequency';
import {
  StyledIcon,
  SharedAlertPanel,
  SharedAlertHeader,
  SharedAlertHeaderTitle,
  SharedAlertContainer,
  SharedAlertInfo,
  SharedAlertWarning,
} from '../styled';
import { colours } from 'utils/theme';
import { Token } from 'components/common/TokenInput';
import licenseType from 'models/licenseType';

const { Option } = AntSelect;

const CreateModal = ({ onCreateSuccess }) => {
  const dispatch = useDispatch();
  const activeAlert = useSelector(getAlertToCreate);
  const [frequency, setFrequency] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showWarning, setShowWarning] = useState('');
  const { user } = useAuth();

  const [
    {
      res: recentItemsResults,
      loading: sanityCheckLoading,
      error: sanityError,
    },
    getUserAlerts,
  ] = usePost({
    url: sanityCheckAlert,
  });

  const [{ res: allUsersRes, loading: allUsersLoading }] = useGet({
    url: allUsersIncludeInactive,
    onMount: true,
  });

  const [
    { res: fetchResearcherListPermissionsRes },
    fetchResearcherListPermissions,
  ] = useGet({ url: researcherListPermissions });
  const [
    { res: fetchOrgListPermissionsRes },
    fetchOrgListPermissions,
  ] = useGet({ url: organisationListPermissions });

  const researcherListIds = useMemo(() => {
    return [
      ...(activeAlert.lists?.map(l => l.id) || []),
      ...(activeAlert.excludeLists?.map(l => l.id) || []),
    ];
  }, [activeAlert.lists, activeAlert.excludeLists]);

  const orgListIds = useMemo(() => {
    return [
      ...(activeAlert.organisationLists?.map(l => l.id) || []),
      ...(activeAlert.organisationExcludeLists?.map(l => l.id) || []),
    ];
  }, [activeAlert.organisationLists, activeAlert.organisationExcludeLists]);

  useEffect(() => {
    if (researcherListIds.length > 0)
      fetchResearcherListPermissions({ query: { ListIds: researcherListIds } });
  }, [researcherListIds, fetchResearcherListPermissions]);

  useEffect(() => {
    if (orgListIds.length > 0)
      fetchOrgListPermissions({ query: { ListIds: orgListIds } });
  }, [orgListIds, fetchOrgListPermissions]);

  const userOptions =
    allUsersRes?.userAccounts
      .filter(u => u.active && u.legacyUserId !== user.userId)
      .map(u => ({
        fullname: u.fullname,
        licenses: u.licenses,
        userId: u.legacyUserId,
      }))
      .sort((a, b) => a.fullname.localeCompare(b.fullname)) || [];

  useEffect(() => {
    getUserAlerts({
      ...activeAlert,
      searchFilters: {
        lists: activeAlert.lists,
        excludeLists: activeAlert.excludeLists,
        organisationLists: activeAlert.organisationLists,
        organisationExcludeLists: activeAlert.organisationExcludeLists,
      },
      nestedQueries: activeAlert.nestedQueries,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ res: success, loading: creating, error }, createAlert] = usePost({
    url: userAlerts,
  });

  useEffect(() => {
    if (success && frequency !== null) {
      onCreateSuccess(activeAlert);
      dispatch(setAlertToCreate());
      setFrequency(null);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, onCreateSuccess, dispatch, frequency, activeAlert]);

  const handleCreateChange = key => value => {
    const updatedAlert = {
      ...activeAlert,
      [key]: value,
    };

    dispatch(setAlertToCreate(updatedAlert));
  };

  const handleChildAlertChange = key => value => {
    const updatedAlert = {
      ...activeAlert,
      childAlert: {
        ...activeAlert.childAlert,
        [key]: value,
      },
    };

    dispatch(setAlertToCreate(updatedAlert));
  };

  const handleCancel = () => {
    dispatch(setAlertToCreate());
  };

  const handleUsersChange = v => {
    handleChildAlertChange('users')(v);

    let haveListAccess = true;
    fetchResearcherListPermissionsRes?.forEach(p => {
      const noAccessUsers = p.accessLevels
        .filter(al => al.accessLevel === 'NoAccess')
        .map(al => al.userId);
      const accessUsers = p.accessLevels
        .filter(al => al.accessLevel !== 'NoAccess')
        .map(al => al.userId);
      haveListAccess =
        haveListAccess &&
        v.every(
          u =>
            !noAccessUsers.includes(u) &&
            (p.defaultAccess !== 'NoAccess' || accessUsers.includes(u))
        );
    });

    fetchOrgListPermissionsRes?.forEach(p => {
      const noAccessUsers = p.accessLevels
        .filter(al => al.accessLevel === 'NoAccess')
        .map(al => al.userId);
      const accessUsers = p.accessLevels
        .filter(al => al.accessLevel !== 'NoAccess')
        .map(al => al.userId);
      haveListAccess =
        haveListAccess &&
        v.every(
          u =>
            !noAccessUsers.includes(u) &&
            (p.defaultAccess !== 'NoAccess' || accessUsers.includes(u))
        );
    });

    const managerLicenses = user.licenses.map(l => l.type);
    const usersMissingManagerLicenses = allUsersRes?.userAccounts.filter(
      userAccount =>
        v.includes(userAccount.legacyUserId) &&
        !managerLicenses.every(l =>
          userAccount.licenses.some(userLicense => userLicense.type === l)
        )
    );
    if (!haveListAccess && usersMissingManagerLicenses?.length > 0) {
      setShowWarning(
        'Selected users may not see this alert if they lack the required license(s) or access to an included list.'
      );
    } else if (!haveListAccess) {
      setShowWarning(
        'Selected users may not see this alert if they lack access to an included list.'
      );
    } else if (usersMissingManagerLicenses?.length > 0) {
      setShowWarning(
        'Selected users may not see this alert if they lack the required license(s).'
      );
    } else {
      setShowWarning('');
    }
  };

  const handleCreate = () => {
    const frequency =
      activeAlert.frequencyRadio === 'Alert'
        ? frequencyOptions.find(freq => freq.value === activeAlert.frequency)
            .enumValue
        : frequencyEnums.NoAlert;
    setFrequency(frequency);

    if (activeAlert.childAlert.users.length) {
      const childFrequency =
        activeAlert.childAlert.frequencyRadio === 'Alert'
          ? frequencyOptions.find(
              freq => freq.value === activeAlert.childAlert.frequency
            ).enumValue
          : frequencyEnums.NoAlert;

      createAlert({
        ...activeAlert,
        lists: activeAlert.lists?.map(l => l.id) || [],
        excludeLists: activeAlert.excludeLists?.map(l => l.id) || [],
        organisationLists: activeAlert.organisationLists?.map(l => l.id) || [],
        organisationExcludeLists:
          activeAlert.organisationExcludeLists?.map(l => l.id) || [],
        frequency,
        recentItemsResults: recentItemsResults,
        childAlert: {
          ...activeAlert.childAlert,
          frequency: childFrequency,
        },
      });
    } else {
      createAlert({
        ...activeAlert,
        lists: activeAlert.lists?.map(l => l.id) || [],
        excludeLists: activeAlert.excludeLists?.map(l => l.id) || [],
        organisationLists: activeAlert.organisationLists?.map(l => l.id) || [],
        organisationExcludeLists:
          activeAlert.organisationExcludeLists?.map(l => l.id) || [],
        frequency,
        recentItemsResults: recentItemsResults,
        childAlert: {
          users: [],
          frequency: frequencyEnums.NoAlert,
        },
      });
    }
  };

  if (!activeAlert) return null;

  const isValid =
    activeAlert.name && activeAlert.monthlyKeywords && activeAlert.frequency;

  return (
    <AntModal
      open
      title={user.disableExport ? 'Save Searches' : 'Save Searches & Alerts'}
      onCancel={handleCancel}
      okText='Save'
      onOk={handleCreate}
      bodyStyle={{ padding: '15px' }}
      okButtonProps={{
        disabled: creating || !isValid,
        hidden: sanityCheckLoading || sanityError,
        'data-testid': 'create-save-button',
      }}
      cancelButtonProps={{
        disabled: creating,
        'data-testid': 'create-cancel-button',
      }}
    >
      {sanityCheckLoading || allUsersLoading ? (
        <Loader />
      ) : sanityError ? (
        <div />
      ) : (
        <div>
          <Detail
            label={
              <div>
                Search Name <span style={{ color: 'red' }}>*</span>
              </div>
            }
          >
            <Input
              showCount
              disabled={creating}
              maxLength={50}
              value={activeAlert.name}
              onChange={handleCreateChange('name')}
              placeholder={
                user.disableExport
                  ? 'Type the name of your saved search'
                  : 'Type the name of your saved search or alert'
              }
            />
          </Detail>
          {!user.disableExport && (
            <Detail style={{ marginTop: '21px', marginBottom: '21px' }}>
              <Radio
                radioStyle={{ height: '30px', lineHeight: '30px' }}
                value={activeAlert.frequencyRadio}
                onChange={handleCreateChange('frequencyRadio')}
                options={frequencyRadioOptions}
              />
              <Select
                disabled={creating || activeAlert.frequencyRadio !== 'Alert'}
                value={activeAlert.frequency}
                onChange={handleCreateChange('frequency')}
                options={frequencyOptions}
                disableTooltip
              />
            </Detail>
          )}
          {user.isUserManager &&
            userOptions?.length &&
            activeAlert.structuredSearch && (
              <SharedAlertContainer>
                <SharedAlertHeader onClick={() => setShowOptions(!showOptions)}>
                  <div>
                    <SharedAlertHeaderTitle>Share</SharedAlertHeaderTitle>
                    <div>
                      Search for members of your team below to share this item
                      with
                    </div>
                  </div>
                  <StyledIcon
                    icon='chevronDown'
                    color={colours.grey}
                    active={showOptions}
                    size='sm'
                  />
                </SharedAlertHeader>
                <SharedAlertPanel
                  style={{ display: showOptions ? 'flex' : 'none' }}
                >
                  <SharedAlertInfo>
                    <Icon
                      icon='circleInfo'
                      color={colours.linkBlue}
                      size='1x'
                    />
                    <div style={{ fontSize: '12px' }}>
                      Please refer to the Shared Alerts FAQ for more
                      information.
                    </div>
                  </SharedAlertInfo>
                  <Select
                    mode='multiple'
                    style={{ width: '100%' }}
                    options={userOptions}
                    optionFilterProp='label'
                    optionLabelProp='children'
                    tagRender={tagRender}
                    renderOption={renderUsers}
                    onChange={handleUsersChange}
                  />
                  {showWarning && (
                    <SharedAlertWarning>
                      <Icon icon='circleInfo' color='#FAAD14' size='1x' />
                      {showWarning}
                    </SharedAlertWarning>
                  )}
                  {!user.disableExport && (
                    <Detail>
                      <Radio
                        radioStyle={{ height: '30px', lineHeight: '30px' }}
                        value={activeAlert.childAlert.frequencyRadio}
                        onChange={handleChildAlertChange('frequencyRadio')}
                        options={frequencyRadioOptions}
                      />
                      <Select
                        disabled={
                          creating ||
                          activeAlert.childAlert.frequencyRadio !== 'Alert'
                        }
                        value={activeAlert.childAlert.frequency}
                        onChange={handleChildAlertChange('frequency')}
                        options={frequencyOptions}
                      />
                    </Detail>
                  )}
                </SharedAlertPanel>
              </SharedAlertContainer>
            )}
        </div>
      )}

      {sanityError && (
        <Alert
          message={
            'This is not a valid search, please correct search before saving again.'
          }
          type='error'
        />
      )}
      {error && <Alert message={error.message?.message ?? ''} type='error' />}
    </AntModal>
  );
};

const getLicenseIcons = licenses => {
  const hasAcademic = licenses.some(x => licenseType.academic === x.type);
  const hasIndustry = licenses.some(x => licenseType.industry === x.type);
  const hasLegacy = licenses.some(x => licenseType.legacy === x.type);
  return (
    <>
      {hasAcademic && (
        <Icon
          icon='book'
          size='sm'
          style={{ marginRight: '5px' }}
          color='inherit'
        />
      )}
      {hasIndustry && (
        <Icon
          icon='company'
          size='sm'
          style={{ marginRight: '5px' }}
          color='inherit'
        />
      )}
      {hasLegacy && (
        <Icon
          icon='image'
          size='sm'
          style={{ marginRight: '5px' }}
          color='inherit'
        />
      )}
    </>
  );
};

const renderUsers = (o, i) => (
  <Option
    title={o.fullname}
    key={`${o.fullname}-${i}`}
    value={o.userId}
    data-testid={`$alert-user-option-${i}`}
    details={o.fullname}
    label={o.fullname}
  >
    {getLicenseIcons(o.licenses)}
    {o.fullname}
  </Option>
);

const tagRender = props => {
  const { label, onClose } = props;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div>
      <Token
        key={label}
        value={label}
        removeToken={onClose}
        style={{ marginTop: '2px', marginBottom: '2px' }}
        onMouseDown={onPreventMouseDown}
      />
    </div>
  );
};

export default CreateModal;
