//@ts-check
import { Statistic } from 'components/common';
import React from 'react';
import { colours } from 'utils/theme';
import { Stats } from './VCFundingStats';
import { siNumber } from 'utils/number';
import dayjs from 'dayjs';

/**
 * @param {{
 * organisation: import('../Funding/index').OrganisationVCFundingDetails,
 * loading: boolean
 * }} props
 */
export default function InvestmentStats({ organisation, loading }) {
  if (loading) return null;

  const hasInvestments = !!organisation.superOrganisationInvestments?.length;

  const last3Years = dayjs().add(-3, 'year');
  const recentFundingRounds = organisation.superOrganisationInvestments
    ?.filter(x => dayjs(x.announcedDate) > last3Years)
    ?.sort((a, b) => dayjs(b.announcedDate).diff(a.announcedDate));

  const mostRecentFundingRaised = recentFundingRounds?.slice(0, 1);

  return (
    <Stats>
      {hasInvestments && (
        <>
          {/* @ts-ignore */}
          <Statistic
            icon='dollarNote'
            title='Total Investments'
            value={organisation.superOrganisationInvestments?.length || 0}
            iconColor={colours.teal}
          />
          {/* @ts-ignore */}
          <Statistic
            icon='dollarNote'
            title='Investments in Last 3 Years'
            value={recentFundingRounds?.length || 0}
            iconColor={colours.teal}
          />
          {/* @ts-ignore */}
          <Statistic
            icon='dollar'
            title='Most Recent Funding Raised'
            value={siNumber(
              mostRecentFundingRaised?.length
                ? mostRecentFundingRaised[0].raisedAmountUsd
                : 0
            )}
            iconColor={colours.teal}
          />
        </>
      )}
    </Stats>
  );
}
