import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGet, usePost } from 'hooks';
import { Drawer } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import dayjs from 'dayjs';

import { getFormValues } from 'selectors/ai';
import {
  aiMessage,
  researcherTradeshows as researcherTradeshowsUrl,
  userHasKeywords,
} from 'services/api';
import {
  Button,
  Detail,
  Icon,
  Input,
  Loader,
  SparklesIcon,
} from 'components/common';

import MessageTypes from './MessageTypes';
import Keywords from './Keywords';
import { keywordTypes, messageTypes } from './config';
import TradeshowLocation from './TradeshowLocation';
import FullLoader from './FullLoader';
import Subject from './Subject';
import Title from './Title';
import { compare } from 'utils/date';
import Message from './Message';
import { saveFormValues } from 'actions/ai';
import DetailsTooltip from './DetailsTooltip';

const GlobalStyle = createGlobalStyle`
  body {
    .ai-message-copy .ant-notification-notice-message {
      display: none;
    }
    .ai-message-copy svg {
      color: #fff;
    }
    .ai-drawer {
      z-index: 2147483600;
    }
    .ant-tooltip {
      z-index: 2147483600;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  height: 100%;
  padding: 24px 24px 8px 24px;
  .checkbox span {
    font-size: 13px;
    color: #161616;
  }
  .button-loader .loader {
    margin: 7px 35px 0 0;
  }
`;

const NoteContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #8d8d8d;
  margin-top: auto;
`;

function AiMessage({
  researcherId,
  researcherTradeshows,
  requestTradeshows,
  city,
  requestLocation,
  onClose,
}) {
  const dispatch = useDispatch();
  const cacheMessage = useSelector(getFormValues);
  const [message, setMessage] = useState({
    type: messageTypes.generic,
    productName: '',
    locationAtTradeshow: '',
    tradeshowId: null,
    keywordType: keywordTypes.default,
    keywords: [],
    meetingTime: '',
    generateSubject: true,
    ...cacheMessage,
  });

  const generatedMessageType = useRef();
  const [generatedMessages, setGeneratedMessages] = useState({});
  const [tradeshows, setTradeshows] = useState(null);
  const [{ res: hasKeywords, loading: keywordsLoading }] = useGet({
    url: userHasKeywords,
    onMount: true,
  });
  const [
    { res: tradeshowsResp, loading: tradeshowsLoading },
    fetchResearcherTradeshows,
  ] = useGet({
    url: researcherTradeshowsUrl(researcherId),
    onMount: false,
  });
  const loading = keywordsLoading || tradeshowsLoading;

  const [
    { res: generatedMessageResp, error, loading: generateMessageLoading },
    generateMessage,
  ] = usePost({
    url: aiMessage,
  });

  const onChange = useCallback((key, value) => {
    setMessage(m => ({ ...m, [key]: value }));
  }, []);

  useEffect(() => {
    if (generatedMessageResp) {
      setGeneratedMessages(x => ({
        ...x,
        [generatedMessageType.current]: generatedMessageResp,
      }));
    }
  }, [generatedMessageResp]);

  useEffect(() => {
    if (error) {
      setGeneratedMessages(x => ({
        ...x,
        [generatedMessageType.current]: {
          message:
            error.status === 412
              ? error.message
              : error.status === 503
              ? 'The service is currently busy. Please try again later.'
              : 'An error occurred generating a message. Please try again later.',
          isError: true,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    if (hasKeywords !== undefined && hasKeywords !== null && !hasKeywords) {
      onChange('keywordType', keywordTypes.custom);
    }
  }, [hasKeywords, onChange]);

  useEffect(() => {
    if (requestTradeshows) {
      fetchResearcherTradeshows();
    }
  }, [fetchResearcherTradeshows, requestTradeshows]);

  const updateTradeshows = useCallback(
    shows => {
      let filteredTradeshows = shows.reduce((acc, current) => {
        if (!acc.some(x => x.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);
      filteredTradeshows = filteredTradeshows
        .filter(x => dayjs(x.date) >= dayjs(new Date().setHours(0, 0, 0, 0)))
        .sort((a, b) => compare(a, b));
      setTradeshows(filteredTradeshows);
      if (filteredTradeshows?.length) {
        onChange('tradeshowId', filteredTradeshows[0].id);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (tradeshowsResp) {
      updateTradeshows(
        tradeshowsResp.map(t => ({
          id: t.sciLeadsTradeShowId,
          name: t.show,
          date: t.date,
        }))
      );
    }
  }, [tradeshowsResp, updateTradeshows]);

  useEffect(() => {
    if (!requestTradeshows && researcherTradeshows) {
      updateTradeshows(researcherTradeshows);
    }
  }, [requestTradeshows, researcherTradeshows, updateTradeshows]);

  const onClear = useCallback(() => {
    setMessage(m => ({
      ...m,
      productName: '',
      locationAtTradeshow: '',
      keywordType: keywordTypes.default,
      keywords: [],
      meetingTime: '',
      tradeshowId: tradeshows?.[0]?.id,
    }));
  }, [tradeshows]);

  const onSubmit = useCallback(() => {
    generatedMessageType.current = message.type;
    dispatch(
      saveFormValues({
        productName: message.productName,
        keywordType: message.keywordType,
        keywords: message.keywords,
      })
    );
    generateMessage({
      requestLocation,
      researcherId,
      ...message,
      useCustomKeywords:
        message.keywordType === keywordTypes.custom ||
        message.keywordType === keywordTypes.custonAndDefault,
      useAccountKeywords:
        message.keywordType === keywordTypes.default ||
        message.keywordType === keywordTypes.custonAndDefault,
      keywords:
        message.keywordType !== keywordTypes.default ? message.keywords : [],
    });
  }, [message, dispatch, generateMessage, requestLocation, researcherId]);

  return (
    <Drawer
      headerStyle={{ padding: '16px' }}
      bodyStyle={{ padding: 0 }}
      title={<Title />}
      footer={null}
      open={true}
      onClose={onClose}
      width={528}
      placement='right'
      className='ai-drawer'
    >
      <GlobalStyle />
      <Container onClick={e => e?.stopPropagation()}>
        <div style={{ color: '#595959' }}>
          Generate a message using our AI Assistant. Use it later to contact
          anyone on social platforms. Select one of three presets that suits
          your needs.
        </div>
        <MessageTypes
          value={message.type}
          onChange={id => onChange('type', id)}
          style={{ margin: '16px 0' }}
          loading={loading || generateMessageLoading}
          tradeshows={tradeshows}
          city={city}
        />
        {loading && <FullLoader style={{ marginTop: '19px' }} />}
        {!loading && (
          <>
            {message.type === messageTypes.tradeshow && (
              <TradeshowLocation
                tradeshows={tradeshows}
                onTradeshowChange={value => onChange('tradeshowId', value)}
                tradeshow={message.tradeshowId}
                location={message.locationAtTradeshow}
                onLocationChange={value =>
                  onChange('locationAtTradeshow', value)
                }
                loading={loading || generateMessageLoading}
                style={{ marginBottom: '16px' }}
              />
            )}
            <Detail
              label={
                <>
                  Product Name
                  <DetailsTooltip title='Maximum 50 characters' />
                </>
              }
              style={{ marginBottom: '16px' }}
            >
              <Input
                placeholder='Product Name'
                value={message.productName}
                onChange={value => onChange('productName', value)}
                disabled={loading || generateMessageLoading}
                maxLength={50}
              />
            </Detail>
            {message.type === messageTypes.meeting && (
              <Detail
                style={{ marginBottom: '20px' }}
                label={
                  <>
                    Date Of Visit
                    <DetailsTooltip title='Maximum 50 characters' />
                  </>
                }
              >
                <Input
                  placeholder='Date Of Visit'
                  value={message.meetingTime}
                  disabled={loading || generateMessageLoading}
                  onChange={newDate => onChange('meetingTime', newDate)}
                  maxLength={50}
                />
              </Detail>
            )}
            <Keywords
              value={message.keywords}
              hasKeywords={hasKeywords}
              onChange={value => onChange('keywords', value)}
              keywordType={message.keywordType}
              onKeywordTypeChange={value => onChange('keywordType', value)}
              loading={loading || generateMessageLoading}
              style={{ marginBottom: '16px' }}
            />
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                size='middle'
                onClick={onClear}
                disabled={loading || generateMessageLoading}
              >
                Clear all
              </Button>
              {!generateMessageLoading ? (
                <Button
                  type='primary'
                  size='middle'
                  icon={
                    !generatedMessages[message.type] ? (
                      <SparklesIcon style={{ marginRight: '6px' }} />
                    ) : (
                      <Icon
                        size='sm'
                        icon='refresh'
                        style={{ marginRight: '6px' }}
                      />
                    )
                  }
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  onClick={onSubmit}
                >
                  {!generatedMessages[message.type]
                    ? 'Generate message'
                    : 'Generate again'}
                </Button>
              ) : (
                <Loader className='button-loader' />
              )}
            </div>
            {message.generateSubject && (
              <Subject
                subject={generatedMessages[message.type]?.subject}
                style={{ marginBottom: '16px' }}
                loading={loading || generateMessageLoading}
                shouldStream={generatedMessageResp}
                disabled={
                  loading ||
                  generateMessageLoading ||
                  !generatedMessages[message.type]?.subject
                }
              />
            )}
            <Message
              value={generatedMessages[message.type]?.message}
              shouldStream={generatedMessageResp}
              isError={generatedMessages[message.type]?.isError}
              disabled={
                loading ||
                generateMessageLoading ||
                !generatedMessages[message.type]?.message
              }
              loading={generateMessageLoading}
            />
            <NoteContainer style={{ marginTop: '16px', paddingBottom: '20px' }}>
              AI relies on patterns and data inputs, so errors may occur.
            </NoteContainer>
          </>
        )}
      </Container>
    </Drawer>
  );
}

AiMessage.propTypes = {
  requestLocation: PropTypes.string.isRequired,
  researcherId: PropTypes.number.isRequired,
  city: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  researcherTradeshows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    })
  ),
  requestTradeshows: PropTypes.bool,
};

export default AiMessage;
