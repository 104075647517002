import { useSelector } from 'react-redux';

import { Statistic } from 'components/common';
import { getSearchRequest } from 'selectors/purchasing';

import { StatisticsContainer } from './styled';

const Statistics = () => {
  const { res, loading } = useSelector(getSearchRequest);

  const { totalSales, sumPurchasers, uniqueLines, averageOrderAmounnt } = res;

  return (
    <StatisticsContainer>
      <Statistic
        icon='company'
        title='Total Purchasers'
        value={loading ? '-' : sumPurchasers}
      />
      <Statistic
        icon='dollar'
        title='Total Amount'
        value={loading ? '-' : totalSales}
      />
      <Statistic
        icon='fingerprint'
        title='Unique Lines'
        value={loading ? '-' : uniqueLines}
      />
      <Statistic
        icon='dollar'
        title='Average Order Amount'
        value={loading ? '-' : averageOrderAmounnt}
      />
    </StatisticsContainer>
  );
};

export default Statistics;
