import { useEffect, useState } from 'react';
import { Alert, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ListCRMLink, Loader, ListCrmTypes } from 'components/common';
import {
  getActiveList,
  getAreAllMembersSelected,
  getExportExtraParams,
  getSelectedMemberIds,
  getSelectedMembers,
} from 'selectors/lists';

import { useAuth, useGet, usePost } from 'hooks';
import { getCRMLists, pushMembers } from 'services/api';
import { crmTypes } from 'utils/helpers/crm';
import { setIsLoading } from 'actions/lists';
import { handleFailedExportNotification } from 'components/Search/Results/Actions/notifications';

const ErrorResponseContent = ({ error }) => {
  //handle api response timeout
  switch (error.status) {
    case 504:
    case 503:
    case 502:
      return (
        <div>
          This is a large list. We will continue to push your list across in the
          background.
          <br />
          <br />
          To check the status of your push, you can view the member count in the
          Salesforce Campaign.
        </div>
      );
    case 403:
    case 400:
      return <div>{error.message || error.statusText}</div>;
    default:
      return <div>Something went wrong.</div>;
  }
};

const ResultsContent = ({ res, loading }) => {
  if (loading) return <Loader />;
  return 'CRM Push started. We will notify you when pushing has finished.';
};

const PushCRM = ({ onClose }) => {
  const activeList = useSelector(getActiveList);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const selectedMemberIds = useSelector(getSelectedMemberIds);
  const selectedMembers = useSelector(getSelectedMembers);
  const allSelected = useSelector(getAreAllMembersSelected);
  const extraParams = useSelector(getExportExtraParams);
  const [crmId, setCrmId] = useState(null);
  let defaultCrmType = null;
  if (user.isSalesforceAuthenticated) {
    defaultCrmType = crmTypes.SALESFORCE;
  } else if (true) {
    defaultCrmType = crmTypes.HUBSPOT;
  }
  const [crmType, setCrmType] = useState(defaultCrmType);
  const [{ res: crmLists, error: getCrmListsError }, loadCrmLists] = useGet({
    url: getCRMLists,
    query: { crmType },
    onMount: true,
  });
  const [{ res, loading, error }, postPush] = usePost({
    url: pushMembers(activeList.id),
  });

  const crmTypeOptions = [];
  if (user.isSalesforceAuthenticated)
    crmTypeOptions.push({
      label: crmTypes.SALESFORCE,
      value: crmTypes.SALESFORCE,
    });
  if (true)
    crmTypeOptions.push({ label: crmTypes.HUBSPOT, value: crmTypes.HUBSPOT });

  const crmOptions = getCrmListsError
    ? []
    : crmLists?.lists?.map(o => ({ label: o.name, value: o.crmId })) || [];

  const handleOk = () => {
    if (res || error) {
      onClose({ fetch: true, fetchMembers: true, ...(extraParams ?? {}) });
      return;
    }

    dispatch(setIsLoading(true));
    if (allSelected) {
      postPush({ crmId, crmType, ...(extraParams ?? {}) });
      return;
    }

    if (selectedMembers.all) {
      postPush({
        crmId,
        crmType,
        excludedFromAllResearcherIds: Object.keys(selectedMembers.exclude),
        ...(extraParams ?? {}),
      });
    } else {
      postPush({
        crmId,
        crmType,
        researcherIds: selectedMemberIds,
        ...(extraParams ?? {}),
      });
    }
  };

  const handleCrmTypeChange = value => {
    setCrmType(value);
    setCrmId(null);
    loadCrmLists();
  };

  const handleChange = value => {
    setCrmId(value);
  };

  useEffect(() => {
    if (res) {
      dispatch(setIsLoading(false));
    }
  }, [res, dispatch]);

  useEffect(() => {
    if (error) {
      if (error.status === 412) {
        onClose();
      }
      handleFailedExportNotification(error.status);
      dispatch(setIsLoading(false));
    }
  }, [error, onClose, dispatch]);

  return (
    <Modal
      open
      title={`Push ${activeList.name} to CRM`}
      onCancel={onClose}
      onOk={handleOk}
      okText={res || error ? 'Ok' : 'Push'}
      okButtonProps={{ disabled: loading || (!res && !crmId) }}
      cancelButtonProps={{
        disabled: loading || error,
        style: { display: error ? 'none' : 'inline-block' },
      }}
    >
      {res && !error && <ResultsContent res={res} loading={loading} />}
      {!res && !error && (
        <div>
          <ListCrmTypes
            value={crmType}
            onChange={handleCrmTypeChange}
            options={crmTypeOptions}
          />
          <ListCRMLink
            value={crmId}
            onChange={handleChange}
            options={crmOptions}
          />
          {crmType && crmId && (
            <Alert
              message={
                <div>
                  <p>
                    To avoid duplicates we look up existing contacts in your CRM
                    using researcher email.
                  </p>
                  <p>New researchers will be created where applicable.</p>
                  <p>
                    We link new and existing researchers with your CRM to the
                    CRM target campaign/list.
                  </p>
                  <p>
                    Please be aware that it may take a few minutes to complete a
                    push.
                  </p>
                  <p>
                    We will notify you on the platform and via email when the
                    push is completed.
                  </p>
                </div>
              }
              type='info'
            />
          )}
        </div>
      )}
      {error && <ErrorResponseContent error={error} />}
      {getCrmListsError && <ErrorResponseContent error={getCrmListsError} />}
    </Modal>
  );
};

export default PushCRM;
