import { useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'components/common';
import { disableInActive } from '../constants';

const Container = styled.div`
  display: flex;
  justify-content: ${props => (props.isEdit ? 'space-between' : 'flex-end')};
  > button,
  .tooltip {
    margin-right: 8px;
  }
`;

const Footer = ({
  isEdit,
  isLoading,
  submitDisabled,
  disableDisabled,
  onSubmit,
  submitBtnText,
  onCancel,
  onDisable,
}) => {
  const [isDisabling, setIsDisabling] = useState(false);
  const cancelBtnRef = useRef(null);
  useEffect(() => {
    cancelBtnRef.current?.focus();
  }, []);

  return (
    <Container isEdit={isEdit}>
      <Button disabled={isLoading} onClick={onCancel} ref={cancelBtnRef}>
        Cancel
      </Button>
      <div>
        {isEdit && (
          <Tooltip
            className='tooltip'
            title={disableDisabled ? disableInActive : ''}
          >
            <Button
              disabled={isLoading || disableDisabled}
              onClick={() => setIsDisabling(true)}
              danger
            >
              Disable
            </Button>
          </Tooltip>
        )}
        <Button
          disabled={isLoading || submitDisabled}
          onClick={onSubmit}
          type='primary'
        >
          {submitBtnText}
        </Button>
      </div>
      <Modal
        open={isDisabling}
        centered={true}
        title={'Disable User'}
        onOk={() => {
          setIsDisabling(false);
          onDisable();
        }}
        onCancel={() => setIsDisabling(false)}
      >
        <div>
          Are you sure you wish to Disable this user? All license entitlements
          will be removed from them
        </div>
      </Modal>
    </Container>
  );
};

Footer.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  submitDisabled: PropTypes.bool.isRequired,
  disableDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitBtnText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDisable: PropTypes.func,
};

Footer.defaultProps = {
  isLoading: false,
  disableDisabled: false,
  onDisable: () => null,
};

export default Footer;
