import styled from 'styled-components';

import { Table } from 'components/common';

export const AuthPanel = styled.div`
  margin-top: ${p => p.theme.gutter};
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-height: 100px;
    object-fit: contain;
    filter: grayscale(100%);
    margin-bottom: ${p => p.theme.gutter};
  }
`;

export const StyledTable = styled(Table)`
  margin-top: ${p => p.theme.gutter};
`;

export const ColoredField = styled.div`
  color: ${p => (p.isDefault ? p.theme.grey : p.theme.black)};
`;

export const ModalContent = styled.div`
  display: flex;
`;

export const FieldOptions = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: scroll;
  flex: 1;
`;

export const FieldOption = styled.div`
  padding: 5px 10px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.1s linear;
  svg {
    margin-left: 10px;
    transition: all 0.1s linear;
    opacity: 0;
  }
  .name {
    font-weight: 500;
  }
  .description {
    font-size: 11px;
  }
  :hover {
    background: rgba(0, 0, 0, 0.05);
    svg {
      opacity: 1;
    }
  }
`;
