import ReactDOM from 'react-dom';

import ChartJS from 'chart.js';
import 'mapbox-gl/dist/mapbox-gl.css';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ChartJS.plugins.register({
  beforeDraw: function(chartInstance) {
    const ctx = chartInstance.chart.ctx;
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
