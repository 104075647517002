import styled from 'styled-components';

import { Icon } from 'components/common';

const sizes = {
  small: '40px',
  medium: '75px',
  logo: '100px',
  large: '125px',
};

const iconSizes = {
  small: '1x',
  medium: '4x',
  large: '6x',
};

const AvatarContainer = styled.div`
  border: 3px solid ${p => (p.image ? p.theme.darkGrey : p.color)};
  height: ${p => sizes[p.size]};
  width: ${p => sizes[p.size]};
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  margin: ${p => (p.centered ? '0 auto' : '0 25px 0 0')};
`;

const getColor = () => `hsl(${Math.floor(Math.random() * 360)}, 40%, 80%)`;
const color = getColor();

const Avatar = ({ image, size, icon = 'user', ...rest }) => {
  return (
    <AvatarContainer color={color} size={size} image={image} {...rest}>
      {image ? (
        <img src={image} alt='Profile' />
      ) : (
        <Icon icon={icon} color={color} size={iconSizes[size]} />
      )}
    </AvatarContainer>
  );
};

Avatar.defaultProps = {
  size: 'medium',
  centered: false,
};

export default Avatar;
