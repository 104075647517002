import styled from 'styled-components';

export const Label = styled.div`
  font-weight: 500;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const MetadataLabel = styled.div`
  display: inline-flex;
  align-items: baseline;
  gap: 5px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-right: 5px;
`;

export const MetadataValue = styled.span`
  font-size: 12px;
  line-height: 18px;
`;

export const OwnersBar = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 5px;
  align-items: flex-start;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 10px;
  ${p => p.theme.breakpoint.mobile} {
    margin-left: 0px;
  }
`;

export const Owners = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const InnerContainerRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .span {
    font-size: 12px;
  }
`;

export const ActivityDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
`;

export const ActivityData = styled.div`
  font-weight: semi-bold;
  font-size: 14px;
`;

export const IconWrapper = styled.div`
  padding-top: 10px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;
