import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AutoComplete as AntDAutoComplete } from 'antd';
import { Tooltip } from 'components/common';

const AutoComplete = React.forwardRef(
  ({ value, onChange, formatter, helpText, ...rest }, ref) => {
    const [displayAndRealValue, setDisplayAndRealValue] = useState(
      formatter ? formatter(value) : value
    );

    const formatedValue = formatter ? formatter(value) : value;
    const formattedDisplay =
      formatedValue?.display !== undefined
        ? formatedValue.display
        : formatedValue;

    useEffect(() => {
      setDisplayAndRealValue(formatedValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formattedDisplay]);

    function changeValue(val) {
      let newFormattedValue = formatter ? formatter(val) : val;

      setDisplayAndRealValue(newFormattedValue);

      if (newFormattedValue?.value !== undefined) {
        onChange(newFormattedValue.value);
      } else {
        onChange(newFormattedValue);
      }
    }

    const autoComplete = (
      <AntDAutoComplete
        {...rest}
        value={
          displayAndRealValue?.display !== undefined
            ? displayAndRealValue.display
            : displayAndRealValue
        }
        onChange={changeValue}
        ref={ref}
      />
    );

    return (
      <>
        {helpText ? (
          <Tooltip title={helpText} placement='top'>
            {autoComplete}
          </Tooltip>
        ) : (
          autoComplete
        )}
      </>
    );
  }
);

export default AutoComplete;
