import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { Form, Button } from 'components/common';
import {
  performPurchasingSearch,
  clearPurchasingFilters,
  setPurchasingFilter,
} from 'actions/organisation';

import Filter from './helpers/FilterField';
import { SearchAreaContainer, ActionButtons } from './styled';
import {
  SLICE,
  getPurchasingFilter,
  getPurchasingLoading,
} from 'selectors/organisation';
import { OrgPurchasingSearchContext } from '..';
import { performBoolSearch, performPhraseSearch } from 'selectors/userSettings';
import BoolErrorMessage from 'components/Search/BoolErrorMessage';

const SearchBar = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

const PurchasingSearchArea = ({ organisation, loading }) => {
  const postSearch = useContext(OrgPurchasingSearchContext);
  const dispatch = useDispatch();

  const [showBoolError, setShowBoolError] = useState(false);
  const getHasBoolError = useSelector(
    performBoolSearch(SLICE, 'purchasingFilters')
  );
  const getHasPhraseError = useSelector(
    performPhraseSearch(SLICE, 'purchasingFilters')
  );
  
  const isDataLoading = useSelector(getPurchasingLoading);
  const isLoading = loading || isDataLoading;

  const searchTermRef = useRef();

  useEffect(() => {
    if (showBoolError === null) {
      setShowBoolError(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBoolError]);

  const handleSearch = () => {
    if (showBoolError !== null && (getHasBoolError || getHasPhraseError))
      setShowBoolError(true);
    else {
      dispatch(
        performPurchasingSearch(postSearch, {
          organisationId: organisation.id,
          newSearch: true,
        })
      );
    }
  };

  const performSearch = () => {
    dispatch(
      performPurchasingSearch(postSearch, {
        organisationId: organisation.id,
        newSearch: true,
      })
    );
  };

  const handleClear = () => {
    dispatch(clearPurchasingFilters);
  };

  return (
    <SearchAreaContainer>
      <SearchBar>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          ref={searchTermRef}
        >
          <Form width='100%' onSubmit={handleSearch} style={{ flex: 1 }}>
            <Filter
              filterType='input'
              placeholder='Enter a search term'
              filterId='term'
              size='default'
              allowClear
              disabled={isLoading}
            />
          </Form>
          {showBoolError ? (
            <BoolErrorMessage
              updateSearchQuery={value =>
                dispatch(setPurchasingFilter('term', value))
              }
              performSearch={performSearch}
              hasBoolFailed={getHasBoolError}
              term={getPurchasingFilter('term')}
              hasParseFailed={getHasPhraseError}
              searchTermRef={searchTermRef}
              setShowBoolError={setShowBoolError}
            />
          ) : (
            <></>
          )}
        </div>
        <ActionButtons>
          <Button
            type='secondary'
            data-testid='clear-search-button'
            onClick={handleClear}
            disabled={isLoading}
          >
            Clear
          </Button>
          <Button
            type='primary'
            data-testid='search-button'
            onClick={handleSearch}
            disabled={isLoading}
          >
            Search
          </Button>
        </ActionButtons>
      </SearchBar>
    </SearchAreaContainer>
  );
};

export default PurchasingSearchArea;
