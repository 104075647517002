import styled from 'styled-components';

import { Pane as CommonPane, Table as CommonTable } from 'components/common';

export const Container = styled.div`
  margin-top: ${p => p.theme.gutter};
  display: flex;
  flex-direction: column;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
  }
`;

export const FloatingComponent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(160deg, #fbf9f1, #fefefe);
  justify-content: center;
  align-items: center;
  gap: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 340px;
  overflow: hidden;
`;

const BaseEllipse = styled.div`
  position: absolute;
  box-sizing: border-box;
  border-radius: 50%;
  z-index: -10;
`;

export const Ellipse4 = styled(BaseEllipse)`
  width: 429px;
  height: 429px;
  left: 573px;
  top: -208px;
  border: 4px solid rgba(170, 213, 220, 0.3);
`;

export const Ellipse2 = styled(BaseEllipse)`
  width: 190px;
  height: 190px;
  left: 157px;
  top: -137px;
  background: #fbf9f1;
  border: 26px solid #f0e5f2;
  box-shadow: 0px 4px 4px rgba(240, 229, 242, 0.5);
`;

export const Ellipse1 = styled(BaseEllipse)`
  width: 361px;
  height: 361px;
  left: -176px;
  top: 164px;
  background: transparent;
  border: 62px solid rgba(254, 226, 214, 0.47);
  box-shadow: 8px -8px 10px rgba(254, 241, 235, 0.25);
`;

export const Ellipse3 = styled(BaseEllipse)`
  width: 414px;
  height: 414px;
  left: 589px;
  top: -206px;
  background: #fafdfc;
  border: 75px solid rgba(238, 247, 248, 0.78);
  box-shadow: -4px 4px 4px 20px rgba(241, 248, 249, 0.5);
`;

export const Column = styled.div`
  flex: 1 1 ${p => p.width || 'auto'};
  display: flex;
  flex-direction: column;
  ${p => p.theme.breakpoint.tablet} {
    width: 100%;
  }
`;

export const LeftColumn = styled(Column)`
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  margin-right: ${p => p.theme.gutter};
  ${p => p.theme.breakpoint.tablet} {
    flex: 0 1 400px;
    width: 100%;
  }
  ${p => p.theme.breakpoint.mobile} {
    margin-right: 0px;
    flex: 1;
  }
`;

export const GraphColumn = styled(Column)`
  width: 50%;
  ${p => p.theme.breakpoint.tablet} {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: ${p => (p.alignTop ? 'flex-start' : 'stretch')};
  ${p => p.theme.breakpoint.mobile} {
    flex-wrap: wrap;
  }
  > * {
    margin-right: ${p => p.theme.gutter};
    :last-child {
      margin-right: 0px;
    }
  }
`;

export const Pane = styled(CommonPane)`
  margin-bottom: ${p => p.theme.gutter};
`;

export const ContentPane = styled(CommonPane)`
  margin-bottom: 40px;
  .researchers-table {
    max-height: 60vh;
    overflow-y: scroll;
  }
`;

export const TableWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;

export const KeyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Dot = styled.div`
  flex: 0 0 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 7px;
`;

export const OrangeDot = styled(Dot)`
  background: ${p => p.theme.orange};
`;

export const TealDot = styled(Dot)`
  background: ${p => p.theme.teal};
`;

export const Table = styled(CommonTable)`
  margin-top: 15px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  ${p => p.theme.breakpoint.tablet} {
    padding: 0px;
  }
`;

export const TableContainer = styled.div`
  display: inline-block;
  width: 100% !important;
  margin-top: 15px;

  .cell {
    padding: 5px 15px !important;
  }
`;

export const OrgDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const OrgInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
`;

export const OrgAboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .about {
    font-size: 13px;
    line-height: 1.3;
    margin: 5px 0 10px;
  }
  img {
    float: right;
    margin: 15px 5px 10px;
  }
  button {
    margin-top: 5px;
    align-self: center;
  }
`;

export const WrappedDescription = styled.div`
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  h3 {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 13px;
    font-weight: bold;
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  > * {
    margin: 0px 5px;
  }
`;

export const FollowContentContainer = styled.div`
  > span {
    cursor: pointer;
    color: #2d9aff;
  }
`;

export const ProfileHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  //button {
  //  align-self: flex-end;
  //  margin-left: 2px;
  //  margin-bottom: 14px;
  //}
`;

export const LogoFlexContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProfileNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
