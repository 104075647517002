import styled from 'styled-components';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common';
import { dismissNotice } from 'actions/purchasing';
import { getShowNotice } from 'selectors/purchasing';

const StyledAlert = styled(Alert)`
  margin-bottom: ${p => p.theme.gutter};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;

const Message = ({ onDismiss }) => {
  return (
    <MessageContainer>
      <span>
        The data in this section is from multiple countries and includes federal
        sources, universities and hospitals as well as the NIH campuses. The
        data is updated on a rolling regular basis and the frequency of new
        information for a particular organization depends on how often they
        release data. For the list of countries included please click in the
        Country filter on the left hand side and view the drop down.
      </span>
      <Button onClick={onDismiss}>Got it</Button>
    </MessageContainer>
  );
};

const PurchasingNotice = () => {
  const showNotice = useSelector(getShowNotice);
  const dispatch = useDispatch();

  if (!showNotice) return null;

  const handleDismiss = () => {
    dispatch(dismissNotice);
  };

  return (
    <StyledAlert type='info' message={<Message onDismiss={handleDismiss} />} />
  );
};

export default PurchasingNotice;
