import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Detail, Icon, Input, Tooltip, Button } from 'components/common';
import MessageLoader from './MessageLoader';

import copy from 'copy-to-clipboard';
import useStreamValue from './useStreamValue';

import styled from 'styled-components';

const CopyButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  color: #161616;
  button: hover svg,
  button: focus svg,
  button: active svg {
    color: #40a9ff;
  }
  ,
  button: disabled svg {
    color: #d9d9d9;
  }
`;

function Message({ loading, disabled, value, isError, shouldStream }) {
  const [copied, setCopied] = useState(false);
  const handleCopy = e => {
    copy(value);
    setCopied(true);
  };

  const handleTooltipOpen = visible => {
    if (visible) {
      setCopied(false);
    }
  };

  const [streamedValue] = useStreamValue(value, shouldStream);

  return (
    <Detail
      label='Message'
      style={{ position: 'relative', marginBottom: '16px', fontSize: '14px' }}
    >
      <div style={{ position: 'relative' }}>
        <Input
          multiline
          readOnly
          rows={12}
          value={streamedValue}
          disabled={disabled}
          style={{
            minHeight: '250px',
            paddingRight: '32px',
            color: isError ? 'red' : '',
          }}
        />
        {loading && (
          <MessageLoader
            style={{
              position: 'absolute',
              top: '1px',
              left: '1px',
              width: 'calc(100% - 2px)',
              height: 'calc(100% - 2px)',
            }}
          />
        )}
      </div>

      <CopyButtonContainer onClick={e => e?.stopPropagation()}>
        <Tooltip
          title={!value ? '' : copied ? 'Copied' : 'Copy message'}
          onOpenChange={handleTooltipOpen}
          placement='left'
          mouseLeaveDelay={0}
        >
          <Button
            type='default'
            size='middle'
            disabled={disabled}
            icon={
              <Icon
                icon='copy'
                color='#161616'
                size='lg'
                style={{ marginRight: '8px' }}
              />
            }
            style={{ marginTop: '16px' }}
            onClick={() => {
              if (!loading && !disabled) {
                handleCopy();
              }
            }}
          >
            Copy message text
          </Button>
        </Tooltip>
      </CopyButtonContainer>
    </Detail>
  );
}

Message.propTypes = {
  value: PropTypes.string,
  isError: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldStream: PropTypes.bool,
};

export default Message;
