import { Radio } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { getGeoSearchData, getMapRegion } from 'selectors/search';
import { setMapRegion } from 'actions/search';

import { RegionSelectorContainer } from '../styled';
import { regions } from '../constants';

const options = [
  { label: 'World', value: regions.world },
  { label: 'United States', value: regions.usa },
  { label: 'Canada', value: regions.canada },
  { label: 'Europe', value: regions.europe },
  { label: 'Asia/Pacific', value: regions.asia },
  { label: 'Africa', value: regions.africa },
  { label: 'South America', value: regions.southAmerica },
];
const RegionSelector = ({ loading, hasMapError }) => {
  const { loading: isGeoSearchLoading } = useSelector(getGeoSearchData);
  const region = useSelector(getMapRegion);
  const dispatch = useDispatch();
  const handleChangeRegion = region => {
    dispatch(setMapRegion(region));
  };

  return (
    <RegionSelectorContainer>
      <span style={{ marginRight: 15 }}>Please select a map:</span>
      <Radio
        disabled={loading || isGeoSearchLoading || hasMapError}
        value={region}
        options={options}
        optionType='button'
        onChange={handleChangeRegion}
        style={{ fontWeight: 'normal' }}
        size='small'
      />
    </RegionSelectorContainer>
  );
};

export default RegionSelector;
