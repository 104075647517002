import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGet } from 'hooks';
import Actions from './Actions';
import Members from './Members';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveList, setListMembers, setModal } from 'actions/lists';
import {
  getActiveModal,
  getPageNumber,
  getPageSize,
  getTableSort,
  getActiveList,
} from 'selectors/lists';
import { getMembersForList } from 'services/api';
import { views } from './constants';
import { Create } from 'components/common/Lists/Modals';
import { Edit, Delete, AddTo, Export, RemoveFrom, PushCRM } from './Modals';
import useAddMembersAndRefetch from './hooks/useAddMembersAndRefetch';
import ListDescription from 'components/common/Lists/ListDescription';

const modals = {
  create: Create,
  createAndAdd: Create,
  edit: Edit,
  delete: Delete,
  addTo: AddTo,
  export: Export,
  removeFrom: RemoveFrom,
  pushCRM: PushCRM,
};

const OrganisationLists = ({ lists, loading, fetchLists }) => {
  const dispatch = useDispatch();
  const activeList = useSelector(getActiveList);
  const pageNumber = useSelector(getPageNumber);
  const pageSize = useSelector(getPageSize);
  const sort = useSelector(getTableSort);

  const activeModal = useSelector(getActiveModal);

  const [
    { res: fetchListMembersResponse, loading: fetchListMembersLoading },
    fetchListMembers,
  ] = useGet({
    url: '',
  });

  const [addingMembersLoading, handleAddMembers] = useAddMembersAndRefetch(
    activeList?.id,
    fetchLists,
    views.organisations
  );

  useEffect(() => {
    if (activeList?.id && lists) {
      const active = lists.find(f => f.id === activeList?.id);
      if (active) {
        dispatch(setActiveList(active));
      } else {
        dispatch(setActiveList(lists.length ? lists[0] : {}));
      }
    }
  }, [activeList?.id, dispatch, lists]);

  useEffect(() => {
    if (activeList?.id) {
      fetchListMembers({
        url: getMembersForList(activeList.id, views.organisations),
        query: {
          pageSize,
          pageNumber,
          orderBy: sort.by,
          isAscending: sort.dir === 'asc',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeList?.id]);

  useEffect(() => {
    if (fetchListMembersResponse) {
      dispatch(setListMembers(fetchListMembersResponse));
    }
  }, [dispatch, fetchListMembersResponse]);

  const onListChange = listId => {
    const activeList = lists.find(f => f.id === +listId);
    dispatch(setActiveList(activeList));
  };

  const onModalClose = options => {
    if (options?.id && activeModal === 'create') {
      fetchLists();
    }

    if (options?.id && activeModal === 'edit') {
      dispatch(setActiveList(options));
      fetchLists();
    }

    if (options?.id && activeModal === 'createAndAdd') {
      handleAddMembers(options);
    }

    if (options?.fetchMembers && activeModal === 'removeFrom') {
      fetchListMembers({
        url: getMembersForList(activeList.id, views.organisations),
        query: {
          pageSize,
          pageNumber,
          orderBy: sort.by,
          isAscending: sort.dir === 'asc',
        },
      });
    }

    if (options?.deleteListSuccessfully) {
      const newLists = lists.filter(x => x.id !== activeList.id);
      const active = newLists.length ? newLists[0] : {};
      dispatch(setActiveList(active));
      fetchLists();
    }

    dispatch(setModal(''));
  };

  const Modal = modals[activeModal];
  return (
    <>
      <Actions
        researcherLists={lists || []}
        loading={loading || addingMembersLoading || fetchListMembersLoading}
        onSelectorChange={onListChange}
        onRefreshListAfterPause={() => {
          fetchLists();
        }}
        hasIndustryLicense={true}
      />
      {activeList.description && !loading && (
        <ListDescription description={activeList.description} />
      )}
      <Members
        onCreateNewItemList={() => {
          dispatch(setModal('createAndAdd'));
        }}
        loading={fetchListMembersLoading}
        view={views.organisations}
      />
      {activeModal && (
        <Modal
          onClose={onModalClose}
          shouldSetActiveList={activeModal !== 'createAndAdd'}
          type={views.organisations}
        />
      )}
    </>
  );
};

OrganisationLists.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  fetchLists: PropTypes.func.isRequired,
};

OrganisationLists.defaultProps = {
  lists: [],
};

export default OrganisationLists;
