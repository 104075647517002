import { useEffect, useRef, useState, memo } from 'react';
import ChartJS from 'chart.js';
import { Empty } from 'antd';

import { HelpTooltip } from 'components/common';

import { getColour } from './helpers';
import { Container, GraphLabel } from './styled';

const GraphBase = memo(
  ({ label, data, type, options, onClick, helpText, fullWidth }) => {
    const ref = useRef();
    const isCursorPointer = useRef(!!onClick);
    const backgroundColor = useRef(getColour(data.data, type));
    const graphRef = useRef();
    const [hasMounted, setHasMounted] = useState();

    useEffect(() => {
      let timeout;
      if (!hasMounted) {
        timeout = setTimeout(() => setHasMounted(true), 1000);
      }
      return () => clearTimeout(timeout);
    }, [hasMounted]);

    useEffect(() => {
      if (!ref.current) return;
      if (!data) return;
      const ctx = ref.current.getContext('2d');
      ctx.clearRect(0, 0, ref.current.width, ref.current.height);

      const defaultOptions = {
        maintainAspectRatio: true,
        responsive: true,
        animation: {
          duration: hasMounted ? 0 : 1000,
        },
      };
      if (isCursorPointer.current) {
        defaultOptions.onHover = e => {
          const item = graphRef.current.getElementsAtEvent(e);
          if (item.length) {
            e.target.style.cursor = 'pointer';
            return;
          }

          e.target.style.cursor = 'default';
        };
      }

      graphRef.current = new ChartJS(ctx, {
        type,
        data: {
          labels: data.labels,
          datasets: [
            {
              label: null,
              data: data.data,
              backgroundColor: backgroundColor.current,
              borderWidth: 1,
            },
          ],
        },
        options: {
          ...defaultOptions,
          ...options,
        },
      });

      return () => graphRef.current.destroy();
    }, [data, type, options, onClick, hasMounted]);

    return (
      <Container fullWidth={fullWidth}>
        <GraphLabel>
          {label}
          {helpText && <HelpTooltip>{helpText}</HelpTooltip>}
        </GraphLabel>
        {data && data.labels && data.labels.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <canvas ref={ref} />
        )}
      </Container>
    );
  },
  () => true
);

GraphBase.defaultProps = {
  onClick: null,
};

export default GraphBase;
