import { Icon, Link, Clamp, Highlight } from 'components/common';
import { Container } from '../styled';
import { colours } from 'utils/theme';
import { format } from 'utils/date';
import { formatCurrency } from 'utils/number';
import OwnersDetails from './OwnersDetail';
import {
  ActivityDetail,
  ActivityData,
  MetadataLabel,
  MetadataValue,
  InnerContainerRow,
  ContentWrapper,
  IconWrapper,
} from './styled';

const Funding = ({
  sciLeadsAwardId,
  title,
  dateAwarded,
  fundingAmountUsd,
  fundingSource,
  highlights,
  researchers,
  organisations,
}) => {
  return (
    <Container>
      <InnerContainerRow>
        <IconWrapper>
          <Icon color={colours.grants} size='1x' icon='award' />
        </IconWrapper>
        <ContentWrapper>
          <Link to={`project/${sciLeadsAwardId}`} showIconHint>
            <Clamp text={title} />
          </Link>
          <ActivityDetail>
            <Highlight highlights={highlights} />
            <InnerContainerRow>
              <ActivityData>
                <MetadataLabel>
                  <Icon
                    icon='calendar'
                    size='sm'
                    color={colours.black}
                    fixedWidth
                  />
                  Date Awarded:{' '}
                </MetadataLabel>
                <MetadataValue>
                  {format(dateAwarded, 'D MMM YYYY')}
                </MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon icon='award' size='sm' color={colours.black} />
                  Funding Source:{' '}
                </MetadataLabel>
                <MetadataValue>{fundingSource ?? '-'}</MetadataValue>
              </ActivityData>
              <ActivityData>
                <MetadataLabel>
                  <Icon icon='sackDollar' size='sm' color={colours.black} />
                  Funding Amount:{' '}
                </MetadataLabel>
                <MetadataValue>
                  ${formatCurrency(fundingAmountUsd)}
                </MetadataValue>
              </ActivityData>
            </InnerContainerRow>
          </ActivityDetail>
          <OwnersDetails
            researchers={researchers}
            organisations={organisations}
          />
        </ContentWrapper>
      </InnerContainerRow>
    </Container>
  );
};

export default Funding;
