import { FixedSizeList as List } from 'react-window';

import Link from '../Link';
import Tooltip from '../Tooltip';

const itemSize = 22;
const maxItems = 10;

const Author = ({ style, data, index, formatName, profileIdKey }) => {
  const author = data[index];
  if (!author) return null;

  const name = formatName(author);

  if (author[profileIdKey]) {
    return (
      <Link style={style} to={`/researcher/${author[profileIdKey]}`}>
        {name}
      </Link>
    );
  }

  return <div style={style}>{name}</div>;
};

const PublicationAuthors = ({
  authors,
  authorCount,
  formatName,
  profileIdKey,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Tooltip
        title={
          <List
            itemData={authors}
            itemCount={authorCount}
            height={
              authorCount < maxItems
                ? authorCount * itemSize + 1
                : itemSize * maxItems
            }
            itemSize={itemSize}
            width={200}
          >
            {props => (
              <Author
                {...props}
                formatName={formatName}
                profileIdKey={profileIdKey}
              />
            )}
          </List>
        }
      >
        {authorCount} authors
      </Tooltip>
    </div>
  );
};

const AuthorName = ({ name }) => (
  <div
    title={name}
    style={{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }}
  >
    {name}
  </div>
);

PublicationAuthors.defaultProps = {
  formatName: author => <AuthorName name={author.author} />,
  profileIdKey: 'superResearcherId',
};

export default PublicationAuthors;
