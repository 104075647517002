import { useState } from 'react';

import Ratings from './Ratings';
import Feedback from './Feedback';
import { Fader } from './styled';

const Content = ({ onNpsScore, onFeedbackMessage, onFeedbackDismiss }) => {
  const [activeContent, setActiveContent] = useState('buttons');
  const [fade, setFade] = useState(false);

  const handleNpsScore = score => {
    onNpsScore(score);
    setFade(true);
    setTimeout(() => {
      setActiveContent('feedback');
    }, 200);
    setTimeout(() => {
      setFade(false);
    }, 400);
  };

  const handleNpsFeedback = feedback => {
    onFeedbackMessage(feedback);
    setFade(true);
    setTimeout(() => setActiveContent('message'), 200);
  };

  const content = {
    buttons: <Ratings onClick={handleNpsScore} />,
    feedback: (
      <Feedback onSubmit={handleNpsFeedback} onDismiss={onFeedbackDismiss} />
    ),
    message: 'Thank you!',
  };

  return <Fader fade={fade}>{content[activeContent]}</Fader>;
};

export default Content;
