import { Tooltip } from 'antd';

import { Badge, IconText } from 'components/common';
import { diff } from 'utils/date';

import { generateChangedText } from './helpers';

const JobTitle = ({
  title,
  previousTitle,
  startDate,
  iconTextComponent = IconText,
}) => {
  const monthDiff = diff(startDate);
  const monthDiffBy3 = Math.ceil(monthDiff / 3) * 3;

  const IconTextComponent = iconTextComponent;

  if (!startDate || monthDiff > 6) {
    return <IconTextComponent value={title} icon='briefcase' />;
  }

  const changedText = generateChangedText('jobs', monthDiffBy3, previousTitle);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconTextComponent value={title} icon='briefcase' />
      <Tooltip title={changedText}>
        <Badge>{monthDiffBy3}</Badge>
      </Tooltip>
    </div>
  );
};

export default JobTitle;
