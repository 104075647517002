export const generateChangedText = (type, months, previous) => {
  const fromText = previous ? ` from ${previous} ` : ' ';
  const monthOrMonths = months > 0 ? 'months' : 'month';
  const monthCount = months > 0 ? `${months} ` : '';

  return `Changed ${type}${fromText}within the last ${monthCount}${monthOrMonths}`;
};

export const formatEmail = (email, emailStatusCategory) => {
  if (email === 'TrialUser' || emailStatusCategory === 'TrialUser')
    return 'Subscribe for Emails';

  const isUnavailable = email === 'Email unavailable';
  const isUndeliverable = emailStatusCategory === 'Undeliverable';
  if (isUnavailable || isUndeliverable) return '';

  return email;
};
