import { RatingContainer, Row } from './styled';

const Rating = ({ value, onClick }) => (
  <RatingContainer data-testid={`rating-${value}`} onClick={onClick}>
    {value}
  </RatingContainer>
);

const Ratings = ({ onClick }) => {
  const generateButtons = num =>
    [...Array(num)].map((_, i) => (
      <Rating key={i} value={i} onClick={() => onClick(i)} />
    ));

  return (
    <div data-testid='net-promoter-score'>
      <div style={{ display: 'flex' }}>{generateButtons(11)}</div>
      <Row>
        <div>Not likely</div>
        <div>Very likely</div>
      </Row>
    </div>
  );
};

export default Ratings;
