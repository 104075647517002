//@ts-check
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { format } from 'utils/date';
import { siNumber } from 'utils/number';

export const HighlightContainer = styled.div`
  margin-left: 5px;
  > div {
    font-size: 14px;
  }
`;
/**
 * @param {{
 *  organisation: import('../Funding/index').OrganisationVCFundingDetails
 * }} props
 **/
const OrganisationInvestmentHighlights = ({ organisation }) => {
  if (!organisation?.superOrganisationInvestments?.length) {
    return null;
  }

  const sortedByAnnoundedDate =
    organisation.superOrganisationInvestments?.sort((a, b) =>
      dayjs(b.announcedDate).diff(dayjs(a.announcedDate))
    ) || [];

  /**
   * @type {Map<string, import('../Funding/index').Investment[]>}
   */
  const groupings = new Map();
  for (let round of sortedByAnnoundedDate) {
    let existing = groupings.get(round.investmentType);
    if (existing) {
      groupings.set(round.investmentType, [...existing, round]);
    } else {
      groupings.set(round.investmentType, [round]);
    }
  }

  const maxGroup = Array.from(groupings.entries()).reduce((prev, curr) => {
    if (!prev || curr[1]?.length > prev[1]?.length) {
      return curr;
    }

    return prev;
  });

  const totalInvested = sortedByAnnoundedDate.reduce(
    (prev, curr) => prev + curr.raisedAmountUsd,
    0
  );

  return (
    <HighlightContainer>
      <div>{`${organisation.organisationName} has made a total of ${
        sortedByAnnoundedDate.length
      } investments between ${format(
        sortedByAnnoundedDate[sortedByAnnoundedDate.length - 1].announcedDate
      )} and now.`}</div>
      {!!maxGroup[1]?.length && (
        <div>{`${organisation.organisationName}'s most commonly invests in ${maxGroup[0]} rounds with a total of ${maxGroup[1].length} at this stage.`}</div>
      )}
      <div>{`The total amount of money into these investments by ${
        organisation.organisationName
      } and their coinvestors is $${siNumber(totalInvested)}`}</div>
    </HighlightContainer>
  );
};

export default OrganisationInvestmentHighlights;
