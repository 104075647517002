import { createContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Skeleton, Button, Typography } from 'antd';

import { DownOutlined } from '@ant-design/icons';

import {
  Container,
  Column,
  LeftColumn,
  Pane,
  ContentPane,
  Row,
  ProfileContainer,
  FloatingComponent,
  Ellipse1,
  Ellipse2,
  Ellipse3,
  Ellipse4,
} from './styled';
import { TabbedPanel } from 'components/common';
import OrganisationProfileFilters from './Filters';
import OrganisationResearchersSearchArea from './Search';
import OrganisationResearchers from './Researchers';

import Statistics from './Statistics';
import { useDispatch, useSelector } from 'react-redux';
import { setView, resetAllFilters } from 'actions/organisation';
import { getOrganisationView } from 'selectors/organisation';
import CrunchbaseAttribution from './Funding/attribution';
import {
  ORGANISATIONACQUISITIONSVIEW,
  ORGANISATIONPEOPLEVIEW,
  ORGANISATIONPURCHASINGVIEW,
  ORGANISATIONTRADESHOWSVIEW,
  ORGANISATIONVCFUNDINGVIEW,
  ORGANISATIONINVESTMENTSVIEW,
  ORGANISATIONCLINICALTRIALSVIEW,
} from './helpers';
import OrganisationDownloadButton from './Downloads';
import Lists from 'components/Researcher/Lists';
import { views } from 'components/Lists/constants';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 7px;
  ${p => p.theme.breakpoint.tablet} {
    padding: 0px;
  }
`;

export const OrgDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
`;

export const WrappedDescription = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
`;

export const OrgSearchContext = createContext();
export const OrgPurchasingSearchContext = createContext();

const { Text } = Typography;

const CTA = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const activeTab = useSelector(getOrganisationView);
  const history = useHistory();

  const searchParams = new URLSearchParams(
    new URL(window.location.href)?.search
  );
  const initialView = searchParams?.get('initialView');

  useEffect(() => {
    dispatch(resetAllFilters(id, false));
  }, [id, dispatch]);

  useEffect(() => {
    if (initialView !== ORGANISATIONPEOPLEVIEW) {
      dispatch(setView(ORGANISATIONPEOPLEVIEW));
    }
  }, [dispatch, activeTab, initialView]);

  const config = {};

  config[ORGANISATIONPEOPLEVIEW] = {
    icon: 'users',
    text: 'People',
    content: (
      <TableContainer>
        <OrganisationResearchersSearchArea organisation={{}} />
        <OrganisationResearchers organisation={{}} loading={false} />
      </TableContainer>
    ),
  };
  config.workItems = {
    icon: 'folderOpen',
    text: 'Activity',
  };
  config[ORGANISATIONTRADESHOWSVIEW] = {
    icon: 'calendar',
    text: 'Tradeshows',
  };

  config[ORGANISATIONCLINICALTRIALSVIEW] = {
    icon: 'trial',
    text: 'Clinical Trials',
  };
  config[ORGANISATIONPURCHASINGVIEW] = {
    icon: 'searchDollar',
    text: 'Products Sold',
  };

  config[ORGANISATIONVCFUNDINGVIEW] = {
    icon: 'sackDollar',
    text: 'VC Funding',
  };

  config[ORGANISATIONACQUISITIONSVIEW] = {
    icon: 'handShake',
    text: 'Acquisitions',
  };

  config[ORGANISATIONINVESTMENTSVIEW] = {
    icon: 'dollarNote',
    text: 'Investments',
  };

  return (
    <>
      <div style={{ filter: 'blur(2px)' }}>
        <OrgSearchContext.Provider value={{}}>
          <Container>
            <Row alignTop>
              <LeftColumn>
                <Pane>
                  <ProfileContainer>
                    <Skeleton.Avatar size={64} shape='square' />
                    <Skeleton paragraph={{ rows: 0 }} />
                    <OrgDetailsContainer>
                      <Skeleton />
                    </OrgDetailsContainer>
                    <WrappedDescription>
                      <Skeleton paragraph={{ rows: 2 }} />
                    </WrappedDescription>
                    <Button icon={<DownOutlined />}>View More</Button>
                  </ProfileContainer>
                </Pane>
                <OrganisationProfileFilters organisation={{}} />
                <Pane title='List Management' style={{ marginTop: 15 }}>
                  <Lists
                    editableLists={[]}
                    loadingLists={true}
                    type={views.organisations}
                    skipMountLoad
                  />
                </Pane>
              </LeftColumn>
              <Column>
                <Statistics
                  organisation={{}}
                  loading={false}
                  displayWhenZero={'-'}
                />
                <ContentPane>
                  <TabbedPanel
                    horizontal
                    activeTab={activeTab}
                    config={config}
                    leftMargin
                  >
                    <OrganisationDownloadButton organisation={{}} />
                  </TabbedPanel>
                </ContentPane>
                <CrunchbaseAttribution activeTab={activeTab} />
              </Column>
            </Row>
          </Container>
        </OrgSearchContext.Provider>
      </div>
      <FloatingComponent>
        <Ellipse1 />
        <Ellipse2 />
        <Ellipse3 />
        <Ellipse4 />
        <Text style={{ fontSize: '24px', color: '#161616', fontWeight: '500' }}>
          Upgrade your License to View BioPharma Content
        </Text>
        <Text
          style={{ fontSize: '14px;', color: '#595959', maxWidth: '528px' }}
        >
          Upgrade your license today to get access to the top-level information
          about all organisations and researchers. Maximise lead generation and
          customers conversion.
        </Text>
        <div>
          <Text
            style={{ fontSize: '13px', color: '#161616', fontWeight: '500' }}
          >
            To speak to a team member about upgrading your subscription,{' '}
            <a href={'mailto:laura@scileads.com'}>click here</a>.
          </Text>
        </div>
        <Button onClick={() => history.goBack()}>
          Go back to the previous page.
        </Button>
      </FloatingComponent>
    </>
  );
};

export default CTA;
