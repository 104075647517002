import styled from 'styled-components';

export const ResearcherAutocompleteBlockContainer = styled.div`
  display: flex;
`;

export const ResearcherAutocompleteLocationBlockContainer = styled.div`
  display: flex;
  font-size: 12px;
`;

export const ResearcherAutocompleteLocationItem = styled.div`
  margin-right: 10px;
  div {
    max-width: 40vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    padding-top: 3px;
    line-height: 13px;
  }
  .icon {
    padding-top: 0;
    margin-right: 2px !important;
  }
`;

export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  ${p => p.theme.breakpoint.tablet} {
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 10px;
  }
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: top;
`;
