import styled from 'styled-components';

const Badge = styled.div`
  cursor: pointer;
  background: ${p => p.theme.teal};
  color: white;
  font-size: 11px;
  padding: 0 4px;
  line-height: 16px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-left: 4px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  font-weight: bold;
`;

export default Badge;
