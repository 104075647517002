export const academicWarning = showAcademicCredits =>
  `You’re close to your monthly ${
    showAcademicCredits ? 'credit' : 'fair usage'
  } limit for Academic; please contact Customer Success if you have any questions.`;
export const industryWarning = showIndustryCredits =>
  `You’re close to your monthly ${
    showIndustryCredits ? 'credit' : 'fair usage'
  } limit for Biopharma; please contact Customer Success if you have any questions.`;
export const commonWarning = (showAcademicCredits, showIndustryCredits) =>
  `You’re close to your monthly ${
    showAcademicCredits ? 'credit' : 'fair usage'
  } limit for Academic, and your monthly ${
    showIndustryCredits ? 'credit' : 'fair usage'
  } limit for Biopharma; please contact Customer Success if you have any questions.`;
export const academicError = showAcademicCredits =>
  `You’re over your monthly ${
    showAcademicCredits ? 'credit' : 'fair usage'
  } limit for Academic; please contact Customer Success if you have any questions.`;
export const industryError = showIndustryCredits =>
  `You’re over your monthly ${
    showIndustryCredits ? 'credit' : 'fair usage'
  } limit for Biopharma; please contact Customer Success if you have any questions.`;
export const commonError = (showAcademicCredits, showIndustryCredits) =>
  `You’re over your monthly ${
    showAcademicCredits ? 'credit' : 'fair usage'
  } limit for Academic, and your monthly ${
    showIndustryCredits ? 'credit' : 'fair usage'
  } limit for Biopharma; please contact Customer Success if you have any questions.`;
export const academicTrial =
  'You cannot export while on a trial. If you would like to export Academic data, please contact Customer Success to purchase a licence.';
export const industryTrial =
  'You cannot export while on a trial. If you would like to export Biopharma data, please contact Customer Success to purchase a licence.';
export const allTrial =
  'You cannot export while on a trial. If you would like to export this data, please contact Customer Success to purchase a licence.';
export const disabledExport =
  'You cannot export. If you would to export this data, please contact Customer Success to purchase a licence.';
export const excludeAcademic =
  'You’re over your monthly credit limit for Academic; academic emails will be excluded from export.';
export const excludeIndustry =
  'You’re over your monthly credit limit for Industry; industry emails will be excluded from export.';
export const noNewEmails =
  "No new emails were found in your search. Although the total count shows '0' emails, you can still export contacts without associated emails.";
export const noContacts =
  'No new contacts were found in your search. Only contacts with emails that have already been exported in previous downloads are available. Please refine your search and try again.';
export const downloadType = {
  researcherSearch: 0,
  organisationSearch: 1,
  organisationResearchersSearch: 2,
  organisationActivitySearch: 3,
  organisationClinicalTrialsSearch: 4,
  tradeshowSearch: 5,
  network: 6,
  researcherList: 7,
  purchasingSearch: 8,
  crmPush: 9,
  organisationOnlyExport: 10,
  organisationList: 11,
};
