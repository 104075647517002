import React from 'react';
import styled from 'styled-components';

import { siNumber } from 'utils/number';

const Container = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  border: 4px solid #f6f3ee;
  font-size: 16px;
  color: ${p => p.theme.black};
  display: flex;
  align-items: center;
  flex-direction: row;
  .content {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: semi-bold;
`;

const Value = styled.span`
  font-size: 26px;
  font-weight: bold;
`;

const PdfStatistic = ({ icon, title, value }) => {
  return (
    <Container>
      {icon}
      <div className={'content'}>
        <Title>{title}</Title>
        <Value>{value && siNumber(value)}</Value>
      </div>
    </Container>
  );
};

export default PdfStatistic;
