import {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from 'react';

import {
  currentUser,
  logInExternalUser,
  logInUser,
  logOutUser,
  verifyUserCode,
} from 'services/api';
import { usePost, useGet } from 'hooks';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const value = useGenerateAuth();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

const useGenerateAuth = () => {
  const [auth, setAuth] = useState({
    user: null,
    pending: true,
    isAuthed: false,
    wasLoggedOut: false,
  });

  const [
    { res: user, error: loginError, loading: loggingIn },
    logIn,
  ] = usePost({ url: logInUser });
  const [
    {
      res: externalUser,
      error: externalLoginError,
      loading: externalLoggingIn,
    },
    externalLogIn,
  ] = usePost({ url: logInExternalUser });
  const [
    { res: userWithVerifiedCode, error: verifyCodeError, loading: isVerifying },
    verifyCode,
  ] = usePost({
    url: verifyUserCode,
  });
  const [{ res: loggedOut }, logOutReq] = usePost({ url: logOutUser });
  const [{ res: userCheck, error: authError }, checkUser] = useGet({
    url: currentUser,
  });

  const logOut = useCallback(() => {
    logOutReq();
    localStorage.removeItem('token');
  }, [logOutReq]);

  const softLogOut = useCallback(() => {
    setAuth({
      user: null,
      pending: false,
      isAuthed: false,
      wasLoggedOut: false,
    });
  }, []);

  useEffect(() => {
    if (user) {
      setAuth({
        user: user.user,
        pending: false,
        isAuthed: true,
        wasLoggedOut: false,
      });
      if (user.token) {
        localStorage.setItem('token', user.token);
      }
    }
  }, [user]);

  useEffect(() => {
    if (externalUser) {
      setAuth({
        user: externalUser.user,
        pending: false,
        isAuthed: true,
        wasLoggedOut: false,
      });
      if (externalUser.token) {
        localStorage.setItem('token', externalUser.token);
      }
    }
  }, [externalUser]);

  useEffect(() => {
    if (userWithVerifiedCode) {
      setAuth({
        user: userWithVerifiedCode.user,
        pending: false,
        isAuthed: true,
        wasLoggedOut: false,
      });
      if (userWithVerifiedCode.token) {
        localStorage.setItem('token', userWithVerifiedCode.token);
      }
    }
  }, [userWithVerifiedCode]);

  useEffect(() => {
    if (loggedOut) {
      setAuth({
        user: null,
        pending: false,
        isAuthed: false,
        wasLoggedOut: true,
      });
    }
  }, [loggedOut]);

  useEffect(() => {
    if (authError) {
      setAuth({
        user: null,
        pending: false,
        isAuthed: false,
        wasLoggedOut: false,
      });
    }
  }, [authError]);

  useEffect(() => {
    if (userCheck) {
      setAuth({
        user: userCheck,
        pending: false,
        isAuthed: true,
        wasLoggedOut: false,
      });
    }
  }, [userCheck]);

  useEffect(() => {
    if (verifyCodeError?.status === 412) {
      setAuth({
        user: null,
        pending: false,
        isAuthed: false,
        wasLoggedOut: false,
      });
    }
  }, [verifyCodeError]);

  const hasLicense = useCallback(
    (allowedLicenses, allowForTrials = false) => {
      if (!auth.isAuthed || !auth.user?.licenses) {
        return false;
      }

      return auth.user.licenses.some(
        x => allowedLicenses.includes(x.type) && (allowForTrials || !x.demoMode)
      );
    },
    [auth.isAuthed, auth.user]
  );

  const hasSpecificLicense = useCallback(
    requiredLicenses => {
      if (!auth.isAuthed || !auth.user?.licenses) {
        return false;
      }

      // Check if both arrays have the same length
      if (auth.user.licenses.length !== requiredLicenses.length) {
        return false;
      }

      // Check if all requiredLicenses are present in auth.user.licenses
      return requiredLicenses.every(requiredLicense =>
        auth.user.licenses.some(
          userLicense =>
            userLicense.type === requiredLicense.type &&
            userLicense.demoMode === requiredLicense.demoMode
        )
      );
    },
    [auth.isAuthed, auth.user]
  );

  return {
    loginError,
    logIn,
    externalLogIn,
    externalLoginError,
    logOut,
    softLogOut,
    checkUser,
    loggingIn,
    externalLoggingIn,
    verifyCode,
    verifyCodeError,
    isVerifying,
    hasLicense,
    hasSpecificLicense,
    ...auth,
  };
};
