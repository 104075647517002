import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  .drawing-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
  }
`;

export const NetworkDiagramContainer = styled.div`
  width: 100%;
  height: ${p => (p.fullscreen ? '70vh' : '400px')};
`;

export const ZoomButtons = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: white;
  z-index: 2;
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
  }
`;
