export const views = {
  savedSearches: 'savedSearches',
  followedResearchers: 'followedResearchers',
  followedOrganisations: 'followedOrganisations',
};

export const metricActions = {
  [views.savedSearches]: 'VisitAlertsTab',
  [views.followedResearchers]: 'VisitFollowedResearchersTab',
  [views.followedOrganisations]: 'VisitFollowedOrganisationsTab',
};
