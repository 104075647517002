import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from 'actions/search';
import {
  categories,
  categoriesAcademic,
  categoriesIndustry,
  categoriesLegacy,
  categoriesLegacyIndustry,
} from 'services/inputTokens';
import { Token } from 'components/common/TokenInput';

import { getFilter, getSearchRequest } from 'selectors/search';

import { Tooltip } from 'antd';

import { useAuth } from 'hooks';
import licenseType from 'models/licenseType';
import { Filter } from '../../helpers';
import { AiIcon } from 'components/common';
import { useState, useCallback, useEffect, Fragment } from 'react';
import { OrgCategoryContainer, OrgCategorySection } from './styled';

let isOpen = false;

const OrgCategory = ({ activeOnly }) => {
  const organisationCategory = 'organisationCategory';
  const valueFromStore = useSelector(getFilter(organisationCategory));
  const { loading } = useSelector(getSearchRequest);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [overflowValues, setOverflowValues] = useState([]);

  const [selectedValues, setSelectedValues] = useState(valueFromStore || []);

  const findParentKey = useCallback((nodes, value) => {
    for (const node of nodes) {
      if (node.children) {
        if (node.children.some(child => child.value === value)) {
          return node.key;
        }
        const parentKey = findParentKey(node.children, value);
        if (parentKey) {
          return parentKey;
        }
      }
    }
    return null;
  }, []);

  const findLabel = useCallback((nodes, value) => {
    for (const node of nodes) {
      if (node.value === value) {
        return node.title;
      }
      if (node.children) {
        const result = findLabel(node.children, value);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }, []);

  const dispatch = useDispatch();

  const { hasSpecificLicense } = useAuth();

  const hasIndustryFullLicenseOnly = hasSpecificLicense([
    { type: licenseType.industry, demoMode: false },
  ]);
  const hasIndustryTrialLicenseOnly = hasSpecificLicense([
    { type: licenseType.industry, demoMode: true },
  ]);
  const hasIndustryFullAndAcademicTrialLicense = hasSpecificLicense([
    { type: licenseType.industry, demoMode: false },
    { type: licenseType.academic, demoMode: true },
  ]);

  const hasAcademicFullLicenseOnly = hasSpecificLicense([
    { type: licenseType.academic, demoMode: false },
  ]);
  const hasAcademicTrialLicenseOnly = hasSpecificLicense([
    { type: licenseType.academic, demoMode: true },
  ]);
  const hasAcademicFullAndIndustryTrialLicense = hasSpecificLicense([
    { type: licenseType.academic, demoMode: false },
    { type: licenseType.industry, demoMode: true },
  ]);

  const hasAcademicAndIndustryFullLicense = hasSpecificLicense([
    { type: licenseType.academic, demoMode: false },
    { type: licenseType.industry, demoMode: false },
  ]);
  const hasAcademicAndIndustryTrialLicense = hasSpecificLicense([
    { type: licenseType.academic, demoMode: true },
    { type: licenseType.industry, demoMode: true },
  ]);
  const hasLegacyTrialLicenseOnly = hasSpecificLicense([
    { type: licenseType.legacy, demoMode: true },
  ]);

  const hasLegacyFullOnly = hasSpecificLicense([
    { type: licenseType.legacy, demoMode: false },
  ]);

  const hasLegacyFullAndIndustryTrialLicense = hasSpecificLicense([
    { type: licenseType.legacy, demoMode: false },
    { type: licenseType.industry, demoMode: true },
  ]);

  const hasLegacyFullAndIndustryFullLicense = hasSpecificLicense([
    { type: licenseType.legacy, demoMode: false },
    { type: licenseType.industry, demoMode: false },
  ]);

  let filterOptions = categoriesAcademic;

  if (
    hasAcademicAndIndustryFullLicense ||
    hasIndustryFullAndAcademicTrialLicense ||
    hasAcademicFullAndIndustryTrialLicense ||
    hasAcademicAndIndustryTrialLicense
  ) {
    filterOptions = categories;
  }

  if (hasLegacyFullOnly || hasLegacyTrialLicenseOnly) {
    filterOptions = categoriesLegacy;
  }

  if (hasAcademicFullLicenseOnly || hasAcademicTrialLicenseOnly) {
    filterOptions = categoriesAcademic;
  }

  if (hasIndustryFullLicenseOnly || hasIndustryTrialLicenseOnly) {
    filterOptions = categoriesIndustry;
  }

  if (
    hasLegacyFullAndIndustryTrialLicense ||
    hasLegacyFullAndIndustryFullLicense
  ) {
    filterOptions = categoriesLegacyIndustry;
  }

  const hasUnmatchedCategories = (valueFromStore, filterOptions) => {
    const collectValues = options => {
      return options.flatMap(option => {
        if (option.children) {
          const childrenValues = collectValues(option.children);
          return [option.value, ...childrenValues];
        }
        return option.value;
      });
    };

    const filterOptionsValues = collectValues(filterOptions);
    return valueFromStore.some(value => !filterOptionsValues.includes(value));
  };

  const unmatched = hasUnmatchedCategories(selectedValues, filterOptions);

  useEffect(() => {
    if (unmatched) {
      dispatch(setFilter(organisationCategory, []));
      setSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTooltipContent = () => {
    return (
      <>
        {overflowValues.map(label => (
          <Fragment key={label}>
            <span>{label}</span>
            <br />
          </Fragment>
        ))}
      </>
    );
  };

  const updateTooltip = useCallback(() => {
    const overflowItem = document.querySelector(
      '.ant-select-selection-overflow-item-rest'
    );
    if (overflowItem) {
      const outerHTML = overflowItem.outerText;
      const isVisible = overflowItem.style.opacity !== '0';
      if (isVisible) {
        const overflowMatch = outerHTML.match(/\+ (\d+) \.\.\./);
        if (overflowMatch && parseInt(overflowMatch[1], 10) > 0) {
          if (!isOpen) {
            setTooltipVisible(true);
          }
          const overflowCount = parseInt(overflowMatch[1], 10);
          const overflowValues = selectedValues
            .slice(-overflowCount)
            .map(value => {
              const option = findLabel(filterOptions, value);
              return option ? option : 'Unknown';
            });
          setOverflowValues(overflowValues);
        } else {
          setTooltipVisible(false);
        }
      } else {
        setTooltipVisible(false);
      }
    } else {
      setTooltipVisible(false);
    }
  }, [selectedValues, filterOptions, findLabel]);

  const handleMouseEnter = () => {
    updateTooltip();
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  useEffect(() => {
    setSelectedValues(valueFromStore || []);
  }, [valueFromStore]);

  useEffect(() => {
    const newExpandedKeys = new Set();

    selectedValues.forEach(value => {
      const parentKey = findParentKey(filterOptions, value);
      if (parentKey) {
        newExpandedKeys.add(parentKey);
      }
      newExpandedKeys.add(value);
    });

    setExpandedKeys(Array.from(newExpandedKeys));
  }, [filterOptions, selectedValues, findParentKey]);

  useEffect(() => {
    updateTooltip();
  }, [selectedValues, updateTooltip]);

  const handleOrgCategoryChange = newValues => {
    if (hasLegacyFullOnly || hasLegacyTrialLicenseOnly) {
      const option1 = 'Industry (All)';
      const option2 = 'BioPharma';

      const hasOption1 = newValues.includes(option1);
      const hasOption2 = newValues.includes(option2);

      if (hasOption1 && hasOption2) {
        if (selectedValues.includes(option2)) {
          newValues = newValues.filter(value => value !== option2);
        } else {
          newValues = newValues.filter(value => value !== option1);
        }
      }
    }

    dispatch(setFilter(organisationCategory, newValues));

    const newExpandedKeys = new Set(expandedKeys);
    newValues.forEach(value => {
      const parentKey = findParentKey(filterOptions, value);
      if (parentKey) {
        newExpandedKeys.add(parentKey);
      }
    });
    setExpandedKeys([...newExpandedKeys]);

    updateTooltip();
  };

  const onExpand = newExpandedKeys => {
    setExpandedKeys(newExpandedKeys);
  };

  const onDropdownVisibleChange = open => {
    isOpen = open;
    if (isOpen) {
      setTooltipVisible(false);
      const newExpandedKeys = new Set(expandedKeys);

      if (selectedValues.length === 0) {
        const expandAllParentNodes = nodes => {
          nodes.forEach(node => {
            if (node.children) {
              newExpandedKeys.add(node.key);
              expandAllParentNodes(node.children);
            }
          });
        };

        expandAllParentNodes(filterOptions);
      } else {
        selectedValues.forEach(value => {
          const parentKey = findParentKey(filterOptions, value);
          if (parentKey) {
            newExpandedKeys.add(parentKey);
          }
          newExpandedKeys.add(value);
        });
      }

      setExpandedKeys([...newExpandedKeys]);
    }
  };

  const abbreviationMap = {
    'Contract Development and Manufacturing Organisation (CDMO)': 'CDMO',
    'Contract Research Organisation (CRO)': 'CRO',
  };

  const tagRender = props => {
    const { onClose, value } = props;

    const label = findLabel(filterOptions, value);

    const displayLabel = abbreviationMap[label] || label;

    return (
      <Token
        key={label}
        value={displayLabel}
        removeToken={onClose}
        style={{ marginTop: '2px', marginBottom: '2px' }}
        onMouseDown={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
      />
    );
  };

  return (
    <OrgCategorySection>
      <span>Category</span>
      <OrgCategoryContainer>
        <Tooltip
          title={renderTooltipContent()}
          placement={'topRight'}
          open={tooltipVisible}
        >
          <Filter
            allowClear={false}
            style={{ width: '100%' }}
            size='medium'
            maxTagCount={'responsive'}
            filterType='treeSelect'
            placeholder='Select organisation categories'
            filterId={organisationCategory}
            activeOnly={activeOnly}
            treeData={filterOptions}
            selectedValues={selectedValues}
            tagRender={tagRender}
            multiple
            treeCheckable={true}
            showSearch={false}
            onChange={handleOrgCategoryChange}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTreeExpand={onExpand}
            onDropdownVisibleChange={onDropdownVisibleChange}
            treeExpandedKeys={expandedKeys}
            disabled={loading}
          />
        </Tooltip>
      </OrgCategoryContainer>
      <AiIcon>
        We are using AI and human ingenuity to better classify scientific
        companies.
      </AiIcon>
    </OrgCategorySection>
  );
};

export default OrgCategory;
