import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProgressBar from './ProgressBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProgressBarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

function StatsProgress({
  showAcademicCredits,
  academicCreditsUsed,
  academicCreditsRequired,
  academicCreditsTotal,
  showIndustryCredits,
  industryCreditsUsed,
  industryCreditsRequired,
  industryCreditsTotal,
  blockTimeout,
  renewDays,
}) {
  if (!showAcademicCredits && !showIndustryCredits) {
    return null;
  }

  return (
    <Container>
      <ProgressBarsContainer>
        {showAcademicCredits && (
          <ProgressBar
            title='Academic'
            icon='book'
            used={academicCreditsUsed}
            required={academicCreditsRequired}
            total={academicCreditsTotal}
            renewDays={renewDays}
            blockTimeout={blockTimeout}
          />
        )}
        {showIndustryCredits && (
          <ProgressBar
            title='Biopharma'
            icon='company'
            used={industryCreditsUsed}
            required={industryCreditsRequired}
            total={industryCreditsTotal}
            renewDays={renewDays}
            blockTimeout={blockTimeout}
          />
        )}
      </ProgressBarsContainer>
    </Container>
  );
}

StatsProgress.propTypes = {
  showAcademicCredits: PropTypes.bool,
  academicCreditsUsed: PropTypes.number,
  academicCreditsRequired: PropTypes.number,
  academicCreditsTotal: PropTypes.number,
  showIndustryCredits: PropTypes.bool,
  industryCreditsUsed: PropTypes.number,
  industryCreditsRequired: PropTypes.number,
  industryCreditsTotal: PropTypes.number,
  period: PropTypes.string,
};

export default StatsProgress;
