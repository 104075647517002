//@ts-check
import dayjs from 'dayjs';
import React from 'react';
import { format } from 'utils/date';
import { siNumber } from 'utils/number';
import { HighlightContainer } from '../Funding/hightlights';

/**
 * @param {{
 * organisation: import('../Funding/index').OrganisationVCFundingDetails
 * }} props
 */
export default function AcquisitionHightlight({ organisation }) {
  const acquisitions =
    organisation.superOrganisationAcquisitions
      ?.filter(x => x.acquisitionMade)
      .sort((a, b) => dayjs(b.acquiredOn).diff(dayjs(a.acquiredOn))) || [];
  let acquisitionHighlight = '';
  if (acquisitions.length) {
    acquisitionHighlight += `${organisation.organisationName} has acquired ${acquisitions.length} organisations. `;
    acquisitionHighlight += `Their most recent acquisition was ${
      acquisitions[0].organisationName
    } on ${format(acquisitions[0].acquiredOn)}. `;
    if (acquisitions[0].priceUsd) {
      acquisitionHighlight += `They acquired ${
        acquisitions[0].organisationName
      } for $${siNumber(acquisitions[0].priceUsd)}. `;
    }
  }

  const acquiredBy =
    organisation.superOrganisationAcquisitions
      ?.filter(x => !x.acquisitionMade)
      .sort((a, b) => dayjs(b.acquiredOn).diff(dayjs(a.acquiredOn))) || [];

  let acquiredHightlight = '';
  for (let aq of acquiredBy) {
    acquiredHightlight += `${
      aq.organisationName
    } completed ${aq.acquisitionType || 'acquisition'} of ${
      organisation.organisationName
    } on ${format(aq.acquiredOn)}`;
    if (aq.priceUsd) {
      acquiredHightlight += ` for $${siNumber(aq.priceUsd)}`;
    }
    acquiredHightlight += '.';
  }

  return (
    <HighlightContainer>
      <div>{acquiredHightlight}</div>
      <div>{acquisitionHighlight}</div>
    </HighlightContainer>
  );
}
