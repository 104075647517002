import { format } from 'utils/date';
import { stateFormatter } from './search';

// Generic formatters
const capitalise = str => `${str[0].toUpperCase()}${str.slice(1)}`;
const quotesOr = value => value.map(v => `"${v}"`).join(' OR ');

const defaultQuotes = (key, value) =>
  value.length ? `${key}:(${quotesOr(value)})` : '';

const dateRange = (key, value) => {
  const from = format(value[0], 'YYYY-MM-DD');
  const to = format(value[1], 'YYYY-MM-DD');

  return `${key}:[${from} TO ${to}]`;
};

const priceRange = (key, value, filters) => {
  if (filters.minPrice && filters.maxPrice) {
    if (key !== 'MinPrice') return '';
    return `Price:[${filters.minPrice} TO ${filters.maxPrice}]`;
  }
  if (filters.minPrice) {
    return `Price:>=${filters.minPrice}`;
  }
  if (filters.maxPrice) {
    return `Price:<=${filters.maxPrice}`;
  }
  return '';
};

const formatCategory = (key, value) => {
  if (!value) return '';
  var values = value.map(v => v);

  const formattedCategories = values.map(category => {
    return `"${category}"`;
  });

  const formattedCategoriesWithOr = formattedCategories.join(' OR ');

  return `Category:(${formattedCategoriesWithOr})`;
};

const formatter = {
  default: defaultQuotes,
  date: dateRange,
  minPrice: priceRange,
  maxPrice: priceRange,
  hasPrice: (_, value) => (value ? 'Price:(>0)' : ''),
  purchaserState: stateFormatter,
  purchaserType: formatCategory,
};

export const noValue = value =>
  !value ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'object' &&
    Object.values(value).filter(v => !!v).length === 0);

export const buildQuery = filters => {
  let _filters = { ...filters };
  const parsed = Object.entries(filters).reduce((acc, [key, value]) => {
    const skip = ['term'];
    if (skip.includes(key) || noValue(value)) {
      if (!_filters.term) _filters.term = '*';
      return acc;
    }

    const format = formatter[key] || formatter.default;

    return [...acc, format(capitalise(key), value, _filters)];
  }, []);

  if (!_filters.term) _filters.term = '(*)';
  const termWithoutWeirdQuotes = _filters.term
    .replace(/“/g, '"')
    .replace(/”/g, '"');

  return [`(${termWithoutWeirdQuotes})`, ...parsed]
    .filter(v => !!v)
    .join(' AND ');
};
