import { useSelector } from 'react-redux';
import {
  getActiveList,
  getAreAllMembersSelected,
  getExportExtraParams,
  getSelectedMemberIds,
  getSelectedMembers,
} from 'selectors/lists';

function useExport() {
  const activeList = useSelector(getActiveList);
  const selectedMemberIds = useSelector(getSelectedMemberIds);
  const selectedMembers = useSelector(getSelectedMembers);
  const allSelected = useSelector(getAreAllMembersSelected);
  const extraParams = useSelector(getExportExtraParams);

  const getExportBody = () => {
    if (allSelected) {
      return {
        listId: activeList?.id,
        ...(extraParams ?? {}),
      };
    }

    if (selectedMembers.all) {
      return {
        listId: activeList?.id,
        excludedFromAllMemberIds: Object.keys(selectedMembers.exclude).map(
          x => +x
        ),
        excludedFromAllResearcherIds: Object.keys(selectedMembers.exclude),
        ...(extraParams ?? {}),
      };
    }

    return {
      listId: activeList?.id,
      researcherIds: selectedMemberIds,
      memberIds: selectedMemberIds,
      ...(extraParams ?? {}),
    };
  };

  return getExportBody;
}

export default useExport;