import React from 'react';

const AlertProcessingContent = () => {
  return (
    <div>
      Working with lists can take time. We'll notify you when processing has
      finished.
    </div>
  );
};

export default AlertProcessingContent;
