import { useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxGl from '!mapbox-gl';

import Popup from './popup';

const usePopup = map => {
  const popUpRef = useRef(
    new mapboxGl.Popup({ closeButton: false, maxWidth: 250 })
  );
  const popupNode = useRef(null);
  useEffect(() => {
    popupNode.current = document.createElement('div');
  }, []);

  const show = useCallback(
    (lngLat, features, radius) => {
      if (features) {
        ReactDOM.render(
          <Popup
            features={features
              .map(x => x.properties)
              .sort((a, b) => b.value - a.value)}
          />,
          popupNode.current
        );
        popUpRef.current
          .setOffset(radius)
          .setLngLat(lngLat)
          .setDOMContent(popupNode.current)
          .addTo(map);
      }
    },
    [map]
  );

  const close = useCallback(() => {
    ReactDOM.unmountComponentAtNode(popupNode.current);
    popUpRef.current.remove();
  }, []);

  return [show, close];
};

export default usePopup;
