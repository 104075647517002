import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { formatNumber } from 'utils/number';
import { HelpTooltip } from 'components/common';
import { appendFilter } from 'actions/search';

import { StyledTable, TableContainer } from '../styled';
import { GraphLabel } from './GraphBase/styled';

const Journals = ({ data }) => {
  const dispatch = useDispatch();

  const handleClick = journalName => {
    dispatch(appendFilter('journal', journalName));
  };

  if (!data) return null;

  const journalConfig = {
    headers: [
      {
        label: <div style={{ marginLeft: 'auto' }}>Rank</div>,
        key: 'rank',
        testId: 'rank',
      },
      { label: 'Journal', key: 'journalName' },
      {
        label: <div style={{ marginLeft: 'auto' }}>Count</div>,
        key: 'count',
        testId: 'count',
      },
    ],

    createRow: (r, i) => {
      const backgroundColor =
        r.rank % 2 === 0 ? 'hsla(15, 100%, 59%, 0.04)' : '#ffffff';
      return (
        <Fragment key={`${r.journal}-${i}`}>
          <div
            style={{
              backgroundColor,
              textAlign: 'right',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => handleClick(r.key)}
          >
            {r.rank}
          </div>
          <div
            style={{ backgroundColor, cursor: 'pointer' }}
            onClick={() => handleClick(r.key)}
          >
            {r.key}
          </div>
          <div
            style={{
              backgroundColor,
              textAlign: 'right',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => handleClick(r.key)}
          >
            {formatNumber(r.value)}
          </div>
        </Fragment>
      );
    },
  };

  return (
    <TableContainer>
      <GraphLabel>
        Journals
        <HelpTooltip>
          Journals ranked by their occurrence in your search
        </HelpTooltip>
      </GraphLabel>
      <StyledTable config={journalConfig} data={data} />
    </TableContainer>
  );
};

export default Journals;
