import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

const CTAContainer = styled.div`
  width: 395px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px 20px;
  border: 2px solid #ed5520;
  a {
    color: #ed5520;
    :active,
    :hover {
      color: #ed5520;
      opacity: 0.85;
    }
    text-decoration: underline;
  }
`;

const CTA = ({ style }) => {
  return (
    <CTAContainer style={style}>
      <Text>
        Want to improve your lead generation? Try SciLeads and see how it can
        help you find and connect with the right scientific leads.&nbsp;
        <a
          href='https://scileads.com/get-started/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Sign up for a free trial now!
        </a>
      </Text>
    </CTAContainer>
  );
};

export default CTA;
