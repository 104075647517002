import { useEffect, useState } from 'react';

import { Detail, Switch } from 'components/common';
import { usePost } from 'hooks';
import { toggleAutoRenew } from 'services/api';

const LicenseRenew = ({ enabled, autoRenew }) => {
  const [{ res, loading }, autoRenewToggle] = usePost({ url: toggleAutoRenew });
  const [autoRenewEnabled, setAutoRenewEnabled] = useState(autoRenew);

  useEffect(() => {
    if (res?.status === 'OK') {
      setAutoRenewEnabled(a => !a);
    }
  }, [res]);

  const handleToggleAutoRenew = () => {
    autoRenewToggle({ enabled: !autoRenewEnabled });
  };

  return (
    <Detail label='License Auto-Renew'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Switch
          disabled={loading || !enabled}
          size='small'
          onChange={handleToggleAutoRenew}
          checked={autoRenewEnabled}
          label={autoRenewEnabled ? 'Enabled' : 'Disabled'}
          reverseLabel
        />
      </div>
    </Detail>
  );
};

export default LicenseRenew;
