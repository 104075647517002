import { forwardRef } from 'react';
import { Button as AntButton } from 'antd';

const Button = forwardRef((props, ref) => {
  return <AntButton {...props} ref={ref} />;
});

Button.defaultProps = {
  'data-testid': 'button',
};

export default Button;
