import { useEffect } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { usePatch } from 'hooks';
import { manageMembers } from 'services/api';
import {
  getActiveList,
  getSelectedMemberIds,
  getAreAllMembersSelected,
  getSelectedMembers,
  getView,
} from 'selectors/lists';
import { clearSelectedMembers } from 'actions/lists';

const RemoveFrom = ({ onClose }) => {
  const dispatch = useDispatch();
  const activeList = useSelector(getActiveList);
  const selectedMemberIds = useSelector(getSelectedMemberIds);
  const selectedMembers = useSelector(getSelectedMembers);
  const allSelected = useSelector(getAreAllMembersSelected);
  const view = useSelector(getView);
  const [{ res: removeRes, loading }, patchMembers] = usePatch({
    url: manageMembers(activeList.id, view),
  });

  useEffect(() => {
    if (removeRes) {
      dispatch(clearSelectedMembers());
      onClose({ fetchMembers: true });
    }
  }, [dispatch, removeRes, onClose]);

  const handleRemove = () => {
    if (allSelected) {
      patchMembers({ shouldRemove: true });
      return;
    }

    if (selectedMembers.all) {
      patchMembers({
        shouldRemove: true,
        excludedFromAllResearcherIds: Object.keys(selectedMembers.exclude),
        ExcludedFromAllMemberIds: Object.keys(selectedMembers.exclude).map(
          x => +x
        ),
      });
    } else {
      patchMembers({
        shouldRemove: true,
        researcherIds: selectedMemberIds,
        memberIds: selectedMemberIds,
      });
    }
  };

  const allOrSelectedText = allSelected ? 'all' : 'the selected';

  return (
    <Modal
      open
      title={`Remove from ${activeList.name}`}
      onCancel={onClose}
      okText='Yes'
      onOk={handleRemove}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      Would you like to remove {allOrSelectedText} members from{' '}
      {activeList.name}?
    </Modal>
  );
};

export default RemoveFrom;
