import { useEffect } from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { usePost } from 'hooks';
import { Button } from 'components/common';
import { log } from 'services/api';

const Container = styled.div`
  text-align: center;
  margin: 150px auto;
  max-width: 400px;
`;

const TopLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Code = styled.div`
  font-weight: bolder;
  font-size: 72px;
`;

const Info = styled.div`
  font-size: 16px;
`;

const FourOhFour = ({ version }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { from, download } = qs.parse(search);

  const [, postMissingRoute] = usePost({
    url: log,
  });

  useEffect(() => {
    if (from) {
      postMissingRoute({
        description: `404 - Route ${from} - v${version}`,
        action: 'UIError',
        outcome: 'Failure',
      });
    }
    if (download) {
      postMissingRoute({
        description: `Expired Download - ${download}`,
        action: 'ExpiredDownload',
        outcome: 'Failure',
      });
    }
  }, [download, from, postMissingRoute, version]);

  return (
    <Container>
      <TopLine>
        <Code>Whoops!</Code>
      </TopLine>
      <Info>
        Oh no, you seem to have taken a wrong turn.
        <br />
        <br />
        If you think there should have been something here, please reach out to
        us via live chat.
        <br />
        <br />
        {download && (
          <>
            Please note that download links expire after 30 days.
            <br />
            <br />
          </>
        )}
      </Info>
      <Button type='secondary' onClick={() => push('/')}>
        Go home
      </Button>
    </Container>
  );
};

export default FourOhFour;
