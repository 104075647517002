import { Fragment, useState, useEffect, useMemo } from 'react';
import { Modal } from 'antd';

import {
  Link,
  Loader,
  TabbedPanel,
  Clamp,
  Select,
  PublicationsFooter,
  PublicationAuthors,
  FundingFooter,
} from 'components/common';
import { format } from 'utils/date';
import { formatCurrency } from 'utils/number';
import { usePatch, useAuth } from 'hooks';
import { manageMembers } from 'services/api';

import { renderLink } from './helpers';
import {
  NotificationFailure,
  NotificationSuccess,
} from 'components/Lists/Notifications';
import { LABEL_BUTTON_ADD_NEW_LIST } from 'components/common/labelConstants';
import licenseType from 'models/licenseType';
import { ConstrainedTable } from './styled';
import Export from 'components/Export';
import { downloadType } from 'components/Export/Summary/constants';

const perPage = [
  { value: 5, label: '5 per page' },
  { value: 10, label: '10 per page' },
  { value: 25, label: '25 per page' },
  { value: 50, label: '50 per page' },
];

const Work = ({
  loading,
  researcher,
  editableLists,
  loadingLists,
  onExportNetwork,
  exportingNetwork,
  onCreateNewItemList,
  view,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [listToAdd, setListToAdd] = useState({});
  const { hasLicense, user } = useAuth();
  const hasOrgLicense = useMemo(
    () => hasLicense([licenseType.industry, licenseType.academic], true),
    [hasLicense]
  );

  const configs = {
    funding: {
      headers: [
        { label: 'Funded Project Title', minWidth: 200, maxContentWidth: 350 },
        { label: 'Date Awarded', minWidth: 120 },
        { label: 'Funding (USD)', minWidth: 120 },
        { label: 'Funding Starts', minWidth: 120 },
        { label: 'Funding Ends', minWidth: 120 },
        { label: 'Description', minWidth: 200, maxContentWidth: 400 },
      ],
      createRow: (r, i) => (
        <Fragment key={`${r.id}`}>
          <div>
            <Link to={`/project/${r.id}`}>
              <Clamp text={r.projectTitle} />
            </Link>
          </div>
          <div>{format(r.projectAwardDate)}</div>
          <div style={{ justifyContent: 'flex-end' }}>
            {r.projectFundingAmountUsd
              ? `$${formatCurrency(r.projectFundingAmountUsd)}`
              : ''}
          </div>
          <div>{format(r.projectStartDate)}</div>
          <div>{format(r.projectEndDate)}</div>
          <div>
            <Clamp lines={2} text={r.projectDescription} />
          </div>
        </Fragment>
      ),
    },
    posters: {
      headers: [
        { label: 'Tradeshow', minWidth: 180, maxContentWidth: 350 },
        { label: 'Year', minWidth: 40 },
        { label: 'Title', minWidth: 300, maxContentWidth: 500 },
        { label: 'Session Type', minWidth: 80 },
      ],
      createRow: (r, i) => (
        <Fragment key={`${r.tradeShowId}-${r.posterId}-${i}`}>
          <div>{r.name}</div>
          <div>{r.year}</div>
          <div>
            <Link to={`/poster/${r.posterId}`}>{r.title}</Link>
          </div>
          <div>{r.sessionType !== 'Uncategorised' ? r.sessionType : '-'}</div>
        </Fragment>
      ),
    },
    trials: {
      headers: [
        { label: 'NCT ID', minWidth: 100 },
        { label: 'Role', minWidth: 100 },
        { label: 'First Posted', minWidth: 100 },
        { label: 'Study Type', minWidth: 80 },
        { label: 'Title', minWidth: 250, maxContentWidth: 350 },
        { label: 'Status', minWidth: 80 },
        { label: 'Phase', minWidth: 80 },
        { label: 'Sponsor', minWidth: 100 },
      ],
      createRow: (r, i) => (
        <Fragment key={r.nctId}>
          <div>
            <Link to={`/trial/${r.nctId}`}>{r.nctId}</Link>
          </div>
          <div>{r.researcherRole || 'Other'}</div>
          <div>{format(r.studyFirstPostedDate)}</div>
          <div>{r.studyType}</div>
          <Clamp text={r.briefTitle} lines={2} />
          <div>{r.overallStatus}</div>
          <div>{r.phase}</div>
          {!hasOrgLicense && <div>{r.leadSponsor}</div>}
          {hasOrgLicense && r.leadSponsorSuperOrg && (
            <Link to={`/organisation/${r.leadSponsorSuperOrg}`}>
              {r.leadSponsor}
            </Link>
          )}
          {hasOrgLicense && !r.leadSponsorSuperOrg && (
            <div>{r.leadSponsor}</div>
          )}
        </Fragment>
      ),
    },
    publications: {
      headers: [
        { label: 'Publication Title', minWidth: 150, maxContentWidth: 450 },
        { label: 'Authors', minWidth: 100 },
        { label: 'Journal/Preprints', minWidth: 150, maxContentWidth: 350 },
        { label: 'Date Published', minWidth: 100 },
        { label: 'Citations', minWidth: 50 },
      ],
      createRow: (r, i) => (
        <Fragment key={`${r.citationId}-${i}`}>
          <div>
            <Link to={`/publication/${r.citationId}`}>
              <Clamp text={r.title} />
            </Link>
          </div>
          <PublicationAuthors
            authors={r.authors}
            authorCount={r.authors.length}
          />
          <Clamp text={r.journal} />
          <div>{format(r.publishedDate)}</div>
          <div>{r.cited}</div>
        </Fragment>
      ),
    },
    network: {
      headers: [
        { label: 'Name', key: 'name', minWidth: 100 },
        { label: 'Collaborations', key: 'collabCount', minWidth: 100 },
        { label: 'Last Collaboration', key: 'lastCollab', minWidth: 100 },
        { label: 'Organisation', key: 'organisation', minWidth: 150 },
        { label: 'Location', key: 'organisationLocation', minWidth: 200 },
      ],
      createRow: (r, i) => (
        <Fragment key={`${r.title}-${i}`}>
          {renderLink(r)}
          <div>{r.collabCount}</div>
          <div>{format(r.lastCollab)}</div>
          <div>{r.organisation || '-'}</div>
          <div>{r.organisationLocation}</div>
        </Fragment>
      ),
    },
  };

  const [
    {
      res: addRes,
      loading: addingResearchers,
      error: addingMembersToListError,
    },
    patchMembers,
  ] = usePatch({});

  useEffect(() => {
    setShowModal(false);
    if (addRes) {
      NotificationSuccess();
    }

    if (addingMembersToListError && addingMembersToListError.status === 400) {
      NotificationFailure(addingMembersToListError.message);
    }
  }, [addRes, addingMembersToListError]);

  if (loading) return <Loader />;

  const {
    projects,
    tradeShows,
    publications,
    clinicalTrials,
    collaborationTable,
    researcherProfile,
    overViewStats = {},
  } = researcher;

  const {
    overview: {
      totalFirstAuthorCount,
      totalLastAuthorCount,
      totalCollaborators,
    },
  } = overViewStats;
  const { allTimeHIndexAverage, threeYearHIndexAverage } = researcherProfile;

  const handleAddAll = id => {
    if (id === 'no_lists') return;
    setShowModal(true);
    setListToAdd(editableLists.find(l => l.value === id));
  };

  const editableListOptions =
    editableLists && editableLists.length === 0
      ? [{ label: 'No lists available', value: 'no_lists' }]
      : editableLists;

  const handleOk = () => {
    patchMembers({
      url: manageMembers(listToAdd.value),
      body: {
        researcherIds: collaborationTable
          .filter(x => x.researcherId)
          .map(x => x.researcherId),
      },
    });
  };

  const config = {};

  if (projects.length) {
    config.funding = {
      icon: 'award',
      text: 'Funding',
      content: (
        <ConstrainedTable
          key='funding-table'
          config={configs.funding}
          data={projects}
          pageSizeOptions={perPage}
          footerInfo={
            <FundingFooter
              allTime={researcherProfile.allTimeTotalFunding}
              threeYear={researcherProfile.threeYearTotalFunding}
            />
          }
          paginate
        />
      ),
    };
  }
  if (tradeShows.length) {
    config.posters = {
      icon: 'image',
      text: 'Posters',
      content: (
        <ConstrainedTable
          key='poster-table'
          config={configs.posters}
          data={tradeShows}
          pageSizeOptions={perPage}
          paginate
        />
      ),
    };
  }
  if (publications.length) {
    config.publications = {
      icon: 'book',
      text: 'Publications',
      content: (
        <ConstrainedTable
          key='publication-table'
          config={configs.publications}
          data={publications}
          pageSizeOptions={perPage}
          footerInfo={
            <PublicationsFooter
              firstNamedCount={totalFirstAuthorCount}
              lastNamedCount={totalLastAuthorCount}
              totalCollabs={totalCollaborators}
              impactAll={allTimeHIndexAverage}
              impactThree={threeYearHIndexAverage}
            />
          }
          paginate
        />
      ),
    };
  }
  if (clinicalTrials.length) {
    config.clinicalTrials = {
      icon: 'trial',
      text: 'Trials',
      content: (
        <ConstrainedTable
          key='trial-table'
          config={configs.trials}
          data={clinicalTrials}
          pageSizeOptions={perPage}
          paginate
        />
      ),
    };
  }
  if (collaborationTable.length) {
    config.network = {
      icon: 'network',
      text: 'Network',
      content: (
        <ConstrainedTable
          key='network-table'
          config={configs.network}
          data={collaborationTable}
          pageSizeOptions={perPage}
          footerActions={
            <div>
              <Select
                value='Add All to List'
                placeholder='Add All to List'
                onCreateNewItem={() => onCreateNewItemList(collaborationTable)}
                buttonText={LABEL_BUTTON_ADD_NEW_LIST}
                size='small'
                disabled={!editableLists || loadingLists}
                options={editableListOptions}
                onChange={handleAddAll}
                dropdownStyle={{ minWidth: '225px' }}
                style={{ marginLeft: 10, width: 125 }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                disableTooltip
              />
              {!user.isRateLimited && !user.disableExport && (
                <Export
                  downloadType={downloadType.network}
                  getQueryBody={() => ({
                    researcherId: researcher?.researcherProfile?.id,
                  })}
                  disabled={exportingNetwork}
                  onExport={onExportNetwork}
                  style={{ marginLeft: 10 }}
                />
              )}
            </div>
          }
          paginate
          sortable
        />
      ),
    };
  }

  if (config && Object.keys(config).length === 0)
    return <p>No Work Items available</p>;

  return (
    <>
      <TabbedPanel horizontal config={config} view={view} />
      <Modal
        open={showModal}
        title={`Add Researcher Network To ${listToAdd.label}`}
        onCancel={() => setShowModal(false)}
        onOk={handleOk}
        okButtonProps={{ disabled: addingResearchers }}
        cancelButtonProps={{ disabled: addingResearchers }}
      >
        <p>
          Would you like to add all researchers in this network to{' '}
          {listToAdd.label}?
          <br />
          <br />
          Please note, only researchers with a SciLeads profile will be added.
        </p>
      </Modal>
    </>
  );
};

export default Work;
