import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useAuth } from 'hooks';

import { getActiveFilters } from 'selectors/search';
import { lists, organisationLists } from 'services/inputTokens';
import licenseType from 'models/licenseType';

import { Filter } from '../helpers';
import FilterGroup from './FilterGroup';
import { listsKeys } from './keys';
import { FilterSection } from './styled';
import { tagRender } from 'components/common/TagRender';

const Lists = ({ loading }) => {
  const { hasLicense } = useAuth();
  const hasOrgFilters = useMemo(
    () => hasLicense([licenseType.industry, licenseType.academic], true),
    [hasLicense]
  );

  const activeFilters = useSelector(getActiveFilters(Object.keys(listsKeys)));

  return (
    <FilterGroup
      icon='list'
      title='Lists'
      activeKeys={activeFilters}
      sectionKey='lists'
    >
      <>
        <FilterSection style={{ borderTop: 'none' }}>
          Researcher Lists
        </FilterSection>
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          tagRender={tagRender}
          filterId={listsKeys.lists.key}
          placeholder='Included'
          options={lists}
          helpText='Results will be limited to researchers present in these lists'
          disabled={loading}
          remoteSearchOnClick
          optionProp={{ optionLabel: 'name', optionValue: 'id' }}
        />
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          tagRender={tagRender}
          filterId={listsKeys.excludeLists.key}
          placeholder='Excluded'
          options={lists}
          helpText='Researchers present in these lists will be excluded from results'
          disabled={loading}
          remoteSearchOnClick
          optionProp={{ optionLabel: 'name', optionValue: 'id' }}
        />
        {hasOrgFilters && (
          <>
            <FilterSection>Organisation Lists</FilterSection>
            <Filter
              filterType='select'
              mode={'multiple'}
              style={{ width: '100%' }}
              tagRender={tagRender}
              filterId={listsKeys.organisationLists.key}
              placeholder='Included'
              options={organisationLists}
              helpText='Results will be limited to organisations present in these lists'
              disabled={loading}
              remoteSearchOnClick
              optionProp={{ optionLabel: 'name', optionValue: 'id' }}
            />
            <Filter
              filterType='select'
              mode={'multiple'}
              style={{ width: '100%' }}
              tagRender={tagRender}
              filterId={listsKeys.organisationExcludeLists.key}
              placeholder='Excluded'
              options={organisationLists}
              helpText='Organisations present in these lists will be excluded from results'
              disabled={loading}
              remoteSearchOnClick
              optionProp={{ optionLabel: 'name', optionValue: 'id' }}
            />
          </>
        )}
      </>
    </FilterGroup>
  );
};

Lists.propTypes = {
  loading: PropTypes.bool,
};

export default Lists;
