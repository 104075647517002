import { useState, useEffect } from 'react';

import { Pane, Loader, Input } from 'components/common';
import { allUsers } from 'services/api';
import { useGet } from 'hooks';

import Actions from './Actions';
import Table from './Table';

import LicenseAllocation from './LicenseAllocation';
import { ActionsContainer } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { getShouldReload } from 'selectors/users';
import { reloadUsers } from 'actions/users';
import { licenseTypeToStringMap } from 'models/licenseType';
import Modal from './Modals';

const filterUser = (term, user) =>
  user.email.toLowerCase().includes(term.toLowerCase()) ||
  user.fullname.toLowerCase().includes(term.toLowerCase()) ||
  user?.licenses?.some(x =>
    licenseTypeToStringMap[x.type].toLowerCase().includes(term.toLowerCase())
  );

const Users = () => {
  const shouldReload = useSelector(getShouldReload);
  const [showInactive, setShowInactive] = useState(true);
  const [filterTerm, setFilterTerm] = useState('');
  const [modal, setModal] = useState('');
  const [activeUser, setActiveUser] = useState(null);
  const [{ res, loading }, fetchUsers] = useGet({
    url: allUsers,
    onMount: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldReload) {
      dispatch(reloadUsers(false));
      fetchUsers();
    }
  }, [fetchUsers, shouldReload, dispatch]);

  if (loading) {
    return (
      <Pane title='User Management'>
        <Loader />
      </Pane>
    );
  }

  const setupModal = (modal, user) => {
    setActiveUser(user);
    setModal(modal);
  };

  const {
    userAccounts,
    licenseAllocation,
    creditsEnabled,
    enterpriseAccount,
    industryShowEmailCreditsToUsers,
    academicShowEmailCreditsToUsers,
  } = res;
  const filteredUsers = userAccounts.reduce((acc, user) => {
    if (!showInactive && (!user.enabled || user.isLicenseExpired)) return acc;
    user.userLicense = user.licenses
      ?.filter(x => x.expiration && !x.demoMode)
      ?.map(x => licenseTypeToStringMap[x.type])
      ?.join(', ');
    if (!filterTerm) return [...acc, user];
    if (filterUser(filterTerm, user)) {
      return [...acc, user];
    }
    return acc;
  }, []);

  const canAdd = licenseAllocation?.licenses?.some(x => x.available > 0);

  return (
    <Pane title='User Management'>
      <LicenseAllocation
        licenses={licenseAllocation?.licenses ?? []}
        integrations={licenseAllocation?.integrations ?? []}
      />
      <ActionsContainer>
        <Input
          value={filterTerm}
          onChange={setFilterTerm}
          placeholder='Filter'
          style={{ marginTop: 10, marginRight: 10 }}
        />
        <Actions
          showInactive={showInactive}
          setShowInactive={setShowInactive}
          setModal={setupModal}
          canAdd={canAdd}
          style={{ marginTop: 10 }}
        />
      </ActionsContainer>
      <Table
        style={{ cellPadding: '5px' }}
        data={filteredUsers}
        creditsEnabled={creditsEnabled}
        industryShowEmailCreditsToUsers={industryShowEmailCreditsToUsers}
        academicShowEmailCreditsToUsers={academicShowEmailCreditsToUsers}
        setModal={setupModal}
      />
      {modal && (
        <Modal
          modal={modal}
          activeUser={activeUser}
          setModal={setModal}
          fetchUsers={fetchUsers}
          licenseAllocation={licenseAllocation}
          enterpriseAccount={enterpriseAccount}
        />
      )}
    </Pane>
  );
};

export default Users;
