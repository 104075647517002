import { useCallback, useEffect } from 'react';
import qs from 'query-string';

import useXHR from './useXHR';

const useDel = ({ url, query, onMount }) => {
  const [state, req, , , setOriginalRequest] = useXHR(onMount);
  const queryString = query ? qs.stringify(query) : null;

  const send = useCallback(
    ({ url: newUrl, query: newQuery } = {}) => {
      const latestUrl = newUrl || url;
      const latestQuery = newQuery ? qs.stringify(newQuery) : queryString;

      const urlToOpen = latestQuery ? `${latestUrl}?${latestQuery}` : latestUrl;

      setOriginalRequest({
        method: 'DELETE',
        url: latestUrl,
      });
      req.open('DELETE', urlToOpen);
      const token = localStorage.getItem('token');
      if (token) {
        req.setRequestHeader('Authorization', `Bearer ${token}`);
      }
      req.send();
    },
    [url, queryString, req, setOriginalRequest]
  );

  useEffect(() => {
    if (onMount) {
      send();
    }
    return () => req.abort();
  }, [onMount, send, req]);

  return [state, send];
};

export default useDel;
