import { Button } from 'antd';
import { Switch as CommonSwitch, Select, Table } from 'components/common';

import styled from 'styled-components';

export const ActionButton = styled(Button).attrs(p => ({
  style: {
    width: `${p.autoWidth ? 'auto' : '130px'}`,
  },
}))`
  height: 25px;
`;

export const StatsContainer = styled.div`
  display: flex;
  width: 250px;
  margin-left: 15px;
`;

export const StatusButton = styled.div`
  display: flex;
  background: ${p => (p.background ? p.background : 'white')};
  color: ${p => (p.color ? p.color : 'white')};
  align-items: center;
  justify-content: center;
  border: none;
  width: 100px;
  height: 25px;
  border-radius: 2px;
`;

export const HistorySelect = styled(Select)`
  flex: 1;
`;

export const ActionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FiltersRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin-right: -15px;
`;

export const FilterItem = styled.div`
  display: flex;
  margin-bottom: 15px;
  margin-right: 15px;
  flex: 1;
  max-width: 300px;
  min-width: 200px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ModalRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const ModalValue = styled.div`
  margin-right: 10px;
`;

export const ExportHistoryTable = styled(Table)`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;

export const Switch = styled(CommonSwitch)`
  font-size: 13px;
`;
