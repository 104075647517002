import { useDispatch } from 'react-redux';

import { siNumber } from 'utils/number';
import { appendFilter } from 'actions/search';

import GraphBase from './GraphBase';
import styled from 'styled-components';
import { geoResearcherKeys } from 'components/Search/Filters/keys';

const PaddedGraphBase = styled(GraphBase)`
  padding-left: 45px;
`;

const Regions = ({ data, label, helpText }) => {
  const dispatch = useDispatch();

  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function(value) {
              return siNumber(value, 1);
            },
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return siNumber(tooltipItem.xLabel);
        },
      },
    },
    aspectRatio: 4,
  };

  const handleClick = region => {
    dispatch(appendFilter(geoResearcherKeys.organisationGeography.key, region));
  };

  return (
    <PaddedGraphBase
      type='horizontalBar'
      label={label}
      data={data}
      options={options}
      fullWidth={true}
      onClick={handleClick}
      helpText={helpText}
    />
  );
};

export default Regions;
