import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getActiveFilters } from 'selectors/search';
import {
  phases,
  statuses,
  roles,
  sponsorsTypeahead,
  leadSponsorTypeahead,
} from 'services/inputTokens';

import { Filter } from '../helpers';
import FilterGroup from './FilterGroup';
import { trialKeys } from './keys';
import { tagRender } from 'components/common/TagRender';
import { renderOption } from 'components/common/OptionRender';

const Trials = ({ loading }) => {
  const activeFilters = useSelector(getActiveFilters(Object.keys(trialKeys)));
  const phaseFilterActive = activeFilters.includes('phase');

  return (
    <FilterGroup
      icon='trial'
      title='Clinical Trials'
      activeKeys={activeFilters}
      sectionKey='trials'
    >
      <>
        <Filter
          filterType='checkbox'
          filterId={trialKeys.hasClinicalTrials.key}
          disabled={loading}
          labelText='Listed on at least one clinical trial'
        />
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          placeholder='Phase'
          options={phases}
          filterId={trialKeys.phase.key}
          disabled={loading}
        />
        <Filter
          disabled={!phaseFilterActive || loading}
          filterType='datePicker'
          placeholder={['Phase Start From', 'Phase Start To']}
          type='range'
          filterId={trialKeys.phaseStartDate.key}
          horizontalTitle='Phase Start Date'
        />
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          placeholder='Status'
          options={statuses}
          filterId={trialKeys.overallStatus.key}
          disabled={loading}
        />
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          placeholder='Lead Sponsor'
          filterId={trialKeys.leadSponsor.key}
          options={leadSponsorTypeahead}
          disabled={loading}
        />
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          placeholder='All Sponsors'
          filterId={trialKeys.sponsors.key}
          options={sponsorsTypeahead}
          disabled={loading}
        />
        <Filter
          filterType='select'
          mode={'multiple'}
          style={{ width: '100%' }}
          renderOption={renderOption}
          tagRender={tagRender}
          placeholder='Role'
          options={roles}
          filterId={trialKeys.roles.key}
          disabled={loading}
        />
        <Filter
          filterType='datePicker'
          placeholder={['Trial Start From', 'Trial Start To']}
          type='range'
          filterId={trialKeys.startDate.key}
          disabled={loading}
        />
        <Filter
          filterType='number'
          filterId={trialKeys.minSponsoredTrials.key}
          placeholder='Min. Sponsored Trials'
          min={0}
          disabled={loading}
        />
      </>
    </FilterGroup>
  );
};

Trials.propTypes = {
  loading: PropTypes.bool,
};

export default Trials;
