import { types } from 'actions/users';

const defaultState = {
  reload: false,
  pageSize: 50,
  pageNumber: 0,
  showInactive: true,
};

const REDUCER = {
  [types.RELOAD_USERS]: (state, action) => {
    return {
      ...state,
      reload: action.payload.reload,
    };
  },
  [types.USERMANAGEMENT_PAGE_SIZE]: (state, action) => {
    return {
      ...state,
      pageSize: action.payload.pageSize,
    };
  },
  [types.USERMANAGEMENT_PAGE_NUMBER]: (state, action) => {
    return {
      ...state,
      pageNumber: action.payload.pageNumber,
    };
  },
  [types.USERMANAGEMENT_SHOW_INACTIVE]: (state, action) => {
    return {
      ...state,
      showInactive: action.payload.showInactive,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = REDUCER[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
