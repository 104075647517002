import { Link } from 'components/common';

export const renderLink = researcher => {
  if (researcher.researcherId > 0) {
    return (
      <Link to={`/researcher/${researcher.researcherId}`}>
        {researcher.name}
      </Link>
    );
  }
  return <div>{researcher.name}</div>;
};
